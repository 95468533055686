/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { CSSObject } from '@emotion/react';
import { TreatmentPlan } from '../../../../models/TreatmentPlan';
import DisplayField from '../../../commons/form/DisplayField';
import { AccordionView } from '../../../commons/DraggableAccordionView';
import AutomationLevelView from '../../../commons/AutomationLevelView';
import { Activity } from '../../../../models/Activity';
import { fonts } from '../../../../style/fonts';
import ActivityPreview from './ActivityPreview';
import { TreatmentPlanTemplate } from '../../../../models/TreatmentPlanTemplate';
import { isCameraSide } from '../TreatmentPlansUtils';

const activitySummaryCss: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  gap: 20,

  label: {
    ...fonts.largeLabel,
  },
};

const treatmentPlanDetailsCss: CSSObject = {
  padding: 20,

  '& > div.field-grouping:first-of-type': {
    marginBottom: '20px',
  },

  label: {
    marginLeft: '6px',
  },
};

function ActivitySummary({ activity }: { activity: Activity }) {
  const summary = `${activity?.activityDefinition?.plane} •
   ${activity?.activityDefinition?.position}`;
  const side = activity?.activityDefinition?.settings?.side;
  return (
    <div css={activitySummaryCss}>
      <label css={{ width: 170 }}>{activity.exerciseName}</label>
      <div css={{ ...fonts.text, width: 210 }}>{summary}</div>
      {side && (
        <div css={{ ...fonts.text, width: 210 }}>
          <label css={{ width: 170 }}>
            {isCameraSide(side) ? 'Facing Camera' : 'Body Side'}:{' '}
          </label>
          {activity?.side}
        </div>
      )}
      <div css={{ ...fonts.text, width: 90 }}>
        <AutomationLevelView
          automationLevel={activity?.activityDefinition?.automationLevel}
        />
      </div>
    </div>
  );
}

export default function TreatmentPlanPreview({
  treatmentPlan,
}: {
  treatmentPlan: TreatmentPlan | TreatmentPlanTemplate;
}) {
  const [expendedItemsIds, setExpendedItemsIds] = useState<string[]>([]);
  return (
    <div css={treatmentPlanDetailsCss}>
      <DisplayField label="Description" value={treatmentPlan.description} />
      <label css={{ ...fonts.largeLabel }}>Activites</label>
      <AccordionView
        items={treatmentPlan.activities}
        expendedItemsIds={expendedItemsIds}
        setExpendedItemsIds={setExpendedItemsIds}
        getAccordionSummary={(_, activity) => {
          return <ActivitySummary activity={activity} />;
        }}
        getAccordionDetails={(_, activity) => {
          return <ActivityPreview activity={activity} />;
        }}
      />
    </div>
  );
}
