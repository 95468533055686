import { User } from '../../models/User';
import { isEmpty } from '../../state-manager/utils/compare';

export function getFormattedName(user: User) {
  if (!isEmpty(user.firstName) && !isEmpty(user.lastName)) {
    return `${user.lastName}, ${user.firstName}`;
  }
  if (!isEmpty(user.firstName)) {
    return user.firstName;
  }
  return user.lastName;
}
