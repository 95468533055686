import { SelectChangeEvent } from '@mui/material';
import { usePDF } from '@react-pdf/renderer';
import { format } from 'date-fns';
import React, { JSXElementConstructor, useEffect, useState } from 'react';

function triggerDownload(url: string, title: string) {
  const documentAnchor = document.createElement('a');
  documentAnchor.href = url;
  documentAnchor.download = title + '.pdf';
  documentAnchor.click();
}

export default function usePdfExport(
  patientName: string | undefined,
  additionalDependency?: string,
) {
  const [pdfInstance, updatePdfInstance] = usePDF();

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (isDownloading && pdfInstance.url && patientName) {
      triggerDownload(
        pdfInstance.url,
        `${patientName}-${format(new Date(), 'MM-dd-yyy')}`,
      );
      setIsDownloading(false);
    }
  }, [pdfInstance.url, patientName, additionalDependency]);

  const initiatePdfDownload = (
    e: React.MouseEvent<HTMLElement> | SelectChangeEvent,
    DocumentComponent: JSXElementConstructor<any> | undefined,
    documentProps?: any,
  ) => {
    if (!DocumentComponent || !document) {
      return;
    }
    e.stopPropagation();

    if (patientName) {
      setIsDownloading(true);
      updatePdfInstance(<DocumentComponent {...documentProps} />);
    }
  };

  return { initiatePdfDownload, isDownloading };
}
