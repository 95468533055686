/** @jsxImportSource @emotion/react */

import { Dialog } from '@mui/material';
import { lazy } from 'react';
import { card, cardBodyCss, cardHeaderCss } from '../../style/shapes';
import { colors } from '../../style/colors';
import CloseIcon from '../../assets/close.svg';
import Loader from '../commons/Loader';
import { fonts } from '../../style/fonts';
import CountdownTimer from '../commons/CountdownTimer';
import { CSSObject } from '@emotion/react';
import { breakpoints } from '../../style/breakpoints';

const QrCodeGraphic = lazy(() => import('../../assets/qrCodeGraphic.svg'));
const QrCode = lazy(() => import('react-qr-code'));

const QR_CODE_EXPIRATION_TIME = 60 * 15;

type QrCodeDialogProps = {
  setIsQrCodeDialogOpen: (value: boolean) => void;
  qrCodeValue?: string;
  patientName: string;
};

const headerInfo: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  gap: '30px',

  '.countdown-timer': {
    display: 'flex',
    gap: '3px',
    ...fonts.largeLabel,
    color: colors.orange,
  },
};

export default function QrCodeDialog({
  setIsQrCodeDialogOpen,
  qrCodeValue,
  patientName,
}: QrCodeDialogProps) {
  return (
    <Dialog
      css={{
        '.MuiDialog-paper': {
          width: 1261,
          height: 835,
          maxHeight: '100%',
          maxWidth: '100%',
        },
        '.MuiDialog-container': { height: '100%' },
      }}
      open={true}
      onClose={() => {
        setIsQrCodeDialogOpen(false);
      }}
    >
      <div
        css={{
          ...card,
          '.loader-background': {
            backgroundColor: `${colors.dividerGrey}80`,
          },
          height: '100%',
        }}
      >
        <div css={cardHeaderCss}>
          <div css={headerInfo}>
            <span>{`${patientName}'s Account Access`}</span>
            <div className="countdown-timer">
              <span>QR Code Expires in</span>
              <CountdownTimer
                timeInSeconds={QR_CODE_EXPIRATION_TIME}
                onTimerEnd={() => setIsQrCodeDialogOpen(false)}
              />
            </div>
          </div>
          <button type="button">
            <CloseIcon
              width={13}
              onClick={() => setIsQrCodeDialogOpen(false)}
            />
          </button>
        </div>
        <div css={{ ...cardBodyCss, maxHeight: 'unset' }}>
          {!qrCodeValue ? (
            <Loader />
          ) : (
            <div>
              <div css={{ ...fonts.text, marginBottom: '20px' }}>
                <p>
                  Either bring this QR code image close to the tablet, or use
                  the code sent to the patient’s email address to open this
                  patient’s activities on the table.
                </p>
                <span>Tips:</span>
                <ul
                  css={{
                    listStyleType: 'square',
                    paddingLeft: '20px',
                    marginTop: 0,
                  }}
                >
                  <li>Ensure that your device’s screen is bright. </li>
                  <li>
                    Display this QR code to the kiosk tablet after selecting{' '}
                    <span css={{ fontWeight: 500 }}>QR Code Sign In</span> on
                    the tablet.
                  </li>
                </ul>
              </div>
              <div css={{ display: 'flex', alignItems: 'start' }}>
                <QrCodeGraphic width={243} />
                <div
                  css={{
                    height: 200,

                    [breakpoints.medium]: {
                      height: 300,
                    },

                    [breakpoints.large]: {
                      height: 580,
                    },
                  }}
                >
                  <QrCode
                    value={qrCodeValue}
                    style={{
                      width: 725,
                      height: '100%',
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
