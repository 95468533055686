/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';

import ActivityVideoView from './ActivityVideoView';
import { fetchMovementExecutionVideoUrl } from '../../../services/treatmentPlanService';
import { useSelector } from 'react-redux';
import { getCurrentPatientIdSelector } from '../../../state-manager/selectors/appSelectors';

export default function ActivityVideo({
  sessionId,
  subSessionId,
  onError,
  currentTime,
  onPlay,
  onVideoTimelineSet,
}: {
  sessionId: string | undefined;
  subSessionId: string | undefined;
  onError?: () => void;
  onPlay?: () => void;
  currentTime?: number | undefined;
  onVideoTimelineSet?: (time: number) => void;
}) {
  const [videoUrl, setVideoUrl] = useState<string | undefined>();
  const patientId = useSelector(getCurrentPatientIdSelector);
  const [isRequestFinished, setIsRequestFinished] = useState<
    boolean | undefined
  >();
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (!sessionId) {
      setVideoUrl(undefined);
    }
    if (sessionId && subSessionId && patientId && !videoUrl) {
      timeout.current = undefined;
      fetchMovementExecutionVideoUrl({ patientId, sessionId, subSessionId })
        .then((result) => {
          if (!result) {
            return;
          }
          setVideoUrl(result.mediaLink);
          timeout.current = setTimeout(() => {
            setVideoUrl(undefined);
            timeout.current = undefined;
          }, result.expiresInSec * 1000);
        })
        .catch(() => {
          onError?.();
          // eslint-disable-next-line no-console
          console.error('failed to fetch activity video url');
        })
        .finally(() => {
          setIsRequestFinished(true);
        });
    }
  }, [sessionId, subSessionId, patientId, videoUrl]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    };
  }, []);

  return (
    <ActivityVideoView
      videoUrl={videoUrl}
      onError={onError}
      currentTime={currentTime}
      onPlay={onPlay}
      onVideoTimelineSet={onVideoTimelineSet}
      isRequestFinished={isRequestFinished}
    />
  );
}
