/** @jsxImportSource @emotion/react */

import { fonts } from '../../../style/fonts';
import { colors } from '../../../style/colors';
import { CSSObject } from '@emotion/react';
import { shapes } from '../../../style/shapes';
import { buttons } from '../../../style/buttons';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../models/factories/userFactories';
import { useCallback, useEffect, useRef, useState } from 'react';
import FileUploader from '../../commons/FileUploader';
import { getCurrentPatientIdSelector } from '../../../state-manager/selectors/appSelectors';
import { usePulling } from '../../commons/hooks/usePulling';
import {
  fetchAssessmentOutput,
  processAssessment,
} from '../../../services/assessmentService';
import DotsLoader from '../../commons/DotsLoader';

const INITIAL_PULLING_DELAY_MS = 30000;
const PULLING_TIME_MS = 500;
export const ASSESSMENTS_HEADER_HEIGHT = 71;
export const ASSESSMENTS_DATES_WIDTH = 140;

enum Status {
  NotStarted = 'notStarted',
  InProcess = 'inProcess',
  Finished = 'finished',
}

type AssessmentsBodyProps = {
  status: Status;
  setStatus: (status: Status) => void;
  assessment: string | null;
  patientId: string | null | undefined;
};

const assessmentsContainerCss: CSSObject = {
  display: 'flex',
  width: '100%',
  border: shapes.border,
  borderRadius: shapes.borderRadius,
  backgroundColor: colors.white,
  margin: '0 30px 20px 30px',

  '.assessments-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: ASSESSMENTS_HEADER_HEIGHT,
    width: '100%',
    padding: '0 20px',
    borderBottom: shapes.border,
    ...fonts.modalHeader,
  },

  '.assessments-body': {
    padding: 20,
    height: `calc(100% - ${ASSESSMENTS_HEADER_HEIGHT}px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  '.assessments-dates': {
    height: '100%',
    width: ASSESSMENTS_DATES_WIDTH,
    borderRight: shapes.border,
  },

  '.assessments': {
    height: '100%',
    width: '100%',
    // width: `calc(100% - ${ASSESSMENTS_DATES_WIDTH}px)`,
  },

  '.assessment': {
    display: 'flex',
    flexDirection: 'column',
    width: '632px',
    height: '350px',
    border: shapes.border,
    borderRadius: shapes.borderRadius,
    padding: '10px 20px 20px 20px',
  },

  '.assessment-processing': {
    width: '100%',
    height: '300px',
    borderColor: colors.blue3,
  },

  '.assessment-header': {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    ...fonts.text,
  },

  '.assessment-body': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },

  '.file-uploader-container': {
    width: '592px',
  },

  '.upload-container': {
    height: '257px',
  },

  '.uploaded-files-container': {
    width: '592px',
  },

  '.assessment-footer': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto',
  },

  '.assessment-body-processing': {
    height: '126px',
  },

  '.assessment-footer-processing': {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: 'auto',
    alignItems: 'center',
  },

  '.loading-dots': {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },

  '.save-container': {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    marginLeft: 'auto',

    '& > label': {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      ...fonts.text,

      '& > input': {
        width: '20px',
        height: 'auto',
      },
    },
  },

  '.assessment-footer-precessed': {
    display: 'flex',
    marginTop: 'auto',
  },

  '.assessment-text': {
    height: '126px',
    border: 'none',
    overflow: 'auto',
    whiteSpace: 'pre-wrap',
  },
};

function usePullingAsessment(
  patientId: string | null | undefined,
  setStatus: (status: Status) => void,
) {
  const [assessment, setAssessment] = useState<string | null>(null);
  const stopPulling = useRef<(() => void) | undefined>();

  const pullingRoutine = useCallback(async () => {
    const sessionId = localStorage.getItem('sessionId');

    if (!sessionId || !patientId) {
      return Promise.resolve();
    }

    await fetchAssessmentOutput(sessionId, patientId).then((data) => {
      if (data.summary_session_status.summary_status === 'Completed') {
        stopPulling.current?.();
        localStorage.removeItem('sessionId');
        setAssessment(data.summary_session_output);
        setStatus(Status.Finished);
      }
    });
  }, [patientId]);

  stopPulling.current = usePulling({
    routine: pullingRoutine,
    pullingTimeMs: PULLING_TIME_MS,
    initialDelayTimeMS: INITIAL_PULLING_DELAY_MS,
  });

  useEffect(() => {
    return () => {
      stopPulling.current?.();
    };
  }, []);

  return assessment;
}

function getTitle(status: Status) {
  if (status === Status.Finished) {
    return 'Select Assessment';
  }

  if (status === Status.InProcess) {
    return 'Assessment';
  }

  return 'Create New Assessment';
}

function AssessmentProcessingBody() {
  return (
    <>
      <div className="assessment-body-processing" />

      <div className="assessment-footer-processing">
        <DotsLoader />
        <div className="save-container">
          <label>
            <input type="checkbox" disabled />
            <span
              css={{
                color: colors.darkGrey,
              }}
            >
              I Approve this Assessment
            </span>
          </label>
          <button type="button" css={{ ...buttons.primary }} disabled>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

function AssessmentProcessedBody({
  assessment,
}: {
  assessment: string | null;
}) {
  if (!assessment) {
    return <></>;
  }
  return (
    <>
      <div className="assessment-body-processed">
        <textarea className="assessment-text" value={assessment}></textarea>
      </div>

      <div className="assessment-footer-precessed">
        <button type="button" css={{ ...buttons.secondary }} disabled>
          Cancel
        </button>
        <div className="save-container">
          <label>
            <input type="checkbox" disabled />
            <span
              css={{
                color: colors.darkGrey,
              }}
            >
              I Approve this Assessment
            </span>
          </label>
          <button type="button" css={{ ...buttons.primary }} disabled>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

function AssessmentsHeader({ status }: { status: Status }) {
  return (
    <div className="assessments-header">
      <span>{getTitle(status)}</span>
      <button type="button" css={{ ...buttons.primary }} disabled>
        New Assessment
      </button>
    </div>
  );
}

function AssessmentsBody({
  status,
  setStatus,
  assessment,
  patientId,
}: AssessmentsBodyProps) {
  const user = useSelector(getUserSelector);
  const formattedCurrentDate = format(new Date(), 'MMMM d, yyyy');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  return (
    <div className="assessments-body">
      <div
        className={`assessment ${
          status !== Status.NotStarted ? 'assessment-processing' : ''
        }`}
      >
        <div className="assessment-header">
          <h2 css={{ ...fonts.h2, display: 'inline-block' }}>
            {formattedCurrentDate}
          </h2>
          <span>{user?.name ?? ''}</span>
        </div>

        {/* Text has not been sent for processing */}
        {status === Status.NotStarted && (
          <>
            <div className="assessment-body">
              <FileUploader
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
              />
            </div>

            {uploadedFile && patientId && (
              <div className="assessment-footer">
                <button
                  type="button"
                  css={{ ...buttons.secondary }}
                  onClick={() => {
                    setUploadedFile(null);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  css={{ ...buttons.primary }}
                  onClick={async () => {
                    setStatus(Status.InProcess);
                    const text = await uploadedFile.text();
                    processAssessment(text, patientId).then((sessionId) =>
                      localStorage.setItem('sessionId', sessionId),
                    );
                  }}
                >
                  Create Assessment
                </button>
              </div>
            )}
          </>
        )}

        {status === Status.InProcess && <AssessmentProcessingBody />}
        {status === Status.Finished && (
          <AssessmentProcessedBody assessment={assessment} />
        )}
      </div>
    </div>
  );
}

export default function Assessments() {
  // TODO: Save patientId to localStorage for recovery
  const patientId = useSelector(getCurrentPatientIdSelector);
  const [status, setStatus] = useState<Status>(Status.NotStarted);
  const assessment = usePullingAsessment(patientId, setStatus);

  return (
    <div className="assessments-container" css={assessmentsContainerCss}>
      {/* <div className="assessments-dates">
        <div className="assessments-header">Date</div>
      </div> */}
      <div className="assessments">
        <AssessmentsHeader status={status} />
        <AssessmentsBody
          status={status}
          setStatus={setStatus}
          assessment={assessment}
          patientId={patientId}
        />
      </div>
    </div>
  );
}
