import { createSelector } from '@reduxjs/toolkit';

import type { ActivityExecution } from './ActivityExecution';
import { getPatientActivitiesExecutionsSelector } from './ActivityExecution';
import { TreatmentPlan } from './TreatmentPlan';
import { getPatientTreatmentPlansSelector } from './factories/treatmentPlanFactories';

export class Session {
  id: string;

  activitiesExecutions: ActivityExecution[];

  constructor({
    sessionId,
    patientActivitiesExecutions,
  }: {
    sessionId: string;
    patientActivitiesExecutions: ActivityExecution[];
  }) {
    this.id = sessionId;
    this.activitiesExecutions = this.getActivitiesExecutions(patientActivitiesExecutions);
  }

  get stopDate() {
    const lastIndex = this.activitiesExecutions.length - 1;
    return this.activitiesExecutions?.[lastIndex]?.stopDate || new Date();
  }

  get startDate() {
    return this.activitiesExecutions?.[0]?.startDate || new Date();
  }

  get duration() {
    return this.stopDate.getTime() - this.startDate.getTime();
  }

  private getActivitiesExecutions(
    patientActivitiesResults: ActivityExecution[],
  ): ActivityExecution[] {
    const activitiesExecutions: ActivityExecution[] =
      patientActivitiesResults.filter(({ sessionId }) => sessionId === this.id) || [];
    activitiesExecutions.sort((activityA, activityB) => {
      return activityA.stopDate.getTime() - activityB.stopDate.getTime();
    });
    return activitiesExecutions;
  }

  get treatmentPlan(): TreatmentPlan | undefined {
    return this.activitiesExecutions[0]?.treatmentPlan;
  }
}

const REPLACED_STATUS = 'Replaced';

export const getPatientSessionsSelector = createSelector(
  [getPatientActivitiesExecutionsSelector, getPatientTreatmentPlansSelector],
  (patientActivitiesExecutions, patientTreatmentPlans): Session[] | undefined => {
    const plansIds = patientTreatmentPlans?.map(({ id }) => id);
    const handledSessionIds: string[] = [];
    const sessions: Session[] = [];
    patientActivitiesExecutions

      ?.filter(
        (activityExecution: ActivityExecution) =>
          activityExecution.status !== REPLACED_STATUS &&
          plansIds?.includes(activityExecution.treatmentPlanId),
      )
      .forEach(({ sessionId }) => {
        if (!handledSessionIds.includes(sessionId)) {
          handledSessionIds.push(sessionId);
          sessions.push(new Session({ sessionId, patientActivitiesExecutions }));
        }
      });
    return sessions;
  },
);

export function groupSessionsByDate(sessions: Session[] | undefined) {
  if (!sessions) {
    return undefined;
  }
  sessions.sort((sessionA, sessionB) => {
    return sessionB.stopDate.getTime() - sessionA.stopDate.getTime();
  });
  const newDates: Record<string, Session[]> = {};
  sessions.forEach((session) => {
    const date = session.stopDate.toDateString();
    if (!newDates[date]) {
      newDates[date] = [];
    }
    newDates[date]?.push(session);
  });
  return newDates;
}
