/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { CSSObject } from '@emotion/react';
import { fonts } from '../../style/fonts';
import { colors } from '../../style/colors';
import { cardHeaderCss } from '../../style/shapes';
import { AccordionView } from '../commons/DraggableAccordionView';
import { FaqItem, questionAndAnswer } from '../../assets/support/Q&A';
import CloseIcon from '../../assets/close.svg';

const supportModalCss: CSSObject = {
  paddingBottom: '20px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '.support-modal-footer': {
    height: '80px',
    padding: '16px',
    backgroundColor: colors.backgroundDarkerGrey,
    border: `1px solid ${colors.dividerGrey}`,
    borderRadius: '10px',
    marginRight: '20px',
    marginLeft: '20px',
  },
  '.support-modal-body': {
    width: '100%',
    alignSelf: 'flex-end',
    flex: 1,
    overflow: 'auto',
    padding: '20px',
  },
};

export default function SupportModal({
  isOpen,
  setModalMode,
}: {
  isOpen: boolean;
  setModalMode: (isOpen: boolean) => void;
}) {
  let onCancel = () => setModalMode(false);
  const [expendedItemsIds, setExpendedItemsIds] = useState<string[]>([]);
  const faqList: FaqItem[] = questionAndAnswer;
  return (
    <>
      {isOpen && (
        <Dialog
          css={{
            '.MuiDialog-paper': {
              minWidth: 800,
              maxWidth: 800,
              height: '100%',
            },
            '.MuiDialog-container': { height: '100%' },
          }}
          open={true}
          onClose={() => {
            onCancel?.();
          }}
        >
          <div css={supportModalCss}>
            <div className={'support-modal-header'} css={cardHeaderCss}>
              Support
              <button type="button">
                <CloseIcon width={13} onClick={onCancel} />
              </button>
            </div>
            <div className={'support-modal-body'}>
              <div css={{ ...fonts.largeLabel, ...{ paddingBottom: '15px' } }}>
                Frequently Asked Questions
              </div>
              <div>
                <AccordionView
                  items={faqList}
                  expendedItemsIds={expendedItemsIds}
                  setExpendedItemsIds={setExpendedItemsIds}
                  getAccordionSummary={(_, item) => {
                    return <div css={fonts.largeLabel}>{item.question} </div>;
                  }}
                  getAccordionDetails={(_, item) => {
                    return (
                      <div
                        css={{
                          ...fonts.text,
                          ...{
                            height: '58px',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '20px',
                          },
                        }}
                      >
                        {item.answer}
                      </div>
                    );
                  }}
                  getId={(item: FaqItem) => faqList.indexOf(item).toString()}
                />
              </div>
            </div>
            <div className={'support-modal-footer'}>
              <div css={{ ...fonts.largeLabel }}>Need additional help?</div>
              <div>
                Please visit{' '}
                <a
                  css={{
                    ...fonts.largeLabel,
                    ...{ textDecoration: 'underline', color: colors.blue3 },
                  }}
                  href={'https://intel.ly/ptmetrics-support'}
                  target="_blank"
                >
                  PT Metrics Support
                </a>{' '}
                to submit a support ticket and get in touch with our team.
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
