/** @jsxImportSource @emotion/react */

import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { format } from 'date-fns';

import { ActivityDefinitionId } from '../../../types/library';
import { ActivityExecution } from '../../../models/ActivityExecution';
import { colors } from '../../../style/colors';
import TriangleIcon from '../../../assets/triangle.svg';
import { CSSObject } from '@emotion/react';
import { useState } from 'react';
import { shapes } from '../../../style/shapes';
import VideoModal from '../objective/VideoModal';
import {
  getDisplayUnit,
  getMeasurementSystemUnit,
  getMeasurementSystemValue,
} from '../../../utils/unitUtils';
import { formatYAxisValue } from './activityGraphUtils';
import { useSelector } from 'react-redux';
import { getMeasurementSystemSelector } from '../../../state-manager/selectors/appSelectors';

const tooltipCss: CSSObject = {
  padding: '10px',
  backgroundColor: colors.white,
  borderRadius: shapes.borderRadius,
  border: shapes.border,
  position: 'relative',
  fontSize: '14px',
  fontWeight: '500',
  display: 'flex',
  flexDirection: 'column',
  filter: `drop-shadow(0px 4px 4px ${colors.dividerGrey})`,
  pointerEvents: 'auto',

  '&::after': {
    content: '""',
    position: 'absolute',
    top: '99%',
    left: '45%',
    backgroundColor: 'white',
    height: '17px',
    width: '17px',
    clipPath: 'polygon(0 0, 100% 0, 50% 100%)',
  },

  button: {
    marginTop: '10px',
    color: colors.blue4,
    cursor: 'pointer',
  },
};

const responsiveContainerWrapperCss: CSSObject = {
  position: 'relative',
  width: '100%',
  height: '100%',
  '& > div': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
};

type Point = {
  date: number;
  goal?: number;
  value: number;
  activityExecution: ActivityExecution;
  metricKey: string;
  metricSide: string | undefined;
};

function getGraphData(
  activityDefinitionId: ActivityDefinitionId | undefined,
  activitiesExecutionsMap: Record<ActivityDefinitionId, ActivityExecution[]>,
  metricKey: string | undefined,
  metricSide: string | undefined,
  measurementSystem: string,
) {
  let data: Point[] = [];

  if (activityDefinitionId && activitiesExecutionsMap) {
    const activitiesExecutions = activitiesExecutionsMap[activityDefinitionId];
    if (activitiesExecutions) {
      activitiesExecutions.sort((activityA, activityB) => {
        return activityA.stopDate > activityB.stopDate ? 1 : -1;
      });
      activitiesExecutions.forEach((activityExecution) => {
        activityExecution.results.forEach(
          ({ key, value, goal, side, unit }) => {
            if (key !== metricKey || side !== metricSide) {
              return;
            }
            const measurementSystemUnit = getMeasurementSystemUnit(
              unit,
              measurementSystem,
            );

            const measurementSystemValue = getMeasurementSystemValue(
              value,
              measurementSystemUnit,
              measurementSystem,
            )?.toFixed(0);

            const measurementSystemGoal = getMeasurementSystemValue(
              goal,
              measurementSystemUnit,
              measurementSystem,
            )?.toFixed(0);

            const point: Point = {
              date: new Date(activityExecution.stopDate).getTime(),
              value: Number(measurementSystemValue),
              goal: Number(measurementSystemGoal),
              activityExecution,
              metricKey: metricKey,
              metricSide: metricSide,
            };
            data.push(point);
          },
        );
      });
    }
  }
  return data;
}

function dateFormatter(date: number) {
  return format(new Date(date), 'M/d');
}

function CustomTooltip(
  props: TooltipProps<ValueType, NameType> & {
    setSelectedActivityExecution: (
      activityExecution: ActivityExecution,
    ) => void;
    standardDeviation: number | undefined;
    measurementSystem: string;
  },
) {
  const {
    active,
    payload,
    setSelectedActivityExecution,
    standardDeviation,
    measurementSystem,
  } = props;

  if (active && payload && payload?.length !== 0) {
    const data = payload[0]?.payload;
    const postMetric = data.activityExecution.getMetric(
      data.metricKey,
    ).postMetricDto;

    const measurementSystemUnit = getDisplayUnit(
      getMeasurementSystemUnit(postMetric.unit, measurementSystem),
    );

    const measurementSystemValue = getMeasurementSystemValue(
      data.value,
      measurementSystemUnit,
      measurementSystem,
    )?.toFixed(1);

    const measurementSystemStandardDeviation = getMeasurementSystemValue(
      standardDeviation,
      measurementSystemUnit,
      measurementSystem,
    )?.toFixed(0);

    return (
      <div className="area-chart-tooltip" css={{ ...tooltipCss }}>
        <span>
          Date:{data ? format(new Date(data.date), ' MMM dd, yyyy') : ' -- '}
        </span>
        <span>
          Time:{data ? format(new Date(data.date), ' hh:mm a') : ' -- '}
        </span>
        <span>
          Measurement:
          {` ${measurementSystemValue}${measurementSystemUnit}  ${
            standardDeviation
              ? `± ${measurementSystemStandardDeviation}${measurementSystemUnit}`
              : ''
          }`}
        </span>
        <button
          onClick={() => {
            setSelectedActivityExecution(payload[0]?.payload.activityExecution);
          }}
        >
          View Session Video
        </button>
      </div>
    );
  }

  return null;
}

const DELTA = 5;

function calcYDomain(
  data: Point[] | undefined,
): [string | number, string | number] {
  let minValue: number | undefined = undefined;
  let maxValue: number | undefined = undefined;
  let minGoal: number | undefined = undefined;
  let maxGoal: number | undefined = undefined;
  data?.forEach((point) => {
    if (minValue === undefined || minValue > point.value) {
      minValue = point.value;
    }
    if (maxValue === undefined || maxValue < point.value) {
      maxValue = point.value;
    }
    if (minGoal === undefined || (point.goal && minGoal > point.goal)) {
      minGoal = point.goal;
    }
    if (maxGoal === undefined || (point.goal && maxGoal < point.goal)) {
      maxGoal = point.goal;
    }
  });

  let dataMin: string | number = 'dataMin';
  if (minGoal !== undefined && minValue !== undefined && minGoal < minValue) {
    dataMin = minGoal - DELTA;
  }

  let dataMax: string | number = 'dataMax';
  if (maxGoal !== undefined && maxValue !== undefined && maxGoal > maxValue) {
    dataMax = maxGoal + DELTA;
  }
  return [dataMin, dataMax];
}

export default function ActivityGraph({
  activityDefinitionId,
  activitiesExecutionsMap,
  metricKey,
  metricSide,
  standardDeviation,
  simplified = false,
}: {
  activityDefinitionId: ActivityDefinitionId;
  activitiesExecutionsMap: Record<ActivityDefinitionId, ActivityExecution[]>;
  metricKey: string | undefined;
  metricSide: string | undefined;
  standardDeviation: number | undefined;
  simplified?: boolean;
}) {
  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  }>();

  const [selectedActivityExecution, setSelectedActivityExecution] =
    useState<ActivityExecution | null>(null);

  const measurementSystem = useSelector(getMeasurementSystemSelector);

  const data = getGraphData(
    activityDefinitionId,
    activitiesExecutionsMap,
    metricKey,
    metricSide,
    measurementSystem,
  );

  const getTooltipCoordinates = ({
    cx,
    cy,
  }: {
    cx: number;
    cy: number;
    dataKey: string;
  }) => {
    const rect = document
      .querySelector('.area-chart-tooltip')
      ?.getBoundingClientRect();
    if (
      rect &&
      tooltipPosition?.x !== cx - rect.width / 2 &&
      tooltipPosition?.y !== cy - rect.height - 26
    ) {
      setTimeout(() => {
        setTooltipPosition({
          x: cx - rect.width / 2,
          y: cy - rect.height - 26,
        });
      }, 0);
    }

    return <></>;
  };

  return (
    // The additional divs are needed to circumvent a known issue
    // with ResponsiveContainer's size calculation
    <div css={{ ...responsiveContainerWrapperCss }}>
      <div>
        <ResponsiveContainer width="99%">
          <LineChart
            data={data}
            margin={{
              top: 35,
              bottom: 20,
              right: 5,
              left: -5,
            }}
          >
            <XAxis
              dataKey="date"
              scale="time"
              type="number"
              domain={['dataMin', 'dataMax']}
              tick={{ fontWeight: 500, fontSize: '14px' }}
              tickFormatter={dateFormatter}
              interval={'preserveStartEnd'}
            />
            <YAxis
              domain={calcYDomain(data)}
              tick={{ fontWeight: 500, fontSize: '14px' }}
              tickFormatter={(value) => {
                if (activityDefinitionId) {
                  return formatYAxisValue(
                    value,
                    activitiesExecutionsMap[activityDefinitionId]?.[0],
                    metricKey,
                  );
                }
                return '';
              }}
            />
            <Tooltip
              position={tooltipPosition}
              allowEscapeViewBox={{ x: true, y: true }}
              content={
                <CustomTooltip
                  setSelectedActivityExecution={setSelectedActivityExecution}
                  standardDeviation={standardDeviation}
                  measurementSystem={measurementSystem}
                />
              }
              trigger="click"
            />
            <Line
              type="linear"
              dataKey="value"
              stroke={colors.purple}
              strokeWidth={1}
              activeDot={getTooltipCoordinates}
              isAnimationActive={false}
              dot={
                simplified
                  ? false
                  : ({ cx, cy }) => {
                      return (
                        <TriangleIcon
                          key={`${cx}, ${cy}`}
                          x={cx - 8}
                          y={cy - 8}
                          width="17px"
                          height="15px"
                          css={{ cursor: 'pointer' }}
                        />
                      );
                    }
              }
            />
            {!simplified && (
              <Line
                type="step"
                dataKey="goal"
                stroke={colors.darkerGrey}
                strokeWidth={1}
                strokeDasharray="5 3"
                dot={false}
                activeDot={false}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
      {selectedActivityExecution && (
        <VideoModal
          onClose={() => setSelectedActivityExecution(null)}
          activityExecution={selectedActivityExecution}
          metricKey={metricKey}
        />
      )}
    </div>
  );
}
