export function getOrdinalSuffix(origNum: number) {
  const tenth = origNum % 10;
  const hundredth = origNum % 100;
  if (tenth === 1 && hundredth !== 11) {
    return 'st';
  }
  if (tenth === 2 && hundredth !== 12) {
    return 'nd';
  }
  if (tenth === 3 && hundredth !== 13) {
    return 'rd';
  }
  return 'th';
}

export function convertBytesToKB(bytes: number) {
  return (bytes / 1024).toFixed(1);
}

export function isNumber(value: any): value is number {
  return !Number.isNaN(value);
}
