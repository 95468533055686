/** @jsxImportSource @emotion/react */

import { CSSObject } from '@emotion/react';
import {
  InputField,
  SelectInputField,
} from '../../../commons/form/forStateFrom';
import { EquipmentInput, SharedInputPropsType } from './treatmentPlanTypes';
import { isLoadInput } from '../TreatmentPlansUtils';
import {
  getMeasurementSystemUnit,
  getUnitInputPropValue,
} from '../../../../utils/unitUtils';
import { getMeasurementSystemSelector } from '../../../../state-manager/selectors/appSelectors';
import { useSelector } from 'react-redux';

const equipmentLoadCss: CSSObject = {
  '.small-input': { flexDirection: 'row-reverse' },

  '.device-load-select': {
    flexDirection: 'row-reverse',

    '.input-parent': {
      height: '40px',
      width: '160px',
    },
  },
};

type EquipmentLoadInputProps = {
  device: EquipmentInput;
  sharedInputProps: SharedInputPropsType;
  activityIndex: number;
  deviceIndex: number;
};

export default function EquipmentLoadInput({
  device,
  sharedInputProps,
  activityIndex,
  deviceIndex,
}: EquipmentLoadInputProps) {
  const measurementSystem = useSelector(getMeasurementSystemSelector);
  const measurementSystemUnit = getMeasurementSystemUnit(
    device.Units,
    measurementSystem,
  );
  return (
    <div css={equipmentLoadCss}>
      {isLoadInput(device) && (
        <InputField
          {...sharedInputProps}
          className="small-input"
          name={`activities.${activityIndex}.equipment.${deviceIndex}.Value`}
          label={measurementSystemUnit}
          type="number"
          required
          unit={getUnitInputPropValue(device.Units, measurementSystem)}
        />
      )}

      {device.Options && (
        <SelectInputField
          {...sharedInputProps}
          name={`activities.${activityIndex}.equipment.${deviceIndex}.Value`}
          className="device-load-select"
          label={device.Units}
          options={device.Options}
          required
        />
      )}
    </div>
  );
}
