/** @jsxImportSource @emotion/react */

import Dialog from '@mui/material/Dialog';
import { CSSObject } from '@emotion/react';
import { fonts } from '../../style/fonts';
import { colors } from '../../style/colors';
import { buttons } from '../../style/buttons';
import {
  card,
  cardBodyCss,
  cardFooterCss,
  cardHeaderCss,
} from '../../style/shapes';

const refreshModalCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '.refresh-modal-header': {
    border: 0,
  },
  '.refresh-modal-body': {
    paddingBottom: 0,
    paddingTop: 0,
  },
  '.refresh-modal-footer': {
    border: 0,
    paddingBottom: 20,
  },
};

export default function RefreshSessionModal({
  setModalMode,
  onRefreshSession,
  onSignOut,
}: {
  setModalMode: (isOpen: boolean) => void;
  onRefreshSession: () => void;
  onSignOut: () => void;
}) {
  return (
    <Dialog
      css={{
        '.MuiDialog-paper': {
          minWidth: 362,
          maxWidth: 362,
          height: 225,
        },
        '.MuiDialog-container': { height: '100%' },
      }}
      open={true}
      onClose={() => {
        setModalMode(false);
      }}
    >
      <div css={refreshModalCss}>
        <div
          css={{
            ...card,
            border: 0,
            '.loader-background': {
              backgroundColor: `${colors.dividerGrey}80`,
            },
          }}
        >
          <div className={'refresh-modal-body'}>
            <div className={'refresh-modal-header'} css={cardHeaderCss}>
              Session Timeout
            </div>
            <div className={'refresh-modal-body'} css={cardBodyCss}>
              <div css={{ ...fonts.text, ...{ paddingBottom: '15px' } }}>
                Your session will expire in less than
                <span css={{ ...fonts.largeLabel }}> 60 seconds</span>. To
                continue your session, please select{' '}
                <span css={{ ...fonts.largeLabel }}> Stay Signed In</span>.
              </div>
            </div>
            {
              <div className={'refresh-modal-footer'} css={cardFooterCss}>
                <button
                  css={{ ...buttons.secondary }}
                  type="button"
                  onClick={onSignOut}
                >
                  Sign Out Now
                </button>
                <button
                  css={{ ...buttons.primary }}
                  type="button"
                  onClick={onRefreshSession}
                >
                  Stay Signed In
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </Dialog>
  );
}
