import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { Authenticator, View, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import {
  AWS_REGION,
  AWS_USER_POOL_ID,
  AWS_USER_POOL_WEB_CLIENT_ID,
} from './externalConfig';
import PtMetricsLogoIcon from './assets/ptMetricsLogo.svg';
import { fonts } from './style/fonts';
import { signInCss } from './style/signIn'; // Import your styles
import { Global } from '@emotion/react';
import RefreshSessionModal from './components/app/RefreshSessionModal';
import { HubCallback, HubCapsule } from '@aws-amplify/core';
import { validateEmail } from './components/forms/create-user-form/userFormValidation';
import App from './App';

const customStorageRefreshToken = {
  setItem: (key: string, value: string) => {
    if (!key.endsWith('.refreshToken')) {
      localStorage.setItem(key, value);
    }
  },
  getItem: (key: string) => {
    if (!key.endsWith('.refreshToken')) {
      return localStorage.getItem(key);
    }
    return null;
  },
  removeItem: (key: string) => {
    if (!key.endsWith('.refreshToken')) {
      localStorage.removeItem(key);
    }
  },
  clear: () => {
    localStorage.clear();
  },
};

Amplify.configure({
  Auth: {
    region: AWS_REGION,
    userPoolId: AWS_USER_POOL_ID,
    userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,
    storage: customStorageRefreshToken,
  },
});

const components = {
  Header(username: string) {
    const currentState = useAuthenticator();
    let headerText;
    let headline;

    if (currentState.route == 'confirmResetPassword') {
      headerText = 'Reset Password';
      headline = `Check your email for a code sent to ${username}, 
      Check your spam folder if you don’t see the email. 
      Enter your new password below.`;
    } else if (currentState.route == 'resetPassword') {
      headerText = 'Forgot Password';
      headline =
        'Please enter your email address to receive a message with a code to reset your password.';
    } else if (currentState.route == 'signIn') {
      headerText = 'Sign In';
      headline = null;
    } else if (currentState.route == 'forceNewPassword') {
      headerText = 'Reset Password';
      headline = 'Enter your new password below.';
    }
    return (
      <View
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          alignSelf: 'center',
          margin: 'auto',
          flexDirection: 'column',
          paddingTop: '60px',
        }}
        textAlign="center"
      >
        <PtMetricsLogoIcon width="371" height="70" />
        <div style={fonts.modalHeader as CSSProperties}>{headerText}</div>
        {headline && (
          <div
            style={{
              ...(fonts.text as CSSProperties),
              width: '500px',
              paddingTop: '20px',
            }}
          >
            {headline}
          </div>
        )}
      </View>
    );
  },
  ForceNewPassword: {
    FormFields() {
      const [password, setPassword] = useState('');

      // Function to check password against the rules
      const checkPasswordStrength = () => {
        return {
          minLength: password.length >= 12,
          hasUpper: /[A-Z]/.test(password),
          hasLower: /[a-z]/.test(password),
          hasNumber: /[0-9]/.test(password),
          hasSpecialChar: /[!@#\$%\^\&*\)\(+=._-]+/.test(password),
        };
      };

      const passwordRules = checkPasswordStrength();

      useEffect(() => {
        const handlePasswordChange = (e: Event) => {
          setPassword((e.target as HTMLInputElement)?.value);
        };

        const passwordInput = document.querySelector('input[name="password"]');
        passwordInput?.addEventListener('input', handlePasswordChange);

        return () => {
          passwordInput?.removeEventListener('input', handlePasswordChange);
        };
      }, []);

      useEffect(() => {
        const changePasswordButton = document.querySelector(
          '.amplify-button--primary[type="submit"]',
        ) as HTMLButtonElement;
        if (changePasswordButton) {
          changePasswordButton.disabled =
            !Object.values(passwordRules).every(Boolean);
          changePasswordButton.classList.toggle(
            'disabled',
            changePasswordButton.disabled,
          );
        }
      }, [passwordRules]);

      return (
        <>
          <Authenticator.ForceNewPassword.FormFields />
          <div style={fonts.text as CSSProperties}>
            {!passwordRules.minLength && <div>Minimum of 12 characters</div>}
            {!passwordRules.hasUpper && (
              <div>At least one uppercase letter</div>
            )}
            {!passwordRules.hasUpper && (
              <div>At least one lowercase letter</div>
            )}
            {!passwordRules.hasNumber && <div>At least one digit (0-9)</div>}
            {!passwordRules.hasSpecialChar && (
              <div>At least one special characters ($ % & ! etc.)</div>
            )}
          </div>
        </>
      );
    },
  },
};

export default function AppWithLogin() {
  const TIME_REFRESH_TOKEN_MSG = 60;
  const [showSessionTimeOutModal, setSessionTimeOutModal] =
    useState<boolean>(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState<string>('');
  const [tokenExpirationTime, setTokenExpirationTime] = useState<number>();
  const sessionIntervalRef = useRef<NodeJS.Timer | null>(null); // Using useRef for sessionInterval

  const handleSignOut = () => {
    if (sessionIntervalRef.current) {
      clearInterval(sessionIntervalRef.current);
      sessionIntervalRef.current = null;
    }
    Auth.signOut();
    setSessionTimeOutModal(false);
  };

  const checkTokenExpiration = async () => {
    try {
      // Fetch the current session
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        const session = await Auth.currentSession();
        // Get the access token and its expiration time
        const accessToken = session.getAccessToken();
        const expirationTime = accessToken.getExpiration(); // Expiration time is already in seconds

        // Get the current time in seconds
        const currentTime = Math.floor(Date.now() / 1000);

        if (
          expirationTime - currentTime < 1 ||
          (tokenExpirationTime &&
            tokenExpirationTime - currentTime < 1 &&
            showSessionTimeOutModal)
        ) {
          handleSignOut();
        } else if (
          !showSessionTimeOutModal &&
          expirationTime - currentTime < TIME_REFRESH_TOKEN_MSG
        ) {
          setSessionTimeOutModal(true);
          setTokenExpirationTime(expirationTime);
        }
      }
    } catch (error) {
      handleSignOut();
    }
  };

  const initSessionCheckInterval = () => {
    const intervalId = setInterval(checkTokenExpiration, 1000);
    sessionIntervalRef.current = intervalId; // Store interval ID in ref
  };

  useEffect(() => {
    const listener: HubCallback = (data: HubCapsule) => {
      const { payload } = data;
      if (payload.event === 'signOut') {
        if (sessionIntervalRef.current) {
          clearInterval(sessionIntervalRef.current);
          sessionIntervalRef.current = null;
        }
      } else if (payload.event === 'signIn') {
        if (!sessionIntervalRef.current) {
          initSessionCheckInterval();
        }
      }
    };

    Hub.listen('auth', listener);

    // Initial session check
    checkTokenExpiration();
    if (!sessionIntervalRef.current) {
      initSessionCheckInterval();
    }

    // Cleanup listener and interval on unmount
    return () => {
      if (sessionIntervalRef.current) {
        clearInterval(sessionIntervalRef.current);
        sessionIntervalRef.current = null;
      }
      Hub.remove('auth', listener);
    };
  }, []); // Run once on mount

  const handleForgotPassword = (username: string) => {
    if (!validateEmail(username)) {
      throw new Error('Please enter a valid email address');
    }
    return Auth.forgotPassword(username)
      .then((data) => {
        setResetPasswordEmail(username);
        return data;
      })
      .catch((error) => {
        if (error.name !== 'UserNotFoundException') {
          throw error;
        }
      });
  };

  const handleStaySignedIn = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const refreshToken = currentUser?.signInUserSession.refreshToken;
      currentUser.refreshSession(refreshToken, (err?: any, session?: any) => {
        if (session) {
          setSessionTimeOutModal(false);
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <Global styles={signInCss} />
      {showSessionTimeOutModal && (
        <RefreshSessionModal
          setModalMode={setSessionTimeOutModal}
          onRefreshSession={handleStaySignedIn}
          onSignOut={handleSignOut}
        />
      )}
      <Authenticator
        formFields={{
          signIn: { username: { label: 'Email' } },
          resetPassword: {
            username: {
              label: 'Enter your email address',
            },
          },
        }}
        hideSignUp={true}
        components={{
          Header: () => components.Header(resetPasswordEmail),
          ForceNewPassword: components.ForceNewPassword,
        }}
        services={{
          handleForgotPassword,
        }}
      >
        <App />
      </Authenticator>
    </>
  );
}
