import { ActivityExecution } from '../../../models/ActivityExecution';
import { getDisplayUnit } from '../../../utils/unitUtils';

export function formatYAxisValue(
  value: any,
  activityExecution: ActivityExecution | undefined,
  metricKey: string | undefined,
) {
  if (activityExecution && metricKey) {
    return `${value} ${getDisplayUnit(activityExecution?.getMetric(metricKey)?.unit)}`;
  }
  return '';
}
