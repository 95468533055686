/** @jsxImportSource @emotion/react */

import {
  Checkbox,
  ListItemText,
  ListSubheader,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { CSSObject } from '@emotion/react';
import {
  ALL_BODY_PARTS_OPTION,
  ALL_MONITORING_TYPES_OPTION,
  BODY_PARTS,
  MONITORING_TYPES,
} from './planCreationUtils';
import { colors } from '../../../../style/colors';
import { fonts } from '../../../../style/fonts';
import Select from '../../../commons/form/Select';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

const activitySelectionFilterCss: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  gap: '22px',

  label: {
    ...fonts.largeLabel,
  },

  '.select-input': {
    width: '163px',
  },
};

type ActivitySelectionFilterProps = {
  selectedBodyPartFilters: string[];
  selectedMonitoringTypeFilters: string[];
  setSelectedBodyPartFilters: Dispatch<SetStateAction<string[]>>;
  setSelectedMonitoringTypeFilters: Dispatch<SetStateAction<string[]>>;
};

export default function ActivitySelectionFilter({
  selectedBodyPartFilters,
  selectedMonitoringTypeFilters,
  setSelectedBodyPartFilters,
  setSelectedMonitoringTypeFilters,
}: ActivitySelectionFilterProps) {
  const selectedFilters = selectedBodyPartFilters.concat(
    selectedMonitoringTypeFilters,
  );

  const areAllSelected = useMemo(() => {
    return (
      selectedBodyPartFilters.includes(ALL_BODY_PARTS_OPTION) &&
      selectedMonitoringTypeFilters.includes(ALL_MONITORING_TYPES_OPTION)
    );
  }, [selectedBodyPartFilters, selectedMonitoringTypeFilters]);

  const areAllBodyPartsSelected = useMemo(() => {
    return BODY_PARTS.every((bodyPart) => {
      return selectedBodyPartFilters.includes(bodyPart);
    });
  }, [selectedBodyPartFilters]);

  const areAllMonitoringTypesSelected = useMemo(() => {
    return MONITORING_TYPES.every((monitoringType) => {
      return selectedMonitoringTypeFilters.includes(monitoringType);
    });
  }, [selectedMonitoringTypeFilters]);

  // If all options of a certain type are selected, unselect them
  // and replace with the appropriate ALL option
  useEffect(() => {
    if (areAllBodyPartsSelected) {
      setSelectedBodyPartFilters((previousValue: string[]) => {
        const filteredPreviousValue = previousValue.filter((value) => {
          return !BODY_PARTS.includes(value);
        });
        return [ALL_BODY_PARTS_OPTION, ...filteredPreviousValue];
      });
    }
    if (areAllMonitoringTypesSelected) {
      setSelectedMonitoringTypeFilters((previousValue: string[]) => {
        const filteredPreviousValue = previousValue.filter((value) => {
          return !MONITORING_TYPES.includes(value);
        });
        return [ALL_MONITORING_TYPES_OPTION, ...filteredPreviousValue];
      });
    }
  }, [areAllBodyPartsSelected, areAllMonitoringTypesSelected]);

  const handleChange = useCallback(
    (newValue: string[]) => {
      // Unselect
      if (newValue.length < selectedFilters.length) {
        const removedOption = selectedFilters.filter((filter) => {
          return !newValue.includes(filter);
        })[0];

        if (!removedOption) {
          return;
        }

        // Unselect body parts
        if (
          BODY_PARTS.includes(removedOption) ||
          removedOption === ALL_BODY_PARTS_OPTION
        ) {
          setSelectedBodyPartFilters((previousValue) => {
            const removedOptionIndex = previousValue.indexOf(removedOption);
            return previousValue.toSpliced(removedOptionIndex, 1);
          });
          return;
        }

        // Unselect monitoring types
        if (
          MONITORING_TYPES.includes(removedOption) ||
          removedOption === ALL_MONITORING_TYPES_OPTION
        ) {
          setSelectedMonitoringTypeFilters((previousValue) => {
            const removedOptionIndex = previousValue.indexOf(removedOption);
            return previousValue.toSpliced(removedOptionIndex, 1);
          });
          return;
        }
      }

      // Select
      const newOption = newValue[newValue.length - 1];

      if (!newOption) {
        return;
      }

      // Select body parts
      if (BODY_PARTS.includes(newOption)) {
        if (selectedBodyPartFilters.includes(ALL_BODY_PARTS_OPTION)) {
          setSelectedBodyPartFilters((previousValue) => {
            const allOptionIndex = previousValue.indexOf(ALL_BODY_PARTS_OPTION);
            const updatedValue = previousValue.toSpliced(allOptionIndex, 1);
            return [...updatedValue, newOption];
          });
        } else {
          setSelectedBodyPartFilters((previousValue) => {
            return [...previousValue, newOption];
          });
        }
        return;
      }

      if (newOption === ALL_BODY_PARTS_OPTION) {
        setSelectedBodyPartFilters([ALL_BODY_PARTS_OPTION]);
        return;
      }

      // Select monitoring types
      if (MONITORING_TYPES.includes(newOption)) {
        if (
          selectedMonitoringTypeFilters.includes(ALL_MONITORING_TYPES_OPTION)
        ) {
          setSelectedMonitoringTypeFilters((previousValue) => {
            const allOptionIndex = previousValue.indexOf(
              ALL_MONITORING_TYPES_OPTION,
            );
            const updatedValue = previousValue.toSpliced(allOptionIndex, 1);
            return [...updatedValue, newOption];
          });
        } else {
          setSelectedMonitoringTypeFilters((previousValue) => {
            return [...previousValue, newOption];
          });
        }
        return;
      }

      if (newOption === ALL_MONITORING_TYPES_OPTION) {
        setSelectedMonitoringTypeFilters([ALL_MONITORING_TYPES_OPTION]);
        return;
      }
    },
    [selectedBodyPartFilters, selectedMonitoringTypeFilters],
  );

  return (
    <div css={activitySelectionFilterCss}>
      <label>View</label>
      <Select
        name="acitivitySelectionFilter"
        value={selectedBodyPartFilters.concat(selectedMonitoringTypeFilters)}
        onChange={handleChange}
        customRenderValue={(selected) => {
          return (
            <Tooltip
              title={areAllSelected ? 'All' : selectedFilters.join(', ')}
            >
              <span>{areAllSelected ? 'All' : selected.join(', ')}</span>
            </Tooltip>
          );
        }}
        menuProps={{
          PaperProps: {
            sx: {
              maxHeight: 424,
              '.MuiListSubheader-root': {
                color: `${colors.black}`,
                ...fonts.smallLabel,
                paddingLeft: '10px',
              },
              '.MuiMenuItem-root': {
                ...fonts.text,
                padding: 0,
              },
            },
          },
        }}
      >
        <ListSubheader>Body Parts</ListSubheader>
        <MenuItem value={ALL_BODY_PARTS_OPTION}>
          <Checkbox
            checked={selectedFilters.indexOf(ALL_BODY_PARTS_OPTION) > -1}
          />
          <ListItemText primary="All" />
        </MenuItem>
        {BODY_PARTS.map((bodyPart) => {
          return (
            <MenuItem key={bodyPart} value={bodyPart}>
              <Checkbox checked={selectedFilters.indexOf(bodyPart) > -1} />
              <ListItemText primary={bodyPart} />
            </MenuItem>
          );
        })}
        <hr css={{ borderColor: colors.mediumGrey }} />
        <ListSubheader>Monitoring</ListSubheader>
        <MenuItem value={ALL_MONITORING_TYPES_OPTION}>
          <Checkbox
            checked={selectedFilters.indexOf(ALL_MONITORING_TYPES_OPTION) > -1}
          />
          <ListItemText primary="All" />
        </MenuItem>
        {MONITORING_TYPES.map((monitoringType) => {
          return (
            <MenuItem key={monitoringType} value={monitoringType}>
              <Checkbox
                checked={selectedFilters.indexOf(monitoringType) > -1}
              />
              <ListItemText primary={monitoringType} />
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}
