/** @jsxImportSource @emotion/react */

import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material';

export default function Loader() {
  const theme = useTheme();
  const zIndex: number = theme.zIndex.modal + 1;

  return (
    <div
      className="loader-background"
      css={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        // backgroundColor: `${colors.dividerGrey}80`,
      }}
    >
      <CircularProgress />
    </div>
  );
}
