import { MovementDefinitionDto } from '../backendType';
import { ActivityDefinition } from '../library';

export function mapToActivityDefinition(
  movementDefinitionDto: MovementDefinitionDto,
): ActivityDefinition {
  const activityDefinition: ActivityDefinition = {
    id: movementDefinitionDto.movement_id,
    name: movementDefinitionDto.movement_info.exercise_name,
    description: movementDefinitionDto.movement_info.description ?? '',
    // Old required equipment array
    equipment: movementDefinitionDto.movement_info.assistant_device_needed,
    // New required equipment array
    requiredEquipment: movementDefinitionDto.movement_info.assistant_equipment_needed,
    // New optional equipment array
    optionalEquipment: movementDefinitionDto.movement_info.assistant_equipment_optional,
    position: movementDefinitionDto.movement_info.position ?? '',
    plane: movementDefinitionDto.movement_info.plane ?? '',
    bodyParts: movementDefinitionDto.movement_info.body_parts ?? [],
    postExerciseMetric: movementDefinitionDto.movement_info.post_exercise_metric,
    realtimeMetric: movementDefinitionDto.movement_info.realtime_metric,
    settings: movementDefinitionDto.movement_info.settings,
    automationLevel: movementDefinitionDto.movement_info['eng._phase'] ?? 0,
    repOn: movementDefinitionDto.movement_info.rep_on,
    category: movementDefinitionDto.movement_info.category,
    end_condition: movementDefinitionDto.movement_info.end_condition,
    time_per_meter: movementDefinitionDto.movement_info.time_per_meter,
  };
  return activityDefinition;
}
