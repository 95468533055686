/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from 'react';
import { CSSObject } from '@emotion/react';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { colors } from '../../../../style/colors';
import Chip from '../../../commons/Chip';
import { fonts } from '../../../../style/fonts';
import PlusIcon from '../../../../assets/plus.svg';
import {
  EquipmentInput,
  SharedInputPropsType,
  TreatmentPlanInput,
} from './treatmentPlanTypes';
import { Path } from '../../../../utils/typeScriptUtils';
import { Equipment } from '../../../../types/backendType';
import EquipmentLoadInput from './EquipmentLoadInput';
import { produce } from 'immer';
import { tooltips } from '../commons/tooltips';
import { breakpoints } from '../../../../style/breakpoints';

const equipmentCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',

  '.equipment': {
    display: 'flex',
    gap: '12px',
    alignItems: 'baseline',

    label: {
      ...fonts.largeLabel,
      [breakpoints.small]: {
        width: '107px',
        minWidth: '107px',
      },

      [breakpoints.large]: {
        width: '148px',
        minWidth: '148px',
      },
    },

    '.equipment-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },

    '.chip-container': {
      svg: {
        transform: 'rotate(45deg)',
      },
    },
  },

  '.add-equipment': {
    display: 'flex',
    gap: '12px',
    alignItems: 'baseline',

    label: {
      ...fonts.largeLabel,
      [breakpoints.small]: {
        width: '107px',
      },

      [breakpoints.large]: {
        width: '148px',
      },
    },

    '.equipment-container': {
      display: 'flex',
      gap: '14px',
    },

    '.chip-container': {
      ...fonts.smallLabel,
      color: colors.blue3,
      border: `1px solid ${colors.blue3}`,
      backgroundColor: 'transparent',
      padding: '5px 8px',
    },
    '.disabled-chip': {
      color: colors.darkerGrey,
      borderColor: colors.darkerGrey,
    },
  },
};

export default function FormEquipment({
  requiredEquipment,
  optionalEquipment,
  sharedInputProps,
  name,
  value,
  activityIndex,
}: {
  requiredEquipment: Equipment[] | undefined;
  optionalEquipment: Equipment[] | undefined;
  sharedInputProps: SharedInputPropsType;
  name: Path<TreatmentPlanInput>;
  value: EquipmentInput[] | undefined;
  activityIndex: number;
}) {
  const [unselectedRequiredDevices, setUnselectedRequiredDevices] = useState<
    EquipmentInput[]
  >([]);
  // Add required equipment to an activity's equipment property
  useEffect(() => {
    sharedInputProps.setFormData(
      produce((draftFormData) => {
        if (!draftFormData.activities[activityIndex]?.equipment) {
          draftFormData.activities[activityIndex]!.equipment = [];
        }
        requiredEquipment?.forEach((device, deviceIndex) => {
          const isExclusiveRequiredDeviceSelected = draftFormData.activities[
            activityIndex
          ]?.equipment?.some(
            (selectedDevice) => selectedDevice.Group === device.Group,
          );
          if (deviceIndex === 0 || !isExclusiveRequiredDeviceSelected) {
            draftFormData.activities[activityIndex]?.equipment?.unshift({
              ...device,
              IsRequired: true,
              Value:
                draftFormData.activities[activityIndex]?.equipment?.[
                  deviceIndex
                ]?.Value ?? device.Default,
            });
            return;
          }

          if (isExclusiveRequiredDeviceSelected) {
            setUnselectedRequiredDevices((previousValue) => {
              return [
                ...previousValue,
                {
                  ...device,
                  IsRequired: true,
                  Value: device.Default,
                },
              ];
            });
            return;
          }
        });
        draftFormData.activities[activityIndex]!.equipment =
          draftFormData.activities[activityIndex]!.equipment?.filter(
            (device, index, array) =>
              array.findIndex(
                (otherDevice) => otherDevice.Name === device.Name,
              ) === index,
          );
      }),
    );
  }, []);

  const unselectedOptionalDevices = useMemo(() => {
    return optionalEquipment?.filter((device) => {
      return (
        !value ||
        value?.every((selectedDevice) => {
          return selectedDevice?.Name !== device.Name;
        })
      );
    });
  }, [optionalEquipment, value]);

  const swapExclusiveRequiredDevices = (newSelectedDevice: EquipmentInput) => {
    const deviceToSwapOut = sharedInputProps.formData.activities[
      activityIndex
    ]?.equipment?.find((device) => {
      return device.Group === newSelectedDevice.Group;
    });

    setUnselectedRequiredDevices((previousValue) => {
      const newDeviceIndex = previousValue.indexOf(newSelectedDevice);
      return previousValue.toSpliced(newDeviceIndex, 1, deviceToSwapOut!);
    });

    sharedInputProps.setFormData(
      produce((draftFormData) => {
        const equipmentArray =
          draftFormData.activities[activityIndex]?.equipment;
        if (equipmentArray) {
          const deviceToSwapOutIndex = equipmentArray.indexOf(deviceToSwapOut!);

          equipmentArray?.splice(deviceToSwapOutIndex, 1, newSelectedDevice);
        }
      }),
    );
  };

  return (
    <div css={equipmentCss}>
      {Boolean(value?.length) && (
        <div className="equipment">
          <label>Equipment</label>
          <div className="equipment-container">
            {/* Selected Equipment */}
            {value?.map((device, deviceIndex) => {
              return (
                <div
                  key={device.Name}
                  css={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    [breakpoints.medium]: { flexWrap: 'nowrap' },
                    gap: '20px',
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      const entryIndex = value.indexOf(device);
                      if (entryIndex !== -1) {
                        const newValue = [
                          ...(value || []).slice(0, entryIndex),
                          ...(value || []).slice(entryIndex + 1),
                        ];
                        if (newValue.length) {
                          sharedInputProps.onChange(newValue, name);
                        } else {
                          sharedInputProps.onChange(undefined, name);
                        }
                      }
                    }}
                    disabled={device.IsRequired}
                  >
                    <Chip
                      text={device.Name}
                      icon={
                        device.IsRequired ? null : (
                          <PlusIcon
                            width={15}
                            height={15}
                            color={colors.blue3}
                          />
                        )
                      }
                    />
                  </button>
                  <EquipmentLoadInput
                    sharedInputProps={sharedInputProps}
                    device={device}
                    activityIndex={activityIndex}
                    deviceIndex={deviceIndex}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {Boolean(
        unselectedOptionalDevices?.length || unselectedRequiredDevices,
      ) && (
        <div className="add-equipment">
          <label>Add Equipment</label>
          <div className="equipment-container">
            {/* Unselected Required Equipment*/}
            {unselectedRequiredDevices.map((device) => {
              return (
                <button
                  key={device.Name}
                  type="button"
                  onClick={() => {
                    swapExclusiveRequiredDevices(device);
                  }}
                >
                  <Chip
                    text={device.Name}
                    icon={<SwapVertIcon width={10} height={9} />}
                    tooltip={tooltips.exclusive_required_equipment}
                  />
                </button>
              );
            })}
            {/* Unselected Optional Equipment */}
            {unselectedOptionalDevices?.map((device) => {
              const isExclusiveOptionalDeviceSelected = value?.some(
                (selectedDevice) => {
                  return selectedDevice.Group === device.Group;
                },
              );
              return (
                <button
                  key={device.Name}
                  type="button"
                  onClick={() => {
                    const newValue = [
                      ...(value || []),
                      { ...device, Value: device.Default },
                    ];
                    sharedInputProps.onChange(newValue, name);
                  }}
                  disabled={isExclusiveOptionalDeviceSelected}
                >
                  <Chip
                    className={
                      isExclusiveOptionalDeviceSelected
                        ? 'disabled-chip'
                        : undefined
                    }
                    text={device.Name}
                    icon={
                      isExclusiveOptionalDeviceSelected ? null : (
                        <PlusIcon width={10} height={9} />
                      )
                    }
                    tooltip={
                      isExclusiveOptionalDeviceSelected
                        ? tooltips.disabled_equipment
                        : undefined
                    }
                  />
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
