/** @jsxImportSource @emotion/react */

import Tooltip from '@mui/material/Tooltip';

import { CSSObject } from '@emotion/react';
import { colors } from '../../style/colors';
import { fonts } from '../../style/fonts';
import { ReactElement } from 'react';
import { ellipsis } from '../../utils/cssUtils';

const chipCss: CSSObject = {
  padding: '4px 12px 6px 12px',
  borderRadius: '4px',
  backgroundColor: colors.mediumGrey,
  ...fonts.text,
  display: 'flex',
  alignItems: 'center',
  width: 'max-content',
  gap: '10px',
  justifyContent: 'center',
  ...ellipsis,
};

export default function Chip({
  text,
  tooltip,
  icon = null,
  cssObject,
  className: externalClassName,
}: {
  text: string | undefined;
  tooltip?: string;
  icon?: null | ReactElement;
  cssObject?: CSSObject;
  className?: string;
}) {
  let className = 'chip-container';
  if (externalClassName) {
    className = `${className} ${externalClassName}`;
  }
  return (
    <Tooltip title={tooltip || text}>
      <div className={className} css={{ ...chipCss, ...cssObject }}>
        {text && text}
        {icon && icon}
      </div>
    </Tooltip>
  );
}
