import { ThunkMiddleware } from 'redux-thunk';
import { RootState } from '../storeTypes';
import { AnyAction } from '@reduxjs/toolkit';
import { getRequestStatusSelector } from '../selectors/appSelectors';
import { fetchUsersAction } from '../thunks/usersThunks';
import { getUserSelector } from '../../models/factories/userFactories';

export default function refreshPatientsMiddleware(): ThunkMiddleware<RootState> {
  return (store) => (next) => (action: AnyAction) => {
    const requestStatusBefore = getRequestStatusSelector('createNewUser')(store.getState());
    const inProcessArray = requestStatusBefore.inProcess;
    next(action);
    const requestStatusAfter = getRequestStatusSelector('createNewUser')(store.getState());
    const succeededArray = requestStatusAfter.succeeded;
    const endedProcesses = succeededArray.filter((value) => inProcessArray.includes(value));
    if (endedProcesses.length > 0) {
      const user = getUserSelector(store.getState());
      if (user) {
        store.dispatch(fetchUsersAction(true));
      }
    }
  };
}
