import { AppThunk } from '../storeTypes';
import { createUser, fetchUser, fetchUsers, updateUser } from '../../services/usersService';
import { CaseDto, UserDto, UserInfoDto, UserRole } from '../../types/backendType';
import { toClinicianInfoDto, toPatientInfoDto } from '../../types/mappers/userMapper';
import { setUsersAction } from '../slices/usersSlice';
import { markStatusFunctionCreator, setMainUseMetadataAction } from '../slices/appSlice';
import { skipRequest } from '../utils/requestStatus';
import { getRequestStatusSelector, getUserIdSelector } from '../selectors/appSelectors';
import { PatientInput } from '../../components/forms/create-user-form/create-patient-form/patientFormTypes';
import { notify } from '../slices/notificationsSlice';
import { UserId } from '../../models/User';
import { getUserDtosSelector } from '../selectors/usersSelectors';
import { fetchCases } from '../../services/casesService';
import { setCasesAction } from '../slices/casesSlice';
import {
  ClinicianInput,
  UserTypes,
} from '../../components/forms/create-user-form/CreateUserUtils';

export function fulfillUserAction(userId: UserId): AppThunk {
  return async (dispatch, getState) => {
    const requestStatus = getRequestStatusSelector('user')(getState());
    const users = getUserDtosSelector(getState());
    if (skipRequest({ id: userId, requestStatus }) || users[userId]) {
      return;
    }
    const markStatus = markStatusFunctionCreator(dispatch, userId, 'user');
    markStatus('inProcess');
    let userDto: UserDto | undefined = undefined;
    let caseDtos: CaseDto[] | undefined = undefined;
    try {
      userDto = await fetchUser(userId);
      const role = userDto?.user_info.role;
      if (role === 'patient') {
        caseDtos = await fetchCases(userId);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      return;
    }
    if (userDto) {
      dispatch(setUsersAction({ users: [userDto] }));
    }
    if (caseDtos) {
      dispatch(setCasesAction({ patientId: userId, cases: caseDtos }));
    }
    markStatus('succeeded');
  };
}

export function prepareMainUserAction(userId: UserId, role: UserRole): AppThunk {
  return async (dispatch) => {
    dispatch(setMainUseMetadataAction({ userId, role }));
    dispatch(fulfillUserAction(userId));
  };
}

export function fetchUsersAction(force?: boolean): AppThunk {
  return async (dispatch, getState) => {
    const requestStatus = getRequestStatusSelector('fetchUsers')(getState());
    if (!force && skipRequest({ requestStatus, id: 'fetchUsers' })) {
      return;
    }
    const markStatus = markStatusFunctionCreator(dispatch, 'fetchUsers', 'fetchUsers');
    markStatus('inProcess');
    let users: UserDto[] | undefined = undefined;
    try {
      users = await fetchUsers({});
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      return;
    }
    markStatus('succeeded');
    if (!users) {
      return;
    }
    dispatch(setUsersAction({ users }));
  };
}

const createUserFailedMsg = 'We are having problems creating new patient';

export function createUserAction(
  type: UserTypes,
  userInput: PatientInput | ClinicianInput,
  emrPatientId?: string | undefined,
): AppThunk {
  return async (dispatch, getState) => {
    const requestStatus = getRequestStatusSelector('createNewUser')(getState());
    if (skipRequest({ requestStatus, id: userInput.id })) {
      return;
    }
    const markStatus = markStatusFunctionCreator(dispatch, userInput.id, 'createNewUser');
    markStatus('inProcess');
    let userDto: UserDto | undefined = undefined;
    try {
      const userInfo: UserInfoDto =
        type === UserTypes.Patient && 'height' in userInput
          ? toPatientInfoDto(userInput, emrPatientId)
          : toClinicianInfoDto(userInput);
      const assignorUserId = getUserIdSelector(getState());
      if (!assignorUserId) {
        throw new Error('user is missing');
      }
      userDto = await createUser({ userInfo, assignorUserId });
      if (!userDto) {
        throw new Error();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      if (e.message.includes('email already exists')) {
        throw new Error('Email already exists');
      } else {
        dispatch(
          notify({
            message: createUserFailedMsg,
            severity: 'error',
          }),
        );
      }
      return;
    }
    markStatus('succeeded');
    const name = `${userInput.firstName} ${userInput.lastName}`;
    dispatch(
      notify({
        message: `Patient '${name}' was created successfully`,
        severity: 'success',
      }),
    );
  };
}

const updateUserFailedMsg = 'We are having problems updating patient info';

export function updateUserAction(
  type: UserTypes,
  userInput: PatientInput | ClinicianInput,
  userId: string,
): AppThunk {
  return async (dispatch) => {
    const markStatus = markStatusFunctionCreator(dispatch, userInput.id, 'updateUser');
    markStatus('inProcess');
    let userDto: UserDto | undefined = undefined;
    try {
      const updateUserFormDto =
        type === UserTypes.Patient && 'height' in userInput
          ? toPatientInfoDto(userInput)
          : toClinicianInfoDto(userInput);
      userDto = await updateUser({ userId, updateUserFormDto: { ...updateUserFormDto } });
      if (!userDto) {
        throw new Error();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      dispatch(
        notify({
          message: updateUserFailedMsg,
          severity: 'error',
        }),
      );
      return;
    }
    dispatch(setUsersAction({ users: [userDto] }));
    markStatus('succeeded');
    const name = `${userInput.firstName} ${userInput.lastName}`;

    dispatch(
      notify({
        message: `Patient '${name}' was updated successfully`,
        severity: 'success',
      }),
    );
  };
}
