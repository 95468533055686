import { CSSObject } from '@emotion/react';

import { colors } from './colors';
import { fonts } from './fonts';

export const shapes = {
  borderRadius: 10,
  border: `1px solid ${colors.dividerGrey}`,
};

export const card: CSSObject = {
  position: 'relative',
  backgroundColor: colors.white,
  border: `1px solid ${colors.dividerGrey}`,
  borderRadius: shapes.borderRadius,
  overflow: 'hidden',
};

export const cardFooterCss: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px',
  borderTop: shapes.border,
};

export const cardHeaderCss: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  ...fonts.modalHeader,
  padding: 20,
  borderBottom: shapes.border,
};

export const cardBodyCss: CSSObject = {
  display: 'flex',
  gap: 20,
  flexDirection: 'column',
  padding: 20,
  maxHeight: 350,
  flexGrow: 1,
  overflowY: 'auto',
};
