import { RootState } from '../storeTypes';

export const getTreatmentPlansDtoSelector = (state: RootState) =>
  state.treatmentPlansState.treatmentPlans;

export const getMovementsResultsSelector = (state: RootState) =>
  state.treatmentPlansState.movementsResults;

export const getTreatmentPlanTemplatesDtoSelector = (state: RootState) => {
  return state.libraryState.treatmentPlanTemplates;
};
