import { transformLegacyEquipment } from '../components/patient-details/treatment-plans/TreatmentPlansUtils';
import {
  ActivityInput,
  EquipmentInput,
} from '../components/patient-details/treatment-plans/creation/treatmentPlanTypes';
import { MovementBaseDto, MovementDto, MovementInputDto } from '../types/backendType';
import { ActivityDefinition } from '../types/library';

export interface IActivity extends Omit<ActivityInput, 'id'> {}

export class Activity implements IActivity {
  private movementDto: MovementDto;

  private activityDefinitions: Record<string, ActivityDefinition> | undefined;

  activityDefinitionId: string;

  goals: Record<string, number>;

  comments?: string | undefined;

  type?: string[] | undefined;

  reps?: number | undefined;

  hold?: number | undefined;

  rest?: number | undefined;

  sets?: number | undefined;

  minimumToConsiderRep?: number | undefined;

  duration?: number | undefined;

  // load?: number | undefined;
  side?: string | undefined;

  equipment?: EquipmentInput[] | undefined;

  description?: string;

  private exercise_name?: string | undefined;

  constructor({
    activityDefinitions,
    movementDto,
  }: {
    movementDto: MovementDto;
    activityDefinitions?: Record<string, ActivityDefinition>;
  }) {
    this.movementDto = movementDto;
    this.activityDefinitions = activityDefinitions;
    Object.keys(this.movementDto.movement_info).forEach(
      (key: keyof MovementBaseDto & keyof MovementInputDto) => {
        this[key] = this.movementDto.movement_info[key];
      },
    );
    this.activityDefinitionId = this.movementDto.movement_id;
    this.goals = {};
    if (Array.isArray(this.movementDto.movement_info?.goals)) {
      this.movementDto.movement_info?.goals?.forEach(({ metric_name, value }) => {
        this.goals[metric_name] = value;
      });
    }
    const legacySelectedEquipment = this.movementDto.movement_info.assistant_device_optional;
    const optionalEquipment = this.activityDefinition?.optionalEquipment;
    const transformedLegacyEquipment = transformLegacyEquipment(
      legacySelectedEquipment,
      optionalEquipment,
    );
    if (transformedLegacyEquipment) {
      this.equipment?.push(...transformedLegacyEquipment);
    }
  }

  get activityDefinition() {
    return this.activityDefinitions?.[this.activityDefinitionId];
  }

  get exerciseName() {
    return this.exercise_name;
  }

  get id() {
    return `${this.movementDto.movement_info.plane} + ${this.movementDto.plan_index}`;
  }

  get bodyParts() {
    return this.movementDto.movement_info.body_parts;
  }

  get activityPosition() {
    return this.movementDto.movement_info.position;
  }

  get metrics() {
    const metricsWithGoals = this.movementDto.movement_info.post_exercise_metric?.filter(
      (metric) => {
        return Object.keys(this.goals).includes(metric.metric_name);
      },
    );

    return metricsWithGoals?.map((metric) => {
      return {
        name: metric.display_name,
        goal: this.goals[metric.metric_name],
      };
    });
  }
}
