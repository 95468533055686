export function getRandomItem<T>(array: T[]): T | undefined {
  return array[Math.floor(Math.random() * array.length)];
}

export function move<T>(array: T[], fromIndex: number, toIndex: number) {
  const newArray = [...array];
  const item = newArray.splice(fromIndex, 1)[0];
  if (item) {
    newArray.splice(toIndex, 0, item);
  }
  return newArray;
}
