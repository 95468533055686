import { SnackbarOrigin } from 'notistack';

export type AppNotificationId = string;

export type Severity = 'error' | 'warning' | 'info' | 'success';

export type AppNotification = {
  id: AppNotificationId;
  message: string;
  dismissed?: boolean;
  severity?: Severity;
  origin?: SnackbarOrigin;
};

export enum MeasurementSystems {
  Imperial = 'imperial',
  Metric = 'metric',
}
