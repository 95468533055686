import {
  OverTimeResult,
  getTwoSidedOverTimeMetricKeys,
  isMetricTwoSided,
} from '../components/patient-details/sessions/SessionsUntils';
import { MovementExecutionMetricResults } from '../types/backendType';
import { capitalizeString } from '../utils/stringUtils';
import { ActivityExecution } from './ActivityExecution';

export class OverTimeResults {
  private overTimeResults;

  constructor(
    overTimeMetricsArray: MovementExecutionMetricResults[],
    activityExecution: ActivityExecution,
  ) {
    const twoSidedMetricsKeys = getTwoSidedOverTimeMetricKeys(overTimeMetricsArray);
    const transformedOverTimeMetrics = overTimeMetricsArray.map(
      ({ metric_name: metricKey, metric_data: { value }, metric_data: { side } }) => {
        return {
          metricKey: twoSidedMetricsKeys.includes(metricKey)
            ? `${metricKey}_${side}`
            : metricKey,
          value,
          side,
        };
      },
    );

    const overTimeResults = transformedOverTimeMetrics.map((overTimeResult) => {
      const postMetric = activityExecution.getMetric(overTimeResult.metricKey);

      if (!postMetric) {
        const errorMsg = `postMetric "${overTimeResult.metricKey}" could not be found`;
        throw new Error(errorMsg);
      }

      return {
        ...overTimeResult,
        name: postMetric.name,
        unit: postMetric.unit,
        standardDeviation: activityExecution.results.find(
          (result) => result.key === overTimeResult.metricKey,
        )?.standardDeviation,
        automationLevel: postMetric.automationLevel,
      };
    });
    this.overTimeResults = overTimeResults;
  }

  get allActivityResults() {
    return this.overTimeResults;
  }

  getResultsByMetric(key: string) {
    return this.overTimeResults.find((result) => result.metricKey === key);
  }

  getFormattedMetricLabel(overTimeMetricResult: OverTimeResult) {
    const formattedSide = isMetricTwoSided(overTimeMetricResult.metricKey)
      ? capitalizeString(overTimeMetricResult.side)
      : '';

    return `${formattedSide} ${
      overTimeMetricResult.name ?? overTimeMetricResult.metricKey
    }`.trim();
  }
}
