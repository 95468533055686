import { CSSObject } from '@emotion/react';
import { shapes } from './shapes';
import { colors } from './colors';
import { fonts } from './fonts';
import { breakpoints } from './breakpoints';

const LABEL_GAP = 12;

const LABEL_SMALL = 107;
const LABEL_LARGE = 148;

export const formCss: CSSObject = {
  hr: {
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: colors.dividerGrey,
    borderBottomWidth: 'thin',
    flexShrink: 0,
  },
  'input, textarea, select': {
    ...fonts.text,
    width: '100%',
    padding: '0 10px',
    borderRadius: shapes.borderRadius,
    border: `1px solid ${colors.dividerGrey}`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover, &:focus, &:active': {
      border: `1px solid ${colors.blue4}`,
    },
    '&:disabled': {
      border: `1px solid ${colors.dividerGrey}`,
      cursor: 'default',
      color: colors.dividerGrey,
    },
  },

  'input, select': {
    height: '40px',
  },
  textarea: {
    padding: '10px',
    resize: 'none',
  },

  'input[type="checkbox"]': {
    display: 'grid',
    verticalAlign: 'middle',
    appearance: 'none',
    margin: 0,
    font: 'inherit',
    color: 'currentcolor',
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    placeContent: 'center',
    backgroundColor: colors.white,
    '&::before': {
      content: '""',
      width: '20px',
      height: '20px',
      transform: 'scale(0)',
      transition: '120ms transform ease-in-out',
      boxShadow: `inset 20px 20px ${colors.white}`,
    },
  },

  'input[type="checkbox"]:checked': {
    backgroundColor: colors.blue3,
    borderColor: colors.blue3,
    '&::before': {
      transform: 'scale(1)',
      width: '12px',
      height: '9px',
      transformOrigin: 'bottom left',
      clipPath:
        // eslint-disable-next-line max-len
        'path("M4.07573 8.82324L0.175729 4.90081C-0.0585762 4.66516 -0.0585762 4.28308 0.175729 4.0474L1.02424 3.19399C1.25854 2.95832 1.63846 2.95832 1.87277 3.19399L4.5 5.8363L10.1272 0.176739C10.3615 -0.058913 10.7415 -0.058913 10.9758 0.176739L11.8243 1.03015C12.0586 1.2658 12.0586 1.64789 11.8243 1.88356L4.92426 8.82326C4.68994 9.05892 4.31004 9.05892 4.07573 8.82324Z")',
    },
    '&:disabled': {
      backgroundColor: colors.dividerGrey,
    },
  },

  '.checkbox-label': {
    ...fonts.text,
    display: 'grid',
    gridTemplateColumns: '20px auto',
    gap: '10px',
    lineHeight: '20px',
    [breakpoints.small]: {
      marginLeft: LABEL_SMALL + LABEL_GAP,
    },

    [breakpoints.large]: {
      marginLeft: LABEL_LARGE + LABEL_GAP,
    },
  },

  select: {
    margin: '0',
    boxSizing: 'border-box',
    paddingRight: '41px',
    appearance: 'none',
    backgroundImage:
      // eslint-disable-next-line max-len
      'url("data:image/svg+xml;charset=UTF-8,%3Csvg%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M0%200H30C35.5228%200%2040%204.47715%2040%2010V30C40%2035.5228%2035.5228%2040%2030%2040H0V0Z%22%20fill%3D%22%2300A3F6%22%2F%3E%0A%3Cpath%20d%3D%22M14%2017L20%2023L26%2017%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E")',
    backgroundSize: '40px',
    backgroundPosition: '100% center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      backgroundImage:
        // eslint-disable-next-line max-len
        'url("data:image/svg+xml;charset=UTF-8,%3Csvg%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M0%200H30C35.5228%200%2040%204.47715%2040%2010V30C40%2035.5228%2035.5228%2040%2030%2040H0V0Z%22%20fill%3D%22%23004A86%22%2F%3E%0A%3Cpath%20d%3D%22M14%2017L20%2023L26%2017%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A")',
    },
    '&:disabled': {
      backgroundImage:
        // eslint-disable-next-line max-len
        'url("data:image/svg+xml;charset=UTF-8,%3Csvg%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20opacity%3D%220.5%22%3E%0A%3Cpath%20d%3D%22M0%200H30C35.5228%200%2040%204.47715%2040%2010V30C40%2035.5228%2035.5228%2040%2030%2040H0V0Z%22%20fill%3D%22%23979797%22%2F%3E%0A%3Cpath%20d%3D%22M14%2017L20%2023L26%2017%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A")',
    },
  },

  '.field-grouping': {
    display: 'flex',
    alignItems: 'center',
    gap: LABEL_GAP,
    width: 'fit-content',
    '&>label': {
      flexGrow: 0,
      flexShrink: 0,
      ...fonts.largeLabel,
      display: 'flex',

      [breakpoints.small]: {
        width: LABEL_SMALL,
      },

      [breakpoints.large]: {
        width: LABEL_LARGE,
      },

      '&.required': {
        '&::after': {
          content: '" *"',
          color: colors.red,
        },
      },
    },
    '&.error': {
      'input, select': {
        border: `1px solid ${colors.red}`,
      },
    },
  },

  '.checkbox-field-grouping': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: LABEL_GAP,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
    },
    '& > legend': {
      ...fonts.largeLabel,
    },
    '&.side-label': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      '& > legend': {
        width: 88,
      },
    },
    '&.error': {
      input: {
        border: `1px solid ${colors.red}`,
      },
    },
  },

  '.old-form': {
    '.field-grouping': {
      flexDirection: 'column',
      gap: LABEL_GAP,
      '&.side-label': {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        '&>label': {
          ...fonts.largeLabel,
          lineHeight: '40px',
        },
      },
    },
  },

  '.united-fields': {
    display: 'flex',
    position: 'relative',
    '& > input': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      '&:hover, &:focus': {
        zIndex: 2,
      },
    },
    '& > select': {
      position: 'absolute',
      left: '52px',
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    },
  },
  '.error-msg': {
    ...fonts.mini,
    color: colors.red,
  },

  button: {
    svg: { color: colors.blue3 },
    '&:hover': { svg: { color: colors.blue2 } },
    '&:active': {
      svg: { color: colors.blue4 },
    },
  },
};

// @include text;
//   &:focus{
//     outline:none;
//   }
// }
