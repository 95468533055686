/** @jsxImportSource @emotion/react */
import {
  Document as PdfDocument,
  Page,
  Text as PdfText,
  View,
  StyleSheet as PdfStyleSheet,
  Image as PdfImage,
  Font,
  Svg,
  Path,
  Rect,
} from '@react-pdf/renderer';
import { colors } from '../../../style/colors';
import { shapes } from '../../../style/shapes';
import PdfLogo from '../../../assets/pdfLogo.png';
import PdfHeaderLogo from '../../../assets/pdfLogo2.png';
import { format } from 'date-fns';
import { Activity } from '../../../models/Activity';
import { Session } from '../../../models/Session';
import { TableMetric } from './SessionsDigest';
import {
  getDisplayUnit,
  getMeasurementSystemUnit,
  getMeasurementSystemValue,
} from '../../../utils/unitUtils';
import { getGoalClassName } from './SessionLegend';
import { getDailyCompliance } from './SessionsUntils';
import { TreatmentPlan } from '../../../models/TreatmentPlan';

const SVG_PATH_D_STRING = `M2.00985 17.2447L0.484425 15.7583C-0.161475 15.1289 -0.161475 14.1111 
0.484425 13.4885L13.8353 0.472046C14.4812 -0.157349 15.5257 -0.157349 16.1647 0.472046L29.5156 
13.4818C30.1615 14.1111 30.1615 15.1289 29.5156 15.7516L27.9902 17.238C27.3374
17.8741 26.2723 17.8607 25.6333 17.2112L17.7519 9.14965V28.393C17.7519 29.2836 
17.0167 30 16.1028 30H13.904C12.9902 30 12.2549 29.2836 12.2549 
28.393V9.14965L4.3667 17.2179C3.72767 17.8741 2.66262 17.8875 2.00985 17.2447Z`;

type SessionInsightsProps = {
  patientName: string;
  clinicianName: string;
  caseId: string;
  duration: number;
  compliance: { value: number; activitiesWithoutExecution?: Activity[] };
  sessions: Session[];
  digestData: [] | TableMetric[];
  measurementSystem: string;
  allSessionsByDate?: Record<string, Session[]>;
  selectedDate: string;
  lastTreatmentPlanOfDay: TreatmentPlan | undefined;
};

Font.register({
  family: 'IntelOne Text',
  fonts: [
    { src: '/fonts/intelone-text-regular.woff' },
    { src: '/fonts/intelone-text-medium.woff' },
  ],
});

const styles = PdfStyleSheet.create({
  page: {
    padding: '77px 77px',
    fontFamily: 'IntelOne Text',
    fontSize: '12px',
    fontWeight: 400,
  },
  logo: {
    width: '112px',
    height: '49px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '14px',
  },
  generalInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: shapes.border,
    backgroundColor: colors.backgroundDarkerGrey,
    padding: '14px',
    marginBottom: '20px',
    width: '100%',
  },
  generalInfoSubcontainer: {
    display: 'flex',
    gap: '15px',
  },
  generalInfoHeader: {
    fontWeight: 500,
    color: colors.blue2,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colors.darkGrey,
    position: 'absolute',
    top: '40px',
    right: '77px',
    left: '77px',
    fontSize: '10px',
  },
  headerLogo: {
    width: '88px',
    height: '16px',
  },
  resultsTable: {
    display: 'flex',
    flexDirection: 'column',
    border: `0.5px solid ${colors.dividerGrey}`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  columnCell: {
    height: '41px',
    minHeight: '41px',
    border: `0.5px solid ${colors.dividerGrey}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '12px',
    border: `0.5px solid ${colors.dividerGrey}`,
    fontSize: '9px',
    fontWeight: 500,
    padding: '6px 9px',
    height: '30px',
  },
  legendSubcontainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '6px',
  },
  legendArrow: {
    width: '8px',
    height: '8px',
  },
  legendSquare: {
    width: '6px',
    height: '6px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colors.darkGrey,
    position: 'absolute',
    bottom: '40px',
    right: '77px',
    left: '77px',
    fontSize: '10px',
  },
  trend: {
    width: '10px',
    height: '10px',
  },
  'met-goal': {
    color: colors.green,
  },
  'not-meet-goal': {
    color: colors.red,
  },
  'no-goal': {
    color: colors.darkGrey,
  },
});

function getBorders(
  currentMetric: TableMetric,
  currentElementIndex: number,
  digestData: TableMetric[],
  isPreviousTheSame: boolean | undefined,
) {
  const next = digestData[currentElementIndex + 1];
  const isNextTheSame =
    next &&
    (next.category === currentMetric.category ||
      next.activityName === currentMetric.activityName);

  if (currentElementIndex === 0) {
    if (isNextTheSame) {
      return { borderBottom: 'none' };
    }
    return;
  }

  if (isPreviousTheSame && !isNextTheSame) {
    return { borderTop: 'none' };
  }

  if (!isPreviousTheSame && isNextTheSame) {
    return { borderBottom: 'none' };
  }

  if (isPreviousTheSame && isNextTheSame) {
    return { borderTop: 'none', borderBottom: 'none' };
  }
}

function Footer({ clinicianName }: { clinicianName: string | undefined }) {
  const currentDate = format(new Date(), 'MM/dd/yyy');

  return (
    <View fixed style={styles.footer}>
      <PdfText>
        Exported by {clinicianName} on {currentDate}
      </PdfText>
      <PdfText
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
      />
    </View>
  );
}

export default function SessionInsightsPDF({
  patientName,
  clinicianName,
  caseId,
  duration,
  compliance,
  sessions,
  digestData,
  measurementSystem,
  selectedDate,
  allSessionsByDate,
  lastTreatmentPlanOfDay,
}: SessionInsightsProps) {
  const formattedDate =
    sessions[0] && format(sessions[0].startDate, 'MM/dd/yyy');

  const orderedDatesArray = Object.keys(allSessionsByDate || {});
  const currentDateIndex = orderedDatesArray.indexOf(selectedDate);
  const previousDate = orderedDatesArray[currentDateIndex + 1];

  const getPreviousDailyCompliance = () => {
    if (!previousDate) {
      return;
    }
    const previousDaySessions = allSessionsByDate?.[previousDate];
    return getDailyCompliance(previousDaySessions, lastTreatmentPlanOfDay)
      ?.value;
  };

  const complianceDifference =
    compliance.value - (getPreviousDailyCompliance() || compliance.value);
  return (
    <PdfDocument>
      <Page style={styles.page}>
        <View
          style={styles.header}
          fixed
          render={({ pageNumber }) => {
            return (
              pageNumber !== 1 && (
                <>
                  <PdfImage src={PdfHeaderLogo} style={styles.headerLogo} />
                  <PdfText>Session {formattedDate}</PdfText>
                  <PdfText>
                    {patientName} ({caseId})
                  </PdfText>
                </>
              )
            );
          }}
        >
          <PdfImage src={PdfHeaderLogo} style={styles.headerLogo} />
          <PdfText>Session {formattedDate}</PdfText>
          <PdfText>
            {patientName} ({caseId})
          </PdfText>
        </View>

        <PdfImage src={PdfLogo} style={styles.logo} />
        <PdfText style={styles.title}>Session Top Insights</PdfText>
        <View style={styles.generalInfoContainer}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '40px',
            }}
          >
            <View style={styles.generalInfoSubcontainer}>
              <View>
                <PdfText style={styles.generalInfoHeader}>Assigned To</PdfText>
                <PdfText style={{ fontWeight: 500, fontSize: '15px' }}>
                  {patientName}
                </PdfText>
              </View>
              <View>
                <PdfText style={styles.generalInfoHeader}>Compliance</PdfText>
                <PdfText
                  style={{
                    color: compliance.value === 100 ? colors.green : colors.red,
                  }}
                >
                  {compliance.value}%
                </PdfText>
              </View>
            </View>

            <View style={styles.generalInfoSubcontainer}>
              <View>
                <PdfText style={styles.generalInfoHeader}>Case ID</PdfText>
                <PdfText>{caseId}</PdfText>
              </View>
              {/* <View>
                <PdfText style={styles.generalInfoHeader}>
                  Skipped Activities
                </PdfText>
                {compliance.activitiesWithoutExecution?.map(
                  (skippedActivity, index) => {
                    return (
                      <PdfText
                        key={index}
                      >{`${skippedActivity.exerciseName} 0%-Not Started`}</PdfText>
                    );
                  },
                )}
              </View> */}
            </View>

            <View style={styles.generalInfoSubcontainer}>
              <View>
                <PdfText style={styles.generalInfoHeader}>
                  Date Performed
                </PdfText>
                <PdfText>{formattedDate}</PdfText>
              </View>
            </View>

            <View>
              <PdfText style={styles.generalInfoHeader}>Duration</PdfText>
              <PdfText>{duration}</PdfText>
            </View>
          </View>
        </View>

        <View style={styles.resultsTable}>
          <View style={styles.legendContainer}>
            <View style={styles.legendSubcontainer}>
              <Svg viewBox="0 0 30 30" height="8px" width="8px">
                <Path d={SVG_PATH_D_STRING} fill={colors.green} />
              </Svg>
              <PdfText>Improved</PdfText>
            </View>
            <View style={styles.legendSubcontainer}>
              <Svg viewBox="0 0 30 30" height="8px" width="8px">
                <Path d={SVG_PATH_D_STRING} fill={colors.red} />
              </Svg>
              <PdfText>Regressed</PdfText>
            </View>
            <View style={styles.legendSubcontainer}>
              <View
                style={{
                  ...styles.legendSquare,
                  backgroundColor: colors.green,
                }}
              />
              <PdfText>Met Goal</PdfText>
            </View>
            <View style={styles.legendSubcontainer}>
              <View
                style={{ ...styles.legendSquare, backgroundColor: colors.red }}
              />
              <PdfText>Did Not Meet Goal</PdfText>
            </View>
            <View style={styles.legendSubcontainer}>
              <View
                style={{
                  ...styles.legendSquare,
                  backgroundColor: colors.black,
                }}
              />
              <PdfText>No Goal</PdfText>
            </View>
          </View>

          <View style={{ ...styles.row, fontWeight: 500 }}>
            <View style={{ ...styles.columnCell, width: '63px' }}>
              <PdfText></PdfText>
            </View>
            <View style={{ ...styles.columnCell, width: '57px' }}>
              <PdfText>Activity</PdfText>
            </View>
            <View style={{ ...styles.columnCell, width: '67px' }}>
              <PdfText>Metric</PdfText>
            </View>
            <View style={{ ...styles.columnCell, width: '56px' }}>
              <PdfText>Current</PdfText>
            </View>
            <View style={{ ...styles.columnCell, width: '47px' }}>
              <PdfText>Trend</PdfText>
            </View>
            <View style={{ ...styles.columnCell, width: '167px' }}>
              <PdfText>Notes</PdfText>
            </View>
          </View>
          {digestData?.map((result, resultIndex) => {
            const previous = digestData[resultIndex - 1];
            const isPreviousTheSame =
              previous &&
              (previous.category === result.category ||
                previous.activityName === result.activityName);

            const bordersObject = getBorders(
              result,
              resultIndex,
              digestData,
              isPreviousTheSame,
            );

            const lastResult = result.results[0];
            const previousResult = result.results[1];

            if (!lastResult) {
              throw new Error(
                'Metric results cannot be undefined at this point',
              );
            }
            const measurementSystemUnit = getMeasurementSystemUnit(
              lastResult.unit,
              measurementSystem,
            );

            const measurementSystemValue = getMeasurementSystemValue(
              lastResult.value,
              measurementSystemUnit,
              measurementSystem,
            )?.toFixed(0);

            const goalClassName = getGoalClassName(lastResult);

            const valueDifference =
              previousResult && lastResult
                ? lastResult.value - previousResult.value
                : 0;

            return (
              <View key={resultIndex} style={styles.row}>
                <View
                  style={{
                    ...styles.columnCell,
                    width: '63px',
                    fontWeight: 500,
                    ...bordersObject,
                  }}
                >
                  {!isPreviousTheSame && <PdfText>{result.category}</PdfText>}
                </View>
                <View
                  style={{
                    ...styles.columnCell,
                    width: '57px',
                    fontWeight: 500,
                    ...bordersObject,
                  }}
                >
                  {!isPreviousTheSame && (
                    <PdfText>{result.activityName}</PdfText>
                  )}
                </View>
                <View style={{ ...styles.columnCell, width: '67px' }}>
                  <PdfText>{result.results[0]?.name}</PdfText>
                </View>
                <View style={{ ...styles.columnCell, width: '56px' }}>
                  <PdfText style={styles[goalClassName]}>
                    {`${measurementSystemValue}${getDisplayUnit(
                      measurementSystemUnit,
                    )}`}
                  </PdfText>
                  <PdfText> ({result.results[0]?.side})</PdfText>
                </View>
                <View style={{ ...styles.columnCell, width: '47px' }}>
                  <Svg
                    viewBox="0 0 30 30"
                    height="16px"
                    width="16px"
                    style={{
                      transform:
                        valueDifference > 0 ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                  >
                    <Path
                      d={SVG_PATH_D_STRING}
                      fill={
                        lastResult.improvementDirection === 'up'
                          ? colors.green
                          : colors.red
                      }
                    />
                  </Svg>
                </View>
                <View style={{ ...styles.columnCell, width: '167px' }}>
                  {result.notesAsText.map((note, noteIndex) => {
                    return <PdfText key={noteIndex}>{note}</PdfText>;
                  })}
                </View>
              </View>
            );
          })}
          <View style={styles.row}>
            <View
              style={{
                ...styles.columnCell,
                borderRight: 'none',
                width: '75px',
                fontWeight: 500,
              }}
            >
              <PdfText>Compliance</PdfText>
            </View>
            <View
              style={{
                ...styles.columnCell,
                borderRight: 'none',
                borderLeft: 'none',
                width: '45px',
              }}
            />
            <View
              style={{
                ...styles.columnCell,
                borderLeft: 'none',
                width: '67px',
              }}
            />
            <View style={{ ...styles.columnCell, width: '56px' }}>
              <PdfText
                style={{
                  color: compliance.value === 100 ? colors.green : colors.red,
                }}
              >
                {compliance.value}%
              </PdfText>
            </View>
            <View style={{ ...styles.columnCell, width: '47px' }}>
              {complianceDifference ? (
                <Svg
                  viewBox="0 0 30 30"
                  height="16px"
                  width="16px"
                  style={{
                    transform:
                      complianceDifference > 0
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                  }}
                >
                  <Path
                    d={SVG_PATH_D_STRING}
                    fill={complianceDifference > 0 ? colors.green : colors.red}
                  />
                </Svg>
              ) : (
                <Svg width="17px" height="4px" viewBox="0 0 17 4">
                  <Rect
                    width="16.5551"
                    height="3.4252"
                    rx="0.570866"
                    fill={colors.darkGrey}
                  />
                </Svg>
              )}
            </View>
            <View style={{ ...styles.columnCell, width: '167px' }}>
              {complianceDifference && previousDate && (
                <PdfText>{`${
                  complianceDifference > 0 ? 'Increased' : 'Decreased'
                } by ${complianceDifference} compared to visit from ${format(
                  new Date(previousDate),
                  'MMMM do',
                )}`}</PdfText>
              )}
            </View>
          </View>
        </View>
        <Footer clinicianName={clinicianName} />
      </Page>
    </PdfDocument>
  );
}
