import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { DeepPartial, Path, ToStringValues } from '../../../utils/typeScriptUtils';
import { get, set } from 'lodash-es';
import { Immutable, produce } from 'immer';

export type FormErrors<FormData> = DeepPartial<ToStringValues<FormData>>;
export type SetFormData<FormData> = Dispatch<SetStateAction<FormData>>;
export type OnChange<FormData> = (value: any, pathName: Path<FormData>) => void;

export function useForm<FormData>(
  initFormData: FormData | undefined,
  validate: (formData: FormData) => FormErrors<FormData> | undefined,
) {
  const [formData, privateSetFormData] = useState<FormData | undefined>();
  const [formErrors, setFormErrors] = useState<FormErrors<FormData> | undefined>();
  const prevFormData = useRef<FormData | undefined>();
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);

  const isDirty = useRef<boolean>(false);
  const [dirtyPaths, setDirtyPaths] = useState<Path<FormData>[]>([]);

  useEffect(() => {
    if (!shouldValidate || !formData) {
      return;
    }
    const newFormErrors = validate(formData);
    setFormErrors(newFormErrors);
  }, [formData, shouldValidate, validate]);

  useEffect(() => {
    if (initFormData) {
      privateSetFormData(initFormData);
    }
  }, [initFormData]);

  const setFormData: SetFormData<FormData> = (
    value: React.SetStateAction<FormData | undefined>,
  ) => {
    isDirty.current = true;
    prevFormData.current = formData;
    privateSetFormData(value);
  };

  const onChange: OnChange<FormData> = (value: any, pathName: Path<FormData>) => {
    let newDirtyPaths = dirtyPaths;
    const origValue = get(initFormData, pathName);
    if (value !== origValue) {
      if (!dirtyPaths.includes(pathName)) {
        newDirtyPaths.push(pathName);
      }
    } else {
      newDirtyPaths = dirtyPaths.filter((currentPath) => currentPath !== pathName);
    }
    setDirtyPaths([...newDirtyPaths]);
    prevFormData.current = formData;
    privateSetFormData(
      produce((draftFormData: Immutable<FormData> | undefined): void => {
        if (draftFormData) {
          set(draftFormData, pathName, value);
        }
      }),
    );
  };

  return {
    formData,
    prevFormData: prevFormData.current,
    setFormData,
    formErrors,
    setFormErrors,
    setShouldValidate,
    onChange,
    isDirty: isDirty.current || dirtyPaths.length > 0,
  };
}
