/** @jsxImportSource @emotion/react */

import { useState, useEffect, useRef } from 'react';
import { CSSObject } from '@emotion/react';

import Loader from '../Loader';
import { AssetMetadataProvider } from '../AssetMetadataProvider';

export default function ActivityVideoView({
  videoUrl,
  onError,
  currentTime,
  onPlay,
  onVideoTimelineSet,
  isRequestFinished,
}: {
  videoUrl: string | undefined;
  onError?: () => void;
  currentTime?: number | undefined;
  onPlay?: () => void;
  onVideoTimelineSet?: (time: number) => void;
  isRequestFinished: boolean | undefined;
}) {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [videoIsReady, setVideoIsReady] = useState<boolean>(false);
  const [videoSize, setVideoSize] = useState<
    { width: number; height: number } | undefined
  >(undefined);
  const [containerSize, setContainerSize] = useState<
    { width: number; height: number } | undefined
  >(undefined);

  const videoCss: CSSObject = {};
  if (!videoIsReady) {
    videoCss.visibility = 'hidden';
  }

  useEffect(() => {
    if (currentTime === undefined || !videoRef.current) {
      return;
    }
    videoRef.current.currentTime = currentTime;
  }, [currentTime, videoRef.current]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.addEventListener('timeupdate', function () {
      const seekedTime = videoRef?.current?.currentTime;
      if (seekedTime !== undefined) {
        onVideoTimelineSet?.(seekedTime);
      }
    });
    videoRef.current.addEventListener('seeked', function () {
      const seekedTime = videoRef?.current?.currentTime;
      if (seekedTime !== undefined) {
        onVideoTimelineSet?.(seekedTime);
      }
    });
  }, [videoRef.current]);

  useEffect(() => {
    if (!videoUrl) {
      return;
    }
    AssetMetadataProvider.getInstance()
      .getVideoMetadata(videoUrl)
      .then(({ size: newSize }) => {
        setVideoSize(newSize);
      });
  }, [videoUrl]);

  useEffect(() => {
    setVideoIsReady(false);
  }, [videoUrl]);

  let factor = 1 / 4;
  if (videoSize && containerSize) {
    if (videoSize.height > videoSize.width) {
      factor = containerSize.height / videoSize.height;
    } else if (videoSize.height <= videoSize.width) {
      factor = containerSize.width / videoSize.width;
    }
  }

  if (isRequestFinished && !videoUrl) {
    return (
      <div
        className="activity-video"
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        no video found
      </div>
    );
  }

  return (
    <>
      <div
        className="activity-video"
        css={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
        ref={(el) => {
          if (!el) {
            return;
          }
          if (
            !containerSize ||
            containerSize.height !== el.offsetHeight ||
            containerSize.width !== el.offsetWidth
          ) {
            setContainerSize({
              height: el.offsetHeight,
              width: el.offsetWidth,
            });
          }
        }}
      >
        {videoSize && (
          <video
            css={{ ...videoCss }}
            ref={videoRef}
            controls
            autoPlay
            src={videoUrl}
            onError={() => {
              // eslint-disable-next-line no-console
              console.error('error with playing activity video');
              onError?.();
            }}
            controlsList="nodownload"
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
            onCanPlay={() => {
              setVideoIsReady(true);
            }}
            onPlay={() => {
              onPlay?.();
            }}
            width={videoSize.width * factor}
            height={videoSize.height * factor}
          />
        )}
        {!videoIsReady && <Loader />}
      </div>
    </>
  );
}
