/** @jsxImportSource @emotion/react */

import { useEffect } from 'react';
import { CSSObject } from '@emotion/react';
import { useSelector } from 'react-redux';
import {
  Switch as Routes,
  Route,
  useHistory,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import Plans from './plans/Plans';
import PlanCreation from './creation/PlanCreation';
import Loader from '../../commons/Loader';
import { getPatientTreatmentPlansSelector } from '../../../models/factories/treatmentPlanFactories';

const treatmentPlansContainer: CSSObject = {
  position: 'relative',
  height: '100%',
  width: '100%',
};

export default function TreatmentPlans() {
  const history = useHistory();
  const location = useLocation();

  const { url } = useRouteMatch();

  const treatmentPlans = useSelector(getPatientTreatmentPlansSelector);

  useEffect(() => {
    const pathname = history.location.pathname;
    if (pathname.includes('edit') || pathname.includes('view')) {
      return;
    }
    if (treatmentPlans !== undefined) {
      if (treatmentPlans.length === 0) {
        history.replace({
          pathname: `${url}/edit`,
          search: history.location.search,
        });
      } else if (treatmentPlans.length > 0) {
        history.replace({
          pathname: `${url}/view`,
          search: history.location.search,
        });
      }
    }
  }, [treatmentPlans, location]);

  return (
    <div className="treatment-plans-container" css={treatmentPlansContainer}>
      <Routes>
        <Route path={`${url}`} render={() => <Loader />} exact />
        <Route path={`${url}/view`} render={() => <Plans />} />
        <Route path={`${url}/edit`} render={() => <PlanCreation />} />
      </Routes>
    </div>
  );
}
