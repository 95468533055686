/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { buttons } from '../../style/buttons';

type MessageDetails = { msg: string; answers: string[] };

export class AskUser {
  private static instance: AskUser | undefined;

  private static waitForAnswerPromiseResolve: (value: string) => void;

  private static onNewMsgCallback: (newMessageDetails: MessageDetails) => void;

  private constructor() {
    // private constructor
  }

  static getInstance(): AskUser {
    if (AskUser.instance === undefined) {
      AskUser.instance = new AskUser();
    }
    return AskUser.instance;
  }

  askUser(messageDetails: MessageDetails): Promise<string> {
    return new Promise<string>((resolve) => {
      AskUser.waitForAnswerPromiseResolve = resolve;
      AskUser.onNewMsgCallback(messageDetails);
    });
  }

  giveAnAnswer(answer: string) {
    AskUser.waitForAnswerPromiseResolve(answer);
  }

  registerOnNewMsg(callback: (newMessageDetails: MessageDetails) => void) {
    AskUser.onNewMsgCallback = callback;
  }
}

export function AskUserView() {
  const [messageDetails, setMessageDetails] = useState<
    MessageDetails | undefined
  >();

  useEffect(() => {
    AskUser.getInstance().registerOnNewMsg((newMessageDetails) => {
      setMessageDetails(newMessageDetails);
    });
  }, []);

  return (
    <>
      {messageDetails && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText>{messageDetails.msg}</DialogContentText>
          </DialogContent>
          <DialogActions>
            {messageDetails.answers.map((answer) => {
              const buttonStyle =
                answer === 'Cancel' ? buttons.secondary : buttons.primary;
              return (
                <button
                  css={{ ...buttonStyle }}
                  key={answer}
                  type="button"
                  onClick={() => {
                    setMessageDetails(undefined);
                    AskUser.getInstance().giveAnAnswer(answer);
                  }}
                >
                  {answer}
                </button>
              );
            })}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
