/** @jsxImportSource @emotion/react */

import { Dialog } from '@mui/material';
import { fonts } from '../../../style/fonts';
import { shapes } from '../../../style/shapes';
import { format } from 'date-fns';
import Chip from '../../commons/Chip';
import ActivityVideo from '../../commons/activity-video/ActivityVideo';
import CloseIcon from './../../../assets/close.svg';
import { ActivityExecution } from '../../../models/ActivityExecution';
import { CSSObject } from '@emotion/react';

const overTimeMetricsHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 71,
  padding: '0 30px',
  ...fonts.navTab,
  borderBottom: shapes.border,

  '& > div:first-of-type': {
    display: 'flex',
    '& > *:first-of-type': {
      marginRight: '2.5rem',
    },
  },

  '& > svg': {
    cursor: 'pointer',
  },
};

const videoContainer: CSSObject = {
  display: 'flex',
  padding: 32,
  justifyContent: 'center',
  '.activity-video, .graph-view': {
    width: 640,
    height: 360,
    border: shapes.border,
    borderRadius: shapes.borderRadius,
  },
};

type VideoModalProps = {
  onClose: () => void;
  activityExecution: ActivityExecution;
  metricKey: string | undefined;
};

export default function VideoModal({
  onClose,
  activityExecution,
  metricKey,
}: VideoModalProps) {
  const metric =
    activityExecution &&
    activityExecution.activityDefinition?.postExerciseMetric?.find(
      ({ metric_name }) => metric_name === metricKey,
    );

  const formattedDate = format(activityExecution.stopDate, ' MMM dd, yyyy');

  const formattedTime = format(activityExecution.stopDate, ' hh:mm a');

  return (
    <Dialog
      onClose={onClose}
      open={true}
      hideBackdrop={true}
      css={{
        '.MuiPaper-root': {
          maxWidth: 'unset',
        },
      }}
    >
      <div css={overTimeMetricsHeaderContainer}>
        <div>
          <span>
            {`${activityExecution.name}: ${
              metric?.display_name ?? metricKey
            } - ${formattedDate} ${formattedTime}`}
          </span>
          {/* TODO: Add Created By when available */}
          <Chip text={activityExecution.type} />
        </div>
        <CloseIcon width={13} onClick={onClose} />
      </div>
      <div css={videoContainer}>
        <ActivityVideo
          sessionId={activityExecution.sessionId}
          subSessionId={activityExecution.subSessionId}
        />
      </div>
    </Dialog>
  );
}
