import { TranscriptOutputDto, TranscriptSessionDto } from '../types/backendType';
import httpClient from './httpClient';

//POST transcript/create
export async function createTranscriptSession(patientId: string) {
  const response = await httpClient.post<TranscriptSessionDto>(
    'transcript/create',
    { audio_media_format: 'wav', num_speakers: 1 },
    {
      headers: { 'x-user-id': patientId },
    },
  );
  return response.data;
}

//POST transcript/process
export async function runTranscriptProcess({
  patientId,
  sessionId,
}: {
  patientId: string;
  sessionId: string;
}) {
  const response = await httpClient.post(`transcript/process/${sessionId}`, null, {
    headers: { 'x-user-id': patientId },
  });
  return response.data;
}

//GET /transcript/output
export async function getTranscriptOutput({
  patientId,
  sessionId,
}: {
  patientId: string;
  sessionId: string;
}) {
  const response = await httpClient.get<TranscriptOutputDto>(
    `transcript/output/${sessionId}`,
    {
      headers: { 'x-user-id': patientId },
    },
  );
  return response.data;
}
