import { ThunkMiddleware } from 'redux-thunk';
import { RootState } from '../storeTypes';
import { AnyAction } from '@reduxjs/toolkit';
import { getRequestStatusSelector } from '../selectors/appSelectors';
import { fetchAssignTreatmentPlansAction } from '../thunks/treatmentPlansThunks';
import { getCurrentPatientSelector } from '../../models/factories/userFactories';

export default function refreshPatientTreatmentPlansMiddleware(): ThunkMiddleware<RootState> {
  return ({ getState, dispatch }) =>
    (next) =>
    (action: AnyAction) => {
      const requestStatusBefore = getRequestStatusSelector('assignTreatmentPlan')(getState());
      const inProcessArray = requestStatusBefore.inProcess;
      next(action);
      const requestStatusAfter = getRequestStatusSelector('assignTreatmentPlan')(getState());
      const succeededArray = requestStatusAfter.succeeded;
      const endedProcesses = succeededArray.filter((value) => inProcessArray.includes(value));
      const currentPatient = getCurrentPatientSelector(getState());
      if (currentPatient && endedProcesses.length > 0) {
        dispatch(
          fetchAssignTreatmentPlansAction({
            patientId: currentPatient.id,
          }),
        );
      }
    };
}
