/** @jsxImportSource @emotion/react */

import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
  DraggableProvided,
} from 'react-beautiful-dnd';
import { CSSObject } from '@emotion/react';

import Chevron from '../../assets/chevron.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import DragIcon from '../../assets/drag.svg';
import { shapes } from '../../style/shapes';
import { colors } from '../../style/colors';
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { breakpoints } from '../../style/breakpoints';

const accordionCss: CSSObject = {
  '.MuiAccordionSummary-root': {
    flexDirection: 'row-reverse',
    padding: '0.6rem 1.5rem',
  },
  '.MuiAccordionSummary-root.Mui-expanded': {
    borderBottom: shapes.border,
    minHeight: 'unset',
  },
  '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '.MuiAccordionSummary-content': {
    margin: 0,
    letterSpacing: '0.36px',
    fontSize: '14px',
    maxWidth: '100%',

    '&:first-of-type': {
      marginLeft: '5px',

      [breakpoints.large]: {
        marginLeft: '15px',
      },
    },
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,

    '&:first-of-type': {
      marginLeft: '15px',
    },
  },
  '.MuiAccordionDetails-root': {
    padding: 0,
  },

  boxShadow: 'none',
  border: shapes.border,
  width: '100%',
  borderRadius: `${shapes.borderRadius}px`,

  '&&': {
    marginTop: 'unset',
  },
  '&:before': {
    display: 'none',
  },
};

const accordionItemViewCss: CSSObject = {
  display: 'flex',
  marginTop: '20px',
  '&:first-of-type': {
    marginTop: '0',
  },
  '& > .drag-icon': {
    margin: '10px 6px',
  },
};

function extractId<T extends {}>(
  item: T,
  getId: ((item: T) => string) | undefined,
) {
  if ('id' in item) {
    return item.id as string;
  }
  if (getId) {
    return getId(item);
  }
  throw new Error('pls supply item with id or getId');
}

const AccordionItemView = forwardRef(function AccordionItemView<T extends {}>(
  {
    item,
    index,
    expendedItemsIds,
    setExpendedItemsIds,
    getAccordionDetails,
    getAccordionSummary,
    draggableProvided,
    getId,
    isDraggable = true,
  }: {
    item: T;
    index: number;
    expendedItemsIds: string[];
    setExpendedItemsIds: (expendedItemsIds: string[]) => void;
    getAccordionDetails: (index: number, item: T) => JSX.Element;
    getAccordionSummary: (index: number, item: T) => JSX.Element;
    draggableProvided?: DraggableProvided;
    getId?: (item: T) => string;
    isDraggable?: boolean;
  },
  ref: React.RefObject<HTMLDivElement>,
) {
  const id = extractId(item, getId);
  return (
    <div
      css={accordionItemViewCss}
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
    >
      {isDraggable && (
        <div {...draggableProvided?.dragHandleProps} className="drag-icon">
          <DragIcon css={{ width: '27px', height: '25px' }} />
        </div>
      )}
      <Accordion
        key={id}
        expanded={expendedItemsIds.includes(id)}
        sx={accordionCss}
        TransitionProps={{ unmountOnExit: true }}
        onChange={() => {
          if (expendedItemsIds.includes(id)) {
            setExpendedItemsIds(
              expendedItemsIds.filter((currentId) => currentId !== id),
            );
          } else {
            expendedItemsIds.push(id);
            setExpendedItemsIds([...expendedItemsIds]);
          }
        }}
        ref={ref}
      >
        <AccordionSummary
          expandIcon={
            <Chevron height={17} width={11} css={{ color: colors.blue4 }} />
          }
          // aria-controls="panel1bh-content"
        >
          <div css={{ display: 'flex', width: '100%' }}>
            <div css={{ width: '100%' }}>
              {getAccordionSummary(index, item)}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>{getAccordionDetails(index, item)}</AccordionDetails>
      </Accordion>
    </div>
  );
});

export function AccordionView<T extends {}>({
  items,
  expendedItemsIds,
  setExpendedItemsIds,
  getAccordionDetails,
  getAccordionSummary,
  getId,
}: {
  items: T[];
  expendedItemsIds: string[];
  setExpendedItemsIds: (expendedItemsIds: string[]) => void;
  getAccordionDetails: (index: number, item: T) => JSX.Element;
  getAccordionSummary: (index: number, item: T) => JSX.Element;
  getId?: (item: T) => string;
}) {
  return (
    <>
      {items.map((item, index) => {
        const id = extractId(item, getId);
        return (
          <AccordionItemView
            key={id}
            item={item}
            index={index}
            expendedItemsIds={expendedItemsIds}
            setExpendedItemsIds={setExpendedItemsIds}
            getAccordionDetails={getAccordionDetails}
            getAccordionSummary={getAccordionSummary}
            getId={getId}
            isDraggable={false}
          />
        );
      })}
    </>
  );
}

export default function DraggableAccordionView<T extends {}>({
  items,
  expendedItemsIds,
  setExpendedItemsIds,
  onDragEnd,
  getAccordionDetails,
  getAccordionSummary,
  getId,
  scrollCallback,
}: {
  items: T[];
  expendedItemsIds: string[];
  setExpendedItemsIds: (expendedItemsIds: string[]) => void;
  onDragEnd: OnDragEndResponder;
  getAccordionDetails: (index: number, item: T) => JSX.Element;
  getAccordionSummary: (index: number, item: T) => JSX.Element;
  getId?: (item: T) => string;
  scrollCallback?: (callback: (id: string) => void) => void;
}) {
  const accordionItemsRefMaps = useRef<Record<string, HTMLDivElement>>({});

  const scrollToAccordionItem = useCallback((itemId: string) => {
    const el = accordionItemsRefMaps.current[itemId];
    el?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    scrollCallback?.(scrollToAccordionItem);
  }, [scrollTo]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="test-items">
        {(droppableProvided) => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {items.map((item, index) => {
              return (
                <Draggable
                  key={`${index}`}
                  draggableId={`item-${index}`}
                  index={index}
                >
                  {(draggableProvided) => (
                    <AccordionItemView
                      item={item}
                      index={index}
                      expendedItemsIds={expendedItemsIds}
                      setExpendedItemsIds={setExpendedItemsIds}
                      getAccordionDetails={getAccordionDetails}
                      getAccordionSummary={getAccordionSummary}
                      draggableProvided={draggableProvided}
                      ref={(el) => {
                        if (el) {
                          const id = extractId(item, getId);
                          accordionItemsRefMaps.current[id] = el;
                        }
                      }}
                    />
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
