/** @jsxImportSource @emotion/react */
// Comment to retrigger deployment. To be removed after
import {
  BrowserRouter as Router,
  Switch as Routes,
  Route,
  useHistory,
} from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { CSSObject, Global } from '@emotion/react';

import store from './state-manager/store';
import { resetCss } from './style/reset';
import { fontFaces, fontsCss } from './style/fonts';
import { formCss } from './style/form';
import { theme } from './theme';
import {
  useOnAuthenticatorChange,
  useHandlePrepareMainUserFailed,
} from './hooks/useOnAuthenticatorChange';
import useNotifier from './hooks/useNotifier';
import PatientDetails from './components/patient-details/PatientDetails';
import MainHeader, { MAIN_HEADER_HEIGHT } from './components/app/MainHeader';
import { AskUserView } from './components/commons/AskUser';
import { colors } from './style/colors';
import Emr from './components/emr/Emr';
import { getUserRoleSelector } from './state-manager/selectors/appSelectors';
import { useEffect } from 'react';
import Loader from './components/commons/Loader';
import Management from './components/management/Management';

const appGlobalCss: CSSObject = {
  'body, html': {
    height: '100%',
    fontFamily: '"IntelOne Text", sans-serif',
    overflowY: 'auto',
    backgroundColor: colors.backgroundGrey,
  },
  html: {
    overflow: 'hidden',
  },
  '#root': {
    height: '100%',
  },
};

function App() {
  useNotifier();
  useOnAuthenticatorChange();
  useHandlePrepareMainUserFailed();

  const history = useHistory();

  const role = useSelector(getUserRoleSelector);

  useEffect(() => {
    if (role === 'patient') {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.delete('patient-id');
      history.replace({
        pathname: '/patient',
        search: searchParams.toString(),
      });
    } else {
      const pathname = history.location.pathname;
      if (pathname === '/') {
        history.replace({
          pathname: '/patients',
          search: history.location.search,
        });
      }
    }
  }, [role]);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Global styles={[resetCss, appGlobalCss, fontFaces, fontsCss, formCss]} />

      <MainHeader />
      <div
        css={{
          height: `calc(100% - ${MAIN_HEADER_HEIGHT})`,
          flexGrow: 1,
        }}
      >
        <Routes>
          <Route exact path="/" render={() => <Loader />} />
          <Route path="/patient" render={() => <PatientDetails />} />
          <Route path="/patients" render={() => <Management />} />
          <Route path="/clinicians" render={() => <Management />} />
          <Route path="/emr" render={() => <Emr />} />
        </Routes>
      </div>
      <AskUserView />
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} autoHideDuration={6000}>
          <Provider store={store}>
            <App />
          </Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}
