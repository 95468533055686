/** @jsxImportSource @emotion/react */

import { ChangeEvent, useEffect, useState } from 'react';

import { fonts } from '../../../style/fonts';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import {
  createDateFromDatePickerString,
  toDatePickerFormat,
} from '../../../utils/dateUtils';
import { useSelector } from 'react-redux';
import { getMeasurementSystemSelector } from '../../../state-manager/selectors/appSelectors';
import {
  imperialToMetricconversionFormulaMap,
  isImperialUnit,
  metricToImperialConversionFormulaMap,
  shouldUseDefaultUnit,
} from '../../../utils/unitUtils';
import { MeasurementSystems } from '../../../types/appTypes';
import { isEmpty } from '../../../state-manager/utils/compare';

const SELECT_EMPTY_LABEL = 'select ..';

export type InputFieldPropsType = {
  name: string;
  className?: string;
  disabled?: boolean;
  helpText?: string;
  type?: React.HTMLInputTypeAttribute;
  id?: string;
  label?: string;
  required?: boolean;
  error?: string;
  footer?: string;
  tooltip?: string;
  min?: string | number;
  max?: string | number;
  unit?: string | undefined;
};

export function createClassName<
  T extends {
    innerClassName: string;
    name?: string;
    className?: string;
    error?: string;
  },
>(props: T) {
  const { innerClassName, className, name, error } = props;
  let finalClassName = innerClassName;
  if (className) {
    finalClassName = `${finalClassName} ${className}`;
  }
  if (name) {
    finalClassName = `${finalClassName} ${name}`;
  }
  if (error) {
    finalClassName = `${finalClassName} error`;
  }
  return finalClassName;
}

function getId<T extends { name: string; id?: string }>(props: T) {
  const { id, name } = props;
  return id ?? name;
}

function createLabel<
  T extends {
    label?: string;
    name: string;
    id?: string;
    required?: boolean;
    additionalLabelElements?: EmotionJSX.Element;
    tooltipLabelText?: EmotionJSX.Element;
  },
>(props: T) {
  const { id, name, required } = props;
  if ('label' in props) {
    if ('tooltipLabelText' in props) {
      return (
        <>
          <Tooltip title={props.tooltipLabelText}>
            <label
              className={`${required && 'required'}`}
              htmlFor={id ?? name}
              css={{ width: 'fit-content' }}
            >
              {props.label}
            </label>
          </Tooltip>
          {props.additionalLabelElements ?? null}
        </>
      );
    }
    return (
      <>
        <label
          className={`${required && 'required'}`}
          htmlFor={id ?? name}
          css={{ width: 'fit-content' }}
        >
          {props.label}
        </label>
        {props.additionalLabelElements ?? null}
      </>
    );
  }
  return null;
}

export function createError<T extends { error?: string }>(props: T) {
  const { error } = props;
  if (error) {
    return (
      <div
        className="error-msg"
        css={{
          marginBottom: '5px',
        }}
      >
        {error}
      </div>
    );
  }
  return null;
}

function getCustomOnChange({
  onChange,
  type,
  absoluteDate,
}: {
  onChange: (event: any) => void;
  type?: React.HTMLInputTypeAttribute;
  absoluteDate?: boolean | undefined;
}) {
  if (type === 'number') {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        onChange(null);
      } else {
        onChange(parseFloat(e.target.value));
      }
    };
  }
  if (type === 'date') {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value as string; //2023-11-14
      const splitValue = value?.split('-');
      const yearValue = splitValue[0];
      const isStilTypingYear = yearValue && yearValue[0] == '0';
      if (isStilTypingYear) {
        onChange(value);
        return;
      }

      if (!value) {
        onChange(undefined);
        return;
      }
      onChange(createDateFromDatePickerString(value, absoluteDate));
    };
  } else {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        onChange(null);
      } else {
        onChange(e.target.value);
      }
    };
  }
}

function getCustomValue({
  value,
  type,
}: {
  value: any;
  type?: React.HTMLInputTypeAttribute;
}) {
  if (!type) {
    return value;
  }

  return value;
}

function createInputProps<T extends SimpleInputFieldPropsType>(props: T) {
  const {
    helpText,
    disabled,
    type,
    onChange,
    value,
    min,
    max,
    autoComplete,
    absoluteDate,
    placeholder,
  } = props;
  const inputProps: {
    id?: string;
    title?: string;
    disabled: boolean | undefined;
    type: React.HTMLInputTypeAttribute | undefined;
    onChange: (event: ChangeEvent) => void;
    value: string;
    min?: string | number;
    max?: string | number;
    autoComplete?: string;
    placeholder?: string;
  } = {
    onChange: getCustomOnChange({ onChange, type, absoluteDate }),
    disabled,
    type,
    value: getCustomValue({ value, type }),
    min,
    max,
    autoComplete,
    placeholder,
  };
  if ('label' in props) {
    inputProps.id = getId(props);
  }
  if (helpText) {
    inputProps.title = helpText;
  }
  return inputProps;
}

type SimpleInputFieldPropsType = InputFieldPropsType & {
  onChange: (value: string) => void;
  value: string;
  textarea?: boolean;
  autoComplete?: string;
  unit?: string;
  absoluteDate?: boolean;
  placeholder?: string;
  disabledErrorText?: boolean;
};

export function SimpleInputField(props: SimpleInputFieldPropsType) {
  const { textarea, footer, tooltip, value, unit, type, disabledErrorText } =
    props;
  const measurementSystem = useSelector(getMeasurementSystemSelector);

  const metricToImperialConverter =
    isImperialUnit(unit) && metricToImperialConversionFormulaMap[unit];
  const [ownValue, setOwnValue] = useState(
    unit &&
      measurementSystem === MeasurementSystems.Imperial &&
      metricToImperialConverter
      ? metricToImperialConverter(Number(value))
      : value,
  );

  useEffect(() => {
    if (measurementSystem === MeasurementSystems.Imperial && unit) {
      if (metricToImperialConverter) {
        setOwnValue(metricToImperialConverter(Number(value)));
      }
    } else {
      setOwnValue(unit ? Number(value) : value);
    }
  }, [unit, measurementSystem]);

  const onChange = (changedValue: any) => {
    let newValue = changedValue;
    if (
      !shouldUseDefaultUnit(unit, measurementSystem) &&
      isImperialUnit(unit)
    ) {
      const imperialToMetricConverter =
        imperialToMetricconversionFormulaMap[unit];
      newValue = imperialToMetricConverter(Number(changedValue));
    }
    setOwnValue(changedValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  let innerClassName = 'field-grouping input-field';
  if (textarea) {
    innerClassName = innerClassName + ' textarea';
  }
  const el = (
    <div
      className={createClassName({
        innerClassName,
        ...props,
      })}
    >
      {createLabel(props)}
      <div
        className="input-parent"
        css={{ position: 'relative', width: '100%' }}
      >
        {!disabledErrorText && createError(props)}
        <div
          className="input-with-footer"
          css={{ position: 'relative', width: '100%' }}
        >
          {textarea ? (
            <textarea {...createInputProps(props)} />
          ) : (
            <input
              {...createInputProps({
                ...props,
                onChange: onChange,
                value:
                  type === 'date' && typeof ownValue === 'object'
                    ? toDatePickerFormat(ownValue)
                    : String(isEmpty(ownValue) ? '' : ownValue),
              })}
            />
          )}
          <div
            css={{ ...fonts.mini, display: 'flex', justifyContent: 'center' }}
          >
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
  if (tooltip) {
    return <Tooltip title={tooltip}>{el}</Tooltip>;
  }
  return el;
}

export type OptionObj = { key: string; value: string; label: string };
function isOptionObj(obj: any): obj is OptionObj {
  return (
    !!(obj as OptionObj).key &&
    !!(obj as OptionObj).value &&
    !!(obj as OptionObj).label
  );
}

export function SimpleSelectInputField(
  props: SimpleInputFieldPropsType & {
    options: readonly string[] | readonly OptionObj[];
  },
) {
  const { options, tooltip } = props;
  const el = (
    <div
      className={createClassName({
        innerClassName: 'field-grouping select-input-field',
        ...props,
      })}
    >
      {createLabel(props)}
      <div
        className="input-parent"
        css={{ position: 'relative', width: '100%' }}
      >
        {createError(props)}
        <select {...createInputProps(props)}>
          <option disabled value="">
            {SELECT_EMPTY_LABEL}
          </option>
          {options.map((currentOption) => {
            if (isOptionObj(currentOption)) {
              const { key, value, label } = currentOption;
              return (
                <option key={key} value={value}>
                  {label}
                </option>
              );
            }
            return (
              <option key={currentOption} value={currentOption}>
                {currentOption}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
  if (tooltip) {
    return <Tooltip title={`${tooltip}`}>{el}</Tooltip>;
  }
  return el;
}

type CheckboxFieldPropsType = {
  disabled: boolean;
  className?: string;
  name: string;
  label: string;
  value: string;
  error?: string;
};

function SimpleCheckboxField(
  props: CheckboxFieldPropsType & {
    checked: boolean;
    onChange: (checked: boolean) => void;
  },
) {
  const { value, checked, disabled, label, className, name, onChange, error } =
    props;
  return (
    <label className={`checkbox-label ${name} ${className}`}>
      {createError({ error })}
      <input
        type="checkbox"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.checked);
        }}
      />
      {label}
    </label>
  );
}

export function SimpleRadioInputField(
  props: InputFieldPropsType & {
    options: readonly { value: string; tooltip?: string }[];
  } & {
    value: string[];
    onChange: (newValue: string[]) => void;
    error?: string;
  },
) {
  const { value, onChange, options, disabled, error } = props;

  return (
    <div
      className={createClassName({
        innerClassName: 'field-grouping radio-input-field',
        ...props,
      })}
    >
      {createLabel(props)}
      <FormControl>
        {createError({ ...props, error })}
        <RadioGroup
          // aria-labelledby="demo-controlled-radio-buttons-group"
          // name="controlled-radio-buttons-group"
          value={value}
          onChange={(event) => {
            onChange([event.target.value]);
          }}
          row
        >
          {options.map(({ value: radioValue, tooltip }) => {
            const el = (
              <FormControlLabel
                key={radioValue}
                value={radioValue}
                control={<Radio disabled={disabled} />}
                label={radioValue}
              />
            );
            if (tooltip) {
              return (
                <Tooltip key={radioValue} title={tooltip}>
                  {el}
                </Tooltip>
              );
            }
            return el;
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export type CheckboxGroupPropsType = {
  disabled: boolean;
  className?: string;
  name: string;
  options: string[];
  legend: string;
};

export function SimpleCheckboxGroup(
  props: CheckboxGroupPropsType & {
    error?: string;
    onChange: (values: string[]) => void;
    value: string[];
  },
) {
  const { options, value: currentStateValue, onChange } = props;
  return (
    <div
      className="input-parent"
      css={{
        position: 'relative',
        width: '100%',
        '.form-error-msg': { bottom: -16 },
      }}
    >
      <div css={{ display: 'flex', gap: 20 }}>
        {options.map((value) => {
          const safeValue = currentStateValue || [];
          return (
            <SimpleCheckboxField
              {...props}
              key={value}
              label={value}
              value={value}
              onChange={() => {
                const index = safeValue.indexOf(value);
                if (index === -1) {
                  safeValue.push(value);
                } else {
                  safeValue.splice(index, 1);
                }
                onChange(safeValue);
              }}
              checked={safeValue.includes(value)}
            />
          );
        })}
      </div>
    </div>
  );
}
