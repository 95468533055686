/** @jsxImportSource @emotion/react */
import { CSSObject } from '@emotion/react';
import Dash from '../../assets/dash.svg';
import SortArrow from '../../assets/sortArrow.svg';
import { colors } from '../../style/colors';
import { Result } from '../../models/ActivityExecution';

const dashIconStyle: CSSObject = {
  color: colors.darkGrey,
  width: '17px',
  height: '4px',
};

export default function TrendIcon({
  resultsToCompare,
  height,
}: {
  resultsToCompare: (
    | Result
    | undefined
    | { value: number; improvementDirection: string }
  )[];
  height: number;
}) {
  if (resultsToCompare?.length === 0) {
    return <Dash css={{ ...dashIconStyle }} />;
  }

  const [previousResult, lastResult] = resultsToCompare;
  const valueDifference =
    previousResult && lastResult ? lastResult.value - previousResult.value : 0;
  const lastImprovementDirection = lastResult?.improvementDirection;

  if (valueDifference === 0 || lastImprovementDirection === 'goal') {
    return <Dash css={{ ...dashIconStyle }} />;
  }

  if (valueDifference > 0) {
    return (
      <SortArrow
        css={{
          color:
            lastImprovementDirection === 'up'
              ? colors.green
              : lastImprovementDirection === 'down'
              ? colors.red
              : colors.darkGrey,
          height: `${height}px`,
          width: 'auto',
        }}
      />
    );
  }
  return (
    <SortArrow
      css={{
        transform: 'rotate(180deg)',
        color:
          lastImprovementDirection === 'up'
            ? colors.red
            : lastImprovementDirection === 'down'
            ? colors.green
            : colors.darkGrey,
        height: `${height}px`,
        width: 'auto',
      }}
    />
  );
}
