import httpClient from './httpClient';
import {
  PatientPrivacySetting,
  UpdateUserFormDto,
  UserDto,
  UserInfoDto,
} from '../types/backendType';
import axios from 'axios';

//Get /account-mgmt/users/{user_id}
export async function fetchUser(userId: string): Promise<UserDto | undefined> {
  try {
    const response = await httpClient.get<UserDto>(`/account-mgmt/users/${userId}`);
    const user = response.data;
    if (user.user_id !== userId) {
      throw new Error(`Failed to fetch user ${userId}: incompatible ids`);
    }
    return user;
  } catch (e) {
    throw new Error(`Failed to fetch user ${userId}: ${e}`);
  }
}

function buildFetchUsersParams({ emrUserId }: { emrUserId?: string }) {
  let params: { emr_user_id?: string } = {};
  if (emrUserId) {
    params = { emr_user_id: emrUserId };
  }
  return params;
}

//GET /account-mgmt/users
export async function fetchUsers({ emrUserId }: { emrUserId?: string }): Promise<UserDto[]> {
  try {
    const response = await httpClient.get<UserDto[]>('/account-mgmt/users', {
      params: buildFetchUsersParams({ emrUserId }),
    });
    const users = response.data;
    return users;
  } catch (e) {
    throw new Error(`Failed to fetch users: ${e}`);
  }
}

function handleUserCreationErrors(e: Error) {
  if (axios.isAxiosError(e)) {
    if ('details' in e.response?.data) {
      const msg = e.response?.data.details;
      if (typeof msg === 'string' && msg.includes('email already exists')) {
        throw new Error('email already exists');
      }
    }
  }
}

//POST /account-mgmt/users
export async function createUser({
  userInfo,
  assignorUserId,
}: {
  userInfo: UserInfoDto;
  assignorUserId: string;
}): Promise<UserDto> {
  try {
    const response = await httpClient.post<UserDto>('/account-mgmt/users', userInfo, {
      headers: { 'x-user-id': assignorUserId },
      params: {
        test_user: userInfo.additional_user_info?.is_test_user,
      },
    });
    const user = response.data;
    return user;
  } catch (e) {
    handleUserCreationErrors(e);
    throw new Error(`Failed to create user: ${e}`);
  }
}

//patch /account-mgmt/users/{user_id}
export async function updateUser({
  updateUserFormDto,
  userId,
}: {
  updateUserFormDto: UpdateUserFormDto;
  userId: string;
}): Promise<UserDto> {
  try {
    const response = await httpClient.patch<UserDto>(
      `/account-mgmt/users/${userId}`,
      updateUserFormDto,
    );
    const user = response.data;
    return user;
  } catch (e) {
    handleUserCreationErrors(e);
    throw new Error(`Failed to update user: ${e}`);
  }
}

//get /account-mgmt/users/consent
export async function fetchUserPrivacyConsents(userId: string) {
  try {
    const response = await httpClient.get('/account-mgmt/users/consent', {
      headers: { 'x-user-id': userId },
    });
    const userConsents = response.data;
    return userConsents;
  } catch (e) {
    throw new Error(`Failed to fetch user privacy settings: ${e}`);
  }
}

//post /account-mgmt/users/consent
export async function updateUserPrivacyConsents(
  userId: string,
  privacySetting: PatientPrivacySetting,
) {
  try {
    await httpClient.post('/account-mgmt/users/consent', privacySetting, {
      headers: { 'x-user-id': userId },
    });
  } catch (e) {
    throw new Error(`Failed to update user privacy settings: ${e}`);
  }
}
