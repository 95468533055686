/** @jsxImportSource @emotion/react */

import { CSSObject } from '@emotion/react';
import MicrophoneIcon from '../../assets/microphone.svg';
import { colors } from '../../style/colors';
import { shapes } from '../../style/shapes';
import { fonts } from '../../style/fonts';

const pushToTalkButtonCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  width: '145px',
  height: '171px',
  padding: '10px',
  border: shapes.border,
  borderRadius: shapes.borderRadius,

  '.record-button': {
    width: '85px',
    height: '85px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: shapes.border,
    borderColor: colors.blue2,
    borderRadius: '100%',

    '&:hover > svg': {
      color: colors.blue3,
    },

    '&:active': {
      backgroundColor: colors.backgroundBlue,
    },

    '&:disabled': {
      borderColor: colors.darkGrey,
      '&>svg': { color: colors.darkGrey },
      '&:active': {
        backgroundColor: 'transparent',
      },
    },
  },
};
export default function PushToTalkButton({
  onMouseDown,
  onMouseUp,
  disabled = false,
}: {
  onMouseDown: () => void;
  onMouseUp: () => void;
  disabled?: boolean;
}) {
  return (
    <div css={pushToTalkButtonCss}>
      <span className="record-text" css={{ ...fonts.h2 }}>
        Push to Record Notes
      </span>
      <button
        type="button"
        className="record-button"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        disabled={disabled}
      >
        <MicrophoneIcon height={45} width={29} color={colors.blue3} />
      </button>
    </div>
  );
}
