import axios, { AxiosInstance, isAxiosError } from 'axios';

import { API_VERSION } from '../config';
import { BASE_URL } from '../externalConfig';
import { isErrorResponseBody } from '../types/backendType';
import { LoginService } from './loginService';

class HttpClient {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: BASE_URL + API_VERSION, //TODO: check if API_VERSION is ok here in CD
    });
  }
}

const httpClient = new HttpClient();

httpClient.instance.interceptors.request.use(async (config) => {
  const loginService = LoginService.getInstance();
  config.headers.Authorization = await loginService.getToken();
  //TODO: check if this is the right user id
  // config.headers['x-user-id'] = loginService.getUserId();
  return config;
});

httpClient.instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (isAxiosError(error)) {
      const errorResponseBody = error.response?.data;
      if (isErrorResponseBody(errorResponseBody)) {
        const message = `${errorResponseBody.title}: ${errorResponseBody.details}`;
        error.message = message;
      }
    }
    throw error;
  },
);

export default httpClient.instance;
