import { debounce } from 'lodash-es';
import { RefObject, useEffect, useState } from 'react';

type Dimensions = {
  clientWidth: number;
  clientHeight: number;
  scrollWidth: number;
  scrollHeight: number;
};

export default function useResizeObserver<T extends RefObject<Element>>(
  element: T | null,
  debounceTime: number,
) {
  const [dimensions, setDimensions] = useState<Dimensions>({
    clientHeight: 0,
    clientWidth: 0,
    scrollHeight: 0,
    scrollWidth: 0,
  });

  useEffect(() => {
    const target = element?.current;
    if (!target) {
      return;
    }

    const observer = new ResizeObserver(
      debounce((entries) => {
        for (const entry of entries) {
          if (entry.contentBoxSize[0]) {
            setDimensions({
              clientHeight: entry.target.clientHeight,
              clientWidth: entry.target.clientWidth,
              scrollWidth: entry.target.scrollWidth,
              scrollHeight: entry.target.scrollHeight,
            });
          }
        }
      }, debounceTime),
    );

    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, []);

  return dimensions;
}
