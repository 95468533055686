/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { CSSObject } from '@emotion/react';
import ActivityVideo from '../../commons/activity-video/ActivityVideo';
import { shapes } from '../../../style/shapes';
import OverTimeMetricsGraph from './OverTimeMetricsGraph';
import { ActivityExecution } from '../../../models/ActivityExecution';
import { breakpoints } from '../../../style/breakpoints';

const overTimeMetricsContainerCss: CSSObject = {
  display: 'flex',
  gap: 20,
  padding: 20,
  justifyContent: 'center',
  border: shapes.border,
  borderRadius: shapes.borderRadius,

  '.activity-video, .graph-view': {
    width: 380,
    height: 360,

    [breakpoints.medium]: {
      width: 480,
    },
    [breakpoints.large]: {
      width: 680,
    },
  },
  '.activity-video': {
    border: shapes.border,
    borderRadius: shapes.borderRadius,
  },
};

export default function OverTimeMetrics({
  activityExecution,
}: {
  activityExecution: ActivityExecution | undefined;
}) {
  const [videoCurrentTime, setVideoCurrentTime] = useState<
    number | undefined
  >();
  const [graphCursorPosition, setGraphCursorPosition] = useState<
    number | undefined
  >();

  return (
    <>
      <div css={overTimeMetricsContainerCss} className="over-time-metrics">
        <OverTimeMetricsGraph
          graphCursorPosition={graphCursorPosition}
          setGraphCursorPosition={setGraphCursorPosition}
          onGraphClick={(newPoint) => {
            setVideoCurrentTime(newPoint.time);
          }}
          activityExecution={activityExecution}
        />
        <ActivityVideo
          sessionId={activityExecution?.sessionId}
          subSessionId={activityExecution?.subSessionId}
          currentTime={videoCurrentTime}
          onPlay={() => {
            setVideoCurrentTime(undefined);
            setGraphCursorPosition(undefined);
          }}
          onVideoTimelineSet={(time) => {
            setGraphCursorPosition(time);
          }}
        />
      </div>
    </>
  );
}
