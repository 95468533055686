import { fetchMovementDefinitions } from '../../services/libraryService';
import { mapToActivityDefinition } from '../../types/mappers/libraryMapper';
import { getRequestStatusSelector } from '../selectors/appSelectors';
import { setActivityDefinitionsAction } from '../slices/librarySlice';
import { markStatusFunctionCreator } from '../slices/appSlice';
import { AppThunk, RequestStatusName } from '../storeTypes';
import { skipRequest } from '../utils/requestStatus';

export function fulfillActivityDefinitionsAction(): AppThunk {
  return async (dispatch, getState) => {
    const requestStatusId = 'fetchActivityDefinitions';
    const requestStatusName: RequestStatusName = 'fetchActivityDefinitions';
    const markStatus = markStatusFunctionCreator(dispatch, requestStatusId, requestStatusName);
    const requestStatus = getRequestStatusSelector(requestStatusName)(getState());
    if (skipRequest({ requestStatus, id: requestStatusId })) {
      return;
    }
    markStatus('inProcess');
    try {
      const movementDefinitionDtoArray = await fetchMovementDefinitions();
      if (movementDefinitionDtoArray) {
        if (movementDefinitionDtoArray.length === 0) {
          // eslint-disable-next-line no-console
          console.error('getting empty movements definitions!');
        }
        const activityDefinitions = movementDefinitionDtoArray.map(mapToActivityDefinition);
        dispatch(setActivityDefinitionsAction({ activityDefinitions }));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      return;
    }
    markStatus('succeeded');
  };
}
