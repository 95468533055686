import { AmplifyUser } from '@aws-amplify/ui';
import { Auth } from 'aws-amplify';
import { isUserRole, UserRole } from '../types/backendType';

export class LoginService {
  private static instance: LoginService | undefined;

  private amplifyUser: AmplifyUser | undefined;

  private signOut: (() => void) | undefined;

  static getInstance(): LoginService {
    if (LoginService.instance === undefined) {
      LoginService.instance = new LoginService();
    }
    return LoginService.instance;
  }

  private constructor() {
    // private constructor
  }

  logout() {
    this.signOut?.();
  }

  getUserId() {
    return this.amplifyUser?.attributes?.preferred_username;
  }

  getUserRole(): UserRole | undefined {
    const role = this.amplifyUser?.attributes?.['custom:v2_role'];
    if (role && isUserRole(role)) {
      return role;
    }
  }

  async getToken() {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  }

  setAmplifyUser(amplifyUser: AmplifyUser) {
    this.amplifyUser = amplifyUser;
  }

  setSignOut(signOut: () => void) {
    this.signOut = signOut;
  }
}
