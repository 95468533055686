export async function uploadBlob({
  blob,
  url,
  onUploadProgress,
}: {
  blob: Blob;
  url: string;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.setRequestHeader('Content-Type', blob.type);
    xhr.upload.onprogress = (event: ProgressEvent) => {
      onUploadProgress?.(event);
    };
    xhr.onload = (event: ProgressEvent) => {
      onUploadProgress?.(event);
      resolve(true);
    };
    xhr.onerror = (event: ProgressEvent) => {
      onUploadProgress?.(event);
      reject();
    };
    xhr.send(blob);
  });
}
