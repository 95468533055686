/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSObject } from '@emotion/react';

import DraggableAccordionView from '../../commons/DraggableAccordionView';
import { getActivityDefinitionsSelector } from '../../../state-manager/selectors/librarySelectors';
import { move } from '../../../utils/arrayUtils';
import { ObjectiveGraphView } from './ObjectiveGraphView';
import {
  ActivityExecution,
  getPatientActivitiesExecutionsSelector,
  groupActivitiesExecutionsByActivityDefinition,
} from '../../../models/ActivityExecution';
import TriangleIcon from '../../../assets/triangle.svg';
import CircleIcon from '../../../assets/circle.svg';
import Dash from '../../../assets/dash.svg';
import { colors } from '../../../style/colors';
import {
  ActivityItemsDateFilter,
  ActivityItemsTypeFilter,
} from './ActivityItemsFilters';
import { ActivityDefinitionId } from '../../../types/library';
import { GraphDetails } from './ObjectiveTypes';
import { createDate } from '../../../utils/dateUtils';
import {
  getDisplayUnit,
  getMeasurementSystemUnit,
  getMeasurementSystemValue,
} from '../../../utils/unitUtils';
import { fonts } from '../../../style/fonts';
import { getLastTwoLatestResults } from './ObjectiveUtils';
import { capitalizeString } from '../../../utils/stringUtils';
import { getMeasurementSystemSelector } from '../../../state-manager/selectors/appSelectors';

const objectiveContainerCss: CSSObject = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const activitySummaryCss: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',

  '&:first-of-type': {
    fontSize: '18px',
    fontWeight: '500',
  },
  '& > div': {
    fontSize: '14px',
    display: 'flex',

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1.25rem',
    },
  },

  '& span:last-child svg': {
    color: colors.darkerGrey,
    width: '7px',
    height: '3px',
  },

  '& span:last-child > svg:first-of-type': {
    marginRight: '3px',
  },

  '& svg': {
    marginRight: '0.65rem',
  },
};

function getGraphList(
  activitiesExecutionsMap: Record<string, ActivityExecution[]> | undefined,
): GraphDetails[] {
  const graphList: GraphDetails[] = [];
  if (!activitiesExecutionsMap) {
    return [];
  }
  Object.values(activitiesExecutionsMap).forEach((activitiesExecutions) => {
    const activityExecution = activitiesExecutions[0];
    if (!activityExecution) {
      return;
    }
    const { activityDefinitionId } = activityExecution;
    const exampleActivity: ActivityExecution | undefined =
      activitiesExecutionsMap[activityDefinitionId]?.[0];
    exampleActivity?.results.forEach(({ key, side, standardDeviation }) => {
      graphList.push({
        id: `${activityDefinitionId}_${key}`,
        activityDefinitionId,
        metricKey: key,
        side,
        standardDeviation,
      });
    });
  });
  return graphList;
}

function getFilteredItemsByType(
  items: GraphDetails[],
  selectedTypeFilter: string,
  activitiesExecutionsMap: Record<ActivityDefinitionId, ActivityExecution[]>,
): GraphDetails[] {
  if (!selectedTypeFilter || selectedTypeFilter === 'allMeasurements') {
    return items;
  }

  const filteredItems = items.filter((item) => {
    return activitiesExecutionsMap?.[item.activityDefinitionId]?.some(
      (activity) => {
        if (selectedTypeFilter === 'goalsOnly') {
          return activity.goals?.[item.metricKey];
        }
        if (selectedTypeFilter === 'assessmentsOnly') {
          return activity.type?.includes('Assessment');
        }
      },
    );
  });

  return filteredItems;
}

const dateFiltersOptions = {
  oneWeek: {
    label: 'One Week',
    predicate: (activityExecution: ActivityExecution) => {
      return activityExecution.stopDate >= createDate({ days: -7 });
    },
  },
  oneMonth: {
    label: 'One Month',
    predicate: (activityExecution: ActivityExecution) => {
      return activityExecution.stopDate >= createDate({ days: -30 });
    },
  },
  '3Months': {
    label: '3 Months',
    predicate: (activityExecution: ActivityExecution) => {
      return activityExecution.stopDate >= createDate({ days: -30 * 3 });
    },
  },
  '6Months': {
    label: '6 Months',
    predicate: (activityExecution: ActivityExecution) => {
      return activityExecution.stopDate >= createDate({ days: -30 * 6 });
    },
  },
} as const;

function isKeyOfDateFiltersOptions(
  id: string,
): id is keyof typeof dateFiltersOptions {
  return Object.keys(dateFiltersOptions).includes(id);
}

export default function Objective() {
  const activityDefinitions = useSelector(getActivityDefinitionsSelector);

  const [expendedItemsIds, setExpendedItemsIds] = useState<string[]>([]);

  const [orderedGraphList, setOrderedGraphList] = useState<GraphDetails[]>([]);

  const [selectedTypeFilter, setSelectedTypeFilter] =
    useState('allMeasurements');

  const [selectedDateFilterId, setSelectedDateFilterId] =
    useState<keyof typeof dateFiltersOptions>('oneMonth');

  const patientActivitiesExecutions = useSelector(
    getPatientActivitiesExecutionsSelector,
  );

  const measurementSystem = useSelector(getMeasurementSystemSelector);

  const [activitiesExecutionsMap, graphList] = useMemo(() => {
    const newActivitiesExecutionsMap =
      groupActivitiesExecutionsByActivityDefinition(
        patientActivitiesExecutions?.filter(
          dateFiltersOptions[selectedDateFilterId].predicate,
        ),
      );
    return [
      newActivitiesExecutionsMap,
      getGraphList(newActivitiesExecutionsMap),
    ];
  }, [patientActivitiesExecutions, selectedDateFilterId]);

  useEffect(() => {
    setOrderedGraphList(graphList);
    setExpendedItemsIds(graphList.map(({ id }) => id));
  }, [graphList]);

  if (!activitiesExecutionsMap) {
    return <></>;
  }

  return (
    <div className="objective-container" css={{ ...objectiveContainerCss }}>
      <div css={{ display: 'flex', gap: 20 }}>
        <ActivityItemsDateFilter
          options={dateFiltersOptions}
          selectedFilterId={selectedDateFilterId}
          setSelectedFilterId={(id) => {
            if (isKeyOfDateFiltersOptions(id)) {
              setSelectedDateFilterId(id);
            }
          }}
        />
        <ActivityItemsTypeFilter
          selectedTypeFilter={selectedTypeFilter}
          setSelectedTypeFilter={setSelectedTypeFilter}
        />
      </div>
      <div className="graphs-container" css={{ hight: 100, flexGrow: 1 }}>
        <DraggableAccordionView
          items={getFilteredItemsByType(
            orderedGraphList,
            selectedTypeFilter,
            activitiesExecutionsMap,
          )}
          expendedItemsIds={expendedItemsIds}
          setExpendedItemsIds={setExpendedItemsIds}
          onDragEnd={({ source, destination }) => {
            if (destination) {
              const newGraphList = move(
                orderedGraphList,
                source.index,
                destination.index,
              );
              setOrderedGraphList(newGraphList);
            }
          }}
          getAccordionSummary={(
            _,
            { activityDefinitionId, metricKey, side },
          ) => {
            const activityDefinition =
              activityDefinitions[activityDefinitionId];
            const metric = activityDefinition?.postExerciseMetric?.find(
              ({ metric_name }) => metric_name === metricKey,
            );
            const activitiesResults =
              activitiesExecutionsMap?.[activityDefinitionId];

            const lastResult = getLastTwoLatestResults(
              activitiesResults,
              metricKey,
              side,
            )?.[1];

            const formattedSide = lastResult?.side
              ? capitalizeString(lastResult.side)
              : '';

            const measurementSystemUnit = getDisplayUnit(
              getMeasurementSystemUnit(lastResult?.unit, measurementSystem),
            );

            const measurementSystemValue = getMeasurementSystemValue(
              lastResult?.value,
              measurementSystemUnit,
              measurementSystem,
            )?.toFixed(0);

            return (
              <div css={{ ...activitySummaryCss }}>
                <div>
                  <span>{`${activityDefinition?.name}: ${formattedSide} ${
                    metric?.display_name ?? metricKey
                  }`}</span>
                  <span css={{ ...fonts.infoPiece }}>
                    {measurementSystemValue}
                    {measurementSystemUnit}
                  </span>
                </div>
                <div>
                  <span>
                    <TriangleIcon css={{ width: '18px', height: '15px' }} />
                    In Clinic
                  </span>
                  <span>
                    <CircleIcon css={{ width: '16px', height: '16px' }} />
                    At Home
                  </span>
                  <span>
                    <Dash />
                    <Dash />
                    Goal
                  </span>
                </div>
              </div>
            );
          }}
          getAccordionDetails={(_, graphDetails) => {
            return (
              <ObjectiveGraphView
                graphDetails={graphDetails}
                activitiesExecutionsMap={activitiesExecutionsMap}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
