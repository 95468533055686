import { useCallback, useEffect, useRef } from 'react';

const DEFAULT_PULLING_TIME_MS = 3000;

export function usePulling({
  routine,
  pullingTimeMs,
  initialDelayTimeMS,
}: {
  routine: () => Promise<void>;
  pullingTimeMs?: number;
  initialDelayTimeMS?: number;
}) {
  const timeout = useRef<NodeJS.Timeout | undefined>();
  const currentRoutine = useRef<() => Promise<void>>();
  const shouldStop = useRef<boolean>(false);

  useEffect(() => {
    const loop = () => {
      if (shouldStop.current) {
        return;
      }
      if (currentRoutine.current !== routine && timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
      if (timeout.current) {
        return;
      }
      timeout.current = setTimeout(() => {
        timeout.current = undefined;
        currentRoutine.current = routine;
        routine().then(() => {
          loop();
        });
      }, pullingTimeMs ?? DEFAULT_PULLING_TIME_MS);
      return () => {
        if (timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = undefined;
        }
      };
    };

    if (initialDelayTimeMS) {
      timeout.current = setTimeout(loop, initialDelayTimeMS);
    } else {
      loop();
    }
  }, [routine]);

  const stop = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = undefined;
    shouldStop.current = true;
  }, []);

  return stop;
}
