import {
  MediaRecorder as ExtendableMediaRecorder,
  register,
  IMediaRecorder,
} from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { useCallback, useEffect, useRef, useState } from 'react';

const MEDIA_STREAM_ACTIVE_ERROR: DOMException = new DOMException(
  'Media Stream is currently active. Not all tracks have ended',
);

enum MediaRecorderStatuses {
  Inactive = 'inactive',
  AcquiringSteam = 'acquiringStream',
  Recording = 'recording',
  Stopped = 'stopped',
}

export default function useMediaRecorder() {
  const mediaRecorder = useRef<IMediaRecorder | null>(null);
  const mediaChunks = useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const [status, setStatus] = useState<MediaRecorderStatuses>(
    MediaRecorderStatuses.Inactive,
  );
  const [error, privateSetError] = useState<DOMException | null>(null);
  const [audioWavBlob, setAudioWavBlob] = useState<Blob | null>(null);

  const setError = (newError: DOMException) => {
    // eslint-disable-next-line no-console
    console.error(newError.message);
    privateSetError(newError);
  };

  useEffect(() => {
    const setup = async () => {
      if (!ExtendableMediaRecorder.isTypeSupported('audio/wav')) {
        const port = await connect();

        await register(port);
      }
    };
    setup();
  }, []);

  const createMediaStream = useCallback(async () => {
    setStatus(MediaRecorderStatuses.AcquiringSteam);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
    } catch (mediaStreamError) {
      setError(mediaStreamError);
      setStatus(MediaRecorderStatuses.Inactive);
    }
  }, []);

  const startRecording = async () => {
    if (mediaStream.current?.active) {
      setError(MEDIA_STREAM_ACTIVE_ERROR);
    }
    if (!mediaStream.current || !mediaStream.current.active) {
      await createMediaStream();
    }

    if (mediaStream.current) {
      mediaRecorder.current = new ExtendableMediaRecorder(mediaStream.current, {
        mimeType: 'audio/wav',
      });

      mediaRecorder.current.ondataavailable = ({ data }: BlobEvent) => {
        mediaChunks.current.push(data);
      };
      mediaRecorder.current.onstop = () => {
        const blob = new Blob(mediaChunks.current, { type: 'audio/wav' });
        setAudioWavBlob(blob);
        setStatus(MediaRecorderStatuses.Stopped);
      };
      mediaRecorder.current.onerror = (event) => {
        setError(event.error);
        setStatus(MediaRecorderStatuses.Inactive);
      };
      mediaRecorder.current.start();
      setStatus(MediaRecorderStatuses.Recording);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaStream.current) {
      if (mediaRecorder.current.state !== 'inactive') {
        mediaRecorder.current.stop();
        mediaStream.current.getTracks().forEach((track) => track.stop());
        mediaChunks.current = [];
      }
    }
  };

  return { status, error, startRecording, stopRecording, audioWavBlob };
}
