/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CSSObject } from '@emotion/react';
import { produce, setAutoFreeze } from 'immer';
import { set, get } from 'lodash-es';
import { cloneDeep } from 'lodash-es';

import { OnDragEndResponder } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Tooltip from '@mui/material/Tooltip';

import { fonts } from '../../../../style/fonts';
import {
  CheckboxField,
  InputField,
  RadioInputField,
} from '../../../commons/form/forStateFrom';
import {
  ActivityInput,
  SharedInputPropsType,
  TreatmentPlanInput,
} from './treatmentPlanTypes';
import FormHeader from './FormHeader';
import DraggableAccordionView from '../../../commons/DraggableAccordionView';
import { ActivityDefinition } from '../../../../types/library';
import { getActivityDefinitionsSelector } from '../../../../state-manager/selectors/librarySelectors';
import AutomationLevelView from '../../../commons/AutomationLevelView';
import { colors } from '../../../../style/colors';
import DuplicateIcon from '../../../../assets/duplicate.svg';
import SortAscIcon from '../../../../assets/sortAsc.svg';
import SortDescIcon from '../../../../assets/sortDesc.svg';
import TrashIcon from '../../../../assets/trash.svg';
import ButtonToggle from '../../../commons/ButtonToggle';
import { tooltips } from '../commons/tooltips';
import {
  copyToTreatmentPlanInput,
  getCategoryOptions,
  markAccordionItemsAsError,
  createDefultPlanName,
  copyTemplateToTreatmentPlanInput,
  useIsPlanHasLocalResults,
} from './planCreationUtils';
import DisplayField from '../../../commons/form/DisplayField';
import { orderActivitiesByPosition, toggleSides } from './planCreationUtils';
import { buttons } from '../../../../style/buttons';
import { cardFooterCss, shapes } from '../../../../style/shapes';
import { ActivityDefinitionPreview } from '../commons/ActivityDefinitionPreview';
import {
  Mode,
  ValidateAs,
  useValidation,
} from '../commons/treatmentPlanFormValidation';
import { useAppDispatch } from '../../../../state-manager/store';
import { notify } from '../../../../state-manager/slices/notificationsSlice';
import {
  createTreatmentPlanTemplateAction,
  saveAndAssignTreatmentPlanAction,
} from '../../../../state-manager/thunks/treatmentPlansThunks';
import ActivityGoals from './ActivityGoals';
import { getPostMetrics } from '../../../../models/PostMetric';
import {
  getEditTemplateIdSelector,
  getMeasurementSystemSelector,
  useInProcess,
  useIsEndedSuccessfully,
} from '../../../../state-manager/selectors/appSelectors';
import Chip from '../../../commons/Chip';
import { getPatientTreatmentPlansSelector } from '../../../../models/factories/treatmentPlanFactories';
import Chevron from '../../../../assets/chevron.svg';
import { AskUser } from '../../../commons/AskUser';
import FormEquipment from './FormEquipment';
import {
  FormErrors,
  SetFormData,
  useForm,
} from '../../../commons/form/useForm';
import { isCameraSide } from '../TreatmentPlansUtils';
import Loader from '../../../commons/Loader';
import {
  getMeasurementSystemUnit,
  getUnitInputPropValue,
} from '../../../../utils/unitUtils';
import { breakpoints } from '../../../../style/breakpoints';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { isEmpty } from '../../../../state-manager/utils/compare';
import { formatTimeFromSecToString } from '../../../../utils/stringUtils';
import { getTreatmentPlanTemplatesSelector } from '../../../../models/factories/treatmentPlanTemplateFactories';
import { cleanEditTemplateIdAction } from '../../../../state-manager/slices/appSlice';

const LEAVING_MSG =
  'Are you sure you want to leave? Unsaved changes will be lost.';

export const PLAN_ID_PARAM = 'planId';

const SIDE_MARGIN_RIGHT = 30;
const DUPLICATE_SIZE = 24;
const TRASH_SIZE = 21;
const TRASH_MARGIN = 50;
const ACCORDION_PADDING = 20;

const HEADER_HEIGHT = 77;

const sortIconCss: CSSObject = {
  marginLeft: '5px',
  width: 16,
  height: 16,
};

const formContainerCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '.form-header': {
    height: HEADER_HEIGHT,
  },
};

const formBodyContainerCss: CSSObject = {
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  overflowY: 'scroll',
  height: `calc(100% - ${HEADER_HEIGHT}px)`,
  flexGrow: 1,
  overflowX: 'hidden',
  textarea: {
    overflow: 'auto',
    whiteSpace: 'pre-wrap',
  },
  '.textarea': {
    alignItems: 'flex-start',
  },
  '.description': {
    '&.field-grouping': {
      width: '100%',
    },
    textarea: {
      height: '86px',
    },
  },
};

const activitiesOperationsCss: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  minWidth: '100%',
  '&>div': {
    display: 'flex',
    gap: 20,
  },
  '.global-actions': {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
};

const activitySummaryCss: CSSObject = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',

  [breakpoints.medium]: {
    flexWrap: 'wrap',
  },

  [breakpoints.large]: {
    flexWrap: 'nowrap',
  },

  '& > div:first-of-type': {
    flexWrap: 'wrap',
    gap: '10px',

    [breakpoints.medium]: {
      flexWrap: 'nowrap',
      gap: '0px',
    },
  },

  '& > div': {
    display: 'flex',
    alignItems: 'center',
  },

  '.activity-name': {
    ...fonts.largeLabel,
    width: '150px',
  },

  '.activity-position-and-plane': {
    ...fonts.displayText,
    width: '145px',
  },

  '.error-chip': {
    border: shapes.border,
    borderColor: colors.red,
    color: colors.red,
    backgroundColor: colors.white,
    padding: '4px 8px',
    minWidth: '90px',
  },

  '.body-side-container': {
    width: 'min-content',
    marginRight: '10px',

    [breakpoints.large]: {
      width: '130px',
      marginRight: SIDE_MARGIN_RIGHT,
    },
  },

  '.camera-side-container': {
    width: 'min-content',
    marginRight: '10px',

    [breakpoints.medium]: {
      marginLeft: '10px',
    },

    [breakpoints.large]: {
      width: '155px',
      marginRight: SIDE_MARGIN_RIGHT,
    },
  },
};

const activityDetailsCss: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 20,
  padding: 20,
  '.activity-definition-preview': {
    [breakpoints.small]: {
      width: '172px',
      height: '286px',
    },
    [breakpoints.medium]: {
      width: '200px',
      height: '280px',
    },

    [breakpoints.large]: {
      width: '330px',
      height: '365px',
    },
  },

  '.button-toggle': {
    ...fonts.largeLabel,
    gap: '12px',

    label: {
      [breakpoints.small]: {
        width: '140px',
      },

      [breakpoints.large]: {
        width: '148px',
      },
    },
  },
};

const activityFieldsCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  width: 'min-content',
  maxWidth: '310px',

  [breakpoints.medium]: {
    maxWidth: '465px',
  },
  [breakpoints.large]: {
    maxWidth: 'unset',
  },

  '.field-grouping.textarea': {
    width: '100%',
  },
  '.small-input': {
    input: { width: 50 },
  },
  '.reps-container': {
    display: 'flex',
    flexWrap: 'wrap',

    [breakpoints.large]: {
      flexWrap: 'nowrap',
    },
  },
  '.tooltip-icon': {
    minWidth: '16px',
    width: '16px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
    justifyContent: 'space-around',
    fontSize: '10px',
    color: colors.blue3,
    border: `1px solid ${colors.blue3}`,
    borderRadius: '100%',
    marginRight: 4,
  },
  '.repOn-inputs': {
    whiteSpace: 'nowrap',
    gap: '0px',
    marginLeft: 20,

    '& > label': {
      width: 150,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      marginRight: '4px',
    },
  },
};

function ActivitySummary({
  activity,
  index,
  activityDefinition,
  setFormData,
  formErrors,
  onSideSelect,
}: {
  activity: ActivityInput;
  index: number;
  activityDefinition: ActivityDefinition;
  setFormData: SetFormData<TreatmentPlanInput>;
  formErrors: FormErrors<TreatmentPlanInput> | undefined;
  onSideSelect: (value: string, index: number) => void;
}) {
  const { settings } = activityDefinition;
  const isScreenMedium = useBreakpoints('medium');

  const duplicateActivity = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setFormData(
      produce((draftFormData) => {
        const duplicatedActivity = cloneDeep(activity);
        duplicatedActivity.id = uuid();
        draftFormData.activities.splice(index + 1, 0, duplicatedActivity);
      }),
    );
  };

  const removeActivity = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setFormData(
      produce((draftFormData) => {
        const filteredActivities = draftFormData.activities.filter(
          ({ id }) => id !== activity.id,
        );
        draftFormData.activities = filteredActivities;
      }),
    );
  };

  return (
    <div css={activitySummaryCss}>
      <div>
        <div className="activity-name">{activityDefinition.name}</div>
        {!isScreenMedium && (
          <AutomationLevelView
            automationLevel={activityDefinition.automationLevel}
          />
        )}
        <div className="activity-position-and-plane">
          {`${activityDefinition.position} • ${activityDefinition.plane}`}
        </div>
        {settings.side && !isCameraSide(settings.side) && (
          <div className="body-side-container">
            <ButtonToggle
              buttons={[
                { buttonLabel: 'L', value: 'Left' },
                { buttonLabel: 'R', value: 'Right' },
              ]}
              onChange={(value) => onSideSelect(value, index)}
              value={activity.side}
              label="Body Side:"
            />
          </div>
        )}
        {isScreenMedium && (
          <AutomationLevelView
            automationLevel={activityDefinition.automationLevel}
          />
        )}
        {settings.side && isCameraSide(settings.side) && (
          <div className="camera-side-container">
            <ButtonToggle
              buttons={[
                { buttonLabel: 'L', value: 'Left' },
                { buttonLabel: 'R', value: 'Right' },
              ]}
              onChange={(value) => onSideSelect(value, index)}
              value={activity.side}
              label="Facing Camera:"
            />
          </div>
        )}
      </div>
      {formErrors?.activities?.[index] && (
        <Chip className="error-chip" text="Incomplete" />
      )}
      <div
        css={{
          marginLeft: '10px',
          [breakpoints.large]: { marginLeft: '20px' },
        }}
      >
        <button
          type="button"
          onClick={duplicateActivity}
          aria-label="Duplicate Activity"
        >
          <DuplicateIcon width={DUPLICATE_SIZE} />
        </button>
        <button
          type="button"
          css={{
            marginLeft: '10px',
            [breakpoints.large]: {
              marginLeft: TRASH_MARGIN,
            },
          }}
          onClick={removeActivity}
          aria-label="Remove Activity"
        >
          <TrashIcon width={TRASH_SIZE} color={colors.blue3} />
        </button>
      </div>
    </div>
  );
}

const calcEstimateTime = (
  activityDefinition: ActivityDefinition,
  currentActivity: ActivityInput,
): number | undefined => {
  const rest = currentActivity?.rest || 0;
  const rate = activityDefinition.settings?.rate?.default || 0;
  const reps = currentActivity?.reps || 0;
  const hold = currentActivity?.hold || 0;
  const sets = currentActivity?.sets || 1;
  const duration = currentActivity?.duration || 0;
  const distance = currentActivity?.distance || 0;
  const timePerMeter = parseFloat(activityDefinition.time_per_meter || '0');
  const startSetDelay = 3;
  const endSetDelay = 2;
  const repetitionPause = 3;
  let setExecutionTime = 0;

  if (activityDefinition.end_condition == 'reps') {
    setExecutionTime = reps * (rate + hold);
  } else if (activityDefinition.end_condition == 'reps_non_algo') {
    setExecutionTime = reps * (hold + repetitionPause);
  } else if (activityDefinition.end_condition == 'time') {
    setExecutionTime = duration;
  } else if (activityDefinition.end_condition == 'total_distance') {
    setExecutionTime = (distance * timePerMeter) / 100;
  }

  return setExecutionTime != 0
    ? sets * (startSetDelay + setExecutionTime + endSetDelay) +
        (sets - 1) * rest
    : 0;
};

function ActivityDetails({
  index,
  activity,
  sharedInputProps,
  activityDefinition,
  onSideSelect,
}: {
  index: number;
  activity: ActivityInput;
  sharedInputProps: SharedInputPropsType;
  activityDefinition: ActivityDefinition;
  onSideSelect: (value: string, index: number) => void;
}) {
  const measurementSystem = useSelector(getMeasurementSystemSelector);
  const { settings } = activityDefinition;
  const metricName = activityDefinition.postExerciseMetric?.find(
    ({ metric_name }) => metric_name === activityDefinition.repOn?.[1],
  )?.display_name;
  const metricUnit = activityDefinition.repOn?.[0];
  const measurementSystemUnit = getMeasurementSystemUnit(
    metricUnit,
    measurementSystem,
  );
  const estimateTime = calcEstimateTime(activityDefinition, activity);
  return (
    <div css={activityDetailsCss}>
      <div css={activityFieldsCss}>
        <DisplayField
          label="Body Part"
          value={activityDefinition.bodyParts.toString()}
        />
        {settings.side && (
          <ButtonToggle
            buttons={[
              { buttonLabel: 'L', value: 'Left' },
              { buttonLabel: 'R', value: 'Right' },
            ]}
            onChange={(value) => onSideSelect(value, index)}
            value={activity.side}
            label={
              isCameraSide(settings.side) ? 'Facing Camera Side:' : 'Body Side:'
            }
            error={sharedInputProps.formErrors?.activities?.[index]?.side}
          />
        )}
        {settings.type && (
          <RadioInputField
            {...sharedInputProps}
            name={`activities.${index}.type`}
            label="Category"
            options={getCategoryOptions(activityDefinition)}
          />
        )}
        <InputField
          {...sharedInputProps}
          name={`activities.${index}.comments`}
          label="Additional Instructions"
          textarea={true}
        />
        <CheckboxField
          {...sharedInputProps}
          name={`activities.${index}.require_patient_acknowledgment`}
          label="Require patient acknowledgment"
          disabled={isEmpty(
            get(sharedInputProps.formData, `activities.${index}.comments`),
          )}
        />

        <FormEquipment
          name={`activities.${index}.equipment`}
          value={get(
            sharedInputProps.formData,
            `activities.${index}.equipment`,
          )}
          requiredEquipment={activityDefinition.requiredEquipment}
          optionalEquipment={activityDefinition.optionalEquipment}
          sharedInputProps={sharedInputProps}
          activityIndex={index}
        />

        {settings.sets && (
          <InputField
            {...sharedInputProps}
            className="small-input"
            name={`activities.${index}.sets`}
            label="Sets"
            type="number"
            required
          />
        )}
        {settings.distance && (
          <InputField
            {...sharedInputProps}
            className="small-input"
            name={`activities.${index}.distance`}
            label="Distance (cm)"
            type="number"
            required
          />
        )}
        {settings.hold && (
          <InputField
            {...sharedInputProps}
            className="small-input"
            name={`activities.${index}.hold`}
            label="Hold (sec)"
            type="number"
            required
            tooltip={tooltips.hold}
          />
        )}
        <div className="reps-container">
          {settings.reps && (
            <InputField
              {...sharedInputProps}
              className="small-input"
              name={`activities.${index}.reps`}
              label="Reps per Set"
              type="number"
              required
              tooltip={tooltips.reps}
            />
          )}
          {settings.minimum_to_consider_rep && activityDefinition.repOn && (
            <>
              <InputField
                {...sharedInputProps}
                className="small-input reps-threshold repOn-inputs"
                name={`activities.${index}.minimumToConsiderRep`}
                label={`Repetition Threshold (${metricName} ${measurementSystemUnit})`}
                additionalLabelElements={
                  <Tooltip title={tooltips.minimum_to_consider_rep}>
                    <span className="tooltip-icon">?</span>
                  </Tooltip>
                }
                tooltipLabelText={`Repetition Threshold (${metricName} ${measurementSystemUnit})`}
                type="number"
                required
                unit={getUnitInputPropValue(metricUnit, measurementSystem)}
              />
              <InputField
                {...sharedInputProps}
                className="small-input repOn-inputs"
                name={`activities.${index}.init_angle_degrees`}
                label="Initial Angle (degrees)"
                additionalLabelElements={
                  <Tooltip title={tooltips.init_angle_degrees}>
                    <span className="tooltip-icon">?</span>
                  </Tooltip>
                }
                type="number"
              />
            </>
          )}
        </div>
        {settings.rest && (
          <InputField
            {...sharedInputProps}
            className="small-input"
            name={`activities.${index}.rest`}
            label="Rest (sec)"
            type="number"
            required
            tooltip={tooltips.rest}
          />
        )}
        {settings.duration && (
          <InputField
            {...sharedInputProps}
            className="small-input"
            name={`activities.${index}.duration`}
            label="Duration (sec)"
            type="number"
            required
          />
        )}

        {activityDefinition.postExerciseMetric && (
          <ActivityGoals
            {...sharedInputProps}
            activityIndex={index}
            metrics={getPostMetrics(activityDefinition)}
            measurementSystem={measurementSystem}
          />
        )}
        <DisplayField
          label="Time Estimate"
          value={
            activityDefinition.postExerciseMetric?.length == 0
              ? 0
              : formatTimeFromSecToString(estimateTime || 0)
          }
        />
      </div>
      <ActivityDefinitionPreview activityDefinition={activityDefinition} />
    </div>
  );
}

function Footer({
  onSaveAndAssign,
  onSaveAsTemplate,
  onCancel,
}: {
  onSaveAndAssign: () => void;
  onSaveAsTemplate: () => void;
  onCancel: () => void;
}) {
  const treatmentPlans = useSelector(getPatientTreatmentPlansSelector);

  return (
    <div css={cardFooterCss}>
      {treatmentPlans && treatmentPlans.length > 0 ? (
        <button css={{ ...buttons.secondary }} type="button" onClick={onCancel}>
          Cancel
        </button>
      ) : (
        <div />
      )}
      <div css={{ display: 'flex', gap: 20 }}>
        <div>
          <button
            css={{ ...buttons.secondary }}
            type="button"
            onClick={onSaveAsTemplate}
          >
            Save as Template
          </button>
        </div>
        <div>
          <button
            css={{ ...buttons.primary }}
            type="button"
            onClick={onSaveAndAssign}
          >
            Save and Assign
          </button>
        </div>
      </div>
    </div>
  );
}

function getInitState(
  initPlanId: string | null,
  initTemplateId: string | undefined,
) {
  if (isEmpty(initPlanId) && isEmpty(initTemplateId)) {
    return {
      id: uuid(),
      name: createDefultPlanName(),
      startDate: new Date(),
      activities: [],
    };
  }
}

export default function Form({
  setFormDataRef,
}: {
  setFormDataRef: React.MutableRefObject<
    SetFormData<TreatmentPlanInput> | undefined
  >;
}) {
  const history = useHistory();

  const patientTreatmentPlans = useSelector(getPatientTreatmentPlansSelector);

  const templates = useSelector(getTreatmentPlanTemplatesSelector);

  const initPlanId = new URLSearchParams(history.location.search).get(
    PLAN_ID_PARAM,
  );
  const initTemplateId = useSelector(getEditTemplateIdSelector);

  const [initFormData, setInitFormData] = useState<
    TreatmentPlanInput | undefined
  >(getInitState(initPlanId, initTemplateId));

  const { formValidation, validate } = useValidation('new', 'plan');

  const {
    formData,
    prevFormData,
    setFormData,
    formErrors,
    setShouldValidate,
    isDirty,
    onChange,
  } = useForm(initFormData, validate);

  useEffect(() => {
    setFormDataRef.current = setFormData;
  }, [setFormData]);

  useEffect(() => {
    if (templates && initTemplateId) {
      const initTemplate = templates[initTemplateId];
      if (initTemplate) {
        const treatmentPlanInput =
          copyTemplateToTreatmentPlanInput(initTemplate);
        setInitFormData(treatmentPlanInput);
      }
    }
  }, [templates, initTemplateId]);

  useEffect(() => {
    if (patientTreatmentPlans && initPlanId) {
      const initTreatmentPlan = patientTreatmentPlans?.find(
        ({ id }) => id === initPlanId,
      );
      if (initTreatmentPlan) {
        const treatmentPlanInput = copyToTreatmentPlanInput(initTreatmentPlan);
        const isDuplicate = patientTreatmentPlans?.[0]?.id != initPlanId;
        setInitFormData(
          Object.assign({}, treatmentPlanInput, {
            name: isDuplicate
              ? createDefultPlanName()
              : treatmentPlanInput.name,
          }),
        );
      }
    }
  }, [patientTreatmentPlans]);

  const activityDefinitions = useSelector(getActivityDefinitionsSelector);

  //TODO - move form to work with immer and remove this
  setAutoFreeze(false);

  const inProcess =
    useInProcess(formData?.id, [
      'createTreatmentPlanTemplate',
      'saveAndAssignTreatmentPlan',
      'editTreatmentPlanTemplate',
    ]) ||
    (initPlanId && !initFormData);

  const dispatch = useAppDispatch();

  const [expandedActivities, setExpandedActivities] = useState<string[]>([]);
  const [orderByPositionSortOrder, setOrderByPositionSortOrder] = useState<
    'asc' | 'desc'
  >('asc');
  const unblock = useRef<() => void | undefined>();

  const isPlanHasLocalResults = useIsPlanHasLocalResults(initPlanId);

  useEffect(() => {
    unblock.current = history.block(isDirty ? LEAVING_MSG : true);
    return () => {
      unblock.current?.();
    };
  }, [isDirty]);

  useEffect(() => {
    return () => {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.delete(PLAN_ID_PARAM);
      history.replace({ search: searchParams.toString() });
      dispatch(cleanEditTemplateIdAction());
    };
  }, []);

  const createTemplateEndedSuccessfully = useIsEndedSuccessfully(formData?.id, [
    'createTreatmentPlanTemplate',
  ]);

  useEffect(() => {
    if (createTemplateEndedSuccessfully) {
      setShouldValidate(false);
      setFormData(
        produce((draftFormData) => {
          draftFormData.id = uuid();
        }),
      );
    }
  }, [createTemplateEndedSuccessfully]);

  const createPlanEndedSuccessfully = useIsEndedSuccessfully(formData?.id, [
    'saveAndAssignTreatmentPlan',
  ]);

  useEffect(() => {
    if (createPlanEndedSuccessfully) {
      unblock.current?.();
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.delete(PLAN_ID_PARAM);
      history.push({
        pathname: '/patient/treatment-plans/view',
        search: searchParams.toString(),
      });
      dispatch(cleanEditTemplateIdAction());
    }
  }, [createPlanEndedSuccessfully]);

  const scrollCallbackRef = useRef<(id: string) => void>();

  useEffect(() => {
    if (prevFormData && formData) {
      const activityWasAdded =
        formData?.activities.length > prevFormData.activities.length;
      if (activityWasAdded) {
        const lastActivitiesIds = prevFormData.activities.map(({ id }) => id);
        const newActivityIndex = formData?.activities.findIndex(
          ({ id }) => !lastActivitiesIds.includes(id),
        );
        const newActivity = formData?.activities[newActivityIndex];
        if (
          newActivity &&
          newActivityIndex === formData?.activities.length - 1
        ) {
          scrollCallbackRef.current?.(newActivity.id);
        }
      }
    }
  }, [formData]);

  const onDragEnd: OnDragEndResponder = ({ source, destination }) => {
    if (!formData) {
      return;
    }
    if (destination) {
      const newActivities = [...formData.activities];
      newActivities.splice(
        destination.index,
        0,
        newActivities.splice(source.index, 1)[0]!,
      );
      setFormData({ ...formData, activities: newActivities });
    }
  };

  const orderByPosition = () => {
    setFormData(
      produce((draftFormData) => {
        const orderActivities = orderActivitiesByPosition(
          draftFormData.activities,
          activityDefinitions,
          orderByPositionSortOrder,
        );
        draftFormData.activities = orderActivities;
      }),
    );
    setOrderByPositionSortOrder((prevValue) =>
      prevValue == 'asc' ? 'desc' : 'asc',
    );
  };

  const swapSides = () => {
    setFormData(
      produce((draftFormData) => {
        draftFormData.activities = toggleSides(draftFormData.activities);
      }),
    );
  };

  const onSave = (type: ValidateAs, mode: Mode) => {
    if (!formData) {
      return;
    }
    setShouldValidate(true);
    formValidation.setValidateAs(type);
    formValidation.setMode(mode);
    formValidation.setOrigin(initFormData);
    const errors = formValidation.validate(formData);
    if (errors) {
      dispatch(
        notify({
          message: 'Please fix the issues and save again',
          severity: 'error',
        }),
      );
      if (Array.isArray(errors.activities)) {
        const index = Object.keys(errors.activities)[0];
        if (index !== undefined) {
          const activityWithError = formData.activities[Number(index)];
          if (activityWithError) {
            scrollCallbackRef?.current?.(activityWithError.id);
          }
        }
      }
      return;
    }
    if (type === 'template') {
      const updateTemplate = mode === 'edit';
      dispatch(createTreatmentPlanTemplateAction(formData, updateTemplate));
    } else if (type === 'plan') {
      dispatch(saveAndAssignTreatmentPlanAction(formData));
    }
  };

  const onCancel = async () => {
    const leave = () => {
      unblock.current?.();
      history.push({
        pathname: '/patient/treatment-plans/view',
        search: history.location.search,
      });
    };
    if (!isDirty) {
      leave();
      return;
    }
    const userAnswer = await AskUser.getInstance().askUser({
      msg: LEAVING_MSG,
      answers: ['Cancel', 'Yes'],
    });
    if (userAnswer === 'Yes') {
      leave();
    }
  };

  const toggleAllItems = () => {
    if (!formData) {
      return;
    }
    if (expandedActivities.length === 0) {
      setExpandedActivities(formData.activities.map(({ id }) => id));
    } else {
      setExpandedActivities([]);
    }
  };

  const getToggleIconCss = () => {
    const toggleIconCss: CSSObject = { color: colors.blue4 };
    if (expandedActivities.length > 0) {
      toggleIconCss.transform = 'rotate(90deg)';
    }
    return toggleIconCss;
  };

  const onSideSelect = (value: string, index: number) =>
    setFormData(
      produce((draftFormData) => {
        set(draftFormData, `activities.${index}.side`, value);
      }),
    );

  if (!formData) {
    return <Loader />;
  }

  const sharedInputProps = { onChange, setFormData, formData, formErrors };
  const descriptionPlaceholder =
    'Describe the patient is treatment goals,interventions, and expected outcomes...';
  const totalEstimationTime = formData?.activities?.reduce(
    (sum: number, activity) => {
      if (
        formData &&
        activityDefinitions &&
        activity.activityDefinitionId &&
        activityDefinitions[activity.activityDefinitionId]
      ) {
        const activityDef = activityDefinitions[activity.activityDefinitionId];
        if (activityDef) {
          return sum + (calcEstimateTime(activityDef, activity) || 0);
        } else {
          return sum;
        }
      } else {
        return sum;
      }
    },
    0,
  );
  return (
    <form
      noValidate
      css={{ ...formContainerCss, ...markAccordionItemsAsError(formErrors) }}
      name="treatment-plan-form"
    >
      <FormHeader sharedInputProps={sharedInputProps} />
      <div css={formBodyContainerCss}>
        <InputField
          {...sharedInputProps}
          name="description"
          label="Description"
          textarea={true}
          placeholder={descriptionPlaceholder}
        />
        <div css={activitiesOperationsCss}>
          <div css={{ flex: 1 }}>
            <button type="button" onClick={toggleAllItems}>
              <Chevron width={11} css={getToggleIconCss()} />
            </button>
            <div css={{ ...fonts.largeLabel }}>Selected Activities</div>
          </div>
          {formData.activities.length > 0 && (
            <div
              className="global-actions"
              css={{
                button: { ...buttons.secondarySmall },
              }}
            >
              <button
                css={{ marginLeft: 77, display: 'flex', alignItems: 'center' }}
                type="button"
                onClick={orderByPosition}
              >
                Order by Position
                {orderByPositionSortOrder === 'desc' ? (
                  <SortAscIcon css={sortIconCss} />
                ) : (
                  <SortDescIcon css={sortIconCss} />
                )}
              </button>
              <button
                css={{
                  width: 104,
                  marginRight:
                    SIDE_MARGIN_RIGHT +
                    DUPLICATE_SIZE +
                    TRASH_MARGIN +
                    TRASH_SIZE +
                    ACCORDION_PADDING,
                }}
                type="button"
                onClick={swapSides}
              >
                Swap Sides
              </button>
            </div>
          )}
          <div css={{ ...fonts.largeLabel, alignSelf: 'flex-end' }}>
            Time Estimate:
            <div
              css={{
                ...fonts.smallLabel,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {formData.activities.length == 0
                ? 0
                : formatTimeFromSecToString(totalEstimationTime)}
            </div>
          </div>
        </div>
        {formData.activities.length > 0 && (
          <DraggableAccordionView
            items={formData.activities}
            expendedItemsIds={expandedActivities}
            setExpendedItemsIds={setExpandedActivities}
            onDragEnd={onDragEnd}
            scrollCallback={(callback) => {
              scrollCallbackRef.current = callback;
            }}
            getAccordionSummary={(index, activity) => {
              const activityDefinition =
                activityDefinitions[activity.activityDefinitionId];
              if (!activityDefinition) {
                return <>cannot display this activity</>;
              }
              return (
                <ActivitySummary
                  index={index}
                  activity={activity}
                  activityDefinition={activityDefinition}
                  setFormData={setFormData}
                  formErrors={formErrors}
                  onSideSelect={onSideSelect}
                />
              );
            }}
            getAccordionDetails={(index, activity) => {
              const activityDefinition =
                activityDefinitions[activity.activityDefinitionId];
              if (!activityDefinition) {
                return <></>;
              }
              return (
                <ActivityDetails
                  index={index}
                  activity={activity}
                  sharedInputProps={sharedInputProps}
                  activityDefinition={activityDefinition}
                  onSideSelect={onSideSelect}
                />
              );
            }}
          />
        )}
      </div>
      <Footer
        onSaveAndAssign={() => {
          const mode = isPlanHasLocalResults ? 'new' : 'edit';
          onSave('plan', mode);
        }}
        onSaveAsTemplate={() => {
          if (initTemplateId !== undefined) {
            const initTemplate = templates[initTemplateId];
            if (!initTemplate) {
              return;
            }
            const saveAsNew = 'Save As New';
            const update = 'Update';
            AskUser.getInstance()
              .askUser({
                msg: `Do you want to update template \"${initTemplate.name}\"?`,
                answers: ['Cancel', saveAsNew, update],
              })
              .then((answer) => {
                if (answer === saveAsNew) {
                  onSave('template', 'new');
                  dispatch(cleanEditTemplateIdAction());
                } else if (answer === update) {
                  onSave('template', 'edit');
                }
              });
          } else {
            onSave('template', 'new');
          }
        }}
        onCancel={onCancel}
      />
      {inProcess && <Loader />}
    </form>
  );
}
