/** @jsxImportSource @emotion/react */

import { CSSObject } from '@emotion/react';
import { colors } from '../../style/colors';

const loadingDotsCss: CSSObject = {
  '@keyframes dot-keyframes': {
    '0%': {
      opacity: '.4',
      transform: 'scale(1, 1)',
    },

    '50%': {
      opacity: 1,
      transform: 'scale(1.2, 1.2)',
    },

    '100%': {
      opacity: '.4',
      transform: 'scale(1, 1)',
    },
  },

  '.loading-dots': {
    '&--dot': {
      animation: 'dot-keyframes 1.5s infinite ease-in-out',
      backgroundColor: colors.blue4,
      borderRadius: '10px',
      display: 'inline-block',
      marginRight: '5px',
      height: '15px',
      width: '15px',

      '&:nth-of-type(2)': {
        animationDelay: '.5s',
      },

      '&:nth-of-type(3)': {
        animationDelay: '1s',
      },
    },
  },
};

export default function DotsLoader() {
  return (
    <div className="loading-dots" css={loadingDotsCss}>
      <div className="loading-dots--dot"></div>
      <div className="loading-dots--dot"></div>
      <div className="loading-dots--dot"></div>
    </div>
  );
}
