/** @jsxImportSource @emotion/react */

import { HTMLProps } from 'react';
import { CSSObject } from '@emotion/react';
import { colors } from '../../style/colors';
import { fonts } from '../../style/fonts';
import { createClassName } from './form/basic';

const BORDER_WIDTH = 1;
const buttonToggleCss: CSSObject = {
  ...fonts.smallLabel,
  display: 'flex',
  alignItems: 'center',
  gap: '4px',

  '& > label': {
    whiteSpace: 'nowrap',
  },

  '.buttons-container': {
    display: 'flex',
  },

  '.buttons-container > button': {
    border: `${BORDER_WIDTH}px solid ${colors.blue3}`,
    padding: '5px 12px',
    color: colors.blue3,
    marginRight: -1 * BORDER_WIDTH,
    '&.selected, &:hover': {
      color: colors.white,
      backgroundColor: colors.blue3,
    },
  },

  '.buttons-container > button:first-of-type': {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },

  '.buttons-container > button:last-of-type': {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },

  '.buttons-container.error > button': {
    border: `1px solid  ${colors.red}`,
  },
};

export default function ButtonToggle(
  props: Omit<HTMLProps<HTMLDivElement>, 'onChange' | 'value'> & {
    buttons: { buttonLabel: string; value: string; disabled?: boolean }[];
    onChange: (value: string) => void;
    value: string | undefined | null;
    label?: string;
    error?: string;
  },
) {
  const { value, onChange, buttons, label, error, ...rest } = props;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (value !== event.currentTarget.value) {
      onChange?.(event.currentTarget.value);
    } else {
      onChange?.('');
    }
  };

  return (
    <div {...rest} css={buttonToggleCss} className="button-toggle">
      <label>{label}</label>
      <div
        className={createClassName({
          innerClassName: 'buttons-container',
          ...props,
        })}
      >
        {buttons.map(({ value: buttonValue, buttonLabel, disabled }) => {
          return (
            <button
              key={buttonLabel}
              type="button"
              value={buttonValue}
              className={value === buttonValue ? 'selected' : undefined}
              onClick={handleClick}
              disabled={disabled}
            >
              {buttonLabel}
            </button>
          );
        })}
      </div>
    </div>
  );
}
