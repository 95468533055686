/* eslint-disable max-len */
export const tooltips = {
  reps: 'Number of exercise cycles performed',
  rest: 'Duration of rest between exercise sets',
  hold: 'Duration of static position',
  side: 'Indicates the side of the affected organ',
  assessment:
    'Evaluate the current state or condition, typically referring to a health or physical examination to gather information',
  exercise:
    'Engage in a planned and structured physical activity designed to enhance or maintain health and fitness levels',
  knee_extension: 'Measures the straightening angle of the knee joint, in degrees',
  ankle_flexion: 'Measure of upward movement at the ankle joint',
  hip_extension: 'Assess the straightening of the hip joint',
  trunk_flexion: 'Measure how far the patient can bend forward at the waist',
  disabled_equipment:
    'This equipment option is not selectable due to an exlusive alternative having been added already',
  exclusive_required_equipment:
    'This equipment option is an exclusive alternative to an already added one. Selecting it will swap them.',
  minimum_to_consider_rep: 'Minimum total arc of motion to be counted as repetition.',
  init_angle_degrees:
    'Adjust initial angle for patient that have limitation to start with the default initial angle.',
};
