import { ThemeOptions, createTheme } from '@mui/material';

import { shapes } from './style/shapes';

export const theme: ThemeOptions = createTheme({
  typography: {
    fontFamily: 'IntelOne Text',
  },
  palette: {},
  shape: {
    borderRadius: shapes.borderRadius,
  },
});
