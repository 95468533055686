import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';

import { getNotificationsSelector } from '../state-manager/selectors/notificationsSelectors';
import { removeNotification } from '../state-manager/slices/notificationsSlice';

let displayed: string[] = [];

export default function useNotifier() {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotificationsSelector);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(
      ({ id, message, dismissed = false, severity, origin }) => {
        if (dismissed) {
          closeSnackbar(id);
          return;
        }

        if (displayed.includes(id)) {
          return;
        }

        enqueueSnackbar(message, {
          key: id,
          action: () => (
            <Button onClick={() => closeSnackbar(id)}>dismiss me</Button>
          ),
          onExited: (_, myKey) => {
            const idStr = `${myKey}`;
            dispatch(removeNotification({ id: idStr }));
            removeDisplayed(idStr);
          },
          variant: severity,
          anchorOrigin: origin,
        });
        storeDisplayed(id);
      },
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
}
