import { AxiosResponse } from 'axios';
import {
  MovementMediaDto,
  MovementResultsOutputDto,
  TreatmentPlanDto,
  TreatmentPlanInputDto,
  TreatmentPlanTemplateDto,
  TreatmentPlanTemplateInputDto,
} from '../types/backendType';
import httpClient from './httpClient';

//GET /treatment-plan/assignment
export async function fetchUserTreatmentPlans({
  userId,
  startDate,
  endDate,
}: {
  userId: string;
  startDate: Date;
  endDate: Date;
}): Promise<TreatmentPlanDto[] | undefined> {
  try {
    const response = await httpClient.get<TreatmentPlanDto[]>('/treatment-plan/assignment', {
      params: {
        executed_plan: true,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      },
      headers: { 'x-user-id': userId }, //TODO: remove this and check if interceptor working
    });
    const treatmentPlanDtoArray = response.data;
    return treatmentPlanDtoArray;
  } catch (e) {
    throw new Error(`Failed to fetch movement treatment plans: ${e}`);
  }
}

//GET /movement/results
export async function fetchMovementsResults({
  userId,
  startDate,
}: {
  userId: string;
  startDate: Date;
}): Promise<MovementResultsOutputDto | undefined> {
  try {
    const response = await httpClient.get<MovementResultsOutputDto>('/movement/results', {
      params: {
        start_date: startDate.toISOString(),
        results_data_only: true,
      },
      headers: { 'x-user-id': userId }, //TODO: remove this and check if interceptor working
    });
    const movementResultsOutputDto = response.data;
    return movementResultsOutputDto;
  } catch (e) {
    throw new Error(`Failed to movement results: ${e}`);
  }
}

// GET /streaming/movement/metrics/{session_id}/{sub_session_id}
export async function fetchMovementExecutionMetricsUrl({
  patientId,
  sessionId,
  subSessionId,
  version,
}: {
  patientId: string;
  sessionId: string;
  subSessionId: string;
  version?: number;
}): Promise<string | undefined> {
  try {
    const response = await httpClient.get<MovementMediaDto>(
      `/streaming/movement/metrics/${sessionId}/${subSessionId}`,
      {
        headers: { 'x-user-id': patientId },
        params: { version },
      },
    );

    const movementMediaDto = response.data;
    if (!movementMediaDto) {
      throw new Error('movementMediaDto is empty');
    }
    return movementMediaDto.mediaLink;
  } catch (e) {
    throw new Error(`Failed to fetch movement metrics : ${e}`);
  }
}

// GET /streaming/movement/media/{session_id}/{sub_session_id}
export async function fetchMovementExecutionVideoUrl({
  patientId,
  sessionId,
  subSessionId,
}: {
  patientId: string;
  sessionId: string;
  subSessionId: string;
}): Promise<MovementMediaDto | undefined> {
  try {
    let response: AxiosResponse<MovementMediaDto, any>;
    try {
      response = await httpClient.get<MovementMediaDto>(
        `/streaming/movement/media/${sessionId}/${subSessionId}`,
        {
          headers: { 'x-user-id': patientId },
          params: { version: 2 },
        },
      );
    } catch (e) {
      response = await httpClient.get<MovementMediaDto>(
        `/streaming/movement/media/${sessionId}/${subSessionId}`,
        {
          headers: { 'x-user-id': patientId },
        },
      );
    }
    const movementMediaDto = response.data;
    if (!movementMediaDto) {
      throw new Error('movementMediaDto is empty');
    }
    return movementMediaDto;
  } catch (e) {
    throw new Error(`Failed to fetch movement media : ${e}`);
  }
}

//POST /treatment-plan/template
export async function createTreatmentPlanTemplate({
  treatmentPlanTemplateInputDto,
}: {
  treatmentPlanTemplateInputDto: TreatmentPlanTemplateInputDto;
}): Promise<TreatmentPlanTemplateDto> {
  try {
    const response = await httpClient.post<TreatmentPlanTemplateDto>(
      '/treatment-plan/template',
      treatmentPlanTemplateInputDto,
    );
    const treatmentPlanTemplateDto = response.data;
    return treatmentPlanTemplateDto;
  } catch (e) {
    throw new Error(`Failed to create treatment plan template: ${e}`);
  }
}

//GET /treatment-plan/template
export async function fetchTreatmentPlanTemplates(): Promise<
  TreatmentPlanTemplateDto[] | undefined
> {
  try {
    const response = await httpClient.get<TreatmentPlanTemplateDto[]>(
      '/treatment-plan/template',
    );
    const treatmentPlanDtoArray = response.data;
    return treatmentPlanDtoArray;
  } catch (e) {
    throw new Error(`Failed to fetch treatment plan template: ${e}`);
  }
}

//POST /treatment-plan/assignment
export async function assignTreatmentPlan({
  patientId,
  treatmentPlanInputDto,
}: {
  patientId: string;
  treatmentPlanInputDto: TreatmentPlanInputDto;
}): Promise<TreatmentPlanDto> {
  try {
    const response = await httpClient.post<TreatmentPlanDto>(
      '/treatment-plan/assignment',
      treatmentPlanInputDto,
      {
        headers: { 'x-user-id': patientId },
      },
    );
    const treatmentPlanDto = response.data;
    return treatmentPlanDto;
  } catch (e) {
    throw new Error(`Failed to assign treatment plan: ${e}`);
  }
}

//DELETE /treatment-plan/assignment
export async function deleteTreatmentPlan(treatmentPlanId: string) {
  try {
    await httpClient.delete(`/treatment-plan/assignment/${treatmentPlanId}`);
  } catch (e) {
    throw new Error(`Failed to delete treatment plan: ${e}`);
  }
}

//DELETE /treatment-plan/template
export async function deleteTreatmentPlanTemplate(treatmentPlanTemplateId: string) {
  try {
    await httpClient.delete(`/treatment-plan/template/${treatmentPlanTemplateId}`);
  } catch (e) {
    throw new Error(`Failed to delete treatment plan template: ${e}`);
  }
}

//PATCH /treatment-plan/template/{template_id}
export async function editTreatmentPlanTemplate(
  templateId: string,
  treatmentPlanTemplateInputDto: TreatmentPlanTemplateInputDto,
): Promise<TreatmentPlanTemplateDto> {
  try {
    const response = await httpClient.patch<TreatmentPlanTemplateDto>(
      `/treatment-plan/template/${templateId}`,
      treatmentPlanTemplateInputDto,
    );
    const treatmentPlanTemplateDto = response.data;
    return treatmentPlanTemplateDto;
  } catch (e) {
    throw new Error(`Failed to patch treatment plan template: ${e}`);
  }
}
