type MediaElement = HTMLMediaElement | HTMLImageElement;
type Size = { width: number; height: number };

function releaseMediaElementResource(element: HTMLMediaElement | HTMLImageElement) {
  element.removeAttribute('src');
  if (element instanceof HTMLMediaElement) {
    element.load();
  }
}

export class AssetMetadataProvider {
  private static instance: AssetMetadataProvider | undefined;

  elements: MediaElement[];

  private constructor() {
    this.elements = [];
  }

  static getInstance(): AssetMetadataProvider {
    if (AssetMetadataProvider.instance === undefined) {
      AssetMetadataProvider.instance = new AssetMetadataProvider();
    }
    return AssetMetadataProvider.instance;
  }

  private elementCleanup(element: MediaElement) {
    this.elements.splice(this.elements.indexOf(element), 1);
    releaseMediaElementResource(element);
  }

  private logError(element: MediaElement, error: MediaError | Error) {
    // eslint-disable-next-line no-console
    console.error(element.src, error);
  }

  getVideoMetadata(url: string): Promise<{ size: Size; duration: number }> {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement('video');
      this.elements.push(videoElement);
      videoElement.onloadedmetadata = () => {
        resolve({
          size: { width: videoElement.videoWidth, height: videoElement.videoHeight },
          duration: videoElement.duration,
        });
        this.elementCleanup(videoElement);
      };
      videoElement.onerror = () => {
        const error =
          videoElement.error ?? new Error('unknown error in loading video to element');
        this.logError(videoElement, error);
        reject(error);
        this.elementCleanup(videoElement);
      };

      // videoElement.crossOrigin = 'Anonymous';
      videoElement.src = url;
    });
  }
}
