/** @jsxImportSource @emotion/react */

import { set, get } from 'lodash-es';
import { CSSObject } from '@emotion/react';

import { TreatmentPlanInput } from './treatmentPlanTypes';
import { tooltips } from '../commons/tooltips';
import { PostMetric } from '../../../../models/PostMetric';
import { FormErrors } from '../../../commons/form/useForm';
import { createError, SimpleInputField } from '../../../commons/form/basic';
import { isEmpty } from '../../../../state-manager/utils/compare';
import { isNumber } from '../../../../utils/numberUtils';
import { fonts } from '../../../../style/fonts';
import {
  getMeasurementSystemUnit,
  getMeasurementSystemValue,
  getMetricMathDisplayText,
  getUnitAsDisplayText,
} from '../../../../utils/unitUtils';
import DisplayField from '../../../commons/form/DisplayField';
import { cellStyle, headerStyle, tableStyle } from '../commons/ActivityPreview';

const activityGoalsCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: '5px',
  justifyContent: 'space-between',
  '.goals': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 3,
    '.input-with-footer': {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    label: {
      ...fonts.text,
    },
  },
  label: { lineHeight: '40px' },
};

export default function ActivityGoals({
  disabled,
  activityIndex,
  metrics,
  formData,
  setFormData,
  formErrors,
  measurementSystem,
}: {
  disabled?: boolean;
  activityIndex: number;
  metrics: PostMetric[] | undefined;
  formData: TreatmentPlanInput;
  setFormData: (formData: TreatmentPlanInput) => void;
  formErrors: FormErrors<TreatmentPlanInput> | undefined;
  measurementSystem: string;
}) {
  if (!metrics || metrics.length === 0) {
    return <></>;
  }

  const filteredMetrics = metrics.filter(
    (metric) => metric.automationLevel !== 0,
  );
  if (filteredMetrics.length === 0) {
    return <></>;
  }
  const path = `activities.${activityIndex}.goals`;
  const goals: Record<string, number> = get(formData, path);
  const goalsErrors: Record<string, string> | undefined = get(formErrors, path);
  return (
    <div className="activity-goals field-grouping" css={activityGoalsCss}>
      <DisplayField label="Goals" value={''}>
        {goalsErrors &&
          Array.from(new Set(Object.values(goalsErrors))).map(
            (error: string) => <div key={error}>{createError({ error })}</div>,
          )}
        <table css={{ ...tableStyle, marginTop: 0 }}>
          <thead>
            <tr>
              <th css={headerStyle}>Activity</th>
              <th css={headerStyle}>Goal</th>
              <th css={headerStyle}>Unit</th>
              <th css={headerStyle}>Range</th>
              <th css={headerStyle}>Error Range</th>
              <th css={headerStyle}>Measure</th>
            </tr>
          </thead>
          <tbody>
            {filteredMetrics.map((metric) => {
              const measurementSystemUnit = getMeasurementSystemUnit(
                metric.unit,
                measurementSystem,
              );
              const measurementSystemSd =
                metric.metric_sd !== 'NA'
                  ? getMeasurementSystemValue(
                      metric.metric_sd,
                      measurementSystemUnit,
                      measurementSystem,
                    )
                  : null;
              const math = getMetricMathDisplayText(metric.math);
              const measurementSystemGoal = goals[metric.key]
                ? getMeasurementSystemValue(
                    goals[metric.key],
                    measurementSystemUnit,
                    measurementSystem,
                  )
                : null;
              if (measurementSystemSd) {
                return (
                  <tr key={metric.name}>
                    <td css={{ ...cellStyle, ...fonts.largeLabel }}>
                      {metric.name}
                    </td>
                    <td css={cellStyle}>
                      {
                        <SimpleInputField
                          key={metric.key}
                          name={metric.key}
                          value={measurementSystemGoal?.toString() || ''}
                          type="number"
                          className="small-input"
                          onChange={(value) => {
                            if (isEmpty(value)) {
                              delete goals[metric.key];
                            } else {
                              if (isNumber(value)) {
                                goals[metric.key] = value;
                              }
                            }
                            const newFormData = { ...formData };
                            set(newFormData, path, goals);
                            setFormData(newFormData);
                          }}
                          error={goalsErrors?.[metric.key]}
                          disabledErrorText={true}
                          tooltip={
                            tooltips[metric.name as keyof typeof tooltips]
                          }
                          disabled={disabled}
                        />
                      }
                    </td>
                    <td css={cellStyle}>
                      {getUnitAsDisplayText(measurementSystemUnit)}
                    </td>
                    <td css={cellStyle}>
                      {`${getMeasurementSystemValue(
                        metric.min,
                        measurementSystemUnit,
                        measurementSystem,
                      )}-${getMeasurementSystemValue(
                        metric.max,
                        measurementSystemUnit,
                        measurementSystem,
                      )}`}
                    </td>

                    <td css={cellStyle}>{`±${measurementSystemSd}`}</td>
                    <td css={cellStyle}>{math}</td>
                  </tr>
                );
              } else {
                return null;
              }
            })}
          </tbody>
        </table>
      </DisplayField>
    </div>
  );
}
