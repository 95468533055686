/** @jsxImportSource @emotion/react */

import { colors } from '../../../style/colors';
import {
  card,
  cardBodyCss,
  cardFooterCss,
  cardHeaderCss,
} from '../../../style/shapes';
import CloseIcon from './../../../assets/close.svg';
import { buttons } from '../../../style/buttons';
import { SimpleInputField } from '../form/basic';
import { useEffect, useState } from 'react';
import { isEmpty } from '../../../state-manager/utils/compare';
import { getPatientCaseDtosSelector } from '../../../state-manager/selectors/casesSelectors';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../state-manager/store';
import DisplayField from '../form/DisplayField';
import {
  createCaseAction,
  fetchCasesAction,
} from '../../../state-manager/thunks/casesThunks';
import Loader from '../Loader';
import {
  useInProcess,
  useIsEndedSuccessfully,
} from '../../../state-manager/selectors/appSelectors';
import { cleanRequestStatusAction } from '../../../state-manager/slices/appSlice';

export default function CaseForm({
  onCancel,
  onClose,
  patientId,
}: {
  onCancel?: () => void;
  onClose: () => void;
  patientId: string;
}) {
  const dispatch = useAppDispatch();
  const [caseId, setCaseId] = useState<string>('');
  const [startCreationProcess, setStartCreationProcess] =
    useState<boolean>(false);
  const caseDtos = useSelector(getPatientCaseDtosSelector(patientId));
  const loading = useInProcess(patientId, ['createCase']);
  const creationFinished = useIsEndedSuccessfully(patientId, ['createCase']);

  useEffect(() => {
    setStartCreationProcess(true);
    dispatch(fetchCasesAction(patientId));
  }, []);

  useEffect(() => {
    if (startCreationProcess && creationFinished) {
      dispatch(
        cleanRequestStatusAction({
          requestStatusName: 'createCase',
          id: patientId,
        }),
      );
      onClose();
    }
  }, [startCreationProcess, creationFinished]);

  const onSubmit = async () => {
    if (!isEmpty(caseId)) {
      dispatch(createCaseAction(patientId, caseId));
    }
  };

  return (
    <div
      css={{
        ...card,
        '.loader-background': { backgroundColor: `${colors.dividerGrey}80` },
      }}
    >
      <div css={cardHeaderCss}>
        Add Case
        {onCancel && (
          <button type="button">
            <CloseIcon width={13} onClick={onCancel} />
          </button>
        )}
      </div>
      <div css={cardBodyCss}>
        <DisplayField
          label="Current Cases"
          value={caseDtos?.map(({ case_id }) => case_id).toString()}
        />
        <SimpleInputField
          name="case-id"
          label="New Case id"
          value={caseId}
          onChange={setCaseId}
        />
      </div>
      <div css={cardFooterCss}>
        {onCancel ? (
          <button
            css={{ ...buttons.secondary }}
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
        ) : (
          <div />
        )}
        <button css={{ ...buttons.primary }} type="button" onClick={onSubmit}>
          Add
        </button>
      </div>
      {loading && <Loader />}
    </div>
  );
}
