/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import Menu from '@mui/material/Menu';

import SettingsIcon from './../../assets/settings.svg';
import { colors } from '../../style/colors';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { fonts } from '../../style/fonts';
import { CSSObject } from '@emotion/react';
import { MeasurementSystems } from '../../types/appTypes';
import { useDispatch, useSelector } from 'react-redux';
import { setMeasurementSystemAction } from '../../state-manager/slices/appSlice';
import { getMeasurementSystemSelector } from '../../state-manager/selectors/appSelectors';

//TODO: take from externalConfig / api
const APP_VERSION = '1.1.0';

const settingsMenuCss: CSSObject = {
  padding: '10px',
  width: '197px',

  '.measurement-system-container': {
    marginBottom: '20px',
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',

    '.MuiFormControlLabel-root': {
      height: '20px',
      '&:first-of-type': {
        marginBottom: '10px',
      },
    },

    '.MuiFormControlLabel-label': {
      ...fonts.text,
    },
  },
};

export default function SettingsMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const measurementSystem = useSelector(getMeasurementSystemSelector);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div
      css={{
        height: '100%',
        display: 'flex',
        '.MuiAvatar-root': {
          backgroundColor: colors.white,
          color: colors.blue1,
        },
      }}
    >
      <button onClick={openMenu}>
        <SettingsIcon width={32} />
      </button>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeMenu}>
        <div css={settingsMenuCss}>
          <div className="measurement-system-container">
            <span css={{ ...fonts.largeLabel }}>Select Measurement</span>
            <RadioGroup
              aria-labelledby="measurement-system-radio-buttons-group-label"
              defaultValue={measurementSystem}
              name="measurement-system-radio-buttons-group"
              value={measurementSystem}
              onChange={(event) => {
                dispatch(
                  setMeasurementSystemAction({
                    measurementSystem: event.target.value,
                  }),
                );
                localStorage.setItem(
                  'measurementSystem',
                  JSON.stringify(event.target.value),
                );
              }}
            >
              <FormControlLabel
                value={MeasurementSystems.Imperial}
                control={<Radio />}
                label="Standard (ft, in)"
              />
              <FormControlLabel
                value={MeasurementSystems.Metric}
                control={<Radio />}
                label="Metric (m, cm)"
              />
            </RadioGroup>
          </div>
          <span>{`Version ${APP_VERSION}`}</span>
        </div>
      </Menu>
    </div>
  );
}
