import { CaseDto } from '../../types/backendType';
import { RootState } from '../storeTypes';

export const getCaseDtosSelector = (state: RootState) => state.casesState.cases;

export const getPatientCaseDtosSelector = (patientId: string | null | undefined) => {
  const selector: (state: RootState) => CaseDto[] | undefined = (state: RootState) => {
    if (patientId) {
      return getCaseDtosSelector(state)[patientId];
    }
  };
  return selector;
};
