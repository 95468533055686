import { CSSObject } from '@emotion/react';

export const hiddenScrollbar: CSSObject = {
  overflow: 'auto',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    width: 0,
    display: 'none',
  },
};

export const ellipsis: CSSObject = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: 0, //fix when ellipsis in flex container
};
