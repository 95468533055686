/** @jsxImportSource @emotion/react */

import { format } from 'date-fns';
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  getDisplayUnit,
  getMeasurementSystemUnit,
  getMeasurementSystemValue,
  getUnitAsDisplayText,
} from '../../../utils/unitUtils';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import SessionLegend, {
  MET_GOAL,
  NOT_MEET_GOAL,
  getGoalClassName,
  legendOptions,
} from './SessionLegend';
import { fonts } from '../../../style/fonts';
import { CSSObject } from '@emotion/react';
import { shapes } from '../../../style/shapes';
import TrendIcon from '../../commons/TrendIcon';
import { Session } from '../../../models/Session';
import { ActivityExecution, Result } from '../../../models/ActivityExecution';
import { getPreviousRowCellValue } from '../../../utils/tableUtils';
import { ActivityDefinitionId } from '../../../types/library';
import { Tooltip } from '@mui/material';
import ActivityGraph from '../activities-graph/ActivityGraph';
import {
  getDailyCompliance,
  simplifiedActivityGraphTooltipCss,
  complianceCustomTooltipCss,
} from './SessionsUntils';
import SortArrow from '../../../assets/sortArrow.svg';
import { colors } from '../../../style/colors';
import { useSelector } from 'react-redux';
import { getMeasurementSystemSelector } from '../../../state-manager/selectors/appSelectors';
import { TreatmentPlan } from '../../../models/TreatmentPlan';
import ButtonToggle from '../../commons/ButtonToggle';

export const IMPROVEMENT_TREND = 'improvement';
export const REGRESSION_TREND = 'regression ';

export const trendLegendOption = {
  [IMPROVEMENT_TREND]: {
    label: 'Improvement',
    color: colors.green,
    valueDifference: 1,
  },
  [REGRESSION_TREND]: {
    label: 'Regression',
    color: colors.red,
    valueDifference: -1,
  },
};
const noInsightsMsgCss: CSSObject = {
  ...fonts.displayText,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.backgroundDarkerGrey,
  border: shapes.border,
  height: '80px',
};

const topInsightsHeaderCss: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  border: shapes.border,
  borderBottom: 'none',
  borderTopRightRadius: shapes.borderRadius,
  borderTopLeftRadius: shapes.borderRadius,
  height: '59px',
  padding: '20px',

  '& > span': {
    ...fonts.h2,
    margin: 0,
  },
};

const sessionDigestTableCss: CSSObject = {
  border: shapes.border,
  borderCollapse: 'collapse',
  width: '100%',

  'td, th': {
    border: shapes.border,
  },

  th: {
    ...fonts.largeLabel,
    padding: '20px',
    textAlign: 'left',
  },

  td: {
    padding: '12px 20px',
  },

  '.met-goal': { color: legendOptions[MET_GOAL].color },
  '.not-meet-goal': {
    color: legendOptions[NOT_MEET_GOAL].color,
  },

  '.current-value': { display: 'flex', gap: '4px', alignItems: 'baseline' },
  '.notes-list': {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },

  '.metric-name': {
    color: colors.blue2,
    textAlign: 'left',
    '&:focus': {
      textDecoration: 'underline',
    },
  },
};

type SessionsDigestProps = {
  allSessionsByDate?: Record<string, Session[]>;
  sessionsForSelectedDate: Session[] | undefined;
  selectedDate: string;
  actvitivitesExecutionsMap?: Record<ActivityDefinitionId, ActivityExecution[]>;
  dailyCompliance: number | undefined;
  dailyComplianceGaps?: string;
  lastTreatmentPlanOfDay: TreatmentPlan | undefined;
  setDigestData: (tableData: TableMetric[]) => void;
};

export type TableMetric = {
  category: string;
  activityName: string | undefined;
  activityDefinitionId: string;
  results: Result[];
  notes: (string | ReactNode)[];
  notesAsText: string[];
};

function getTrendLabel(result: Result, metricValueChange: number) {
  let trendAsElement;
  let trendAsText;
  if (metricValueChange != result.value) {
    if (result.improvementDirection === 'up') {
      if (Math.sign(metricValueChange) === 1) {
        trendAsElement = <span css={{ color: colors.green }}>Improved</span>;
        trendAsText = 'Improved';
      } else {
        trendAsElement = <span css={{ color: colors.red }}>Regressed</span>;
        trendAsText = 'Regressed';
      }
    }
    if (result.improvementDirection === 'down') {
      if (Math.sign(metricValueChange) === 1) {
        trendAsElement = <span css={{ color: colors.red }}>Regressed</span>;
        trendAsText = 'Regressed';
      } else {
        trendAsElement = <span css={{ color: colors.green }}>Improved</span>;
        trendAsText = 'Improved';
      }
    }
  }

  if (
    result.improvementDirection === 'goal' ||
    metricValueChange === 0 ||
    metricValueChange == result.value
  ) {
    trendAsElement = null;
    trendAsText = '';
  }

  return { trendAsElement, trendAsText };
}

function getColumns(
  activitiesExecutionsMap: Record<ActivityDefinitionId, ActivityExecution[]>,
  measurementSystem: string,
) {
  const columnHelper = createColumnHelper<TableMetric>();

  return [
    columnHelper.accessor((row) => row.category, {
      id: 'category',
      header: () => <span />,
      size: 171,
      cell: (cell) => {
        const cellValue = cell.getValue();
        const previousCategoryCellValue = getPreviousRowCellValue(
          cell.row.id,
          'category',
          cell.table,
        );
        if (previousCategoryCellValue === cellValue) {
          return <span />;
        }
        return <span css={{ ...fonts.largeLabel }}>{cellValue}</span>;
      },
    }),
    columnHelper.accessor((row) => row.activityName, {
      id: 'activity',
      header: () => <span>Activity</span>,
      size: 151,
      cell: (cell) => {
        const cellValue = cell.getValue();
        const previousActivityCellValue = getPreviousRowCellValue(
          cell.row.id,
          'activity',
          cell.table,
        );
        if (previousActivityCellValue === cellValue) {
          return <span />;
        }
        return <span css={{ ...fonts.largeLabel }}>{cellValue}</span>;
      },
    }),
    columnHelper.accessor((row) => [row.results[0], row.activityDefinitionId], {
      id: 'metric',
      header: () => <span>Metric</span>,
      size: 244,
      cell: (cell: CellContext<TableMetric, [Result, string]>) => {
        // If the tooltip's state is set anywhere outside of this function
        // it's onClose handler does not trigger properly
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [openTooltip, setOpenTooltip] = useState<boolean>(false);
        const cellValue = cell.getValue();
        const metric = cellValue[0];
        const activityDefinitionId = cellValue[1];
        return (
          <Tooltip
            title={
              <div css={{ width: '460px', height: '155px' }}>
                <ActivityGraph
                  activityDefinitionId={activityDefinitionId}
                  activitiesExecutionsMap={activitiesExecutionsMap}
                  metricKey={metric?.key}
                  metricSide={metric?.side}
                  standardDeviation={metric?.standardDeviation}
                  simplified
                />
              </div>
            }
            arrow
            placement="top"
            componentsProps={simplifiedActivityGraphTooltipCss}
            open={openTooltip}
            onClose={() => {
              setOpenTooltip(false);
            }}
            disableHoverListener
          >
            <button
              className="metric-name"
              onClick={() => {
                setOpenTooltip(true);
              }}
            >
              {`${metric?.name} (${getUnitAsDisplayText(metric?.unit)})`}
            </button>
          </Tooltip>
        );
      },
    }),
    columnHelper.accessor((row) => row.results, {
      id: 'current',
      header: () => <span>Current</span>,
      size: 133,
      cell: (cell) => {
        const cellValue = cell.getValue();
        const latestResult = cellValue[0];

        const measurementSystemUnit = getMeasurementSystemUnit(
          latestResult?.unit,
          measurementSystem,
        );

        const measurementSystemValue = getMeasurementSystemValue(
          latestResult?.value,
          measurementSystemUnit,
          measurementSystem,
        )?.toFixed(0);
        return (
          <div className="current-value">
            <span
              className={getGoalClassName({
                metGoal: latestResult?.metGoal,
              })}
            >
              {measurementSystemValue}
            </span>
            {latestResult?.side && (
              <span css={{ color: 'unset' }}>{`(${latestResult?.side})`}</span>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.results, {
      id: 'trend',
      header: () => <span>Trend</span>,
      size: 100,
      cell: (cell) => {
        const cellValue = cell.getValue();
        return (
          <div className="trend">
            <TrendIcon
              resultsToCompare={[cellValue[1], cellValue[0]]}
              height={15}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.notes, {
      id: 'notes',
      header: () => <span>Notes</span>,
      size: 445,
      cell: (cell) => (
        <ul className="notes-list">
          {cell.getValue().map((note, index) => {
            return <li key={index}>{note}</li>;
          })}
        </ul>
      ),
    }),
  ];
}

function SessionDigestTable({
  tableData,
  actvitivitesExecutionsMap,
  dailyCompliance,
  dailyComplianceGaps,
  previousDailyCompliance,
  previousDate,
  measurementSystem,
  insightMode,
  changeInsightMode,
}: {
  tableData: TableMetric[];
  actvitivitesExecutionsMap?: Record<ActivityDefinitionId, ActivityExecution[]>;
  dailyCompliance: number | undefined;
  dailyComplianceGaps?: string;
  previousDailyCompliance: number | undefined;
  previousDate: string | undefined;
  measurementSystem: string;
  insightMode: InsightMode;
  changeInsightMode: (insightMode: InsightMode) => void;
}) {
  const columns = useMemo(
    () => getColumns(actvitivitesExecutionsMap || {}, measurementSystem),
    [measurementSystem],
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const complianceNote = useMemo(() => {
    if (!dailyCompliance || !previousDailyCompliance || !previousDate) {
      return;
    }
    const complianceDifference = dailyCompliance - previousDailyCompliance;
    if (!complianceDifference) {
      return;
    }
    const trendLabel =
      Math.sign(complianceDifference) === 1 ? 'Increased' : 'Decreased';
    const date = new Date(previousDate);
    return `${trendLabel} by ${Math.abs(
      complianceDifference,
    )}% compared to visit from ${format(date, 'MMMM do')}
  `;
  }, [dailyCompliance, previousDailyCompliance, previousDate]);

  return (
    <div css={{ marginBottom: '20px' }}>
      <div css={topInsightsHeaderCss}>
        <span css={{ display: 'flex' }}>
          Insights
          <ButtonToggle
            value={insightMode}
            buttons={[
              { buttonLabel: 'Top', value: 'top' },
              { buttonLabel: 'All', value: 'all' },
            ]}
            onChange={changeInsightMode}
            css={{ marginLeft: '10px' }}
          />
        </span>
        <div css={{ display: 'flex', gap: 20 }}>
          {!!tableData.length &&
            Object.values(trendLegendOption).map(
              ({ label, color, valueDifference }) => {
                return (
                  <div
                    css={{ display: 'flex', alignItems: 'center', gap: 10 }}
                    key={label}
                  >
                    <SortArrow
                      css={{
                        transform:
                          valueDifference < 0 ? 'rotate(180deg)' : undefined,
                        color,
                        height: '15px',
                        width: 'auto',
                      }}
                    />
                    <div>{label}</div>
                  </div>
                );
              },
            )}
          {!!tableData.length && <SessionLegend />}
        </div>
      </div>
      {!!tableData.length ? (
        <table css={sessionDigestTableCss}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    css={{
                      width: cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td colSpan={3} css={{ ...fonts.largeLabel }}>
                Compliance
              </td>
              <td
                css={{
                  color: dailyCompliance === 100 ? colors.green : colors.red,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                <Tooltip
                  componentsProps={complianceCustomTooltipCss}
                  placement="top"
                  title={
                    <>
                      <span css={{ ...fonts.largeLabel }}>
                        Compliance Gaps:
                      </span>
                      <br />
                      <div css={{ whiteSpace: 'pre-wrap' }}>
                        {dailyComplianceGaps}
                      </div>
                    </>
                  }
                  arrow
                >
                  <div css={{ width: 'fit-content' }}>
                    {dailyCompliance ?? 0}%
                  </div>
                </Tooltip>
              </td>
              <td>
                {dailyCompliance && previousDailyCompliance && (
                  <TrendIcon
                    resultsToCompare={[
                      {
                        value: previousDailyCompliance,
                        improvementDirection: 'up',
                      },
                      { value: dailyCompliance, improvementDirection: 'up' },
                    ]}
                    height={15}
                  />
                )}
              </td>
              <td>{complianceNote && complianceNote}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div css={noInsightsMsgCss}>
          {`None of the results are ${
            insightMode == 'top' ? 'significantly' : ''
          } different from the last session.`}
        </div>
      )}
    </div>
  );
}
type InsightMode = 'top' | 'all';

export default function SessionsDigest({
  allSessionsByDate,
  sessionsForSelectedDate,
  selectedDate,
  actvitivitesExecutionsMap,
  dailyCompliance,
  dailyComplianceGaps,
  lastTreatmentPlanOfDay,
  setDigestData,
}: SessionsDigestProps) {
  const [insightMode, setInsightMode] = useState<InsightMode>('top');
  const measurementSystem = useSelector(getMeasurementSystemSelector);

  // All activity executions for all sessions for the selected day that have results
  const allActivitiesForSelectedDate = useMemo(() => {
    return sessionsForSelectedDate
      ?.map((session) => {
        const filteredActivityExectutions = session.activitiesExecutions.filter(
          (activityExecution) => {
            return activityExecution.results.length > 0;
          },
        );
        return filteredActivityExectutions;
      })
      .flat();
  }, [sessionsForSelectedDate]);

  // Split all daily activities in two
  // first array = all the latest activity executions with goals
  // second array = additional same day executions of the same activity
  const splitActivities = useMemo(() => {
    return allActivitiesForSelectedDate?.reduce(
      (
        accumulator: [ActivityExecution[], ActivityExecution[]],
        currentActivity,
      ) => {
        if (
          accumulator[0].find(
            (value) =>
              currentActivity.activityDefinitionId ===
              value.activityDefinitionId,
          )
        ) {
          accumulator[1].push(currentActivity);
        } else if (
          currentActivity.goals &&
          Object.keys(currentActivity.goals).length > 0
        ) {
          accumulator[0].push(currentActivity);
        }
        return accumulator;
      },
      [[], []],
    );
  }, [allActivitiesForSelectedDate]);

  const lastExecutions = splitActivities?.[0];
  const previousSameDayExecutions = splitActivities?.[1];

  const orderedDatesArray = Object.keys(allSessionsByDate || {});
  const currentDateIndex = orderedDatesArray.indexOf(selectedDate);

  // Recursively go through previous days to find previous execution with results
  const getPreviousDayExecution = useCallback(
    (dateIndex: number, activity: ActivityExecution) => {
      const previousDate = orderedDatesArray[dateIndex];

      if (!previousDate) {
        return;
      }

      let previousDayActivityExecution;

      const previousDaySessions = allSessionsByDate?.[previousDate];

      if (previousDaySessions) {
        for (let session of previousDaySessions) {
          const executionInPreviousSession = session.activitiesExecutions.find(
            (previousActivityExecution) => {
              return (
                activity.activityDefinitionId ===
                  previousActivityExecution.activityDefinitionId &&
                Boolean(previousActivityExecution.results.length) &&
                activity.activity?.side ===
                  previousActivityExecution.activity?.side &&
                previousActivityExecution.status === 'Completed'
              );
            },
          );
          if (executionInPreviousSession) {
            previousDayActivityExecution = executionInPreviousSession;
            break;
          }
        }

        if (previousDayActivityExecution) {
          return previousDayActivityExecution;
        } else {
          getPreviousDayExecution(dateIndex + 1, activity);
        }
      }
    },
    [orderedDatesArray, allSessionsByDate],
  );

  // For every last execution attempt to find a previous one with results from the same day
  // if no such execution exists go through previous days
  const lastAndPreviousActvityExecutions = useMemo(() => {
    return lastExecutions?.map((activity) => {
      const previousSameDayExecution = previousSameDayExecutions?.find(
        (currentExecution) =>
          currentExecution.activityDefinitionId ===
            activity.activityDefinitionId &&
          Boolean(currentExecution.results.length) &&
          activity.activity?.side === currentExecution.activity?.side &&
          currentExecution.status === 'Completed',
      );
      if (previousSameDayExecution) {
        return [activity, previousSameDayExecution];
      } else {
        return [
          activity,
          getPreviousDayExecution(currentDateIndex + 1, activity),
        ];
      }
    });
  }, [previousSameDayExecutions, lastExecutions]);

  // Compare activity metrics with goals to previous execution
  // and return new objects for each pair
  // that has a value difference greater or equal to the minimum clinical significance
  const transformedAndComparedMetrics = useMemo(() => {
    return lastAndPreviousActvityExecutions
      ?.map(([lastExecution, previousExecution]) => {
        if (
          (insightMode === 'top' && (!previousExecution || !lastExecution)) ||
          (insightMode === 'all' && !lastExecution)
        ) {
          return;
        }

        const metricResults = lastExecution?.results;
        const changedMetricResults = metricResults?.map((result) => {
          const metricMinClinicalSignificance =
            lastExecution?.activityDefinition?.postExerciseMetric?.find(
              (postMetric) => {
                return postMetric.metric_name === result.key;
              },
            )?.metric_mcs;

          const previousMetricResult = previousExecution?.results.find(
            (previousMetric) => {
              return (
                previousMetric.key === result.key &&
                previousMetric.side === result.side
              );
            },
          );

          if (
            (insightMode === 'top' && !previousMetricResult) ||
            !metricMinClinicalSignificance
          ) {
            return;
          }
          const metricValueChange = previousMetricResult
            ? result.value - previousMetricResult.value
            : result.value;

          if (
            insightMode === 'all' ||
            metricMinClinicalSignificance <= Math.abs(metricValueChange)
          ) {
            const trendLabel = getTrendLabel(result, metricValueChange);
            const measurementSystemUnit = getDisplayUnit(
              getMeasurementSystemUnit(result.unit, measurementSystem),
            );

            const measurementSystemValue = getMeasurementSystemValue(
              Math.abs(metricValueChange),
              measurementSystemUnit,
              measurementSystem,
            )?.toFixed(0);

            // eslint-disable-next-line max-len
            const noteText =
              previousExecution && result.improvementDirection !== 'goal'
                ? ` by ${measurementSystemValue}${measurementSystemUnit} ` +
                  `compared to visit from ${format(
                    previousExecution.startDate,
                    'MMMM do',
                  )}`
                : '';

            const valueDifferenceNote = (
              <span>
                {trendLabel.trendAsElement}
                {noteText}
              </span>
            );
            return {
              category: lastExecution.activityDefinition?.category?.[0] ?? '',
              activityDefinitionId: lastExecution.activityDefinitionId,
              activityName: lastExecution.activityDefinition?.name,
              results: [result, previousMetricResult],
              notes: [valueDifferenceNote],
              notesAsText: [`${trendLabel.trendAsText} ${noteText}`],
            };
          }
          return;
        });
        return changedMetricResults?.filter((metricResult) =>
          Boolean(metricResult),
        );
      })
      .filter((metricResultPerActivity) => {
        return (
          Boolean(metricResultPerActivity) &&
          Boolean(metricResultPerActivity?.length)
        );
      })
      .flat();
  }, [lastAndPreviousActvityExecutions, insightMode]);

  const previousDailyCompliance = useMemo(() => {
    const previousDate = orderedDatesArray[currentDateIndex + 1];
    if (!previousDate) {
      return;
    }
    const previousDaySessions = allSessionsByDate?.[previousDate];
    return getDailyCompliance(previousDaySessions, lastTreatmentPlanOfDay)
      ?.value;
  }, [currentDateIndex, allSessionsByDate]);

  useEffect(() => {
    if (transformedAndComparedMetrics?.length) {
      setDigestData(transformedAndComparedMetrics as TableMetric[]);
    }
  }, [transformedAndComparedMetrics]);

  const changeInsightMode = (value: InsightMode) =>
    value ? setInsightMode(value) : null;

  return (
    <SessionDigestTable
      // Assertion used due to TS not detecting previously done undefined filtering
      tableData={transformedAndComparedMetrics as TableMetric[]}
      actvitivitesExecutionsMap={actvitivitesExecutionsMap}
      dailyCompliance={dailyCompliance}
      dailyComplianceGaps={dailyComplianceGaps}
      previousDailyCompliance={previousDailyCompliance}
      previousDate={orderedDatesArray[currentDateIndex + 1]}
      measurementSystem={measurementSystem}
      insightMode={insightMode}
      changeInsightMode={changeInsightMode}
    />
  );
}
