/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AvatarMui from '@mui/material/Avatar';
import LogoutIcon from '@mui/icons-material/Logout';

import Avatar from '../commons/Avatar';
import { colors } from '../../style/colors';
import { useAppDispatch } from '../../state-manager/store';
import { logoutAction } from '../../state-manager/thunks/loginThunks';
import { getUserSelector } from '../../models/factories/userFactories';

export default function UserMenu() {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div
      css={{
        height: '100%',
        display: 'flex',
        '.MuiAvatar-root': {
          backgroundColor: colors.white,
          color: colors.blue1,
        },
      }}
    >
      <button onClick={openMenu}>
        {user ? (
          <Avatar user={user} />
        ) : (
          <AvatarMui>
            <LogoutIcon />
          </AvatarMui>
        )}
      </button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            dispatch(logoutAction());
            closeMenu();
          }}
        >
          logout
        </MenuItem>
      </Menu>
    </div>
  );
}
