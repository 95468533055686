import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import appSlice from './slices/appSlice';
import usersSlice from './slices/usersSlice';
import treatmentPlansSlice from './slices/treatmentPlansSlice';
import notificationsSlice from './slices/notificationsSlice';
import librarySlice from './slices/librarySlice';
import refreshPatientsMiddleware from './middlewares/refreshPatients';
import refreshPatientTreatmentPlansMiddleware from './middlewares/refreshPatientTreatmentPlans';
import casesSlice from './slices/casesSlice';

const store = configureStore({
  reducer: {
    appState: appSlice.reducer,
    usersState: usersSlice.reducer,
    treatmentPlansState: treatmentPlansSlice.reducer,
    notificationsState: notificationsSlice.reducer,
    libraryState: librarySlice.reducer,
    casesState: casesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      refreshPatientsMiddleware(),
      refreshPatientTreatmentPlansMiddleware(),
    ]),
});

export default store;

type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
