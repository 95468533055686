/** @jsxImportSource @emotion/react */

import { fonts } from '../../../style/fonts';
import { colors } from '../../../style/colors';
import { CSSObject } from '@emotion/react';
import { shapes } from '../../../style/shapes';
import { buttons } from '../../../style/buttons';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../models/factories/userFactories';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PushToTalkButton from '../../commons/PushToTalkButton';
import useMediaRecorder from '../../../hooks/useMediaRecorder';
import {
  createTranscriptSession,
  getTranscriptOutput,
  runTranscriptProcess,
} from '../../../services/notesService';
import { getCurrentPatientIdSelector } from '../../../state-manager/selectors/appSelectors';
import { usePulling } from '../../commons/hooks/usePulling';
import { uploadBlob } from '../../../services/fileService';
import DotsLoader from '../../commons/DotsLoader';

export const PULLING_INTERVAL_MS = 5000;
export const NOTES_HEADER_HEIGHT = 71;
export const NOTES_DATES_WIDTH = 140;

const TITLE_MARGIN_RIGHT = 30;
const DATE_MARGIN_RIGHT = 21;
const CANCEL_BUTTON_WIDTH = '70px';

const notesContainerCss: CSSObject = {
  display: 'flex',
  width: '100%',
  border: shapes.border,
  borderRadius: shapes.borderRadius,
  backgroundColor: colors.white,
  margin: '0 30px 20px 30px',

  '.notes-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: NOTES_HEADER_HEIGHT,
    width: '100%',
    padding: '0 20px',
    borderBottom: shapes.border,
    ...fonts.modalHeader,
  },

  '.notes-body': {
    padding: 20,
    height: `calc(100% - ${NOTES_HEADER_HEIGHT}px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  '.notes-dates': {
    height: '100%',
    width: NOTES_DATES_WIDTH,
    borderRight: shapes.border,
  },

  '.notes': {
    height: '100%',
    width: '100%',
    // width: `calc(100% - ${NOTES_DATES_WIDTH}px)`,
  },

  '.note': {
    border: shapes.border,
    borderColor: colors.blue3,
    borderRadius: shapes.borderRadius,
    padding: '10px 20px 20px 20px',
    overflow: 'hidden',
  },

  '.note-header': {
    '& > span:not(:last-of-type)': {
      ...fonts.h2,
    },
    '& > span:last-of-type': {
      ...fonts.text,
    },
  },

  '.note-body': {
    //Temporary placeholder height
    height: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '.note-footer': {
    display: 'flex',
    alignItems: 'center',
  },

  '.loading-dots': {
    position: 'relative',
    left: `calc(50% - ${CANCEL_BUTTON_WIDTH})`,
    transform: 'translateX(-50%)',
  },
};

function NotesHeader({
  isCreatingNote,
  setIsCreatingNote,
}: {
  isCreatingNote: boolean;
  setIsCreatingNote: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="notes-header">
      <span>Notes</span>
      <button
        type="button"
        onClick={() => setIsCreatingNote(true)}
        css={{ ...buttons.primary }}
        disabled={isCreatingNote}
      >
        New Note
      </button>
    </div>
  );
}

function usePullingTranscriptionOutput({
  patientId,
  sessionId,
  isPullingStarted,
}: {
  patientId: string | null | undefined;
  sessionId: string | undefined;
  isPullingStarted: boolean;
}) {
  const [transcription, setTranscription] = useState<string | undefined>();
  const stopPulling = useRef<(() => void) | undefined>();

  const pullingRoutine = useCallback(async () => {
    if (!sessionId || !patientId || !isPullingStarted) {
      return Promise.resolve();
    }

    await getTranscriptOutput({ patientId, sessionId }).then((response) => {
      const status = response.transcript_session_status.transcript_status;
      if (status === 'Completed') {
        stopPulling.current?.();
        if (response.transcript_session_output) {
          //TODO: wait for fix from BE
          const transcript = JSON.parse(response.transcript_session_output)
            .results.transcripts[0]?.transcript;
          if (transcript !== undefined) {
            setTranscription(transcript);
          }
        }
      }
    });
  }, [patientId, sessionId, isPullingStarted]);

  stopPulling.current = usePulling({
    routine: pullingRoutine,
    pullingTimeMs: PULLING_INTERVAL_MS,
  });

  useEffect(() => {
    return () => {
      stopPulling.current?.();
    };
  }, []);

  return transcription;
}

enum FlowStatus {
  NotStarted,
  Recording,
  Processing,
  Finished,
}

function useTranscriptionFlow() {
  const patientId = useSelector(getCurrentPatientIdSelector);
  const { startRecording, stopRecording, audioWavBlob } = useMediaRecorder();
  const [uploadingUrl, setUploadingUrl] = useState<string | undefined>();
  const [isPullingStarted, startPulling] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string | undefined>();
  const [isBlobUploaded, blobUploaded] = useState<boolean>(false);
  const [status, setStatus] = useState<FlowStatus>(FlowStatus.NotStarted);

  const transcription = usePullingTranscriptionOutput({
    patientId,
    sessionId,
    isPullingStarted,
  });

  useEffect(() => {
    if (transcription !== undefined) {
      setStatus(FlowStatus.Finished);
    }
  }, [transcription]);

  useEffect(() => {
    if (!audioWavBlob || !uploadingUrl) {
      return;
    }
    uploadBlob({ blob: audioWavBlob, url: uploadingUrl }).then(() => {
      blobUploaded(true);
    });
  }, [uploadingUrl, audioWavBlob]);

  useEffect(() => {
    if (isBlobUploaded && sessionId && patientId) {
      runTranscriptProcess({ patientId, sessionId }).then(() => {
        startPulling(true);
      });
    }
  }, [isBlobUploaded, sessionId, patientId]);

  const startTranscriptSession = async () => {
    if (!patientId) {
      throw new Error('patientId is expected in this stage');
    }
    const transcriptSessionDto = await createTranscriptSession(patientId);
    setSessionId(transcriptSessionDto.transcript_session_status.session_id);
    setUploadingUrl(transcriptSessionDto.transcript_media_upload.mediaLink);
  };

  const onStopRecording = () => {
    stopRecording();
    setStatus(FlowStatus.Processing);
    startTranscriptSession();
  };

  const onStartRecording = () => {
    startRecording();
    setStatus(FlowStatus.Recording);
  };

  return {
    transcription,
    startRecording: onStartRecording,
    stopRecording: onStopRecording,
    status,
  };
}

function NewNote({ onClose }: { onClose: () => void }) {
  const user = useSelector(getUserSelector);
  const formattedCurrentDate = format(new Date(), 'MMMM d, yyyy');

  const [isRecordDisabled, disabledRecord] = useState<boolean>(false);

  const { transcription, startRecording, stopRecording, status } =
    useTranscriptionFlow();
  const shouldDisplayLoader = status === FlowStatus.Processing;

  return (
    <div className="note">
      <div className="note-header">
        <span css={{ marginRight: TITLE_MARGIN_RIGHT }}>Notes</span>
        <span css={{ marginRight: DATE_MARGIN_RIGHT }}>
          {formattedCurrentDate}
        </span>
        <span>{user?.name ?? ''}</span>
      </div>
      <div className="note-body">
        <div>{transcription}</div>
        <PushToTalkButton
          onMouseDown={startRecording}
          onMouseUp={async () => {
            disabledRecord(true);
            stopRecording();
          }}
          disabled={isRecordDisabled}
        />
      </div>
      <div className="note-footer">
        <button type="button" onClick={onClose} css={{ ...buttons.secondary }}>
          Cancel
        </button>
        {shouldDisplayLoader && <DotsLoader />}
      </div>
    </div>
  );
}

function NotesBody({
  isCreatingNote,
  setIsCreatingNote,
}: {
  isCreatingNote: boolean;
  setIsCreatingNote: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="notes-body">
      {isCreatingNote && (
        <NewNote
          onClose={() => {
            setIsCreatingNote(false);
          }}
        />
      )}
    </div>
  );
}

export default function Notes() {
  const [isCreatingNote, setIsCreatingNote] = useState<boolean>(false);

  return (
    <div className="notes-container" css={notesContainerCss}>
      {/* <div className="notes-dates">
        <div className="notes-header">Date</div>
      </div> */}
      <div className="notes">
        <NotesHeader
          isCreatingNote={isCreatingNote}
          setIsCreatingNote={setIsCreatingNote}
        />
        <NotesBody
          isCreatingNote={isCreatingNote}
          setIsCreatingNote={setIsCreatingNote}
        />
      </div>
    </div>
  );
}
