import { createSelector } from '@reduxjs/toolkit';
import { getMovementsResultsSelector } from '../state-manager/selectors/treatmentPlansSelectors';
import { MovementResultsDto } from '../types/backendType';
import { getCurrentPatientIdSelector } from '../state-manager/selectors/appSelectors';
import { ActivityDefinition, ActivityDefinitionId } from '../types/library';
import { getActivityDefinitionsSelector } from '../state-manager/selectors/librarySelectors';
import { TreatmentPlan } from './TreatmentPlan';
import { PostMetric } from './PostMetric';
import { getPatientTreatmentPlansSelector } from './factories/treatmentPlanFactories';
import { isEmpty } from '../state-manager/utils/compare';
import { normalizeMetricKey } from '../components/patient-details/sessions/SessionsUntils';

export type Result = {
  key: string;
  name: string;
  unit: string;
  value: number;
  goal: number | undefined;
  metGoal: boolean | undefined;
  improvementDirection: string | undefined;
  side: string | undefined;
  math: string | undefined;
  standardDeviation: number | undefined;
  automationLevel: number | undefined;
};

export class ActivityExecution {
  private movementResultsDto: MovementResultsDto;

  activityDefinition: ActivityDefinition | undefined;

  treatmentPlan: TreatmentPlan;

  id: string;

  activityDefinitionId: string;

  treatmentPlanId: string;

  sessionId: string;

  subSessionId: string;

  status: string;

  statusDescription: string;

  stopDate: Date;

  startDate: Date;

  activityIndexInPlan: number;

  constructor({
    movementResultsDto,
    activityDefinition,
    treatmentPlan,
  }: {
    movementResultsDto: MovementResultsDto;
    activityDefinition?: ActivityDefinition;
    treatmentPlan: TreatmentPlan;
  }) {
    this.movementResultsDto = movementResultsDto;
    this.activityDefinition = activityDefinition;
    this.treatmentPlan = treatmentPlan;
    this.activityDefinitionId = this.movementResultsDto.movement_id;
    this.treatmentPlanId = this.movementResultsDto.treatment_plan_id;
    this.sessionId = this.movementResultsDto.session_id;
    this.subSessionId = this.movementResultsDto.sub_session_id;
    this.status = this.movementResultsDto.status;
    this.statusDescription = this.movementResultsDto.status_description;
    this.stopDate = new Date(this.movementResultsDto.stop_date);
    this.startDate = new Date(this.movementResultsDto.start_date);
    this.id = `${this.sessionId}-${this.subSessionId}`;
    this.activityIndexInPlan = this.movementResultsDto.plan_index - 1;
  }

  get activity() {
    return this.treatmentPlan?.activities[this.activityIndexInPlan];
  }

  get goals() {
    return this.activity?.goals;
  }

  get name() {
    return this.activityDefinition?.name;
  }

  getGoal(metricKey: string) {
    return this.goals?.[metricKey];
  }

  getMetric(key: string) {
    const normalizedKey = normalizeMetricKey(key);
    if (this.activityDefinition) {
      try {
        return new PostMetric({
          activityDefinition: this.activityDefinition,
          key: normalizedKey,
        });
      } catch (e) {
        return undefined;
      }
    }
  }

  get type() {
    return this.activity?.type?.[0];
  }

  get sets() {
    return this.movementResultsDto?.results_data?.summary?.num_of_sets;
  }

  get reps() {
    return this.movementResultsDto?.results_data?.sets_info?.map(({ reps }) => reps);
  }

  get hold() {
    return this.movementResultsDto?.results_data?.sets_info?.map(({ hold }) => hold);
  }

  get compliance() {
    return this.movementResultsDto.movement_completion_rate;
  }

  get automationLevel() {
    let automationLevel = this.movementResultsDto.results_data?.summary?.phase_eng[0];
    if (isEmpty(automationLevel)) {
      automationLevel = this.activityDefinition?.automationLevel;
    }
    return automationLevel;
  }

  get results() {
    const mapper = ({
      key,
      value,
      unit,
      side,
      standardDeviation,
    }: {
      key: string;
      value: number;
      unit: string;
      side: string | undefined;
      standardDeviation: number | undefined | 'NA';
    }) => {
      const goal = this.goals?.[key];
      const metric = this.getMetric(key);
      let metGoal: undefined | boolean = undefined;
      if (goal !== undefined) {
        if (metric?.improvementDirection === 'up') {
          metGoal = goal <= value;
        } else {
          metGoal = goal > value;
        }
      }
      const improvementDirection = metric?.improvementDirection;
      const math = metric?.math;
      const libraryStandardDeviation = metric?.libraryStandardDeviation;
      const automationLevel = metric?.automationLevel;

      const getStandardDeviation = () => {
        if (standardDeviation && standardDeviation !== 'NA') {
          return standardDeviation;
        } else if (libraryStandardDeviation && libraryStandardDeviation !== 'NA') {
          return libraryStandardDeviation;
        } else {
          return undefined;
        }
      };

      return {
        key,
        name: metric?.name ?? key,
        unit,
        value,
        goal,
        metGoal,
        improvementDirection,
        side: side == 'NA' ? undefined : side,
        math: math === 'NA' ? undefined : math,
        standardDeviation: getStandardDeviation(),
        automationLevel: automationLevel,
      };
    };

    const newResults: Result[] = [];
    this.movementResultsDto.results_data?.summary?.post_metrics_array?.forEach((result) => {
      const {
        metric_data: { side, unit, value, metric_sd: standardDeviation },
        metric_name: key,
      } = result;
      if (!isEmpty(value)) {
        newResults.push(mapper({ key, value, unit, side, standardDeviation }));
      }
    });

    if (newResults) {
      return newResults.filter((result) => {
        return result.automationLevel !== 0;
      });
    }

    return [];
  }
}

export const getPatientActivitiesExecutionsSelector = createSelector(
  [
    getMovementsResultsSelector,
    getCurrentPatientIdSelector,
    getActivityDefinitionsSelector,
    getPatientTreatmentPlansSelector,
  ],
  (
    movementsResults,
    patientId,
    activityDefinitions,
    patientTreatmentPlans,
  ): ActivityExecution[] | undefined => {
    if (!patientId) {
      return;
    }
    const activityExecutions: ActivityExecution[] = [];
    movementsResults[patientId]
      ?.filter((movementResultsDto) => {
        return movementResultsDto.movement_details?.movement_info?.supported !== 'false';
      })
      .forEach((movementResultsDto) => {
        const treatmentPlan = patientTreatmentPlans?.find(({ id }) => {
          return id === movementResultsDto.treatment_plan_id;
        });
        if (!treatmentPlan) {
          return;
        }
        const activityDefinition = activityDefinitions?.[movementResultsDto.movement_id];
        activityExecutions.push(
          new ActivityExecution({
            movementResultsDto,
            activityDefinition,
            treatmentPlan,
          }),
        );
      });
    return activityExecutions;
  },
);

export function groupActivitiesExecutionsByActivityDefinition(
  activitiesExecutions: ActivityExecution[] | undefined,
): Record<ActivityDefinitionId, ActivityExecution[]> | undefined {
  const activitiesExecutionsMap: Record<ActivityDefinitionId, ActivityExecution[]> = {};
  if (!activitiesExecutions) {
    return;
  }
  activitiesExecutions?.forEach((activityExecution) => {
    if (!activitiesExecutionsMap[activityExecution.activityDefinitionId]) {
      activitiesExecutionsMap[activityExecution.activityDefinitionId] = [];
    }
    activitiesExecutionsMap[activityExecution.activityDefinitionId]?.push(activityExecution);
  });
  return activitiesExecutionsMap;
}
