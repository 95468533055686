/** @jsxImportSource @emotion/react */

import { fonts } from '../../../style/fonts';
import {
  imperialToMetricconversionFormulaMap,
  isImperialUnit,
} from '../../../utils/unitUtils';

export default function DisplayField({
  label,
  value,
  children,
  unit,
  valuePrefix,
}: {
  label: string;
  value: string | number | undefined;
  children?: React.ReactNode;
  unit?: string;
  valuePrefix?: string;
}) {
  let newValue = value;
  if (isImperialUnit(unit)) {
    const imperialToMetricConverter =
      imperialToMetricconversionFormulaMap[unit];
    newValue = imperialToMetricConverter(Number(value)) ?? value;
  }
  return (
    <div css={{ display: 'flex' }}>
      <div className="field-grouping">
        <label css={{ alignSelf: 'flex-start' }}>{label}</label>
        <div css={{ ...fonts.text }}>
          {valuePrefix ?? ''}
          {newValue ?? ''}
        </div>
      </div>
      {children && <div>{children}</div>}
    </div>
  );
}
