/** @jsxImportSource @emotion/react */

import { colors } from '../../../style/colors';
import { fonts } from '../../../style/fonts';

export const NO_GOAL = 'no-goal';
export const MET_GOAL = 'met-goal';
export const NOT_MEET_GOAL = 'not-meet-goal';

export const legendOptions = {
  [MET_GOAL]: { label: 'Met Goal', color: '#22AD00' },
  [NOT_MEET_GOAL]: { label: 'Did Not Meet Goal', color: colors.red },
  [NO_GOAL]: { label: 'No Goal', color: '#000000' },
};

export function getGoalClassName({
  value,
  goal,
  metGoal,
  automationLevel,
}: {
  value?: number | undefined;
  goal?: number | undefined;
  metGoal?: boolean | undefined;
  automationLevel?: number | undefined;
}) {
  if (automationLevel === 0) {
    return NO_GOAL;
  }
  if (metGoal) {
    return MET_GOAL;
  } else if (metGoal === false) {
    return NOT_MEET_GOAL;
  }
  if (value === undefined || goal === undefined) {
    return NO_GOAL;
  }
  if (value === goal) {
    return MET_GOAL;
  }
  return NOT_MEET_GOAL;
}

export default function SessionLegend() {
  return (
    <div css={{ display: 'flex', gap: 20, ...fonts.largeLabel }}>
      {Object.values(legendOptions).map(({ label, color }) => {
        return (
          <div
            css={{ display: 'flex', alignItems: 'center', gap: 10 }}
            key={label}
          >
            <div css={{ width: 16, height: 16, backgroundColor: color }} />
            <div>{label}</div>
          </div>
        );
      })}
    </div>
  );
}
