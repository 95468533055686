import { Dispatch, AppThunk, RequestStatusName, RootState } from '../storeTypes';
import {
  assignTreatmentPlan,
  createTreatmentPlanTemplate,
  deleteTreatmentPlan,
  deleteTreatmentPlanTemplate,
  editTreatmentPlanTemplate,
  fetchMovementsResults,
  fetchTreatmentPlanTemplates,
  fetchUserTreatmentPlans,
} from '../../services/treatmentPlanService';
import {
  toTreatmentPlanInputDto,
  toTreatmentPlanTemplateInputDto,
} from '../../types/mappers/treatmentPlanMapper';
import {
  setMovementsResultsAction,
  setTreatmentPlansAction,
} from '../slices/treatmentPlansSlice';
import {
  getCurrentPatientIdSelector,
  getRequestStatusSelector,
  getSelectedCaseIdSelector,
  getUserIdSelector,
} from '../selectors/appSelectors';
import { skipRequest } from '../utils/requestStatus';
import { cleanRequestStatusAction, markStatusFunctionCreator } from '../slices/appSlice';
import {
  MovementResultsOutputDto,
  TreatmentPlanDto,
  TreatmentPlanInputDto,
  TreatmentPlanTemplateDto,
  TreatmentPlanTemplateInputDto,
} from '../../types/backendType';
import { TreatmentPlanInput } from '../../components/patient-details/treatment-plans/creation/treatmentPlanTypes';
import { notify } from '../slices/notificationsSlice';
import {
  addTreatmentPlanTemplateAction,
  deleteTreatmentPlanTemplateFromStoreAction,
  setTreatmentPlanTemplatesAction,
} from '../slices/librarySlice';
import { getActivityDefinitionsSelector } from '../selectors/librarySelectors';
import { END_DATE_FOR_REQUESTS, START_DATE_FOR_REQUESTS } from '../../config';
import { AskUser } from '../../components/commons/AskUser';
import { TreatmentPlan } from '../../models/TreatmentPlan';
import { UserId } from '../../models/User';
import { TreatmentPlanTemplate } from '../../models/TreatmentPlanTemplate';
import { getPatientTreatmentPlansSelector } from '../../models/factories/treatmentPlanFactories';
import { getPatientActivitiesExecutionsSelector } from '../../models/ActivityExecution';

export function fetchAssignTreatmentPlansAction({
  patientId,
}: {
  patientId: string;
}): AppThunk {
  return async (dispatch) => {
    const markStatus = markStatusFunctionCreator(
      dispatch,
      patientId,
      'fetchAssignTreatmentPlans',
    );
    markStatus('inProcess');
    let treatmentPlansDto: TreatmentPlanDto[] | undefined;
    try {
      treatmentPlansDto = await fetchUserTreatmentPlans({
        userId: patientId,
        startDate: START_DATE_FOR_REQUESTS,
        endDate: END_DATE_FOR_REQUESTS,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      return;
    }
    markStatus('succeeded');
    if (!treatmentPlansDto) {
      return;
    }
    dispatch(setTreatmentPlansAction({ treatmentPlansDto, patientId }));
  };
}

async function fetchMovementsResultsRoutine({
  dispatch,
  getState,
  patientId,
  startDate,
}: {
  dispatch: Dispatch;
  getState: () => RootState;
  patientId: string;
  startDate?: string;
}) {
  const markStatus = markStatusFunctionCreator(
    dispatch,
    patientId,
    'fetchActivitiesResultsByUserId',
  );
  const requestStatus = getRequestStatusSelector('fetchActivitiesResultsByUserId')(getState());
  if (skipRequest({ requestStatus, id: patientId })) {
    return;
  }
  markStatus('inProcess');
  let movementResultsOutputDto: MovementResultsOutputDto | undefined;
  try {
    movementResultsOutputDto = await fetchMovementsResults({
      userId: patientId,
      startDate: startDate ? new Date(startDate) : START_DATE_FOR_REQUESTS,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    markStatus('failed');
    throw e;
  }
  markStatus('succeeded');
  if (!movementResultsOutputDto) {
    return;
  }
  dispatch(
    setMovementsResultsAction({
      patientId,
      movementsResults: movementResultsOutputDto.movements,
    }),
  );
  return movementResultsOutputDto;
}

export function fulfillAllActivitiesResultsAction(
  patientId: UserId,
  startDate?: string,
): AppThunk {
  return async (dispatch, getState) => {
    try {
      fetchMovementsResultsRoutine({ dispatch, getState, patientId, startDate });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

async function createTreatmentPlanTemplateRoutine({
  dispatch,
  treatmentPlanForm,
  getState,
  edit,
}: {
  dispatch: Dispatch;
  treatmentPlanForm: TreatmentPlanInput;
  getState: () => RootState;
  edit?: boolean;
}) {
  const id = treatmentPlanForm.id;
  const requestStatusName: RequestStatusName = edit
    ? 'editTreatmentPlanTemplate'
    : 'createTreatmentPlanTemplate';
  const requestStatus = getRequestStatusSelector(requestStatusName)(getState());
  if (edit) {
    cleanRequestStatusAction({ requestStatusName, id });
  }
  if (!edit && skipRequest({ requestStatus, id })) {
    return;
  }
  const markStatus = markStatusFunctionCreator(dispatch, id, requestStatusName);
  markStatus('inProcess');
  let treatmentPlanTemplateDto: TreatmentPlanTemplateDto | undefined = undefined;
  try {
    const activityDefinitions = getActivityDefinitionsSelector(getState());
    const treatmentPlanTemplateInputDto: TreatmentPlanTemplateInputDto =
      toTreatmentPlanTemplateInputDto(treatmentPlanForm, activityDefinitions);
    if (edit) {
      treatmentPlanTemplateDto = await editTreatmentPlanTemplate(
        treatmentPlanForm.id,
        treatmentPlanTemplateInputDto,
      );
    } else {
      treatmentPlanTemplateDto = await createTreatmentPlanTemplate({
        treatmentPlanTemplateInputDto,
      });
    }

    if (!treatmentPlanTemplateDto) {
      throw new Error();
    }
    const treatmentPlanTemplatesDto = await fetchTreatmentPlanTemplates();
    if (treatmentPlanTemplatesDto) {
      dispatch(setTreatmentPlanTemplatesAction({ treatmentPlanTemplatesDto }));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    markStatus('failed');
    throw e;
  }
  markStatus('succeeded');
}

export function createTreatmentPlanTemplateAction(
  treatmentPlanForm: TreatmentPlanInput,
  edit?: boolean,
): AppThunk {
  return async (dispatch, getState) => {
    try {
      await createTreatmentPlanTemplateRoutine({
        dispatch,
        getState,
        treatmentPlanForm,
        edit,
      });
    } catch (e) {
      dispatch(
        notify({
          message: 'failed to create treatment plan template',
          severity: 'error',
        }),
      );
      return;
    }
    dispatch(
      notify({
        message: 'the treatment plan template was created successfully',
        severity: 'success',
      }),
    );
  };
}

export function fetchTreatmentPlanTemplatesAction(): AppThunk {
  return async (dispatch) => {
    const markStatus = markStatusFunctionCreator(
      dispatch,
      'fetchTreatmentPlanTemplates',
      'fetchTreatmentPlanTemplates',
    );
    markStatus('inProcess');
    let treatmentPlanTemplatesDto: TreatmentPlanTemplateDto[] | undefined;
    try {
      treatmentPlanTemplatesDto = await fetchTreatmentPlanTemplates();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      return;
    }
    markStatus('succeeded');
    if (!treatmentPlanTemplatesDto) {
      return;
    }
    dispatch(setTreatmentPlanTemplatesAction({ treatmentPlanTemplatesDto }));
  };
}

async function deleteTreatmentPlanRoutine({
  dispatch,
  treatmentPlanId,
  getState,
  patientId,
}: {
  dispatch: Dispatch;
  treatmentPlanId: string;
  getState: () => RootState;
  patientId: string;
}) {
  const requestStatusId = treatmentPlanId;
  const requestStatusName: RequestStatusName = 'deleteTreatmentPlan';
  const requestStatus = getRequestStatusSelector(requestStatusName)(getState());
  if (skipRequest({ requestStatus, id: requestStatusId })) {
    return;
  }
  const markStatus = markStatusFunctionCreator(dispatch, requestStatusId, requestStatusName);

  markStatus('inProcess');

  const movementResultsOutputDto = await fetchMovementsResultsRoutine({
    dispatch,
    getState,
    patientId,
  });
  const isTreatmentPlanHasResults = movementResultsOutputDto?.movements.some(
    ({ treatment_plan_id }) => treatment_plan_id === treatmentPlanId,
  );
  if (isTreatmentPlanHasResults) {
    dispatch(
      notify({
        message:
          'The treatment plan that has already been executed, and cannot be deleted or modified',
        severity: 'error',
      }),
    );
    return;
  }

  try {
    await deleteTreatmentPlan(treatmentPlanId);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    markStatus('failed');
    throw e;
  }
  markStatus('succeeded');
}

export function deleteTreatmentPlanAction({
  treatmentPlan,
  patientId,
}: {
  treatmentPlan: TreatmentPlan;
  patientId: string;
}): AppThunk {
  return async (dispatch, getState) => {
    const userAnswer = await AskUser.getInstance().askUser({
      msg: `Are you sure you want to delete \"${treatmentPlan.name}\"?`,
      answers: ['Cancel', 'Delete'],
    });
    if (userAnswer === 'Cancel') {
      return;
    }
    await deleteTreatmentPlanRoutine({
      dispatch,
      getState,
      treatmentPlanId: treatmentPlan.id,
      patientId,
    });
    dispatch(fetchAssignTreatmentPlansAction({ patientId }));
  };
}

export function deleteTreatmentPlanTemplateAction({
  treatmentPlanTemplate,
}: {
  treatmentPlanTemplate: TreatmentPlanTemplate;
}): AppThunk {
  return async (dispatch, getState) => {
    const userAnswer = await AskUser.getInstance().askUser({
      msg: `Are you sure you want to delete \"${treatmentPlanTemplate.name}\"?`,
      answers: ['Cancel', 'Delete'],
    });
    if (userAnswer === 'Cancel') {
      return;
    }
    const requestStatusId = treatmentPlanTemplate.id;
    const requestStatusName: RequestStatusName = 'deleteTreatmentPlanTemplate';
    const requestStatus = getRequestStatusSelector(requestStatusName)(getState());
    if (skipRequest({ requestStatus, id: requestStatusId })) {
      return;
    }
    const markStatus = markStatusFunctionCreator(dispatch, requestStatusId, requestStatusName);
    markStatus('inProcess');
    try {
      dispatch(deleteTreatmentPlanTemplateFromStoreAction({ id: treatmentPlanTemplate.id }));
      await deleteTreatmentPlanTemplate(treatmentPlanTemplate.id);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      dispatch(
        notify({
          message: `failed to delete template: ${treatmentPlanTemplate.name}`,
          severity: 'error',
        }),
      );
      dispatch(
        addTreatmentPlanTemplateAction({
          treatmentPlanTemplateDto: treatmentPlanTemplate.treatmentPlanTemplateDto,
        }),
      );
      return;
    }
    markStatus('succeeded');
  };
}

function doesTreatmentPlanHaveLocalResults(planId: string, getState: () => RootState) {
  const activitiesExecutions = getPatientActivitiesExecutionsSelector(getState());
  const hasLocalResults = activitiesExecutions?.some(
    ({ treatmentPlanId }) => treatmentPlanId === planId,
  );
  return hasLocalResults;
}

function getPlanIdToCleanBeforeAssign(getState: () => RootState) {
  const treatmentPlans = getPatientTreatmentPlansSelector(getState());
  const lastPlan = treatmentPlans?.[0];
  if (lastPlan) {
    const hasLocalResults = doesTreatmentPlanHaveLocalResults(lastPlan.id, getState);
    if (!hasLocalResults) {
      return lastPlan.id;
    }
  }
}

export function saveAndAssignTreatmentPlanAction(
  treatmentPlanForm: TreatmentPlanInput,
): AppThunk {
  return async (dispatch, getState) => {
    const patientId = getCurrentPatientIdSelector(getState());
    if (!patientId) {
      throw new Error('patientId is missing');
    }
    const caseId = getSelectedCaseIdSelector(getState());
    if (!caseId) {
      throw new Error('caseId is missing');
    }
    const requestStatusId = treatmentPlanForm.id;
    const requestStatusName: RequestStatusName = 'saveAndAssignTreatmentPlan'; //////
    cleanRequestStatusAction({ requestStatusName, id: requestStatusId });
    const markStatus = markStatusFunctionCreator(dispatch, requestStatusId, requestStatusName);
    markStatus('inProcess');
    try {
      const planIdToDelete: string | undefined = getPlanIdToCleanBeforeAssign(getState);
      if (planIdToDelete) {
        try {
          await deleteTreatmentPlan(planIdToDelete);
        } catch (e) {
          if (
            'details' in e &&
            !e.details.contain('This plan already has execution results')
          ) {
            throw e;
          }
        }
      }
      const activitiesDefinitions = getActivityDefinitionsSelector(getState());
      const userId = getUserIdSelector(getState());
      if (!userId) {
        throw new Error('userId is missing');
      }
      const treatmentPlanInputDto: TreatmentPlanInputDto = toTreatmentPlanInputDto({
        treatmentPlanForm,
        assignorId: userId,
        activitiesDefinitions,
        caseId,
      });
      const treatmentPlanDto = await assignTreatmentPlan({
        patientId,
        treatmentPlanInputDto,
      });
      if (!treatmentPlanDto) {
        throw new Error();
      }
      const treatmentPlansDto = await fetchUserTreatmentPlans({
        userId: patientId,
        startDate: START_DATE_FOR_REQUESTS,
        endDate: END_DATE_FOR_REQUESTS,
      });
      if (treatmentPlansDto) {
        dispatch(setTreatmentPlansAction({ treatmentPlansDto, patientId }));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      return;
    }
    markStatus('succeeded');
  };
}
