import { DONT_CARE, Equipment, OptionsSetting } from '../../../types/backendType';
import { EquipmentInput } from './creation/treatmentPlanTypes';

export function isCameraSide(sideSetting: OptionsSetting) {
  return Boolean(sideSetting.options.includes(DONT_CARE));
}

// Transform all legacy selected values (strings) into objects
export function transformLegacyEquipment(
  legacySelectedEquipment: string[] | undefined,
  optionalEquipment: Equipment[] | undefined,
) {
  if (legacySelectedEquipment) {
    const normalizedLegacySelectedEquipment = legacySelectedEquipment
      .map((legacySelectedDevice) => {
        if (legacySelectedDevice.includes('|')) {
          return legacySelectedDevice.split('|');
        }
        return legacySelectedDevice;
      })
      .flat();

    const transformedLegacyEquipment: EquipmentInput[] = [];
    normalizedLegacySelectedEquipment.forEach((legacySelectedDevice) => {
      const deviceObject = optionalEquipment?.find(
        (device) => device.Name === legacySelectedDevice,
      );
      if (deviceObject) {
        transformedLegacyEquipment.push({
          ...deviceObject,
          IsRequired: false,
          Value: deviceObject.Default,
        });
      }
    });
    return transformedLegacyEquipment;
  }
}

export function isLoadInput(device: EquipmentInput) {
  return Boolean(device.Units && !device.Options);
}
