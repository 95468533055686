/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';

import { PatientFormMode } from './patientFormTypes';
import { getPatientCaseDtosSelector } from '../../../../state-manager/selectors/casesSelectors';
import CaseForm from '../../../commons/case/CaseForm';
import { useAppDispatch } from '../../../../state-manager/store';
import { fetchCasesAction } from '../../../../state-manager/thunks/casesThunks';
import DisplayField from '../../../commons/form/DisplayField';

export function CasesInFormView({
  mode,
  patientId,
}: {
  mode: PatientFormMode;
  patientId: string;
}) {
  const dispatch = useAppDispatch();

  const validPatientId = mode.mode === 'edit' || mode.mode === 'preview';

  const caseDtos = useSelector(
    getPatientCaseDtosSelector(validPatientId ? patientId : null),
  );

  useEffect(() => {
    if (validPatientId) {
      dispatch(fetchCasesAction(patientId));
    }
  }, [mode]);

  const [isCaseDialogOpen, setIsCaseDialogOpen] = useState<boolean>(false);
  const caseIds = caseDtos?.map(({ case_id }) => case_id);
  return (
    <>
      <div css={{ display: 'flex' }}>
        <DisplayField label="Cases" value={caseIds?.toString()} />
      </div>
      {isCaseDialogOpen && (
        <Dialog
          css={{
            '.MuiDialog-paper': { width: 500, maxWidth: 800 },
            '.MuiDialog-container': { height: '100%' },
          }}
          open={true}
          onClose={() => {
            setIsCaseDialogOpen(false);
          }}
        >
          <CaseForm
            patientId={patientId}
            onClose={() => {
              setIsCaseDialogOpen(false);
            }}
            onCancel={() => {
              setIsCaseDialogOpen(false);
            }}
          />
        </Dialog>
      )}
    </>
  );
}
