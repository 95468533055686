import { TreatmentPlanInput } from '../components/patient-details/treatment-plans/creation/treatmentPlanTypes';
import { MovementDto, TreatmentPlanTemplateDto } from '../types/backendType';
import { WithRequired } from '../utils/typeScriptUtils';
import { Activity, IActivity } from './Activity';
import { ActivityDefinition } from '../types/library';

export type TreatmentPlanTemplateId = string;

export interface ITreatmentPlanTemplate
  extends WithRequired<
    Omit<TreatmentPlanInput, 'activities' | 'startDate'> & {
      activities: IActivity[];
    },
    'name'
  > {}

export class TreatmentPlanTemplate implements ITreatmentPlanTemplate {
  treatmentPlanTemplateDto: TreatmentPlanTemplateDto;

  private activityDefinitions: Record<string, ActivityDefinition> | undefined;

  id: TreatmentPlanTemplateId;

  name: string;

  description: string;

  constructor({
    treatmentPlanTemplateDto,
    activityDefinitions,
  }: {
    treatmentPlanTemplateDto: TreatmentPlanTemplateDto;
    activityDefinitions?: Record<string, ActivityDefinition> | undefined;
  }) {
    this.treatmentPlanTemplateDto = treatmentPlanTemplateDto;
    this.activityDefinitions = activityDefinitions;
    this.id = this.treatmentPlanTemplateDto.treatment_plan_id;
    this.name = this.treatmentPlanTemplateDto.name;
    this.description = this.treatmentPlanTemplateDto.description;
  }

  get activities() {
    return this.treatmentPlanTemplateDto.movements.map((movement) => {
      const movementDto: MovementDto = { ...movement };
      return new Activity({ movementDto, activityDefinitions: this.activityDefinitions });
    });
  }
}
