import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { NotificationsState } from '../storeTypes';
import { resetAction } from '../actions/resetAction';
import { AppNotification, AppNotificationId, Severity } from '../../types/appTypes';
import { SnackbarOrigin } from 'notistack';

const initialState: NotificationsState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
  reducers: {
    removeNotification: (
      draftState: NotificationsState,
      action: PayloadAction<{ id: AppNotificationId }>,
    ) => {
      const { id } = action.payload;
      draftState.notifications = draftState.notifications.filter(
        (notification) => id !== notification.id,
      );
    },

    closeNotification: (
      draftState: NotificationsState,
      action: PayloadAction<{ id: AppNotificationId }>,
    ) => {
      const { id } = action.payload;
      const notification = draftState.notifications.find(
        (currentNotification) => id === currentNotification.id,
      );
      if (notification) {
        notification.dismissed = true;
      }
    },

    notify: (
      draftState: NotificationsState,
      action: PayloadAction<{
        message: string;
        severity?: Severity;
        origin?: SnackbarOrigin;
      }>,
    ) => {
      let { message, severity, origin } = action.payload;
      if (origin == undefined) {
        origin = { vertical: 'top', horizontal: 'center' };
      }
      const notification: AppNotification = {
        id: uuid(),
        message,
        severity,
        origin,
      };
      if (
        !draftState.notifications.some((currentNotification) => {
          return currentNotification.message === message;
        })
      ) {
        draftState.notifications.push(notification);
      }
    },
  },
});

export const { removeNotification, closeNotification, notify } = notificationsSlice.actions;

export default notificationsSlice;
