/** @jsxImportSource @emotion/react */

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CSSObject } from '@emotion/react';

import { getPatientTreatmentPlansSelector } from '../../../../models/factories/treatmentPlanFactories';
import { AccordionView } from '../../../commons/DraggableAccordionView';
import { useEffect, useState } from 'react';
import { TreatmentPlan } from '../../../../models/TreatmentPlan';
import { fonts } from '../../../../style/fonts';
import { buttons } from '../../../../style/buttons';
import TreatmentPlanPreview from '../commons/TreatmentPlanPreview';
import { PLAN_ID_PARAM } from '../creation/Form';
import { useAppDispatch } from '../../../../state-manager/store';
import {
  getCurrentPatientSelector,
  getUsersSelector,
} from '../../../../models/factories/userFactories';
import { fulfillUserAction } from '../../../../state-manager/thunks/usersThunks';
import {
  ActivityExecution,
  getPatientActivitiesExecutionsSelector,
} from '../../../../models/ActivityExecution';
import { Tooltip } from '@mui/material';
import TreatmentPlanPDF from './TreatmentPlanPDF';
import { getSelectedCaseIdSelector } from '../../../../state-manager/selectors/appSelectors';
import usePdfExport from '../../../../hooks/usePdfExport';
import { isUnrestrictedUserSelector } from '../../../../state-manager/selectors/appSelectors';

const plansCss: CSSObject = {
  button: {
    width: 92,
  },
};

const treatmentPlanSummaryCss: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  ...fonts.text,
  '.header-cell': {
    flex: 1,
    maxWidth: '400px',
  },
  '.name': {
    ...fonts.h2,
  },
  '.h2-label': {
    ...fonts.h2,
    display: 'inline',
  },
  '.info': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  '.treatment-plan-date-rang': {},
};

function getLastExecutionDate(
  treatmentPlanId: string,
  activitiesExecutions: ActivityExecution[] | undefined,
) {
  if (!activitiesExecutions || !activitiesExecutions.length) {
    return;
  }
  return activitiesExecutions.find((execution) => {
    return execution.treatmentPlanId === treatmentPlanId;
  })?.stopDate;
}

function TreatmentPlanSummary({
  index,
  treatmentPlan,
  treatmentPlans,
  goToForm,
  lastExecutionDate,
}: {
  index: number;
  treatmentPlan: TreatmentPlan;
  treatmentPlans: TreatmentPlan[];
  goToForm: (planId: string) => void;
  lastExecutionDate: Date | undefined;
}) {
  const { name, startDate, assignedDate, assignorUserId } = treatmentPlan;
  const previousPlan = treatmentPlans[index - 1];
  const endDate = previousPlan?.startDate;
  const dispatch = useAppDispatch();
  const users = useSelector(getUsersSelector);
  const isUnrestrictedUser = useSelector(isUnrestrictedUserSelector);

  const [clinicianName, setClinicianName] = useState<string | undefined>();
  const patient = useSelector(getCurrentPatientSelector);
  const caseId = useSelector(getSelectedCaseIdSelector);

  const documentProps = {
    treatmentPlan: treatmentPlan,
    patientName: patient?.name,
    caseId: caseId,
    clinicianName: clinicianName,
  };

  const { initiatePdfDownload, isDownloading } = usePdfExport(patient?.name);

  useEffect(() => {
    if (users) {
      const clinician = users[assignorUserId];
      if (clinician) {
        setClinicianName(clinician.name);
      } else {
        dispatch(fulfillUserAction(assignorUserId));
      }
    }
  }, [users]);

  return (
    <div css={treatmentPlanSummaryCss}>
      <div className="info">
        <div className="name header-cell">{name}</div>
        <div className="treatment-plan-date-rang header-cell">{`${startDate.toLocaleDateString()}-${
          endDate ? endDate.toLocaleDateString() : 'Current'
        }`}</div>
        <div className="header-cell">
          <div className="h2-label">Created by: </div>
          {clinicianName} ({assignedDate.toLocaleDateString()},
          {' ' + assignedDate.toLocaleTimeString()})
        </div>
        <div className="header-cell">
          <span className="h2-label">Last Execution: </span>
          {lastExecutionDate && (
            <span>
              {`${lastExecutionDate.toLocaleDateString()} 
              ${lastExecutionDate.toLocaleTimeString()}`}
            </span>
          )}
        </div>
      </div>
      {treatmentPlan && patient && (
        <button
          type="button"
          css={{ ...buttons.secondary, marginRight: '38px' }}
          onClick={(e) =>
            initiatePdfDownload(e, TreatmentPlanPDF, documentProps)
          }
          disabled={isDownloading}
        >
          {isDownloading ? 'Loading...' : 'Export'}
        </button>
      )}

      {isUnrestrictedUser ? (
        endDate ? (
          <button
            type="button"
            css={{ ...buttons.secondary }}
            onClick={(e) => {
              e.stopPropagation();
              goToForm(treatmentPlan.id);
            }}
          >
            Duplicate
          </button>
        ) : (
          <button
            type="button"
            css={{ ...buttons.primary }}
            onClick={(e) => {
              e.stopPropagation();
              goToForm(treatmentPlan.id);
            }}
          >
            Update
          </button>
        )
      ) : (
        ''
      )}
    </div>
  );
}

export default function Plans() {
  const history = useHistory();
  const treatmentPlans = useSelector(getPatientTreatmentPlansSelector);
  const [expendedItemsIds, setExpendedItemsIds] = useState<string[]>([]);
  const [
    doesLastTreatmentPlanHaveResults,
    setDoesLastTreatmentPlanHaveResults,
  ] = useState<boolean | undefined>(undefined);
  const isUnrestrictedUser = useSelector(isUnrestrictedUserSelector);

  const goToForm = (planId?: string) => {
    const searchParams = new URLSearchParams(history.location.search);
    if (planId) {
      searchParams.set(PLAN_ID_PARAM, planId);
    } else {
      searchParams.delete(PLAN_ID_PARAM);
    }
    history.push({
      pathname: '/patient/treatment-plans/edit',
      search: searchParams.toString(),
    });
  };

  const activitiesExecutions = useSelector(
    getPatientActivitiesExecutionsSelector,
  );

  useEffect(() => {
    if (!activitiesExecutions) {
      return;
    }
    const lastTreatmentPlanId = treatmentPlans?.[0]?.id;
    const haveResults = activitiesExecutions.some(
      ({ treatmentPlanId }) => treatmentPlanId === lastTreatmentPlanId,
    );
    setDoesLastTreatmentPlanHaveResults(haveResults);
  }, [treatmentPlans, activitiesExecutions]);

  const disabledNewButton = doesLastTreatmentPlanHaveResults !== true;

  return (
    <div css={plansCss}>
      {isUnrestrictedUser && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 24px',
          }}
        >
          <Tooltip
            title={
              disabledNewButton
                ? `A new treatment plan cannot be created now 
              because the currently active plan has not been executed. 
              Please use the Update button, or try to refresh.`
                : ''
            }
          >
            <span>
              <button
                disabled={disabledNewButton}
                css={{ ...buttons.primary }}
                type="button"
                onClick={() => {
                  goToForm();
                }}
              >
                New
              </button>
            </span>
          </Tooltip>
        </div>
      )}
      {treatmentPlans && (
        <AccordionView
          items={treatmentPlans}
          expendedItemsIds={expendedItemsIds}
          setExpendedItemsIds={setExpendedItemsIds}
          getAccordionSummary={(index, treatmentPlan) => {
            return (
              <TreatmentPlanSummary
                index={index}
                treatmentPlan={treatmentPlan}
                treatmentPlans={treatmentPlans}
                goToForm={goToForm}
                lastExecutionDate={getLastExecutionDate(
                  treatmentPlan.id,
                  activitiesExecutions,
                )}
              />
            );
          }}
          getAccordionDetails={(_, treatmentPlan) => {
            return <TreatmentPlanPreview treatmentPlan={treatmentPlan} />;
          }}
        />
      )}
    </div>
  );
}
