/** @jsxImportSource @emotion/react */

import { fonts } from '../../style/fonts';
import NavTabs from '../commons/NavTabs';
import {
  getRequestStatusSelector,
  isUnrestrictedUserSelector,
} from '../../state-manager/selectors/appSelectors';
import { useSelector } from 'react-redux';
import { Route, Switch as Routes, useLocation } from 'react-router-dom';
import CliniciansList from './CliniciansList';
import { CSSObject } from '@emotion/react';
import { useEffect, useState } from 'react';
import { SearchTextField } from '../commons/SearchTextField';
import { buttons } from '../../style/buttons';
import { PatientFormMode } from '../forms/create-user-form/create-patient-form/patientFormTypes';
import { isInnerEnvironment } from '../../config';
import { getUserSelector } from '../../models/factories/userFactories';
import { useAppDispatch } from '../../state-manager/store';
import { isFailed } from '../../state-manager/utils/requestStatus';
import { notify } from '../../state-manager/slices/notificationsSlice';
import { fetchUsersAction } from '../../state-manager/thunks/usersThunks';
import PatientsList from './PatientsList';
import { FormMode } from '../commons/form/formUtils';
import { User } from '../../models/User';

const managementContainerCss: CSSObject = {
  height: '100%',
  padding: '20px 30px',

  '.title': {
    ...fonts.h1,
    margin: '0',
    marginBottom: '18px',
  },

  '.tab-button': {
    ...fonts.smallTab,
    fontWeight: 700,
  },

  '.subcontainer': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '22px',
  },

  '.search-container': {
    display: 'flex',
    alignItems: 'center',
  },
};

function getTabs(isUnrestrictedUser: boolean) {
  return [
    {
      label: 'Patients',
      id: 'patients',
      navigateTo: '/patients',
      isSelected: (pathname: string) => {
        return pathname.includes('patients');
      },
    },
    {
      label: 'Clinicians',
      id: 'clinicians',
      navigateTo: '/clinicians',
      isSelected: (pathname: string) => {
        return pathname.includes('clinicians');
      },
      hidden: !isUnrestrictedUser,
    },
  ];
}

function useFetchClinicianUsersFailed() {
  const requestStatus = useSelector(getRequestStatusSelector('fetchUsers'));
  const user = useSelector(getUserSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user && user.isClinician()) {
      const clinicianId = user.id;
      if (isFailed({ id: clinicianId, requestStatus })) {
        dispatch(
          notify({
            message:
              'Failed to retreive users - please contact customer service',
            severity: 'error',
            origin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }),
        );
      }
    }
  }, [requestStatus]);
}

export default function Management() {
  useFetchClinicianUsersFailed();

  const dispatch = useAppDispatch();
  const isUnrestrictedUser = useSelector(isUnrestrictedUserSelector);
  const [nameSearchTerm, setNameSearchTerm] = useState<string>();
  const location = useLocation();
  const isPatientsView = location.pathname.includes('patients');
  const [patientFormMode, setPatientFormMode] = useState<
    PatientFormMode | undefined
  >();
  const [clinicianFormMode, setClinicianFormMode] = useState<
    FormMode<User> | undefined
  >();

  useEffect(() => {
    dispatch(fetchUsersAction());
  }, []);

  return (
    <div css={managementContainerCss}>
      <h1 className="title">Management</h1>
      <div className="subcontainer">
        <NavTabs navTabs={getTabs(isUnrestrictedUser)} />
        <div className="search-container">
          {/* {!isPatientsView && <span>Search for a Clinician</span>} */}
          <SearchTextField
            onSearchTermChange={setNameSearchTerm}
            searchTerm={nameSearchTerm}
          />
        </div>
        <div>
          {isPatientsView && (
            <div css={{ display: 'flex', gap: '10px' }}>
              <button
                type="button"
                css={{
                  ...buttons.primary,
                }}
                onClick={() => {
                  setPatientFormMode({ mode: 'new' });
                }}
              >
                Add Patient
              </button>
              <button
                type="button"
                css={{
                  ...buttons.secondary,
                }}
                hidden={!isInnerEnvironment()}
                onClick={() => {
                  setPatientFormMode({ mode: 'newTestUser' });
                }}
              >
                Add Test Patient
              </button>
            </div>
          )}
          {!isPatientsView && (
            <button
              type="button"
              css={{ ...buttons.primary }}
              onClick={() => {
                setClinicianFormMode({ mode: 'new' });
              }}
            >
              Add Clinician
            </button>
          )}
        </div>
      </div>
      <Routes>
        <Route
          path="/patients"
          render={() => (
            <PatientsList
              nameSearchTerm={nameSearchTerm}
              setNameSearchTerm={setNameSearchTerm}
              patientFormMode={patientFormMode}
              setPatientFormMode={setPatientFormMode}
            />
          )}
        />
        <Route
          path="/clinicians"
          render={() => (
            <CliniciansList
              nameSearchTerm={nameSearchTerm}
              setNameSearchTerm={setNameSearchTerm}
              clinicianFormMode={clinicianFormMode}
              setClinicianFormMode={setClinicianFormMode}
            />
          )}
        />
      </Routes>
    </div>
  );
}
