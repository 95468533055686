/** @jsxImportSource @emotion/react */

import { Dispatch, SetStateAction } from 'react';
import { CSSObject } from '@emotion/react';
import TrayIcon from '../../assets/tray.svg';
import { colors } from '../../style/colors';
import { fonts } from '../../style/fonts';
import { shapes } from '../../style/shapes';
import { buttons } from '../../style/buttons';
import { convertBytesToKB } from '../../utils/numberUtils';
import TrashIcon from '../../assets/trash.svg';

const fileUploaderCss: CSSObject = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',

  '.upload-container': {
    border: shapes.border,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '45px 0 67px 0',
    backgroundColor: colors.lightGrey,
    ...fonts.text,

    '& input[type="file"]': {
      display: 'none',
    },
  },

  '.custom-upload': {
    marginTop: '5px',
    ...buttons.primarySmall,
    cursor: 'pointer',
  },

  '.uploaded-files-container': {
    display: 'flex',
    flexDirection: 'column',
    border: shapes.border,
    borderRadius: '6px',
    padding: '10px',
  },

  '.uploaded-file': {
    display: 'flex',
    justifyContent: 'space-between',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
};

function dropHandler(
  event: React.DragEvent<HTMLDivElement>,
  setUploadedFile: Dispatch<SetStateAction<File>>,
) {
  event.preventDefault();
  if (event.dataTransfer?.items) {
    const files = [...event.dataTransfer.items]
      .map((item) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          return file;
        } else {
          return null;
        }
      })
      .filter((file) => file !== null) as File[];

    if (files[0]) {
      setUploadedFile(files[0]);
    }
  }
}

function uploadHandler(
  event: React.ChangeEvent<HTMLInputElement>,
  setUploadedFile: Dispatch<SetStateAction<File>>,
) {
  if (event.target.files) {
    const files = Array.from(event.target.files);
    if (files[0]) {
      setUploadedFile(files[0]);
    }
  }
}

function removeFile(
  value: string,
  setUploadedFiles: Dispatch<SetStateAction<File | null>>,
) {
  if (value) {
    setUploadedFiles(null);
  }
}

export default function FileUploader({
  uploadedFile,
  setUploadedFile,
}: {
  uploadedFile: File | null;
  setUploadedFile: Dispatch<SetStateAction<File>>;
}) {
  return (
    <div css={fileUploaderCss} className="file-uploader-container">
      {!uploadedFile && (
        <div
          className="upload-container"
          onDrop={(event) => dropHandler(event, setUploadedFile)}
          onDragOver={(event) => {
            event.preventDefault();
          }}
        >
          <TrayIcon height={38} width={48} color={colors.blue3} />
          <span>Drag & drop your files here</span>
          <span>or</span>
          <label className="custom-upload">
            Browse Files
            <input
              type="file"
              onChange={(event) => uploadHandler(event, setUploadedFile)}
            />
          </label>
        </div>
      )}

      {uploadedFile && (
        <div className="uploaded-files-container">
          <div key={uploadedFile.name} className="uploaded-file">
            <div>
              <span css={{ ...fonts.largeLabel }}>{uploadedFile.name}</span>
              <span>{` (${convertBytesToKB(uploadedFile.size)}KB)`}</span>
            </div>
            <div>
              <button
                type="button"
                value={uploadedFile.name}
                onClick={(event) =>
                  removeFile(event.currentTarget.value, setUploadedFile)
                }
              >
                <TrashIcon width={20} color={colors.blue3} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
