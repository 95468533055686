/** @jsxImportSource @emotion/react */

import Dialog from '@mui/material/Dialog';

import CreatePatientFormContainer from './create-patient-form/CreatePatientFormContainer';
import { PatientFormMode } from './create-patient-form/patientFormTypes';
import { FormMode } from '../../commons/form/formUtils';
import { User } from '../../../models/User';
import CreateClinicianFormView from './create-clinician-form/CreateClinicianFormView';

export default function CreateUserModal({
  formMode,
  setFormMode,
  type,
  onCreateUser,
}: {
  formMode: PatientFormMode | FormMode<User> | undefined;
  setFormMode: (formMode: PatientFormMode | FormMode<User> | undefined) => void;
  type: 'patient' | 'clinician';
  onCreateUser?: (name: string) => void;
}) {
  let emrPatientId: string | undefined = undefined;
  if (type === 'patient' && formMode && 'emrPatientId' in formMode) {
    emrPatientId = formMode.emrPatientId;
  }

  let onCancel: undefined | ((reason?: string) => void) = undefined;
  if (!emrPatientId) {
    onCancel = (reason) => {
      if (reason === 'backdropClick') {
        return;
      }
      setFormMode(undefined);
    };
  }

  return (
    <>
      {formMode && (
        <Dialog
          css={{
            '.MuiDialog-paper': {
              width: 600,
              maxWidth: 702,
              minWidth: '300px',
            },
            '.MuiDialog-container': { height: '100%' },
          }}
          open={true}
          onClose={(_, reason) => {
            onCancel?.(reason);
          }}
        >
          {type === 'patient' && (
            <CreatePatientFormContainer
              onCreatePatient={(name: string | undefined) => {
                if (name) {
                  onCreateUser?.(name);
                }
                setFormMode(undefined);
              }}
              formMode={formMode}
              onFinish={() => {
                setFormMode(undefined);
              }}
              onCancel={onCancel}
            />
          )}
          {type === 'clinician' && formMode.mode !== 'newTestUser' && (
            <CreateClinicianFormView
              onCreateUser={(name: string | undefined) => {
                if (name) {
                  onCreateUser?.(name);
                }
                setFormMode(undefined);
              }}
              formMode={formMode}
              onFinish={() => {
                setFormMode(undefined);
              }}
              onCancel={onCancel}
            />
          )}
        </Dialog>
      )}
    </>
  );
}
