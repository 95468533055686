import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppState, RequestStatusName, requestStatusNames } from '../storeTypes';
import { resetAction } from '../actions/resetAction';
import {
  RequestStatus,
  RequestStatusOption,
  createEmptyRequestStatus,
  markTheStatus,
} from '../utils/requestStatus';
import { Dispatch } from '../storeTypes';
import { TreatmentPlanId } from '../../models/TreatmentPlan';
import { UserId } from '../../models/User';
import { MeasurementSystems } from '../../types/appTypes';
import { UserRole } from '../../types/backendType';

const localMeasurementSystem = localStorage.getItem('measurementSystem');

const initialState: AppState = {
  currentPatientId: null,
  currentTreatmentPlanId: undefined,
  selectedCaseId: null,
  measurementSystem: localMeasurementSystem
    ? JSON.parse(localMeasurementSystem)
    : MeasurementSystems.Imperial,
  requestsStatus: requestStatusNames.reduce((accumulator, requestStatusName) => {
    accumulator[requestStatusName] = createEmptyRequestStatus();
    return accumulator;
  }, {} as Record<RequestStatusName, RequestStatus>),
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
  reducers: {
    setCurrentPatientIdAction: (
      draftState: AppState,
      action: PayloadAction<{ patientId: UserId | null | undefined }>,
    ) => {
      const { patientId } = action.payload;
      draftState.currentPatientId = patientId;
      draftState.currentTreatmentPlanId = undefined;
    },
    setCurrentTreatmentPlanIdAction: (
      draftState: AppState,
      action: PayloadAction<{
        treatmentPlanId: TreatmentPlanId | undefined;
      }>,
    ) => {
      draftState.currentTreatmentPlanId = action.payload.treatmentPlanId;
    },
    setMainUseMetadataAction: (
      draftState: AppState,
      action: PayloadAction<{ userId: UserId; role: UserRole }>,
    ) => {
      const { userId, role } = action.payload;
      draftState.userId = userId;
      draftState.role = role;
      draftState.currentTreatmentPlanId = undefined;
    },
    setMeasurementSystemAction: (
      draftState: AppState,
      action: PayloadAction<{ measurementSystem: string }>,
    ) => {
      const { measurementSystem } = action.payload;
      draftState.measurementSystem = measurementSystem;
    },

    markRequestStatusAction: (
      draftState: AppState,
      action: PayloadAction<{
        id: string;
        requestStatusName: RequestStatusName;
        status: RequestStatusOption;
      }>,
    ) => {
      const { id, requestStatusName, status } = action.payload;
      draftState.requestsStatus[requestStatusName] = markTheStatus({
        id,
        status,
        requestStatus: draftState.requestsStatus[requestStatusName],
      });
    },

    cleanRequestStatusAction: (
      draftState: AppState,
      action: PayloadAction<{
        id: string;
        requestStatusName: RequestStatusName;
      }>,
    ) => {
      const { id, requestStatusName } = action.payload;
      const newRequestStatus = { ...draftState.requestsStatus[requestStatusName] };
      newRequestStatus.failed = newRequestStatus.failed.filter(
        (currentId) => currentId !== id,
      );
      newRequestStatus.inProcess = newRequestStatus.inProcess.filter(
        (currentId) => currentId !== id,
      );
      newRequestStatus.succeeded = newRequestStatus.succeeded.filter(
        (currentId) => currentId !== id,
      );
      draftState.requestsStatus[requestStatusName] = newRequestStatus;
    },

    setSelectedCaseIdAction: (
      draftState: AppState,
      action: PayloadAction<{ caseId: string | null }>,
    ) => {
      const { caseId } = action.payload;
      draftState.selectedCaseId = caseId;
    },
    setEditTemplateIdAction: (
      draftState: AppState,
      action: PayloadAction<{ editTemplateId: TreatmentPlanId | undefined }>,
    ) => {
      const { editTemplateId } = action.payload;
      draftState.editTemplateId = editTemplateId;
    },
    cleanEditTemplateIdAction: (draftState: AppState) => {
      draftState.editTemplateId = undefined;
    },
  },
});

export const {
  setCurrentPatientIdAction,
  setCurrentTreatmentPlanIdAction,
  setMainUseMetadataAction,
  markRequestStatusAction,
  cleanRequestStatusAction,
  setSelectedCaseIdAction,
  setMeasurementSystemAction,
  setEditTemplateIdAction,
  cleanEditTemplateIdAction,
} = appSlice.actions;

export default appSlice;

export function markStatusFunctionCreator(
  dispatch: Dispatch,
  id: string,
  requestStatusName: RequestStatusName,
) {
  return function (status: RequestStatusOption) {
    dispatch(
      markRequestStatusAction({
        id,
        requestStatusName,
        status,
      }),
    );
  };
}
