/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { CSSObject } from '@emotion/react';
import { format } from 'date-fns';

import { AccordionView } from '../../commons/DraggableAccordionView';
import { shapes } from '../../../style/shapes';
import { fonts } from '../../../style/fonts';
import { ActivityExecution, Result } from '../../../models/ActivityExecution';
import { Session } from '../../../models/Session';
import { hiddenScrollbar } from '../../../utils/cssUtils';
import { getOrdinalSuffix } from '../../../utils/numberUtils';
import OverTimeMetrics from './OverTimeMetrics';
import { colors } from '../../../style/colors';
import { DONT_CARE } from '../../../types/backendType';
import { isEmpty } from '../../../state-manager/utils/compare';
import {
  getDisplayUnit,
  getMeasurementSystemUnit,
  getMeasurementSystemValue,
} from '../../../utils/unitUtils';
import {
  getLegacyPartiallyCompletedText,
  getRepsArray,
  getShouldShowLegacyPartiallyCompleted,
  getShouldShowPartiallyCompleted,
  simplifiedActivityGraphTooltipCss,
} from './SessionsUntils';
import SessionLegend, {
  MET_GOAL,
  NOT_MEET_GOAL,
  getGoalClassName,
  legendOptions,
} from './SessionLegend';
import { Tooltip } from '@mui/material';
import ActivityGraph from '../activities-graph/ActivityGraph';
import { ActivityDefinitionId } from '../../../types/library';
import { useSelector } from 'react-redux';
import { getMeasurementSystemSelector } from '../../../state-manager/selectors/appSelectors';
import { breakpoints } from '../../../style/breakpoints';
import useBreakpoints from '../../../hooks/useBreakpoints';

const SESSION_HEADER_HEIGHT = 63;

function getResultsTableCss(
  numberOfGridColumns: number,
  numberOfGridRows: number,
): CSSObject {
  return {
    display: 'grid',
    gridTemplateColumns: `${
      numberOfGridColumns === 3 ? '230px 84px 125px' : '230px 84px 125px 200px'
    } `,
    border: shapes.border,
    borderRadius: shapes.borderRadius,
    width: 'fit-content',
    gridGap: '1.5px',
    backgroundColor: colors.dividerGrey,
    overflow: 'hidden',

    [breakpoints.medium]: {
      gridTemplateColumns: `${
        numberOfGridColumns === 3
          ? '230px 84px 125px'
          : '230px 84px 125px 255px'
      } `,
    },
    [breakpoints.large]: {
      gridTemplateColumns: `${
        numberOfGridColumns === 3
          ? '230px 84px 125px'
          : '230px 84px 125px 413px'
      } `,
    },

    div: {
      padding: '11px 20px',
      backgroundColor: colors.white,
    },

    'div:nth-of-type(3n-2)': { ...fonts.largeLabel },
    'div:nth-of-type(-n+3)': { ...fonts.largeLabel },

    '.partially-completed': {
      gridColumn: `${numberOfGridColumns}`,
      gridRow: `2 / ${numberOfGridRows + 2}`,
      whiteSpace: 'break-spaces',
      ...fonts.largeLabel,
    },
  };
}

const sessionViewCss: CSSObject = {
  border: shapes.border,
  borderRadius: shapes.borderRadius,
  marginBottom: 20,
  '.session-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: shapes.border,
    height: SESSION_HEADER_HEIGHT,
    width: '100%',
    padding: 20,
    gap: 20,
    '.name': {
      ...fonts.h2,
    },
    '.time-range': {
      ...fonts.text,
    },
  },
  '.session-body': {
    width: '100%',
    height: `calc(100% - ${SESSION_HEADER_HEIGHT}px)`,
  },
  '.met-goal': { color: legendOptions[MET_GOAL].color },
  '.not-meet-goal': {
    color: legendOptions[NOT_MEET_GOAL].color,
  },
  '.ellipsis': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const metricsLineViewCss: CSSObject = {
  display: 'flex',
  gap: 8,
  flexGrow: 1,
  ...hiddenScrollbar,

  [breakpoints.medium]: {
    gap: 10,
  },

  [breakpoints.large]: {
    width: 150,
    gap: 40,
  },

  '.metric-name': {
    color: colors.blue2,
    '&:focus': {
      textDecoration: 'underline',
    },
  },
};

const sessionSummaryCss: CSSObject = {
  display: 'flex',
  ...fonts.text,
  flexDirection: 'column',
  gap: '5px',
  paddingLeft: '5px',

  [breakpoints.large]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const NUM_OF_METRICS_TO_SHOW_IN_HEADER = 3;
function getMainMetrics(activityExecution: ActivityExecution) {
  const postExerciseMetric =
    activityExecution.activityDefinition?.postExerciseMetric;
  if (!postExerciseMetric) {
    return [];
  }
  const metricsToShow: Result[] = [];
  let index = 0;
  while (
    metricsToShow.length < NUM_OF_METRICS_TO_SHOW_IN_HEADER &&
    index <= postExerciseMetric.length - 1
  ) {
    const postMetric = postExerciseMetric[index];
    index = index + 1;
    if (!postMetric) {
      continue;
    }
    const metrics = activityExecution.results.filter(
      ({ key }) => key === postMetric.metric_name,
    );
    metricsToShow.push(...metrics);
  }
  return metricsToShow;
}

function MetricsLineView({
  activityExecution,
  activitiesExecutionsMap,
}: {
  activityExecution: ActivityExecution;
  activitiesExecutionsMap: Record<ActivityDefinitionId, ActivityExecution[]>;
}) {
  const metricsToShow = getMainMetrics(activityExecution);
  const [openTooltip, setOpenTooltip] = useState<number | null>(null);
  const measurementSystem = useSelector(getMeasurementSystemSelector);

  return (
    <div css={metricsLineViewCss}>
      {metricsToShow.map(
        (
          { key, name, value, metGoal, unit, side, standardDeviation },
          index,
        ) => {
          let displayName = name;
          if (side) {
            displayName = `${name} (${side})`;
          }

          const measurementSystemUnit = getDisplayUnit(
            getMeasurementSystemUnit(unit, measurementSystem),
          );

          const measurementSystemValue = getMeasurementSystemValue(
            value,
            measurementSystemUnit,
            measurementSystem,
          )?.toFixed(1);

          return (
            <Tooltip
              key={side ? `${key}_${side}` : key}
              title={
                <div css={{ width: '460px', height: '155px' }}>
                  <ActivityGraph
                    activityDefinitionId={
                      activityExecution.activityDefinitionId
                    }
                    activitiesExecutionsMap={activitiesExecutionsMap}
                    metricKey={key}
                    metricSide={side}
                    standardDeviation={standardDeviation}
                    simplified
                  />
                </div>
              }
              arrow
              placement="top"
              componentsProps={simplifiedActivityGraphTooltipCss}
              open={openTooltip === index}
              onClose={() => {
                setOpenTooltip(null);
              }}
              disableHoverListener
            >
              <div css={{ display: 'flex' }}>
                <button
                  className="metric-name"
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenTooltip(openTooltip === index ? null : index);
                  }}
                >{`${displayName}: `}</button>
                <div className={getGoalClassName({ metGoal })}>
                  {measurementSystemValue}
                  {measurementSystemUnit}
                </div>
              </div>
            </Tooltip>
          );
        },
      )}
    </div>
  );
}

function FullPosition({
  activityExecution,
}: {
  activityExecution: ActivityExecution;
}) {
  const activityDefinition = activityExecution.activityDefinition;
  const settings = activityDefinition?.settings;
  let fullPosition = `${activityDefinition?.plane} • ${activityDefinition?.position}`;
  if (settings?.side && !settings.side.options.includes(DONT_CARE)) {
    fullPosition = fullPosition + ` • ${activityExecution.activity?.side}`;
  }
  return <div css={{ ...fonts.text, width: 190 }}>{fullPosition}</div>;
}

function SessionSummaryView({
  activityExecution,
  activitiesExecutionsMap,
}: {
  activityExecution: ActivityExecution;
  activitiesExecutionsMap: Record<ActivityDefinitionId, ActivityExecution[]>;
}) {
  const text = activityExecution?.name || '';
  const [showTooltip, setShowTooltip] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setShowTooltip(container.scrollWidth > container.clientWidth);
    }
  }, [text]);

  const isScreenLarge = useBreakpoints('large');

  return (
    <div css={sessionSummaryCss}>
      <div
        css={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          gap: 20,
          whiteSpace: 'nowrap',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            ref={containerRef}
            css={{ width: 140, ...fonts.largeLabel }}
            onClick={() => {
              navigator.clipboard.writeText(
                JSON.stringify({
                  sessionId: activityExecution.sessionId,
                  subSessionId: activityExecution.subSessionId,
                }),
              );
            }}
          >
            {showTooltip ? (
              <Tooltip title={text}>
                <div className="ellipsis">{text}</div>
              </Tooltip>
            ) : (
              text
            )}
          </div>

          <FullPosition activityExecution={activityExecution} />
          {isScreenLarge && (
            <MetricsLineView
              activityExecution={activityExecution}
              activitiesExecutionsMap={activitiesExecutionsMap}
            />
          )}
        </div>
        {activityExecution.compliance !== undefined && (
          <div css={{ width: '128px' }}>
            Compliance:
            <span
              className={getGoalClassName({
                value: activityExecution.compliance,
                goal: 100,
              })}
            >
              {` ${
                Number.isInteger(activityExecution.compliance)
                  ? activityExecution.compliance
                  : activityExecution.compliance.toFixed(1)
              }%`}
            </span>
          </div>
        )}
      </div>
      {!isScreenLarge && (
        <MetricsLineView
          activityExecution={activityExecution}
          activitiesExecutionsMap={activitiesExecutionsMap}
        />
      )}
    </div>
  );
}

function ResultsTable({
  activityExecution,
}: {
  activityExecution: ActivityExecution;
}) {
  // TODO: Uncomment when actual hold is supposted
  // const averageHoldTime = activityExecution.hold?.reduce(
  //   (accumulator, holdPerSet) => accumulator + holdPerSet,
  //   0,
  // );
  const measurementSystem = useSelector(getMeasurementSystemSelector);

  const shouldShowLegacyPartiallyCompleted =
    getShouldShowLegacyPartiallyCompleted(
      activityExecution.status,
      activityExecution.statusDescription,
    );

  const shouldShowPartiallyCompleted = getShouldShowPartiallyCompleted(
    activityExecution.status,
    activityExecution.activityDefinition?.automationLevel,
  );

  const numberOfGridColumns =
    shouldShowLegacyPartiallyCompleted || shouldShowPartiallyCompleted ? 4 : 3;

  const numberOfGridRows = useMemo(() => {
    let gridRows = 2;
    if (!isEmpty(activityExecution.activity?.hold)) {
      gridRows++;
    }
    if (!isEmpty(activityExecution.activity?.duration)) {
      gridRows++;
    }
    if (activityExecution.results.length > 0) {
      gridRows += activityExecution.results.length;
    }
    return gridRows;
  }, [
    activityExecution.activity?.hold,
    activityExecution.activity?.duration,
    activityExecution.results,
  ]);

  const resultsTableCss = useMemo(() => {
    return getResultsTableCss(numberOfGridColumns, numberOfGridRows);
  }, [numberOfGridColumns]);

  const repsArray = useMemo(() => {
    if (activityExecution.activity) {
      return getRepsArray(
        activityExecution.activity.sets,
        activityExecution.reps,
      );
    }
  }, [activityExecution.activity?.sets, activityExecution.reps]);

  return (
    <div css={resultsTableCss}>
      <div></div>
      <div>Goal</div>
      <div>Actual</div>
      {(shouldShowLegacyPartiallyCompleted || shouldShowPartiallyCompleted) && (
        <div>Partially Completed</div>
      )}

      <div>Sets</div>
      <div>{activityExecution.activity?.sets}</div>
      <div
        className={getGoalClassName({
          value: activityExecution.sets,
          goal: activityExecution.activity?.sets,
          automationLevel: activityExecution.automationLevel,
        })}
      >
        {activityExecution.sets}
      </div>

      {!isEmpty(activityExecution.activity?.reps) && (
        <>
          <div>Reps</div>
          <div>{activityExecution.activity?.reps}</div>
          <div
            css={{
              'span:not(:last-of-type)::after': {
                content: '", "',
                color: colors.black,
              },
            }}
          >
            {repsArray?.map((repsPerSet: number, index: number) => (
              <span
                key={index}
                className={getGoalClassName({
                  value: repsArray?.[index],
                  goal: activityExecution.activity?.reps,
                  automationLevel: activityExecution.automationLevel,
                })}
              >
                {repsPerSet}
              </span>
            ))}
          </div>
        </>
      )}

      {!isEmpty(activityExecution.activity?.hold) && (
        <>
          <div>Hold (seconds)</div>
          <div>{activityExecution.activity?.hold}</div>
          <div />
        </>
      )}
      {/* TODO: Uncomment when actual hold is supposted */}
      {/* <div
        className={getGoalClassName({
          value: activityExecution.hold?.[activityExecution.hold?.length - 1],
          goal: activityExecution.activity?.hold,
        })}
      >{`${averageHoldTime} average`}</div> */}

      {!isEmpty(activityExecution.activity?.duration) && (
        <>
          <div>Duration (seconds)</div>
          <div>{activityExecution.activity?.duration}</div>
          {/* TODO: Add actual when available */}
          <div />
        </>
      )}

      {activityExecution.results.map((metric) => {
        const measurementSystemUnit = getDisplayUnit(
          getMeasurementSystemUnit(metric.unit, measurementSystem),
        );

        const metricSide = metric.side !== 'NA' ? metric.side : '';
        const nameAdditions = [measurementSystemUnit, metricSide].filter(
          (addition) => {
            return Boolean(addition);
          },
        );
        const name = `${metric.name} ${
          nameAdditions.length > 0 ? `(${nameAdditions.join(', ')})` : ''
        } `;

        const measurementSystemValue = getMeasurementSystemValue(
          metric.value,
          measurementSystemUnit,
          measurementSystem,
        )?.toFixed(0);

        const measurementSystemGoal =
          getMeasurementSystemValue(
            metric.goal,
            measurementSystemUnit,
            measurementSystem,
          ) ?? 0;
        return (
          <Fragment key={name}>
            <div>{name}</div>
            <div>{measurementSystemGoal}</div>
            <div
              className={getGoalClassName({
                metGoal: metric.metGoal,
                automationLevel: activityExecution.automationLevel,
              })}
            >
              {`${measurementSystemValue || ''} ${
                metric.math ? metric.math : ''
              }`}
            </div>
          </Fragment>
        );
      })}

      {/* Legacy Partially Completed column */}
      {shouldShowLegacyPartiallyCompleted && (
        <div className="partially-completed">
          {getLegacyPartiallyCompletedText(activityExecution.statusDescription)}
        </div>
      )}

      {/*New Partially Completed Column */}
      {shouldShowPartiallyCompleted && (
        <div className="partially-completed">
          {activityExecution.statusDescription}
        </div>
      )}
    </div>
  );
}

function SessionDetailsView({
  activityExecution,
}: {
  activityExecution: ActivityExecution;
}) {
  return (
    <div css={{ padding: 20, '& > .over-time-metrics': { marginBottom: 20 } }}>
      <OverTimeMetrics activityExecution={activityExecution} />
      <ResultsTable activityExecution={activityExecution} />
    </div>
  );
}

function SessionHeader({
  session,
  index,
  total,
}: {
  session: Session;
  index: number;
  total: number;
}) {
  const order = total - index;
  const ordinalSuffix = getOrdinalSuffix(order);
  return (
    <div className="session-header">
      <div css={{ display: 'flex', alignItems: 'center', gap: 20 }}>
        <div className="name">{`${order}${ordinalSuffix} Session`}</div>
        <div className="time-range">
          {format(session.startDate, 'hh:mm a') +
            ' - ' +
            format(session.stopDate, 'hh:mm a')}
        </div>
      </div>
      <SessionLegend />
    </div>
  );
}

function SessionView({
  session,
  index,
  total,
  activitiesExecutionsMap,
}: {
  session: Session;
  index: number;
  total: number;
  activitiesExecutionsMap: Record<ActivityDefinitionId, ActivityExecution[]>;
}) {
  const [expendedItemsIds, setExpendedItemsIds] = useState<string[]>([]);

  return (
    <>
      <div css={sessionViewCss}>
        <SessionHeader session={session} index={index} total={total} />
        <div className="session-body">
          <div css={{ padding: 20 }}>
            {session.activitiesExecutions && (
              <AccordionView
                items={session.activitiesExecutions}
                expendedItemsIds={expendedItemsIds}
                setExpendedItemsIds={setExpendedItemsIds}
                getAccordionSummary={(_, activityExecution) => (
                  <SessionSummaryView
                    activityExecution={activityExecution}
                    activitiesExecutionsMap={activitiesExecutionsMap}
                  />
                )}
                getAccordionDetails={(_, activityExecution) => (
                  <SessionDetailsView activityExecution={activityExecution} />
                )}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default function SessionsView({
  sessions,
  activitiesExecutionsMap,
}: {
  sessions: Session[];
  activitiesExecutionsMap?: Record<ActivityDefinitionId, ActivityExecution[]>;
}) {
  return (
    <>
      {!!activitiesExecutionsMap &&
        sessions.map((session, index) => {
          return (
            <SessionView
              activitiesExecutionsMap={activitiesExecutionsMap}
              key={session.id}
              session={session}
              index={index}
              total={sessions.length}
            />
          );
        })}
    </>
  );
}
