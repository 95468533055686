import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';

import CreatePatientFormView from './CreatePatientFormView';
import {
  useInProcess,
  useIsEndedSuccessfully,
  useIsFailed,
  useIsFinished,
} from '../../../../state-manager/selectors/appSelectors';
import { useAppDispatch } from '../../../../state-manager/store';
import {
  createUserAction,
  updateUserAction,
} from '../../../../state-manager/thunks/usersThunks';
import { User } from '../../../../models/User';
import {
  PatientInput,
  PatientFormMode,
  PatientFormData,
} from './patientFormTypes';
import { patientFormValidation } from '../userFormValidation';
import { useForm } from '../../../commons/form/useForm';
import { cleanRequestStatusAction } from '../../../../state-manager/slices/appSlice';
import { notify } from '../../../../state-manager/slices/notificationsSlice';
import {
  fetchUserPrivacyConsents,
  updateUserPrivacyConsents,
} from '../../../../services/usersService';
import { PatientPrivacySetting } from '../../../../types/backendType';
import {
  CURRENT_PRIVACY_VERSION,
  HIPAA_AUTHORIZATION_ID,
  PRIVACY_NOTICE_ID,
} from './CreatePatientFormUtils';
import { getCancelWithConfirm, getTitle, UserTypes } from '../CreateUserUtils';

function toUserFormData(user: User): PatientFormData {
  const userFormData: PatientFormData = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    height: user.height,
    creationDate: new Date(user.creationDate),
    isTestUser: user.isTestUser(),
    dateOfBirth: user.dateOfBirth,
    role: user.role,
  };
  if (userFormData.isTestUser) {
    const additionalInfo = user.additionalInfo;
    userFormData.weight = additionalInfo?.weight;
    userFormData.surgeryDate = additionalInfo?.surgery_date
      ? new Date(additionalInfo?.surgery_date)
      : undefined;
    userFormData.medicalCondition = additionalInfo?.medical_condition;
    userFormData.surgicalLeg = additionalInfo?.surgical_leg;
    userFormData.gender = additionalInfo?.gender;
    userFormData.ethnicity = additionalInfo?.ethnicity;
  }
  return userFormData;
}

function isPatientInput(
  userFormData: PatientFormData,
): userFormData is PatientInput {
  const formErrors = patientFormValidation(userFormData);
  return !formErrors;
}

const defaultPrivacySetting = {
  agree: false,
  consent_date: undefined,
  document_version: undefined,
};

// Creates default declined privacy settings if a setting is neither declined nor approved,
// which lack a date and a version
function transformPrivacySetting(
  consentData: PatientPrivacySetting[],
  setPatientPrivacySettings: Dispatch<SetStateAction<PatientPrivacySetting[]>>,
) {
  if (!consentData || !consentData.length) {
    setPatientPrivacySettings([
      {
        ...defaultPrivacySetting,
        document_id: PRIVACY_NOTICE_ID,
      },
      {
        ...defaultPrivacySetting,
        document_id: HIPAA_AUTHORIZATION_ID,
      },
    ]);
  } else {
    const lastPrivacyNoticeDocument = consentData.find(
      (document: PatientPrivacySetting) => {
        return document.document_id === PRIVACY_NOTICE_ID;
      },
    );
    const lastHipaaAuthorizationDocument = consentData.find(
      (document: PatientPrivacySetting) => {
        return document.document_id === HIPAA_AUTHORIZATION_ID;
      },
    );

    if (lastPrivacyNoticeDocument) {
      setPatientPrivacySettings([lastPrivacyNoticeDocument]);
    } else {
      setPatientPrivacySettings([
        {
          ...defaultPrivacySetting,
          document_id: PRIVACY_NOTICE_ID,
        },
      ]);
    }

    if (lastHipaaAuthorizationDocument) {
      setPatientPrivacySettings((previousValue) => {
        return [...(previousValue || []), lastHipaaAuthorizationDocument];
      });
    } else {
      setPatientPrivacySettings((previousValue) => {
        return [
          ...(previousValue || []),
          {
            ...defaultPrivacySetting,
            document_id: HIPAA_AUTHORIZATION_ID,
          },
        ];
      });
    }
  }
}

export default function CreatePatientFormContainer({
  onCreatePatient,
  formMode,
  onFinish,
  onCancel,
}: {
  onCreatePatient: (name?: string) => void;
  formMode: PatientFormMode;
  onFinish: () => void;
  onCancel?: () => void;
}) {
  const dispatch = useAppDispatch();
  const { mode } = formMode;
  let initData: User | undefined;
  if ('initData' in formMode) {
    initData = formMode.initData;
  }

  const initFormData = useRef<PatientFormData>(
    initData
      ? toUserFormData(initData)
      : {
          id: uuid(),
          creationDate: new Date(),
          isTestUser: formMode.mode === 'newTestUser' ? true : false,
          role: UserTypes.Patient,
        },
  );

  const [patientPrivacySettings, setPatientPrivacySettings] =
    useState<PatientPrivacySetting[]>();
  const [
    isUpdatingPatientPrivacySettings,
    setIsUpdatingPatientPrivacySettings,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (!initFormData.current) {
      return;
    }
    const id = initFormData.current.id;
    dispatch(
      cleanRequestStatusAction({
        id,
        requestStatusName: 'createNewUser',
      }),
    );
    dispatch(
      cleanRequestStatusAction({
        id,
        requestStatusName: 'updateUser',
      }),
    );
  }, [initFormData.current]);

  const {
    formData,
    formErrors,
    setFormErrors,
    setShouldValidate,
    isDirty,
    onChange,
  } = useForm(initFormData.current, patientFormValidation);

  useEffect(() => {
    if (!formData?.id || mode.includes('new')) {
      return;
    }
    fetchUserPrivacyConsents(formData.id)
      .then((consentData) => {
        transformPrivacySetting(consentData, setPatientPrivacySettings);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [formData?.id]);

  const handlePrivacySettingsChange = useCallback(
    (documentId: string) => {
      setIsUpdatingPatientPrivacySettings(true);
      if (!patientPrivacySettings) {
        return;
      }
      const currentDateString = new Date().toISOString();
      const updatedPrivacySetting = {
        agree: false,
        document_version: CURRENT_PRIVACY_VERSION,
        document_id: documentId,
        consent_date: currentDateString,
      };

      if (formData) {
        updateUserPrivacyConsents(formData.id, updatedPrivacySetting)
          .then(() => {
            dispatch(
              notify({
                message: 'The privacy settings of the user have been updated',
                severity: 'success',
              }),
            );
            setPatientPrivacySettings((previousValue) => {
              if (documentId === PRIVACY_NOTICE_ID && previousValue?.[1]) {
                return [updatedPrivacySetting, previousValue[1]];
              } else if (previousValue?.[0]) {
                return [previousValue[0], updatedPrivacySetting];
              }
            });
          })
          .catch(() => {
            dispatch(
              notify({
                message: 'Failed to update the privacy settings of the user',
                severity: 'error',
              }),
            );
          });
      }
      setIsUpdatingPatientPrivacySettings(false);
    },
    [formData?.id, patientPrivacySettings],
  );

  let emrPatientId: string | undefined = initData?.emrUserId;
  if ('emrPatientId' in formMode) {
    emrPatientId = formMode.emrPatientId;
  }

  const loading = useInProcess(formData?.id, ['createNewUser', 'updateUser']);
  const creationFinished = useIsFinished(formData?.id, ['createNewUser']);
  const creationFailed = useIsFailed(formData?.id, ['createNewUser']);
  const creationEndedSuccessfully = useIsEndedSuccessfully(formData?.id, [
    'createNewUser',
  ]);
  const updateEndedSuccessfully = useIsEndedSuccessfully(formData?.id, [
    'updateUser',
  ]);

  useEffect(() => {
    if (formData && updateEndedSuccessfully === true) {
      onFinish();
    }
  }, [updateEndedSuccessfully]);

  useEffect(() => {
    if (formData && creationEndedSuccessfully === true) {
      onCreatePatient(`${formData.firstName}`);
    }
  }, [creationEndedSuccessfully]);

  const onSubmit = async () => {
    setShouldValidate(true);
    if (!formData) {
      return;
    }
    const newFormErrors = patientFormValidation(formData);
    if (!newFormErrors) {
      if (!isPatientInput(formData)) {
        return;
      }
      if (mode === 'new' || mode === 'newTestUser') {
        try {
          await dispatch(
            createUserAction(UserTypes.Patient, formData, emrPatientId),
          );
        } catch (error) {
          if (error.message.includes('Email')) {
            dispatch(
              notify({
                message: error.message,
                severity: 'error',
              }),
            );
            setFormErrors({ email: error.message });
          }
        }
      } else if (mode === 'edit') {
        dispatch(updateUserAction(UserTypes.Patient, formData, formData.id));
      }
    }
  };

  if (!formData) {
    return <></>;
  }

  return (
    <CreatePatientFormView
      formData={formData}
      onChange={onChange}
      onSubmit={onSubmit}
      onCancel={getCancelWithConfirm(onCancel, isDirty)}
      formErrors={formErrors}
      loading={!!loading}
      disabled={
        mode === 'preview' || (!creationFinished && (loading || creationFailed))
      }
      showFooter={mode !== 'preview'}
      title={getTitle(formMode, UserTypes.Patient)}
      mode={formMode}
      patientPrivacySettings={patientPrivacySettings}
      handlePrivacySettingsChange={handlePrivacySettingsChange}
      isUpdatingPatientPrivacySettings={isUpdatingPatientPrivacySettings}
      emrPatientId={emrPatientId}
    />
  );
}
