import { Table } from '@tanstack/react-table';

export function getPreviousRowCellValue(rowId: string, columnId: string, table: Table<any>) {
  const previousRowId = Number(rowId) - 1;
  if (previousRowId >= 0) {
    const previousRowCellValue = table
      .getRow(String(previousRowId))
      .getAllCells()
      .find((cell) => cell.column.id === columnId)
      ?.getValue();
    return previousRowCellValue;
  }
}
