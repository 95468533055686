import { LoginService } from '../../services/loginService';
import { resetAction } from '../actions/resetAction';
import { AppThunk } from '../storeTypes';

export function logoutAction(): AppThunk {
  return async (dispatch) => {
    dispatch(resetAction());
    LoginService.getInstance().logout();
  };
}
