/** @jsxImportSource @emotion/react */
import { CSSObject } from '@emotion/react';

import { ActivityExecution } from '../../../models/ActivityExecution';
import ActivityGraph from '../activities-graph/ActivityGraph';
import { GraphDetails } from './ObjectiveTypes';
import { MeasurementsSummary } from './MeasurementsSummary';

const objectiveGraphViewCss: CSSObject = {
  height: 384,
  width: '100%',
  display: 'flex',
  padding: '0 2.3rem 1.25rem 1.25rem',
};

export function ObjectiveGraphView({
  graphDetails,
  activitiesExecutionsMap,
}: {
  graphDetails: GraphDetails;
  activitiesExecutionsMap: Record<string, ActivityExecution[]>;
}) {
  const { id, activityDefinitionId, metricKey, side, standardDeviation } =
    graphDetails;
  const activitiesResults = activitiesExecutionsMap?.[activityDefinitionId];

  return (
    <div key={id} css={{ ...objectiveGraphViewCss }}>
      <ActivityGraph
        activityDefinitionId={activityDefinitionId}
        metricKey={metricKey}
        activitiesExecutionsMap={activitiesExecutionsMap}
        metricSide={side}
        standardDeviation={standardDeviation}
      />
      <MeasurementsSummary
        activitiesResults={activitiesResults}
        metricKey={metricKey}
        metricSide={side}
      />
    </div>
  );
}
