/** @jsxImportSource @emotion/react */

import { CSSObject } from '@emotion/react';

import { colors } from '../../../style/colors';
import { fonts } from '../../../style/fonts';
import { ActivityExecution } from '../../../models/ActivityExecution';
import {
  getDisplayUnit,
  getMeasurementSystemUnit,
  getMeasurementSystemValue,
} from '../../../utils/unitUtils';
import { getLastTwoLatestResults } from './ObjectiveUtils';
import TrendIcon from '../../commons/TrendIcon';
import { useSelector } from 'react-redux';
import { getMeasurementSystemSelector } from '../../../state-manager/selectors/appSelectors';

const measurementsSummaryStyle: CSSObject = {
  border: `1px solid ${colors.dividerGrey}`,
  borderRadius: '10px',
  display: 'inline-flex',
  justifyContent: 'start',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '1rem',
  flexBasis: '180px',
  flexShrink: '0',
  margin: '2rem 0 2rem 60px',
  ...fonts.largerH1,

  '&>div': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },

  '&>span': {
    fontSize: '32px',
    fontFamily: fonts.h1?.fontFamily,
  },

  '& svg': {
    marginLeft: '0.5rem',
  },
};

export function MeasurementsSummary({
  activitiesResults,
  metricKey,
  metricSide,
}: {
  activitiesResults: ActivityExecution[] | undefined;
  metricKey: string;
  metricSide: string | undefined;
}) {
  const [previousResult, lastResult] =
    getLastTwoLatestResults(activitiesResults, metricKey, metricSide) || [];
  const measurementSystem = useSelector(getMeasurementSystemSelector);

  const measurementSystemUnit = getDisplayUnit(
    getMeasurementSystemUnit(lastResult?.unit, measurementSystem),
  );

  const measurementSystemValue = getMeasurementSystemValue(
    lastResult?.value,
    measurementSystemUnit,
    measurementSystem,
  )?.toFixed(0);

  const measurementSystemGoal =
    getMeasurementSystemValue(
      lastResult?.goal,
      measurementSystemUnit,
      measurementSystem,
    ) ?? 0;

  return (
    <div css={{ ...measurementsSummaryStyle }}>
      <div>
        <span>
          {measurementSystemValue ?? 0}
          {measurementSystemUnit}
        </span>
        <TrendIcon
          resultsToCompare={[previousResult, lastResult]}
          height={30}
        />
      </div>
      <span>Goal: {measurementSystemGoal}</span>
    </div>
  );
}
