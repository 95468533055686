// Uppercases the first letter of a piece of text
export function capitalizeString(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function formatTimeFromSecToString(seconds: number) {
  const units = [
    { label: 'year', value: 60 * 60 * 24 * 365 },
    { label: 'day', value: 60 * 60 * 24 },
    { label: 'hour', value: 60 * 60 },
    { label: 'minute', value: 60 },
    { label: 'second', value: 1 },
  ];

  for (let unit of units) {
    if (seconds >= unit.value) {
      const time = Math.floor(seconds / unit.value);
      return `${time} ${unit.label}${time > 1 ? 's' : ''}`;
    }
  }

  return '0 seconds'; // If time is less than 1 second
}
