import { createDate } from './utils/dateUtils';

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

const innerEnvs = ['mercury', 'venus', 'mars', 'localhost'];
const experimentalEnvs = ['mercury', 'localhost'];

export function isExperimentalEnvironment() {
  const host = window.location.host;
  return experimentalEnvs.some((name) => host.includes(name));
}

export function isInnerEnvironment() {
  const host = window.location.host;
  return innerEnvs.some((name) => host.includes(name));
}

export const API_VERSION = '/api/v1';

export const START_DATE_FOR_REQUESTS = createDate({ days: -3650 });

export const END_DATE_FOR_REQUESTS = createDate({ days: 3650 });

export const DATA_DOG_CLIENT = 'pub86a30ce24c65c8032b8d87cc887213c4';
