/** @jsxImportSource @emotion/react */

import { useHistory } from 'react-router-dom';

import TabsView, { Tab } from './TabsView';

export interface NavTab extends Tab {
  navigateTo: string;
  isSelected: (pathname: string) => boolean;
  keepSearchParams?: boolean;
}

export default function NavTabs({ navTabs }: { navTabs: NavTab[] }) {
  const history = useHistory();

  const pathname = history.location.pathname;

  const selectedId = navTabs.find((tab) => tab.isSelected(pathname))?.id;

  return (
    <TabsView
      tabs={navTabs}
      onClick={(id) => {
        const navTab = navTabs.find(({ id: currentId }) => currentId === id);
        return (
          navTab &&
          history.push({
            pathname: navTab.navigateTo,
            search: navTab?.keepSearchParams ? history.location.search : '',
          })
        );
      }}
      selectedId={selectedId ? selectedId : ''}
    />
  );
}
