import { isEmpty } from '../../../state-manager/utils/compare';
import { FormErrors } from '../../commons/form/useForm';
import { PatientFormData } from './create-patient-form/patientFormTypes';
import { ClinicianFormData, UserTypes } from './CreateUserUtils';

const REQUIRED = 'Required';
const REQUIRED_FIELDS_FOR_PATIENT: (keyof PatientFormData)[] = ['firstName', 'height'];
const REQUIRED_FIELDS_FOR_TEST_USER: (keyof PatientFormData)[] = [
  'weight',
  'medicalCondition',
  'gender',
  'ethnicity',
  'surgicalLeg',
];
const REQUIRED_FIELDS_FOR_CLINICIAN: (keyof ClinicianFormData)[] = [
  'firstName',
  'lastName',
  'email',
];
const SHORTEST_PERSON_CM = 65;
const TALLEST_PERSON_CM = 230;
const FATTEST_PERSON_KG = 250;
const THINNEST_PERSON_KG = 20;
const EMAIL_TEST =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function requiredFieldsValidation(
  formData: PatientFormData & ClinicianFormData,
  formErrors: FormErrors<PatientFormData & ClinicianFormData>,
) {
  if (formData.role === UserTypes.Patient) {
    REQUIRED_FIELDS_FOR_PATIENT.forEach((fieldName) => {
      if (isEmpty(formData[fieldName])) {
        formErrors[fieldName] = REQUIRED;
      }
    });
    if (formData.isTestUser) {
      REQUIRED_FIELDS_FOR_TEST_USER.forEach((fieldName) => {
        if (isEmpty(formData[fieldName])) {
          formErrors[fieldName] = REQUIRED;
        }
      });
    }
    if (!formData.isTestUser && isEmpty(formData.email)) {
      formErrors.email = REQUIRED;
    }
    if (!formData.isTestUser && isEmpty(formData.lastName)) {
      formErrors.lastName = REQUIRED;
    }
  }

  if (formData.role === UserTypes.Clinician) {
    REQUIRED_FIELDS_FOR_CLINICIAN.forEach((fieldName) => {
      if (isEmpty(formData[fieldName])) {
        formErrors[fieldName] = REQUIRED;
      }
    });
  }
}

function nameValidation(formData: PatientFormData, formErrors: FormErrors<PatientFormData>) {
  if (
    formData.isTestUser &&
    formData.firstName &&
    !/^[A-Z]{2}\d{6}$/.test(formData.firstName)
  ) {
    formErrors.firstName = 'Name should contain 2 uppercase letters followed by 6 digits';
  }
}

function heightValidation(formData: PatientFormData, formErrors: FormErrors<PatientFormData>) {
  if (!isEmpty(formData.height)) {
    if (SHORTEST_PERSON_CM > formData.height || TALLEST_PERSON_CM < formData.height) {
      formErrors.height = 'Height should be within the normal range';
    }
  }
}

function weightValidation(formData: PatientFormData, formErrors: FormErrors<PatientFormData>) {
  if (!isEmpty(formData.weight)) {
    if (THINNEST_PERSON_KG > formData.weight || FATTEST_PERSON_KG < formData.weight) {
      formErrors.weight = 'Weight should be within the normal range';
    }
  }
}

export const validateEmail = (email: string) => {
  return String(email).toLowerCase().match(EMAIL_TEST);
};

function emailValidation(formData: PatientFormData, formErrors: FormErrors<PatientFormData>) {
  if (!formData.isTestUser && formData.email && !validateEmail(formData.email)) {
    formErrors.email = 'Please enter a valid email address';
  }
}

function dateOfBirthValidation(
  formData: PatientFormData,
  formErrors: FormErrors<PatientFormData>,
) {
  if (formData.dateOfBirth) {
    const now = new Date();
    const dob = new Date(formData.dateOfBirth);

    const ageInMs = now.getTime() - dob.getTime();

    // 120 years in milliseconds
    const msInAYear = 365.25 * 24 * 60 * 60 * 1000;
    const maxAgeInMs = 120 * msInAYear;
    if (ageInMs < 0 || ageInMs > maxAgeInMs) {
      formErrors.dateOfBirth = 'Please enter a valid age between 0 and 120.';
    }
  }
}

export function patientFormValidation(formData: PatientFormData) {
  const formErrors: FormErrors<PatientFormData> = {};
  nameValidation(formData, formErrors);
  emailValidation(formData, formErrors);
  dateOfBirthValidation(formData, formErrors);
  heightValidation(formData, formErrors);
  weightValidation(formData, formErrors);
  requiredFieldsValidation(formData, formErrors);
  if (Object.keys(formErrors).length === 0) {
    return undefined;
  }
  return formErrors;
}

export function clinicianFormValidation(formData: PatientFormData) {
  const formErrors: FormErrors<PatientFormData> = {};
  emailValidation(formData, formErrors);
  requiredFieldsValidation(formData, formErrors);

  if (Object.keys(formErrors).length === 0) {
    return undefined;
  }
  return formErrors;
}
