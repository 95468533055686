/** @jsxImportSource @emotion/react */

import React, { ChangeEvent, useEffect, useState } from 'react';
import { CSSObject } from '@emotion/react';

import { buttons } from '../../../style/buttons';
import { colors } from '../../../style/colors';
import { fonts } from '../../../style/fonts';
import { shapes } from '../../../style/shapes';
import EditIcon from '../../../assets/edit.svg';
import { InputFieldPropsType } from './basic';
import { isDate, toDatePickerFormat } from '../../../utils/dateUtils';

const editableInputCss: CSSObject = {
  display: 'flex',
  alignItems: 'baseline',
  gap: '14px',
  ...fonts.smallLabel,
  border: `1px solid ${colors.blue3}`,
  borderRadius: shapes.borderRadius,
  padding: '20px 10px',
  transform: 'translate(0, 30px)',
  backgroundColor: colors.backgroundDarkerGrey,
  zIndex: 1,

  label: {
    marginTop: '12px',
  },

  '& > div:first-of-type': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },

  '.input-container': {
    position: 'relative',

    '.error-message': {
      position: 'absolute',
      top: '-15px',
      left: 0,
      color: colors.red,
    },
    '.input': {
      backgroundColor: colors.white,
      borderRadius: '4px',
    },
    '.error': {
      borderColor: colors.red,
    },
  },

  '.buttons-container': {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default function EditableInput({
  label,
  value,
  onChange,
  error,
  ...props
}: {
  label?: string;
  value: string | Date;
  onChange: (newValue: string) => void;
  error?: string;
} & InputFieldPropsType) {
  const [isEditing, setIsEditing] = useState(false);
  const [origValue, setOrigValue] = useState<string>('');

  const { type } = props;

  useEffect(() => {
    if (isEditing) {
      setOrigValue(isDate(value) ? toDatePickerFormat(value) : value);
    }
  }, [isEditing]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setIsEditing(false);
    }
  };

  return (
    <>
      {isEditing ? (
        <div css={editableInputCss}>
          <label htmlFor={label?.toLocaleLowerCase()}>{label}</label>
          <div>
            <div className="input-container">
              {error && <span className="error-message">{error}</span>}
              <input
                onKeyDown={handleKeyDown}
                value={isDate(value) ? toDatePickerFormat(value) : value}
                className={`input ${error ? 'error' : ''}`}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onChange(event.currentTarget.value)
                }
                {...props}
                id={label?.toLowerCase()}
              />
            </div>
            <div className="buttons-container">
              <button
                type="button"
                css={{ ...buttons.secondarySmall }}
                onClick={() => {
                  setIsEditing(false);
                  onChange(origValue);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                css={{ ...buttons.primarySmall }}
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <div css={{ display: 'flex', gap: '8px', alignItems: 'baseline' }}>
            <span
              css={{
                ...fonts.modalHeader,
                ...(type === 'date' && isEditing
                  ? { fontWeight: 400, fontSize: '20px' }
                  : {}),
              }}
            >
              {isDate(value) ? value.toLocaleDateString() : value}
            </span>
            <button
              type="button"
              onClick={() => setIsEditing((previousValue) => !previousValue)}
              aria-label={`Edit ${label}`}
            >
              <EditIcon height={15} width={15} color={colors.blue3} />
            </button>
          </div>
          {error && <div className="error-msg">{error}</div>}
        </div>
      )}
    </>
  );
}
