import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TreatmentPlansState } from '../storeTypes';
import { resetAction } from '../actions/resetAction';
import { MovementResultsDto, TreatmentPlanDto } from '../../types/backendType';

const initialState: TreatmentPlansState = {
  treatmentPlans: {},
  movementsResults: {},
};

const treatmentPlansSlice = createSlice({
  name: 'treatmentPlansSlice',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
  reducers: {
    setTreatmentPlansAction: (
      draftState: TreatmentPlansState,
      action: PayloadAction<{ treatmentPlansDto: TreatmentPlanDto[]; patientId: string }>,
    ) => {
      const { treatmentPlansDto, patientId } = action.payload;
      treatmentPlansDto.sort(
        (
          { start_date: startDateStrA, assigned_date: assignedDateStrA },
          { start_date: startDateStrB, assigned_date: assignedDateStrB },
        ) => {
          const dateA = new Date(startDateStrA);
          const dateB = new Date(startDateStrB);
          if (dateA.getTime() === dateB.getTime()) {
            return new Date(assignedDateStrA) < new Date(assignedDateStrB) ? 1 : -1;
          }
          return dateA < dateB ? 1 : -1;
        },
      );
      draftState.treatmentPlans[patientId] = treatmentPlansDto;
    },
    setMovementsResultsAction: (
      draftState: TreatmentPlansState,
      action: PayloadAction<{ patientId: string; movementsResults: MovementResultsDto[] }>,
    ) => {
      const { movementsResults, patientId } = action.payload;
      if (!draftState.movementsResults[patientId]) {
        draftState.movementsResults[patientId] = movementsResults;
      } else {
        movementsResults.forEach((movementsResult) => {
          if (
            !draftState.movementsResults[patientId]?.find(
              ({ session_id, sub_session_id }) =>
                session_id === movementsResult.session_id &&
                movementsResult.sub_session_id === sub_session_id,
            )
          ) {
            draftState.movementsResults[patientId]?.push(movementsResult);
          }
        });
      }
    },
  },
});

export const { setTreatmentPlansAction, setMovementsResultsAction } =
  treatmentPlansSlice.actions;

export default treatmentPlansSlice;
