import { createSelector } from '@reduxjs/toolkit';
import { getTreatmentPlanTemplatesDtoSelector } from '../../state-manager/selectors/treatmentPlansSelectors';
import { getActivityDefinitionsSelector } from '../../state-manager/selectors/librarySelectors';
import { TreatmentPlanTemplate, TreatmentPlanTemplateId } from '../TreatmentPlanTemplate';

export const getTreatmentPlanTemplatesSelector = createSelector(
  [getTreatmentPlanTemplatesDtoSelector, getActivityDefinitionsSelector],
  (treatmentPlanTemplatesDtoMap, activityDefinitions) => {
    const treatmentPlanTemplatesMap: Record<TreatmentPlanTemplateId, TreatmentPlanTemplate> =
      {};
    Object.values(treatmentPlanTemplatesDtoMap).forEach((treatmentPlanTemplateDto) => {
      treatmentPlanTemplatesMap[treatmentPlanTemplateDto.treatment_plan_id] =
        new TreatmentPlanTemplate({ treatmentPlanTemplateDto, activityDefinitions });
    });
    return treatmentPlanTemplatesMap;
  },
);
