import { AnyAction, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';

import { AppNotification } from '../types/appTypes';
import { RequestStatus } from './utils/requestStatus';
import { ActivityDefinition, ActivityDefinitionId } from '../types/library';
import {
  CaseDto,
  MovementResultsDto,
  TreatmentPlanDto,
  TreatmentPlanTemplateDto,
  UserDto,
  UserRole,
} from '../types/backendType';
import { TreatmentPlanId } from '../models/TreatmentPlan';
import { TreatmentPlanTemplateId } from '../models/TreatmentPlanTemplate';
import { UserId } from '../models/User';

export type TreatmentPlansState = {
  treatmentPlans: Record<UserId, TreatmentPlanDto[]>;
  movementsResults: Record<UserId, MovementResultsDto[]>;
};

export type UsersState = {
  users: Record<UserId, UserDto>;
};

export type CasesState = {
  cases: Record<UserId, CaseDto[]>;
};

export type NotificationsState = {
  notifications: AppNotification[];
};

export const requestStatusNames = [
  'user',
  'fetchActivitiesResultsByUserId',
  'fetchAssignTreatmentPlans',
  'deleteTreatmentPlan',
  'deleteTreatmentPlanTemplate',
  'fetchActivityDefinitions',
  'createTreatmentPlanTemplate',
  'editTreatmentPlanTemplate',
  'fetchTreatmentPlanTemplates',
  'assignTreatmentPlan',
  'createNewUser',
  'updateUser',
  'updateTreatmentPlan',
  'saveAndAssignTreatmentPlan',
  'fetchCases',
  'createCase',
  'fetchUsers',
] as const;

export type RequestStatusName = (typeof requestStatusNames)[number];

export type AppState = {
  userId?: UserId;
  role?: UserRole;
  currentPatientId: UserId | null | undefined;
  selectedCaseId: string | null;
  currentTreatmentPlanId: TreatmentPlanId | undefined;
  requestsStatus: Record<RequestStatusName, RequestStatus>;
  measurementSystem: string;
  editTemplateId?: TreatmentPlanId | undefined;
};

export type LibraryState = {
  treatmentPlanTemplates: Record<TreatmentPlanTemplateId, TreatmentPlanTemplateDto>;
  activityDefinitions: Record<ActivityDefinitionId, ActivityDefinition>;
};

export type RootState = {
  appState: AppState;
  usersState: UsersState;
  treatmentPlansState: TreatmentPlansState;
  notificationsState: NotificationsState;
  libraryState: LibraryState;
  casesState: CasesState;
};

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export type Dispatch = ThunkDispatch<RootState, unknown, AnyAction>;
