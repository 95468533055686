import { MovementDefinitionDto } from '../types/backendType';
import httpClient from './httpClient';

// GET /movement/new-library
export async function fetchMovementDefinitions(): Promise<
  MovementDefinitionDto[] | undefined
> {
  try {
    const response = await httpClient.get<MovementDefinitionDto[]>('/movement/new-library');
    const movementDefinitionDtoArray = response.data;
    return movementDefinitionDtoArray;
  } catch (e) {
    throw new Error(`Failed to fetch movement definitions: ${e}`);
  }
}
