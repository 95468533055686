import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CasesState } from '../storeTypes';
import { resetAction } from '../actions/resetAction';
import { CaseDto } from '../../types/backendType';

const initialState: CasesState = {
  cases: {},
};

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
  reducers: {
    setCasesAction: (
      draftState: CasesState,
      action: PayloadAction<{ cases: CaseDto[]; patientId: string }>,
    ) => {
      const { patientId, cases } = action.payload;
      draftState.cases[patientId] = cases;
    },
    addCaseAction: (
      draftState: CasesState,
      action: PayloadAction<{ newCase: CaseDto; patientId: string }>,
    ) => {
      const { patientId, newCase } = action.payload;
      draftState.cases[patientId] = [...(draftState.cases[patientId] ?? []), newCase];
    },
  },
});

export const { setCasesAction, addCaseAction } = usersSlice.actions;

export default usersSlice;
