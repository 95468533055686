/** @jsxImportSource @emotion/react */

import { CSSObject } from '@emotion/react';
import { ActivityDefinition } from '../../../../types/library';
import DisplayField from '../../../commons/form/DisplayField';
import { Activity } from '../../../../models/Activity';
import { ActivityDefinitionPreview } from './ActivityDefinitionPreview';
import { isEmpty } from '../../../../state-manager/utils/compare';
import {
  getMetricMathDisplayText,
  getUnitAsDisplayText,
  getMeasurementSystemUnit,
  getUnitInputPropValue,
  getMeasurementSystemValue,
} from '../../../../utils/unitUtils';
import { useSelector } from 'react-redux';
import { getMeasurementSystemSelector } from '../../../../state-manager/selectors/appSelectors';
import { fonts } from '../../../../style/fonts';
import { colors } from '../../../../style/colors';

const LABEL_WIDTH = '360px';

const activityPreviewCss: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  padding: '20px',

  '.activity-definition-preview': {
    maxWidth: '509px',
    minWidth: '509px',
    height: '410px',
  },
  '.activity-details-info': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    paddingLeft: '15px',
  },

  '.field-grouping': {
    label: {
      width: LABEL_WIDTH,
    },
    div: {
      width: `calc(100% - ${LABEL_WIDTH})`,
    },
  },

  '.goal-metric': {
    display: 'flex',
    gap: '20px',
  },
};

function getRenderData(activity: Activity | ActivityDefinition) {
  if ('activityDefinition' in activity) {
    return {
      bodyParts: activity.activityDefinition?.bodyParts.toString(),
      type: activity.type?.[0],
      description: activity.comments,
      sets: activity.sets,
      reps: activity.reps,
      hold: activity.hold,
      rest: activity.rest,
      duration: activity.duration,
      metrics: activity.metrics,
      activityDefinition: activity.activityDefinition,
      metricName: activity?.activityDefinition?.postExerciseMetric?.find(
        ({ metric_name }) =>
          metric_name === activity?.activityDefinition?.repOn?.[1],
      )?.display_name,
      metricUnit: activity.activityDefinition?.repOn?.[0],
      minimumToConsiderRep: activity.minimumToConsiderRep,
    };
  } else {
    return {
      bodyParts: activity.bodyParts.toString(),
      type: activity.settings.type?.options[0],
      description: activity.description,
      sets: activity.settings.sets?.default,
      reps: activity.settings.reps?.default,
      hold: activity.settings.hold?.default,
      rest: activity.settings.rest?.default,
      duration: activity.settings.duration?.default,
      activityDefinition: activity,
      metricName: activity.postExerciseMetric?.find(
        ({ metric_name }) => metric_name === activity.repOn?.[1],
      )?.display_name,
      metricUnit: activity.repOn?.[0],
      minimumToConsiderRep:
        activity.settings?.minimum_to_consider_rep?.options?.[0],
    };
  }
}

export const tableStyle: CSSObject = {
  width: '100%',
  borderCollapse: 'collapse',
  margin: '20px 0',
  textAlign: 'left',
};

export const headerStyle: CSSObject = {
  ...fonts.largeLabel,
  border: `1px solid ${colors.dividerGrey}`,
  padding: '12px 12px',
};

export const cellStyle: CSSObject = {
  padding: '12px 12px',
  border: `1px solid ${colors.dividerGrey}`,
  textTransform: 'capitalize',
};

export default function ActivityPreview({
  activity,
}: {
  activity: Activity | ActivityDefinition;
}) {
  const activityData = getRenderData(activity);
  const isGoals =
    activityData.activityDefinition?.postExerciseMetric &&
    activityData.activityDefinition?.postExerciseMetric.length > 0 &&
    activityData.activityDefinition?.postExerciseMetric.find(
      (metric) => metric.metric_sd !== 'NA',
    );

  const measurementSystem = useSelector(getMeasurementSystemSelector);

  const repThresholdMeasurementSystemUnit = getMeasurementSystemUnit(
    activityData.metricUnit,
    measurementSystem,
  );
  const isGoalsDefined = activityData.metrics?.find(
    (metric_data) => metric_data.goal,
  );

  return (
    <div css={activityPreviewCss}>
      <div className="activity-details-info">
        <DisplayField label="Body part" value={activityData.bodyParts} />
        <DisplayField label="Category" value={activityData.type} />
        {!isEmpty(activityData.sets) && (
          <DisplayField label="Sets" value={activityData.sets} />
        )}
        {!isEmpty(activityData.reps) && (
          <DisplayField label="Reps per Sets" value={activityData.reps} />
        )}
        {!isEmpty(activityData.hold) && (
          <DisplayField label="Hold (sec)" value={activityData.hold} />
        )}
        {!isEmpty(activityData.rest) && (
          <DisplayField label="Rest (sec)" value={activityData.rest} />
        )}
        {!isEmpty(activityData.duration) && (
          <DisplayField label="Duration (sec)" value={activityData.duration} />
        )}
        {activityData.minimumToConsiderRep && (
          <DisplayField
            label={`Repetition Threshold (${activityData.metricName} 
            ${repThresholdMeasurementSystemUnit})`}
            value={activityData.minimumToConsiderRep}
            unit={getUnitInputPropValue(
              activityData.metricUnit,
              measurementSystem,
            )}
          />
        )}

        {isGoals ? (
          <DisplayField label="Goals" value={''}>
            <table css={tableStyle}>
              <thead>
                <tr>
                  <th css={headerStyle}>Activity</th>
                  {isGoalsDefined && <th css={headerStyle}>Goal</th>}
                  <th css={headerStyle}>Unit</th>
                  <th css={headerStyle}>Range</th>
                  <th css={headerStyle}>Error Range</th>
                  <th css={headerStyle}>Measure</th>
                </tr>
              </thead>
              <tbody>
                {activityData.activityDefinition?.postExerciseMetric?.map(
                  (metric) => {
                    let goal =
                      activityData.metrics && activityData.metrics.length > 0
                        ? activityData.metrics?.find(
                            (metric_data) =>
                              metric_data.name == metric.display_name,
                          )?.goal
                        : null;
                    const measurementSystemUnit = getMeasurementSystemUnit(
                      metric.unit,
                      measurementSystem,
                    );
                    const measurementSystemSd =
                      metric.metric_sd !== 'NA'
                        ? getMeasurementSystemValue(
                            metric.metric_sd,
                            measurementSystemUnit,
                            measurementSystem,
                          )
                        : null;
                    const math = getMetricMathDisplayText(
                      metric['Metric math'],
                    );
                    const measurementSystemGoal = goal
                      ? getMeasurementSystemValue(
                          goal,
                          measurementSystemUnit,
                          measurementSystem,
                        )
                      : null;
                    if (measurementSystemSd) {
                      return (
                        <tr key={metric.display_name}>
                          <td css={{ ...cellStyle, ...fonts.largeLabel }}>
                            {metric.display_name}
                          </td>
                          {isGoalsDefined && (
                            <td css={cellStyle}>{measurementSystemGoal}</td>
                          )}
                          <td css={cellStyle}>
                            {getUnitAsDisplayText(measurementSystemUnit)}
                          </td>
                          <td css={cellStyle}>{`${getMeasurementSystemValue(
                            metric.min,
                            measurementSystemUnit,
                            measurementSystem,
                          )}-${getMeasurementSystemValue(
                            metric.max,
                            measurementSystemUnit,
                            measurementSystem,
                          )}`}</td>

                          <td css={cellStyle}>{`±${measurementSystemSd}`}</td>
                          <td css={cellStyle}>{math}</td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  },
                )}
              </tbody>
            </table>
          </DisplayField>
        ) : null}
      </div>
      {activityData.activityDefinition && (
        <ActivityDefinitionPreview
          activityDefinition={activityData.activityDefinition}
        />
      )}
    </div>
  );
}
