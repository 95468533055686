import { User } from '../../../models/User';
import { UserRole } from '../../../types/backendType';
import { capitalizeString } from '../../../utils/stringUtils';
import { AskUser } from '../../commons/AskUser';
import { FormMode } from '../../commons/form/formUtils';
import {
  editableFields,
  PatientFormData,
  PatientFormMode,
  PatientInput,
} from './create-patient-form/patientFormTypes';

const LEAVING_MSG = 'Are you sure you want to leave? Unsaved changes will be lost.';

export enum UserTypes {
  Patient = 'patient',
  Clinician = 'clinician',
}

export type ClinicianInput = {
  id: string;
  creationDate: Date;
  isTestUser: boolean;
  firstName: string;
  lastName: string;
  email: string;
  prefix?: string;
  role: UserRole;
};

export type ClinicianFormData = Partial<ClinicianInput> & {
  id: string;
  creationDate: Date;
  isTestUser: boolean;
  role: UserRole;
};

const clinicianEditableFields: (keyof ClinicianInput)[] = ['firstName', 'lastName', 'prefix'];

export function isEditableFields(
  type: UserRole,
  name: string,
): name is keyof PatientInput | keyof ClinicianInput {
  if (type === UserTypes.Clinician) {
    return clinicianEditableFields.includes(name as keyof ClinicianInput);
  }
  return editableFields.includes(name as keyof PatientInput);
}

export function isDisabled(
  type: UserRole,
  name: keyof PatientFormData | keyof ClinicianFormData,
  disabled: boolean,
  mode: string,
) {
  if (disabled) {
    return true;
  }
  if (mode === 'edit') {
    return !isEditableFields(type, name);
  }
}

export function getCancelWithConfirm(onCancel: (() => void) | undefined, isDirty: boolean) {
  if (onCancel) {
    const onCancelWithConfirm = async () => {
      if (!isDirty) {
        onCancel();
        return;
      }
      const userAnswer = await AskUser.getInstance().askUser({
        msg: LEAVING_MSG,
        answers: ['Cancel', 'Yes'],
      });
      if (userAnswer === 'Yes') {
        onCancel();
      }
    };
    return onCancelWithConfirm;
  }
}

export function getTitle(mode: FormMode<User> | PatientFormMode, userType: UserRole) {
  const capitalizedUser = capitalizeString(userType);
  switch (mode.mode) {
    case 'new':
      return `Add ${capitalizedUser}`;
    case 'newTestUser':
      return 'Add Test Patient';
    case 'preview':
      return `${capitalizedUser} info`;
    case 'edit':
      return `Edit ${capitalizedUser}`;
  }
}
