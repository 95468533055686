/** @jsxImportSource @emotion/react */

import { createSelector } from '@reduxjs/toolkit';
import { getUsersSelector } from '../../models/factories/userFactories';
import { User } from '../../models/User';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import TableView from '../commons/TableView';
import { createColumnHelper } from '@tanstack/react-table';
import EditIcon from '@mui/icons-material/Edit';
import ViewEyeIcon from '../../assets/viewEye.svg';
import { CSSObject } from '@emotion/react';
import { getFormattedName } from './ManagementUtils';
import { FormMode } from '../commons/form/formUtils';
import CreateUserModal from '../forms/create-user-form/CreateUserModal';

const getCliniciansRowsData = createSelector(
  [getUsersSelector],
  (users): User[] => {
    return Object.values(users).filter((user) => user.isClinician());
  },
);

type CliniciansListProps = {
  nameSearchTerm: string | undefined;
  clinicianFormMode: FormMode<User> | undefined;
  setClinicianFormMode: (clinicianFormMode: FormMode<User> | undefined) => void;
  setNameSearchTerm: ((name: string) => void) | undefined;
};

const cliniciansTableCss: CSSObject = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',

  '.table-button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  '.centrally-algned-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function getColumns(
  setClinicianFormMode: (clinicianFormMode: FormMode<User> | undefined) => void,
) {
  const columnHelper = createColumnHelper<User>();
  const columns = [
    columnHelper.accessor((row) => getFormattedName(row), {
      header: 'Clinician Name',
      size: 393,
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: 'alphanumeric',
      sortUndefined: false,
      cell: (cell) => {
        return <span>{cell.getValue()}</span>;
      },
    }),
    columnHelper.accessor((row) => row.email, {
      header: 'Email',
      size: 1139,
      enableColumnFilter: false,
      enableSorting: false,
      cell: (cell) => {
        return <span>{cell.getValue()}</span>;
      },
    }),
    columnHelper.accessor(() => '', {
      header: 'Details',
      size: 154,
      cell: (cell) => {
        return (
          <button
            type="button"
            className="table-button"
            onClick={(e) => {
              e.stopPropagation();
              setClinicianFormMode({
                mode: 'preview',
                initData: cell.row.original,
              });
            }}
          >
            <ViewEyeIcon height="24" width="24" />
          </button>
        );
      },
      enableResizing: false,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor(() => '', {
      header: 'Edit',
      size: 149,
      cell: (cell) => {
        return (
          <button
            type="button"
            className="table-button"
            onClick={(e) => {
              e.stopPropagation();
              setClinicianFormMode({
                mode: 'edit',
                initData: cell.row.original,
              });
            }}
          >
            <EditIcon />
          </button>
        );
      },
      enableResizing: false,
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];
  return columns;
}

export default function CliniciansList({
  nameSearchTerm,
  clinicianFormMode,
  setClinicianFormMode,
  setNameSearchTerm,
}: CliniciansListProps) {
  const cliniciansRowsData = useSelector(getCliniciansRowsData);
  const columns = useMemo(() => getColumns(setClinicianFormMode), []);
  const initSorting = [{ id: 'Clinician Name', desc: false }];

  const TableViewWithType = TableView<User>;

  return (
    <div css={cliniciansTableCss}>
      <TableViewWithType
        tableId="CliniciansTable"
        data={cliniciansRowsData}
        columns={columns}
        globalFilter={nameSearchTerm}
        onRowClick={() => {}}
        getRowId={(data) => data.id}
        initSorting={initSorting}
        protectedColumnsIdSorted={['Clinician Name']}
      />
      <CreateUserModal
        type="clinician"
        formMode={clinicianFormMode}
        setFormMode={setClinicianFormMode}
        onCreateUser={setNameSearchTerm}
      />
    </div>
  );
}
