/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react';
import { CSSObject } from '@emotion/react';
import { LazyLoadImage, ScrollPosition } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { CDN_BASE_URL } from '../../externalConfig';
import { ActivityDefinition } from '../../types/library';
import { shapes } from '../../style/shapes';

export function getActivityImageURL(activityDefinition: ActivityDefinition) {
  // eslint-disable-next-line max-len
  return `${CDN_BASE_URL}/media/movements/thumbnails/${activityDefinition.id}.jpg?v=3`;
}

function getActivityGifURL(activityDefinition: ActivityDefinition) {
  // eslint-disable-next-line max-len
  return `${CDN_BASE_URL}/media/movements/images/${activityDefinition.id}.gif?v=3`;
}

const activityThumbnailCss: CSSObject = {
  overflow: 'hidden',
  width: '100%',
  'img, .placeholder': {
    width: '100%',
    border: shapes.border,
    borderRadius: '8px',
    aspectRatio: '838/473',
  },
};

export default function ActivityThumbnail({
  activityDefinition,
  scrollPosition,
  width,
  height,
}: {
  activityDefinition: ActivityDefinition;
  scrollPosition?: ScrollPosition | undefined;
  width?: number | string;
  height?: number | string;
}) {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isGifLoaded, setIsGifLoaded] = useState<boolean>(false);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!isImageLoaded || !isHovered) {
      return;
    }
    const img = new Image();
    img.src = getActivityGifURL(activityDefinition);
    img.onload = () => {
      setIsGifLoaded(true);
    };
  }, [isImageLoaded, isHovered]);

  return (
    <div
      className="activity-thumbnail"
      css={activityThumbnailCss}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <LazyLoadImage
        src={
          isHovered && isGifLoaded
            ? getActivityGifURL(activityDefinition)
            : getActivityImageURL(activityDefinition)
        }
        onLoad={() => {
          setIsImageLoaded(true);
        }}
        scrollPosition={scrollPosition}
        height={height}
        width={width}
        effect="blur"
      />
    </div>
  );
}
