/** @jsxImportSource @emotion/react */

import { Checkbox, MenuItem, Select as MuiSelect } from '@mui/material';
import ChevronIcon from '../../../assets/chevron.svg';
import { colors } from '../../../style/colors';
import { CSSObject } from '@emotion/react';
import React, { memo } from 'react';

const selectCss: CSSObject = {
  overflow: 'hidden',
  minWidth: '100px',
  height: '40px',

  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.blue3,
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline ': {
    borderWidth: '1px',
  },
};

const CustomIcon = memo((props) => {
  return (
    <button
      {...props}
      css={{
        padding: '16px 13px',
        backgroundColor: colors.blue3,
        top: '0 !important',
        right: '0 !important',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ChevronIcon
        height={14}
        width={8}
        css={{ transform: 'rotate(90deg)', color: colors.white }}
      />
    </button>
  );
});

export type SelectOption<T> = { label: string; value: T };

type SelectPropTypes<T> = {
  name: string;
  value: T[];
  onChange: (value: T[]) => void;
  options?: SelectOption<T>[];
  isMultiSelect?: boolean;
  disabledOptions?: SelectOption<T>[];
  children?: React.ReactNode;
  customRenderValue?: (value: T[]) => React.ReactNode;
  menuProps?: Object;
};

// Currently only supports multiselect
// but can be extended
export default function Select<T>({
  name,
  value,
  onChange,
  options,
  disabledOptions,
  children,
  customRenderValue,
  menuProps,
}: SelectPropTypes<T>) {
  return (
    <MuiSelect
      sx={selectCss}
      className="select-input"
      multiple
      name={name}
      value={value}
      renderValue={(selectedValues) =>
        customRenderValue
          ? customRenderValue(selectedValues)
          : selectedValues
              .map((selectedValue) => {
                return options?.find((option) => option.value === selectedValue)
                  ?.label;
              })
              .join(', ')
      }
      onChange={(event) => {
        const eventValue = event.target.value;
        if (typeof eventValue !== 'string') {
          onChange(eventValue);
        }
      }}
      IconComponent={CustomIcon}
      MenuProps={menuProps}
    >
      {children && children}
      {!children &&
        options?.map((option) => {
          return (
            <MenuItem
              key={option.label}
              // Known issue - https://github.com/mui/material-ui/issues/14286
              value={option.value as any}
              sx={{
                '&.Mui-selected, &.Mui-focusVisible, &.Mui-selected:hover': {
                  backgroundColor: 'unset',
                },
              }}
              disabled={disabledOptions?.includes(option)}
            >
              <Checkbox checked={value.indexOf(option.value) > -1} />
              {option.label}
            </MenuItem>
          );
        })}
    </MuiSelect>
  );
}
