export type CompareFn<Obj> = (a: Obj, b: Obj) => number;

export function comparisonRoutine<Obj, Val>(
  a: Obj,
  b: Obj,
  valA: Val,
  valB: Val,
  nextCompareFn?: CompareFn<Obj>,
) {
  if (valA > valB) {
    return 1;
  } else if (valA < valB) {
    return -1;
  } else {
    if (nextCompareFn) {
      return nextCompareFn(a, b);
    } else {
      return 0;
    }
  }
}

export function isEmpty(
  value: string | number | boolean | Date | undefined | null,
): value is undefined | null | '' {
  return value === undefined || value === null || value === '';
}
