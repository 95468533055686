/** @jsxImportSource @emotion/react */

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import EditIcon from '@mui/icons-material/Edit';

import { User } from '../../../models/User';
import TableView from '../../commons/TableView';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import format from 'date-fns/format';
import { colors } from '../../../style/colors';
import { fonts } from '../../../style/fonts';
import { CSSObject } from '@emotion/react';
import { getFormattedName } from '../ManagementUtils';

const PatientNameCss: CSSObject = {
  color: colors.basicBlue,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
};

function getColumns({
  onViewPatientDetailsClick,
  onEditPatientDetailsClick,
}: {
  onViewPatientDetailsClick: (patient: User) => void;
  onEditPatientDetailsClick: (patient: User) => void;
}) {
  const columnHelper = createColumnHelper<User>();
  const columns = [
    columnHelper.accessor((row) => getFormattedName(row), {
      header: 'Patient Name',
      size: 345,
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: 'alphanumeric',
      sortUndefined: false,
      cell: (cell) => (
        <span css={{ ...PatientNameCss, ...fonts.largeLabel }}>
          {cell.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor((row) => row.emrUserId, {
      header: 'EMR Patient ID',
      size: 181,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.dateOfBirth, {
      header: 'DOB',
      size: 182,
      cell: (cell) => {
        const cellValue = cell.getValue();
        return cellValue && format(cellValue, 'dd/MM/yyyy');
      },
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.email, {
      header: 'Email',
      size: 350,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.creationDate, {
      header: 'Registration Date',
      size: 350,
      cell: (cell) => {
        return cell.getValue().toLocaleDateString();
      },
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor(() => '', {
      header: 'Details',
      size: 91,
      cell: (cell) => {
        return (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onViewPatientDetailsClick(cell.row.original);
            }}
          >
            <PersonSearchIcon />
          </button>
        );
      },
      enableResizing: false,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor(() => '', {
      header: 'Edit',
      size: 91,
      cell: (cell) => {
        return (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onEditPatientDetailsClick(cell.row.original);
            }}
          >
            <EditIcon />
          </button>
        );
      },
      enableResizing: false,
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];
  return columns;
}

const TableViewWithType = TableView<User>;

export default function PatientsTableView({
  patientsRowsData,
  onRowClick,
  nameSearchTerm,
  // loading,
  onViewPatientDetailsClick,
  onEditPatientDetailsClick,
}: {
  patientsRowsData: User[];
  onRowClick: (data: User) => void;
  nameSearchTerm?: string;
  // loading: boolean;
  onViewPatientDetailsClick: (patient: User) => void;
  onEditPatientDetailsClick: (patient: User) => void;
}) {
  const columns = useMemo(
    () => getColumns({ onViewPatientDetailsClick, onEditPatientDetailsClick }),
    [],
  );

  const initSorting = [{ id: 'Patient Name', desc: false }];

  return (
    <TableViewWithType
      tableId="PatientsTable"
      data={patientsRowsData}
      columns={columns}
      globalFilter={nameSearchTerm}
      // setGlobalFilter={setGlobalFilter}
      onRowClick={onRowClick}
      getRowId={(data) => data.id}
      initSorting={initSorting}
      protectedColumnsIdSorted={['Patient Name']}
    />
  );
}
