/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { CSSObject } from '@emotion/react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { colors } from '../../../style/colors';
import Chevron from '../../../assets/chevron.svg';

const BORDER_WIDTH = 1;

const activityItemsFilter: CSSObject = {
  fontWeight: '500',
  fontSize: '14px',
  display: 'inline-flex',
  marginBottom: '19px',

  '& > button': {
    borderStyle: 'solid',
    padding: '10px 12px',
    borderWidth: BORDER_WIDTH,
    marginRight: -1 * BORDER_WIDTH,
  },

  '& > button, svg': {
    color: colors.blue4,
    '&.selected, &:hover': {
      color: colors.blue2,
      zIndex: 1,
    },
  },
};

const activityItemsDateFilterCss: CSSObject = {
  ...activityItemsFilter,
  '& > button:first-of-type': {
    borderRadius: '4px',
  },
};

const typeFiltersContainerCss: CSSObject = {
  ...activityItemsFilter,
  '& > button:first-of-type': {
    borderRadius: '4px 0 0 4px',
  },

  '& > button:last-of-type': {
    borderRadius: '0 4px 4px 0',
  },
};

const typeFiltersMap: { label: string; id: string }[] = [
  {
    label: 'All Measurements',
    id: 'allMeasurements',
  },
  {
    label: 'Goals Only',
    id: 'goalsOnly',
  },
  {
    label: 'Assessments Only',
    id: 'assessmentsOnly',
  },
];

export function ActivityItemsTypeFilter({
  selectedTypeFilter,
  setSelectedTypeFilter,
}: {
  selectedTypeFilter: string;
  setSelectedTypeFilter: (id: string) => void;
}) {
  return (
    <div css={{ ...typeFiltersContainerCss }}>
      {typeFiltersMap.map((filter) => {
        const isSelected = selectedTypeFilter === filter.id;
        return (
          <button
            key={filter.id}
            id={filter.id}
            onClick={() => setSelectedTypeFilter(filter.id)}
            className={isSelected ? 'selected' : ''}
          >
            {filter.label}
          </button>
        );
      })}
    </div>
  );
}

export function ActivityItemsDateFilter({
  selectedFilterId,
  setSelectedFilterId,
  options,
}: {
  selectedFilterId: string;
  setSelectedFilterId: (id: string) => void;
  options: Record<string, { label: string }>;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const selectedEntry = Object.entries(options).find(
    ([key]) => key === selectedFilterId,
  );

  return (
    <div css={{ ...activityItemsDateFilterCss }}>
      <button onClick={openMenu}>
        <>
          {selectedEntry?.[1].label}
          <Chevron
            width={6}
            css={{
              marginLeft: 8,
              transform: 'rotate(90deg)',
            }}
          />
        </>
      </button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {Object.entries(options).map(([id, { label }]) => {
          const isSelected = selectedFilterId === id;
          return (
            <MenuItem
              key={id}
              selected={isSelected}
              onClick={() => {
                setSelectedFilterId(id);
                closeMenu();
              }}
            >
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
