import { ActivityExecution, Result } from '../../../models/ActivityExecution';

export function getLastTwoLatestResults(
  activitiesResults: ActivityExecution[] | undefined,
  metricKey: string,
  metricSide: string | undefined,
): (Result | undefined)[] | undefined {
  if (activitiesResults && metricKey) {
    activitiesResults.sort((activityA, activityB) => {
      return activityA.stopDate > activityB.stopDate ? 1 : -1;
    });

    const filteredResults = activitiesResults
      .map((activity) =>
        activity.results.filter(
          (result) => result.key === metricKey && result.side === metricSide,
        ),
      )
      .flat();

    const lastResult = Object.values(filteredResults)[filteredResults.length - 1];
    const previousResult =
      filteredResults.length > 1
        ? Object.values(filteredResults)[filteredResults.length - 2]
        : undefined;

    return [previousResult, lastResult];
  }
  return [];
}
