import { createSelector } from '@reduxjs/toolkit';
import { getTreatmentPlansDtoSelector } from '../../state-manager/selectors/treatmentPlansSelectors';
import { getActivityDefinitionsSelector } from '../../state-manager/selectors/librarySelectors';
import {
  getCurrentPatientIdSelector,
  getSelectedCaseIdSelector,
} from '../../state-manager/selectors/appSelectors';
import { TreatmentPlan } from '../TreatmentPlan';
import { getCaseDtosSelector } from '../../state-manager/selectors/casesSelectors';

export const getPatientTreatmentPlansSelector = createSelector(
  [
    getTreatmentPlansDtoSelector,
    getCurrentPatientIdSelector,
    getActivityDefinitionsSelector,
    getSelectedCaseIdSelector,
    getCaseDtosSelector,
  ],
  (
    treatmentPlansDto,
    patientId,
    activityDefinitions,
    selectedCaseId,
    casesToPatientMap,
  ): TreatmentPlan[] | undefined => {
    if (!patientId || !selectedCaseId) {
      return;
    }
    if (!treatmentPlansDto[patientId] || !casesToPatientMap[patientId]) {
      return;
    }
    const treatmentPlans = treatmentPlansDto[patientId]
      ?.filter(({ case_id }) => case_id === selectedCaseId)
      .map((treatmentPlanDto) => {
        return new TreatmentPlan({ treatmentPlanDto, activityDefinitions });
      });
    return treatmentPlans;
  },
);
