import { createSelector } from '@reduxjs/toolkit';
import { getUserDtosSelector } from '../../state-manager/selectors/usersSelectors';
import { User, UserId } from '../User';
import {
  getCurrentPatientIdSelector,
  getUserIdSelector,
} from '../../state-manager/selectors/appSelectors';

export const getUsersSelector = createSelector([getUserDtosSelector], (userDtos) => {
  const usersMap: Record<UserId, User> = {};
  Object.values(userDtos).forEach((userDto) => {
    const user = new User(userDto);
    usersMap[user.id] = user;
  });
  return usersMap;
});

export const getCurrentPatientSelector = createSelector(
  [getCurrentPatientIdSelector, getUsersSelector],
  (patientId, users) => {
    if (patientId) {
      const user = users[patientId];
      if (user && user.isPatient()) {
        return user;
      }
    }
  },
);

export const getUserSelector = createSelector(
  [getUserIdSelector, getUserDtosSelector],
  (userId, users) => {
    if (userId) {
      const userDto = users[userId];
      if (userDto) {
        return new User(userDto);
      }
    }
  },
);
