/** @jsxImportSource @emotion/react */

import { InputAdornment, TextField, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';

export function SearchTextField({
  searchTerm,
  onSearchTermChange,
  placeholder,
  width,
  enableAutocomplete,
  name,
}: {
  searchTerm: string | undefined;
  onSearchTermChange: (searchTerm: string) => void;
  placeholder?: string;
  width?: number;
  enableAutocomplete?: boolean;
  name?: string;
}) {
  const [inputValue, setInputValue] = useState(searchTerm ?? '');
  const [searchHistory, setSearchHistory] = useState<string[]>([]);

  useEffect(() => {
    const history = JSON.parse(
      localStorage.getItem(`searchHistory_${name}`) || '[]',
    );
    setSearchHistory(history);
  }, []);

  const handleSearchTermChange = (newSearchTerm: string) => {
    setInputValue(newSearchTerm);
    onSearchTermChange(newSearchTerm);
  };

  const handleBlur = () => {
    if (inputValue && !searchHistory.includes(inputValue)) {
      const updatedHistory = [...searchHistory, inputValue].slice(-5);
      setSearchHistory(updatedHistory);
      localStorage.setItem(
        `searchHistory_${name}`,
        JSON.stringify(updatedHistory),
      );
    }
  };

  if (enableAutocomplete) {
    return (
      <Autocomplete
        disableClearable
        freeSolo
        options={searchHistory}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) =>
          handleSearchTermChange(newInputValue)
        }
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            type="search"
            sx={{
              height: '56px',
              width: width ?? '400px',
              margin: '5px',
              input: {
                '&:hover, &:focus, &:active': {
                  borderColor: 'transparent',
                },
              },
            }}
            placeholder={placeholder ?? 'Search'}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              onSearchTermChange(event.target.value);
            }}
            value={searchTerm ?? ''}
          />
        )}
      />
    );
  } else {
    return (
      <TextField
        variant="outlined"
        type="search"
        sx={{
          height: '56px',
          width: width ?? '400px',
          margin: '5px',
          input: {
            '&:hover, &:focus, &:active': {
              borderColor: 'transparent',
            },
          },
        }}
        placeholder={placeholder ?? 'Search'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          onSearchTermChange(event.target.value);
        }}
        value={searchTerm ?? ''}
      />
    );
  }
}
