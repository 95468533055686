/** @jsxImportSource @emotion/react */
import AvatarMui from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import { fonts } from '../../style/fonts';
import { User } from '../../models/User';

function acronym({ name }: { name: string }) {
  const a = name.replace('dr.', '');
  const split = a.split(' ');
  const final = `${split[0]?.charAt(0) ?? ''}${split[1]?.charAt(0) ?? ''}`;
  return final;
}

export default function Avatar({ user }: { user: User }) {
  return (
    <Tooltip title={user.name}>
      <AvatarMui sx={{ ...fonts.largeLabel }}>
        {acronym(user).toUpperCase()}
      </AvatarMui>
    </Tooltip>
  );
}
