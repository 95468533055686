import React from 'react';
import { createRoot } from 'react-dom/client';
import AppWithLogin from './AppWithLogin';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { DATA_DOG_CLIENT, isInnerEnvironment } from './config';
import '../i18next.config';

datadogRum.init({
  applicationId: '80543832-ef88-4f5c-b91b-b20a37b22a11',
  clientToken: DATA_DOG_CLIENT,
  site: 'datadoghq.com',
  service: 'portal',
  env: window.location.host,
  sessionSampleRate: 100,
  sessionReplaySampleRate: isInnerEnvironment ? 20 : 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogLogs.init({
  clientToken: DATA_DOG_CLIENT,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

createRoot(document.getElementById('root')).render(<AppWithLogin />);
