/* eslint-disable max-len */
/* eslint-disable quotes */
export type FaqItem = {
  question: string;
  answer: string;
};

export const questionAndAnswer: FaqItem[] = [
  {
    question: "What happens if I'm inactive on the clinician portal?",
    answer:
      'After 20 minutes of inactivity, the portal will automatically sign you out to protect patient information.',
  },
  {
    question: "How do I edit a patient's profile in the clinician portal?",
    answer:
      "Navigate to the patient list page, search for the patient with name or patient ID and you'll find an option to edit the patient's profile information stored in the PT Metrics cloud.",
  },
  {
    question:
      'Can I modify sets, reps, hold time, and rest time in the Treatment Plan Builder?',
    answer:
      'Yes, the Treatment Plan Builder allows you to modify these parameters for each activity without expanding each activity in the treatment plan.',
  },
  {
    question: "What does a 'non-significant digest' mean in the post-visit summary?",
    answer:
      'It refers to additional details of the session, including activities, metrics, and notes that are not highlighted as significant but are still available for review.',
  },
  {
    question: 'How are skipped activities displayed in the post-visit summary?',
    answer:
      'The compliance row in the post-visit summary will provide an option to show a list of skipped activities along with the reasons for skipping.',
  },
  {
    question: 'What if the patient aborts an exercise before completion?',
    answer:
      'If an exercise is aborted and then repeated, the clinician portal will display the most updated results for that exercise.',
  },
  {
    question: 'How do I generate a login QR code for a patient?',
    answer:
      "On the per-patient detail page, there's a button to generate and display a login QR code for the patient, allowing them to log in to the patient app.",
  },
  {
    question: 'What should I do if I enter the wrong email or password?',
    answer:
      "If the login credentials are incorrect, a message will indicate a login error without specifying which detail is incorrect. You can try entering the information again or use the 'Forgot Password' feature.",
  },
  {
    question: "How do I reset a patient's password using the clinician portal?",
    answer:
      "Use the 'Generate Login QR-code' button on the per-patient detail page to create a new QR code that the patient can use to reset their password.",
  },
  {
    question: 'How can I manage multiple treatment episodes for a patient?',
    answer:
      'The clinician portal allows you to manage multiple treatment episodes or cases for a patient, organizing and tracking treatment plans, exercises, and measurements specific to each case. Add a case to the patient profile by editing patient information from the per-patient page or from the patient list.',
  },
  {
    question: 'How do I add a new patient to PT Metrics?',
    answer:
      "To add a new patient, navigate to the patient list page and 'Add Patient' section in the clinician portal and fill out the required fields, including case ID and details.",
  },
  {
    question: 'How can I see which clinician assigned a treatment plan?',
    answer:
      'The treatment plans history display includes the name of the clinician who assigned it and the date/time of the assignment for each plan.',
  },
  {
    question: 'Can I update a treatment plan after assigning it?',
    answer:
      "Yes, you can update a treatment plan that hasn't been used yet, and the plan's date will automatically update to reflect the change.",
  },
  {
    question: 'How do I manage treatment plans for different body parts?',
    answer:
      'The Treatment Plan Builder allows you to select and customize exercises for various body parts, including new additions like the wrist and middle back.',
  },
  {
    question: "What is the 'non-significant digest' in the post-visit summary?",
    answer:
      "It's a summary of the rest of the activities, metrics, and notes from a session that weren't flagged as significant but are still available for review.",
  },
  {
    question: 'How are qualitative check errors displayed?',
    answer:
      'Qualitative check errors are displayed in the post-visit summary for each visit on the session screen.',
  },
  {
    question: 'Where can I find qualitative check messages on metrics?',
    answer:
      'Qualitative check messages are displayed on the metrics graph on the per-session screen when you hover over the key metrics.',
  },
  {
    question: 'How is the most recent exercise result displayed if a patient repeats it?',
    answer:
      "The clinician portal will display the most updated results for an exercise if it's repeated after being aborted.",
  },
  {
    question: 'How do I navigate between different case IDs for a patient?',
    answer:
      'The clinician portal provides means to navigate between case IDs when displaying associated per-patient treatment plans and progress.',
  },
  {
    question: "Can I see a graph of a patient's progress over time?",
    answer:
      'Yes, the clinician portal displays a longitudinal progress graph when you hover over key metrics in the collapsed activity list on the session screen.',
  },
  {
    question: 'How is a new patient account created automatically?',
    answer:
      'When a clinician logs into the clinician portal via a patient’s page in an EMR, a new patient account is created automatically if the patient is new.',
  },
  {
    question: 'Where can I find explanations for activity goals?',
    answer:
      'Each goal in the Treatment Plan Builder includes an explanation of how the goal is measured (max, avg, etc.).',
  },
  {
    question: 'How do I add case details for a patient?',
    answer:
      'The clinician portal provides means to add case ID & case details when adding a new patient or editing an existing one.',
  },
  {
    question: 'Can I see case details when viewing treatment plans?',
    answer:
      'Yes, the clinician portal displays case ID & case details when showing associated treatment plans and progress.',
  },
  {
    question:
      'What happens when I log in to the clinician portal via a patient’s page in an EMR?',
    answer:
      'The clinician portal will open at the patient’s Treatment Plan tab, allowing you to manage their plans directly.',
  },
  {
    question: 'How are partially completed exercises displayed?',
    answer:
      'The clinician portal displays the reason for all partially completed exercises in the session screen.',
  },
  {
    question: 'Can I set which body side faces the camera for exercises?',
    answer:
      'The Treatment Plan Builder allows setting a per-patient, per-movement body side facing the camera for symmetric sagittal movements.',
  },
  {
    question: 'Are units displayed for exercise metrics?',
    answer:
      'Yes, the clinician portal displays units in the results table for each metric of an exercise in the session screen.',
  },
  {
    question: 'Can I switch the body side assignment for treatment plans?',
    answer:
      'Yes, when assigning a treatment plan to a patient, you have the option to switch the body side assignment of movements.',
  },
  {
    question: 'Are logs containing PII or PHI sent to the cloud?',
    answer:
      "Logs sent to any cloud location other than the PT-metrics cloud do not contain patients' PII or PHI.",
  },
  {
    question: 'Can I see the estimated execution time for tasks?',
    answer: 'The Treatment Plan Builder displays the estimated execution time for each task.',
  },
  {
    question: 'Can I set the duration for each movement?',
    answer:
      'The Treatment Plan Builder enables setting a per-patient, per-movement duration for exercises.',
  },
];
