import { AppThunk } from '../storeTypes';
import { CaseDto } from '../../types/backendType';
import { markStatusFunctionCreator, setSelectedCaseIdAction } from '../slices/appSlice';
import { skipRequest } from '../utils/requestStatus';
import {
  getCurrentPatientIdSelector,
  getRequestStatusSelector,
} from '../selectors/appSelectors';
import { UserId } from '../../models/User';
import { createCase, fetchCases } from '../../services/casesService';
import { addCaseAction, setCasesAction } from '../slices/casesSlice';
import { getPatientCaseDtosSelector } from '../selectors/casesSelectors';

export function fetchCasesAction(patientId: UserId): AppThunk {
  return async (dispatch, getState) => {
    const requestStatus = getRequestStatusSelector('fetchCases')(getState());
    if (skipRequest({ requestStatus, id: patientId })) {
      return;
    }
    const markStatus = markStatusFunctionCreator(dispatch, patientId, 'fetchCases');
    markStatus('inProcess');
    let cases: CaseDto[] | undefined = undefined;
    try {
      cases = await fetchCases(patientId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      return;
    }
    markStatus('succeeded');
    if (!cases) {
      return;
    }
    dispatch(setCasesAction({ cases, patientId }));
  };
}

export function createCaseAction(patientId: UserId, caseId: string): AppThunk {
  return async (dispatch, getState) => {
    const requestStatus = getRequestStatusSelector('createCase')(getState());
    const processId = patientId;
    if (skipRequest({ requestStatus, id: processId })) {
      return;
    }
    const markStatus = markStatusFunctionCreator(dispatch, processId, 'createCase');
    markStatus('inProcess');
    let newCase: CaseDto | undefined = undefined;
    try {
      newCase = await createCase(patientId, caseId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      markStatus('failed');
      return;
    }
    markStatus('succeeded');
    if (!newCase) {
      return;
    }
    dispatch(addCaseAction({ newCase, patientId }));
  };
}

export function changeSelectedCaseId(caseId: string): AppThunk {
  return async (dispatch, getState) => {
    const patientId = getCurrentPatientIdSelector(getState());
    const caseDtos = getPatientCaseDtosSelector(patientId)(getState());
    if (caseDtos === undefined) {
      throw new Error(
        `patientId: ${patientId} don't have cases in client, pls fetch cases before`,
      );
    }
    const isCaseIdExist = caseDtos.some(({ case_id }) => case_id === caseId);
    if (isCaseIdExist) {
      dispatch(setSelectedCaseIdAction({ caseId }));
    } else {
      throw new Error(`caseId ${caseId} not exist for patientId: ${patientId}`);
    }
  };
}
