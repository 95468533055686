const REQUEST_STATUS = ['inProcess', 'failed', 'succeeded'] as const;
export type RequestStatusOption = (typeof REQUEST_STATUS)[number];

export type RequestStatus = {
  inProcess: string[];
  failed: string[];
  succeeded: string[];
};

export function createEmptyRequestStatus() {
  return {
    inProcess: [],
    failed: [],
    succeeded: [],
  };
}

function filterOut(id: string, array: string[]) {
  return array.filter((currentId) => currentId !== id);
}

function markAsSuccessful({
  id,
  requestStatus,
}: {
  id: string;
  requestStatus: RequestStatus;
}): RequestStatus {
  const {
    inProcess: currentInProcess,
    failed: currentFailed,
    succeeded: currentSucceeded,
  } = requestStatus;
  let inProcess = [...currentInProcess];
  let failed = [...currentFailed];
  let succeeded = [...currentSucceeded];

  if (inProcess.includes(id)) {
    inProcess = filterOut(id, inProcess);
  }
  if (failed.includes(id)) {
    failed = filterOut(id, failed);
  }
  if (!succeeded.includes(id)) {
    succeeded.push(id);
  }
  return { inProcess, failed, succeeded };
}

function markAsFailed({
  id,
  requestStatus,
}: {
  id: string;
  requestStatus: RequestStatus;
}): RequestStatus {
  const {
    inProcess: currentInProcess,
    failed: currentFailed,
    succeeded: currentSucceeded,
  } = requestStatus;
  let inProcess = [...currentInProcess];
  let failed = [...currentFailed];
  let succeeded = [...currentSucceeded];

  if (inProcess.includes(id)) {
    inProcess = filterOut(id, inProcess);
  }
  if (succeeded.includes(id)) {
    succeeded = filterOut(id, failed);
  }
  if (!failed.includes(id)) {
    failed.push(id);
  }
  return { inProcess, failed, succeeded };
}

function markAsInProcess({
  id,
  requestStatus,
}: {
  id: string;
  requestStatus: RequestStatus;
}): RequestStatus {
  const {
    inProcess: currentInProcess,
    failed: currentFailed,
    succeeded: currentSucceeded,
  } = requestStatus;
  let inProcess = [...currentInProcess];
  let failed = [...currentFailed];
  let succeeded = [...currentSucceeded];

  if (failed.includes(id)) {
    failed = filterOut(id, inProcess);
  }
  if (succeeded.includes(id)) {
    succeeded = filterOut(id, failed);
  }
  if (!inProcess.includes(id)) {
    inProcess.push(id);
  }
  return { inProcess, failed, succeeded };
}

export function markTheStatus({
  id,
  requestStatus,
  status,
}: {
  id: string;
  requestStatus: RequestStatus;
  status: RequestStatusOption;
}) {
  switch (status) {
    case 'inProcess':
      return markAsInProcess({ id, requestStatus });
    case 'failed':
      return markAsFailed({ id, requestStatus });
    case 'succeeded':
      return markAsSuccessful({ id, requestStatus });
    default:
      return requestStatus;
  }
}

export function skipRequest({
  id,
  requestStatus,
}: {
  id: string;
  requestStatus: RequestStatus;
}): boolean {
  const { inProcess, succeeded } = requestStatus;
  return inProcess.includes(id) || succeeded.includes(id);
}

export function isFinished({
  id,
  requestStatus,
}: {
  id: string;
  requestStatus: RequestStatus;
}): boolean {
  const { inProcess, failed, succeeded } = requestStatus;
  return !inProcess.includes(id) && (failed.includes(id) || succeeded.includes(id));
}

export function isFailed({
  id,
  requestStatus,
}: {
  id: string;
  requestStatus: RequestStatus;
}): boolean {
  const { failed } = requestStatus;
  return failed.includes(id);
}

export function isInProcess({
  id,
  requestStatus,
}: {
  id: string;
  requestStatus: RequestStatus;
}): boolean {
  const { inProcess } = requestStatus;
  return inProcess.includes(id);
}

export function isSucceeded({
  id,
  requestStatus,
}: {
  id: string;
  requestStatus: RequestStatus;
}): boolean {
  const { succeeded } = requestStatus;
  return succeeded.includes(id);
}
