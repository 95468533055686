import { AssessmentOutputDTO, AssessmentProcessingDTO } from '../types/backendType';
import httpClient from './httpClient';

//POST /summary/process
export async function processAssessment(text: string, patientId: string) {
  try {
    const response = await httpClient.post<AssessmentProcessingDTO>(
      '/summary/process',
      {
        input: text,
        config: {
          model_type: 'GPT',
        },
      },
      {
        headers: { 'x-user-id': patientId },
      },
    );
    const sessionId = response.data.session_id;
    return sessionId;
  } catch (e) {
    throw new Error(`Failed to begin processing assessment: ${e}`);
  }
}

//GET /summary/output
export async function fetchAssessmentOutput(sessionId: string, patientId: string) {
  try {
    const response = await httpClient.get<AssessmentOutputDTO>(
      `/summary/output/${sessionId}`,
      {
        headers: { 'x-user-id': patientId },
      },
    );
    return response.data;
  } catch (e) {
    throw new Error(`Failed to process assessment: ${e}`);
  }
}
