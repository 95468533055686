/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import {
  Switch as Routes,
  Route,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { CSSObject } from '@emotion/react';
import { useSelector } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';

import Objective from './objective/Objective';
import {
  useFetchTreatmentPlanTemplates,
  useFulfillActivityDefinitionsAction,
  useFulfillAllPatientActivitiesData,
  useFulfillPatientCases,
  useFulfillPatientData,
  useFulfillPatientTreatmentPlans,
  useHandleFulfillPatientFailed,
  useHandleFulfillTreatmentPlansFailed,
  useOnCasesChangeUpdateUrl,
  usePullingPatientResults,
  useUpdateCurrentPatientId,
  useUpdateStoreCaseIdFromUrl,
  useAddMandatoryCase,
} from './patientDetailsHooks';
import SessionsContainer from './sessions/SessionsContainer';
import TreatmentPlans from './treatment-plans/TreatmentPlans';
import NavTabs, { NavTab } from '../commons/NavTabs';
import { breakpoints } from '../../style/breakpoints';
import { getCurrentPatientSelector } from '../../models/factories/userFactories';
import { fonts } from '../../style/fonts';
import { ellipsis } from '../../utils/cssUtils';
import ArrowIcon from './../../assets/arrow.svg';
import Assessments from './assessments/Assessments';
import Notes from './notes/Notes';
import { isExperimentalEnvironment } from '../../config';
import { sendPatientQRCode } from '../../services/qrCodeService';
import { useAppDispatch } from '../../state-manager/store';
import { Tooltip } from '@mui/material';
import { notify } from '../../state-manager/slices/notificationsSlice';
import CreateUserModal from '../forms/create-user-form/CreateUserModal';
import { User } from '../../models/User';
import { getPatientCaseDtosSelector } from '../../state-manager/selectors/casesSelectors';
import {
  getSelectedCaseIdSelector,
  isUnrestrictedUserSelector,
} from '../../state-manager/selectors/appSelectors';
import { PatientFormMode } from '../forms/create-user-form/create-patient-form/patientFormTypes';
import {
  setCurrentPatientIdAction,
  setSelectedCaseIdAction,
} from '../../state-manager/slices/appSlice';
import QRCodeIcon from '../../assets/qrCode.svg';
import QrCodeDialog from './PatientDetailsQrCodeDialog';

const headerHeight = 84;
const patientDetailsPadding = 30;

const patientDetailsHeaderCss: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  height: headerHeight,
  padding: `0 ${patientDetailsPadding}px`,
  '.name': {
    ...fonts.h1,
    ...ellipsis,
    maxWidth: 200,
    marginRight: 5,
  },
  '.tabs-container': {
    height: '100%',
    '&:first-of-type': {
      marginRight: 74,
      width: 210,
    },
    '&:last-of-type': {
      marginLeft: 239,
    },
  },

  '.qr-code-button': {
    marginRight: '10px',
  },
};

const patientDetailsCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  '.tabs-container': {
    fontSize: '20px',
  },
  '.patient-details-content': {
    display: 'flex',
    justifyContent: 'center',
    height: `calc(100% - ${headerHeight}px)`,
    flexGrow: 1,
  },
  '.objective-container': {
    paddingLeft: patientDetailsPadding,
    '.graphs-container': {
      paddingRight: patientDetailsPadding,
    },
  },
  '.overview-container': {
    padding: `0 ${patientDetailsPadding}px ${patientDetailsPadding}px ${patientDetailsPadding}px`,
  },
  '.treatment-plans-container': {
    padding: `0 ${patientDetailsPadding}px`,
    paddingBottom: patientDetailsPadding,
  },
  '.sessions-container': {
    height: `calc(100% - ${patientDetailsPadding}px)`,
    width: `calc(100% - ${2 * patientDetailsPadding}px)`,
  },
  '@media (max-width: 1286px)': {
    '.patient-header': {
      justifyContent: 'flex-end',
    },
  },
  [breakpoints.small]: {
    '.patient-header': {
      visibility: 'hidden',
    },
  },
};

function getPatientNavButtons(isUnrestrictedUser: boolean) {
  const patientNavButtons: NavTab[] = [
    {
      id: 'treatmentPlans',
      navigateTo: '/patient/treatment-plans',
      label: 'Treatment Plans',
      isSelected: (pathname: string) => {
        return pathname.includes('treatment-plans');
      },
      keepSearchParams: true,
    },
    {
      id: 'objective',
      navigateTo: '/patient/objective',
      label: 'Objective',
      isSelected: (pathname: string) => {
        return pathname.includes('objective');
      },
      keepSearchParams: true,
    },
    {
      id: 'sessions',
      navigateTo: '/patient/sessions',
      label: 'Sessions',
      isSelected: (pathname: string) => {
        return pathname.includes('sessions');
      },
      keepSearchParams: true,
    },
    {
      id: 'assessments',
      navigateTo: '/patient/assessments',
      label: 'Assessments',
      isSelected: (pathname: string) => {
        return pathname.includes('assessments');
      },
      keepSearchParams: true,
      hidden: !isExperimentalEnvironment() || !isUnrestrictedUser,
    },
    {
      id: 'notes',
      navigateTo: '/patient/notes',
      label: 'Notes',
      isSelected: (pathname: string) => {
        return pathname.includes('notes');
      },
      keepSearchParams: true,
      hidden: !isExperimentalEnvironment() || !isUnrestrictedUser,
    },
  ];
  return patientNavButtons;
}

const GO_BACK: NavTab = {
  id: 'goBack',
  navigateTo: '/patients',
  view: (
    <div css={{ display: 'flex', svg: { marginRight: 10 } }}>
      <ArrowIcon width={6} />
      Patient List
    </div>
  ),
  isSelected: (pathname: string) => {
    return pathname == '/patients';
  },
};

function Header({ onEditPatient }: { onEditPatient: (patient: User) => void }) {
  const dispatch = useAppDispatch();
  const patient = useSelector(getCurrentPatientSelector);
  const isUnrestrictedUser = useSelector(isUnrestrictedUserSelector);
  const caseDtos = useSelector(getPatientCaseDtosSelector(patient?.id));
  const selectedCaseId = useSelector(getSelectedCaseIdSelector);
  const history = useHistory();
  let options: string[] = [];
  if (caseDtos) {
    options = caseDtos?.map(({ case_id }) => case_id);
  }
  const [isQrCodeDialogOpen, setIsQrCodeDialogOpen] = useState<boolean>(false);
  const [qrCodeValue, setQrCodeValue] = useState<string | undefined>();

  return (
    <div css={patientDetailsHeaderCss}>
      {isUnrestrictedUser && <NavTabs navTabs={[GO_BACK]} />}
      {patient?.email && (
        <Tooltip title="Show and send QR code to patient for faster sign-in.">
          <button
            type="button"
            onClick={async () => {
              setQrCodeValue(undefined);
              sendPatientQRCode(patient.email)
                .then((response) => {
                  dispatch(
                    notify({
                      message: response.successMessage,
                      severity: 'success',
                    }),
                  );
                  setQrCodeValue(`${response.fullOtp}&${patient.email}`);
                })
                .catch((error) => {
                  dispatch(
                    notify({
                      message: error.message,
                      severity: 'error',
                    }),
                  );
                });
              setIsQrCodeDialogOpen(true);
            }}
            className="qr-code-button"
          >
            <QRCodeIcon width={34} height={34} />
          </button>
        </Tooltip>
      )}
      <div className="name">{patient?.name ?? ''}</div>
      {patient && isUnrestrictedUser && (
        <button
          type="button"
          onClick={() => {
            onEditPatient(patient);
          }}
        >
          <EditIcon />
        </button>
      )}
      {patient?.emrUserId && (
        <select
          css={{ width: '120px' }}
          onChange={(event) => {
            const caseId = event.target.value;
            const searchParams = new URLSearchParams(history.location.search);
            searchParams.set('case-id', caseId);
            history.push({
              pathname: history.location.pathname,
              search: searchParams.toString(),
            });
          }}
          value={selectedCaseId || ''}
        >
          {options.map((caseId) => (
            <option key={caseId} value={caseId}>
              {caseId}
            </option>
          ))}
        </select>
      )}
      <NavTabs navTabs={getPatientNavButtons(isUnrestrictedUser)} />
      {isQrCodeDialogOpen && patient && (
        <QrCodeDialog
          setIsQrCodeDialogOpen={setIsQrCodeDialogOpen}
          qrCodeValue={qrCodeValue}
          patientName={patient?.name}
        />
      )}
    </div>
  );
}

export default function PatientDetails() {
  useUpdateCurrentPatientId();
  useFulfillPatientData();
  useOnCasesChangeUpdateUrl();
  useFulfillPatientTreatmentPlans();
  useFulfillAllPatientActivitiesData();
  useFulfillActivityDefinitionsAction();
  useHandleFulfillPatientFailed();
  useHandleFulfillTreatmentPlansFailed();
  useFetchTreatmentPlanTemplates();
  usePullingPatientResults();
  useFulfillPatientCases();
  useUpdateStoreCaseIdFromUrl();
  useAddMandatoryCase();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [patientFormMode, setPatientFormMode] = useState<
    PatientFormMode | undefined
  >(undefined);

  useEffect(() => {
    return () => {
      dispatch(setSelectedCaseIdAction({ caseId: null }));
      dispatch(setCurrentPatientIdAction({ patientId: null }));
    };
  }, []);

  return (
    <div css={patientDetailsCss}>
      <Header
        onEditPatient={(patient: User) => {
          setPatientFormMode({ mode: 'edit', initData: patient });
        }}
      />
      <div className="patient-details-content">
        <Routes>
          <Route exact path={`${url}`}>
            <Redirect
              to={{
                pathname: `${url}/treatment-plans`,
                search: history.location.search,
              }}
            />
          </Route>
          <Route
            path={`${url}/treatment-plans`}
            render={() => <TreatmentPlans />}
          />
          <Route path={`${url}/objective`} render={() => <Objective />} />
          <Route
            path={`${url}/sessions`}
            render={() => <SessionsContainer />}
          />
          <Route path={`${url}/notes`} render={() => <Notes />} />
          <Route path={`${url}/assessments`} render={() => <Assessments />} />
        </Routes>
      </div>
      <CreateUserModal
        type="patient"
        formMode={patientFormMode}
        setFormMode={setPatientFormMode}
      />
    </div>
  );
}
