import { CSSObject } from '@emotion/react';

import { colors } from './colors';
import { fonts } from './fonts';

const primaryCommon: CSSObject = {
  color: colors.white,
  background: 'linear-gradient(338.15deg, #0084D4 12.01%, #00A3F6 83.38%)',
  borderRadius: '4px',
  '&:hover': {
    background:
      // eslint-disable-next-line max-len
      'linear-gradient(338.15deg, #0068B5 12.01%, #00A3F6 83.38%), linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))',
  },
  '&:disabled': {
    background: 'transparent',
    backgroundColor: colors.darkGrey,
  },
};

const secondaryCommon: CSSObject = {
  color: colors.blue4,
  borderRadius: '4px',
  border: `1px solid ${colors.blue4}`,
  '&:hover': {
    color: colors.blue2,
    borderColor: colors.blue2,
  },
  '&:disabled': {
    color: colors.darkGrey,
    borderColor: colors.darkGrey,
  },
};

export const buttons: Record<string, CSSObject> = {
  primary: {
    ...primaryCommon,
    ...fonts.largeLabel,
    padding: '10px 12px',
  },
  primarySmall: {
    ...primaryCommon,
    ...fonts.smallLabel,
    padding: '3px 8px',
  },
  secondary: {
    ...secondaryCommon,
    ...fonts.largeLabel,
    padding: '9px 11px',
  },
  secondarySmall: {
    ...secondaryCommon,
    ...fonts.smallLabel,
    padding: '2px 7px',
  },
} as const;
