import { Expression, QualitativeOptional } from '../types/backendType';
import { OverTimeResults } from './OverTimeResults';

export class QualitativeOverTimeResults {
  private qualitativeOverTimeResults;

  private qualitativeMetaData;

  constructor(
    qualitativeOverTimeResults: (number[] | null)[],
    qualitativeMetaData: QualitativeOptional[],
  ) {
    this.qualitativeOverTimeResults = qualitativeOverTimeResults;
    this.qualitativeMetaData = qualitativeMetaData;
  }

  get allQualitativeMetaData() {
    return this.qualitativeMetaData;
  }

  get allQualitativeResults() {
    return this.qualitativeOverTimeResults;
  }

  getQualitativeExpression(
    qualitativeData: QualitativeOptional,
    overTimeResults: OverTimeResults,
  ) {
    let expression = '';
    qualitativeData.expression.forEach((exp: Expression) => {
      let text = '';
      if ('metric' in exp) {
        let result = overTimeResults.getResultsByMetric(exp.metric);
        let displayName = result
          ? overTimeResults.getFormattedMetricLabel(result)
          : exp.metric.replace('_', ' ').replace(/\b\w/g, (char) => char.toUpperCase());
        text = displayName;
      } else {
        text = exp.operator;
      }
      expression += text;
    });

    return expression;
  }

  getQualitativeDetails(index: number) {
    const qualitativeResult = this.qualitativeOverTimeResults[index];
    return this.qualitativeMetaData.filter((_, i) => qualitativeResult?.includes(i));
  }
}
