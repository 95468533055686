/** @jsxImportSource @emotion/react */

import {
  Document as PdfDocument,
  Page,
  Text as PdfText,
  View,
  StyleSheet as PdfStyleSheet,
  Image as PdfImage,
  Font,
} from '@react-pdf/renderer';
import PdfLogo from '../../../../assets/pdfLogo.png';
import { shapes } from '../../../../style/shapes';
import { colors } from '../../../../style/colors';
import { TreatmentPlan } from '../../../../models/TreatmentPlan';
import { format } from 'date-fns';
import WarningTriangle from '../../../../assets/warningTriangle.png';
import PdfHeaderLogo from '../../../../assets/pdfLogo2.png';
import { getActivityImageURL } from '../../../commons/ActivityThumbnail';
import { CDN_BASE_URL } from '../../../../externalConfig';

const ACTIVITY_START_POSITION_URL = `${CDN_BASE_URL}/media/movements/start_exercise_positions/`;

Font.register({
  family: 'IntelOne Text',
  fonts: [
    { src: '/fonts/intelone-text-regular.woff' },
    { src: '/fonts/intelone-text-medium.woff' },
  ],
});

const styles = PdfStyleSheet.create({
  page: {
    padding: '77px 77px',
    fontFamily: 'IntelOne Text',
    fontSize: '12px',
    fontWeight: 400,
  },
  logo: {
    width: '112px',
    height: '49px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '14px',
  },
  generalInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: shapes.border,
    backgroundColor: colors.backgroundDarkerGrey,
    padding: '14px',
    marginBottom: '20px',
  },
  generalInfoHeader: {
    fontWeight: 500,
    color: colors.blue2,
  },
  activityContainer: {
    border: shapes.border,
    padding: '20px 12px',
  },
  warningIcon: {
    height: '16px',
    width: '16px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colors.darkGrey,
    position: 'absolute',
    bottom: '40px',
    right: '77px',
    left: '77px',
    fontSize: '10px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colors.darkGrey,
    position: 'absolute',
    top: '40px',
    right: '77px',
    left: '77px',
    fontSize: '10px',
  },
  headerLogo: {
    width: '88px',
    height: '16px',
  },
  activityName: {
    fontSize: '14px',
    fontWeight: 500,
    color: colors.blue3,
    marginBottom: '14px',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '22px',
    justifyContent: 'space-between',
    paddingLeft: '13px',
  },
  firstColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    maxWidth: '50%',
  },
  longTextSubcontainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  image: {
    width: '174px',
    height: '90px',
  },
});

type TreatmentPlanPDFProps = {
  treatmentPlan: TreatmentPlan;
  patientName: string;
  caseId: string | null;
  clinicianName: string | undefined;
};

function Footer({ clinicianName }: { clinicianName: string | undefined }) {
  const currentDate = format(new Date(), 'MM/dd/yyy');
  return (
    <View fixed style={styles.footer}>
      <PdfText>
        Exported by {clinicianName} on {currentDate}
      </PdfText>
      <PdfText
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
      />
    </View>
  );
}

export default function TreatmentPlanPDF({
  treatmentPlan,
  patientName,
  caseId,
  clinicianName,
}: TreatmentPlanPDFProps) {
  const formattedDate = format(treatmentPlan.assignedDate, 'MM/dd/yyy');

  return (
    <PdfDocument>
      <Page style={styles.page}>
        <View>
          <PdfImage src={PdfLogo} style={styles.logo} />
          <PdfText style={styles.title}>Treatment Plan</PdfText>

          <View style={styles.generalInfoContainer}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '40px',
                marginBottom: '10px',
              }}
            >
              <View>
                <PdfText style={styles.generalInfoHeader}>Assigned To</PdfText>
                <PdfText style={{ fontWeight: 500, fontSize: '15px' }}>
                  {patientName}
                </PdfText>
              </View>
              <View>
                <PdfText style={styles.generalInfoHeader}>Case ID</PdfText>
                <PdfText>{caseId}</PdfText>
              </View>
              <View>
                <PdfText style={styles.generalInfoHeader}>Date Created</PdfText>
                <PdfText>{formattedDate}</PdfText>
                <PdfText>
                  ({format(treatmentPlan.assignedDate, 'hh:mmaaa z')})
                </PdfText>
              </View>
            </View>
            <View>
              <PdfText style={styles.generalInfoHeader}>Created By</PdfText>
              <PdfText>{clinicianName}</PdfText>
            </View>
          </View>
        </View>

        {treatmentPlan.description && (
          <View style={{ marginBottom: '20px' }}>
            <PdfText style={styles.generalInfoHeader}>PT Note</PdfText>
            <PdfText>{treatmentPlan.description}</PdfText>
          </View>
        )}

        <View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2px',
              alignItems: 'center',
            }}
          >
            <PdfImage src={WarningTriangle} style={styles.warningIcon} />
            <PdfText style={styles.generalInfoHeader}>Caution</PdfText>
          </View>
          <PdfText>
            Pain is commonly experienced as part of the rehabilitation process
            in performing activities. Monitor your pain level and stop the
            activity if you experience a severe level of pain. Let your physical
            therapist know that you are experiencing severe pain to confirm
            whether to continue the activities.
          </PdfText>
          <PdfText>
            If you skip any of these activities, you'll have a chance to perform
            them later. Try to perform all the activities in the recommended
            order.
          </PdfText>
        </View>

        <Footer clinicianName={clinicianName} />
      </Page>

      {treatmentPlan?.activities.map((activity, index) => {
        return (
          <Page key={index} style={styles.page}>
            <View style={styles.header} fixed>
              <PdfImage src={PdfHeaderLogo} style={styles.headerLogo} />
              <PdfText>{treatmentPlan.name}</PdfText>
              <PdfText>
                {patientName} ({caseId})
              </PdfText>
            </View>

            <View style={styles.activityContainer}>
              <PdfText style={styles.activityName}>
                {index + 1}. {activity.exerciseName}
              </PdfText>

              <View style={styles.columnContainer}>
                <View style={styles.firstColumn}>
                  <View style={{ display: 'flex' }}>
                    <View style={styles.subcontainer}>
                      <PdfText style={{ fontWeight: 500 }}>Body Part: </PdfText>
                      <PdfText>{activity.bodyParts?.join(', ')}</PdfText>
                    </View>

                    <View style={styles.subcontainer}>
                      <PdfText style={{ fontWeight: 500 }}>Position: </PdfText>
                      <PdfText>{activity.activityPosition}</PdfText>
                    </View>
                  </View>

                  <View>
                    <PdfText style={{ fontWeight: 500 }}>
                      Required Equipment:{' '}
                    </PdfText>
                    {activity.equipment?.map((equipment) => {
                      return (
                        <PdfText key={equipment.Name}>{equipment.Name}</PdfText>
                      );
                    })}
                  </View>

                  <View>
                    <View>
                      <View style={styles.subcontainer}>
                        <PdfText>Sets: </PdfText>
                        <PdfText style={{ fontWeight: 500 }}>
                          {activity.sets}
                        </PdfText>
                      </View>

                      <View style={styles.subcontainer}>
                        <PdfText>Reps per Sets: </PdfText>
                        <PdfText style={{ fontWeight: 500 }}>
                          {activity.reps}
                        </PdfText>
                      </View>

                      <View style={styles.subcontainer}>
                        <PdfText>Hold (sec): </PdfText>
                        <PdfText style={{ fontWeight: 500 }}>
                          {activity.hold}
                        </PdfText>
                      </View>

                      <View style={styles.subcontainer}>
                        <PdfText>Rest (sec): </PdfText>
                        <PdfText style={{ fontWeight: 500 }}>
                          {activity.rest}
                        </PdfText>
                      </View>
                    </View>
                  </View>

                  <View>
                    <PdfText
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      Instructions:
                    </PdfText>
                    <PdfText>{activity.description}</PdfText>
                  </View>

                  {activity.comments && (
                    <View>
                      <PdfText
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        PT Comments:{' '}
                      </PdfText>
                      <PdfText>{activity.comments}</PdfText>
                    </View>
                  )}
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {activity.activityDefinition && (
                    <>
                      <PdfImage
                        style={styles.image}
                        src={
                          ACTIVITY_START_POSITION_URL +
                          `${activity.activityDefinition.id}.jpg?v=3`
                        }
                      />
                      <PdfImage
                        src={getActivityImageURL(activity.activityDefinition)}
                        style={styles.image}
                      />
                    </>
                  )}
                </View>
              </View>
            </View>

            <Footer clinicianName={clinicianName} />
          </Page>
        );
      })}
    </PdfDocument>
  );
}
