import { breakpointMap, SizeLabel } from '../style/breakpoints';
import useWindowDimensions from './useWindowDimensions';

export default function useBreakpoints(size: SizeLabel) {
  const [width] = useWindowDimensions();
  if (width && breakpointMap[size]) {
    return width >= breakpointMap[size];
  } else {
    return false;
  }
}
