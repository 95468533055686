/** @jsxImportSource @emotion/react */

import { Session } from '../../../models/Session';
import { colors } from '../../../style/colors';
import { fonts } from '../../../style/fonts';

export default function SessionsDates({
  sessionsByDateSelector,
  selectedDate,
  onClick,
}: {
  sessionsByDateSelector: Record<string, Session[]>;
  selectedDate: string | undefined;
  onClick: (date: string) => void;
}) {
  return (
    <>
      {Object.keys(sessionsByDateSelector).map((dateString) => {
        const formattedDate = new Date(dateString).toLocaleDateString();
        let className = '';
        if (dateString === selectedDate) {
          className = className + ' selected';
        }
        return (
          <button
            key={formattedDate}
            type="button"
            className={className}
            css={{
              ...fonts.largeLabel,
              padding: '15px 0',
              color: colors.blue4,
              '&:hover': {
                color: colors.blue2,
              },
              '&.selected': {
                color: colors.blue2,
              },
            }}
            onClick={() => {
              onClick(dateString);
            }}
          >
            {formattedDate}
          </button>
        );
      })}
    </>
  );
}
