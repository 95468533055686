import { useSelector } from 'react-redux';
import { RequestStatusName, RootState } from '../storeTypes';
import { RequestStatus, isFailed, isInProcess, isSucceeded } from '../utils/requestStatus';

export const getCurrentPatientIdSelector = (state: RootState) =>
  state.appState.currentPatientId;

export const getSelectedCaseIdSelector = (state: RootState) => state.appState.selectedCaseId;

export const getRequestStatusSelector = (requestStatusName: RequestStatusName) => {
  const requestStatusSelector: (state: RootState) => RequestStatus = (state: RootState) =>
    state.appState.requestsStatus[requestStatusName];
  return requestStatusSelector;
};

export const getMeasurementSystemSelector = (state: RootState) =>
  state.appState.measurementSystem;

const getRequestsStatusSelector = (requestStatusNames: RequestStatusName[]) => {
  const requestsStatusSelector: (state: RootState) => RequestStatus[] = (state: RootState) => {
    return requestStatusNames.map((requestStatusName) => {
      return state.appState.requestsStatus[requestStatusName];
    });
  };
  return requestsStatusSelector;
};

export function useInProcess(id: string | undefined, requestStatusNames: RequestStatusName[]) {
  const requestsStatus = useSelector(getRequestsStatusSelector(requestStatusNames));
  if (!id) {
    return false;
  }
  return requestsStatus.some((requestStatus) => isInProcess({ id, requestStatus }));
}

export function useIsEndedSuccessfully(
  id: string | undefined,
  requestStatusNames: RequestStatusName[],
) {
  const requestsStatus = useSelector(getRequestsStatusSelector(requestStatusNames));
  if (!id) {
    return false;
  }
  return requestsStatus.some((requestStatus) => isSucceeded({ id, requestStatus }));
}

export function useIsFinished(
  id: string | undefined,
  requestStatusNames: RequestStatusName[],
) {
  const requestsStatus = useSelector(getRequestsStatusSelector(requestStatusNames));
  if (!id) {
    return false;
  }
  return requestsStatus.some(
    (requestStatus) =>
      !isInProcess({ id, requestStatus }) &&
      (isFailed({ id, requestStatus }) || isSucceeded({ id, requestStatus })),
  );
}

export function useIsFailed(id: string | undefined, requestStatusNames: RequestStatusName[]) {
  const requestsStatus = useSelector(getRequestsStatusSelector(requestStatusNames));
  if (!id) {
    return false;
  }
  return requestsStatus.some((requestStatus) => isFailed({ id, requestStatus }));
}

export const getUserIdSelector = (state: RootState) => state.appState.userId;

export const getEditTemplateIdSelector = (state: RootState) => state.appState.editTemplateId;

export const getUserRoleSelector = (state: RootState) => state.appState.role;
export const isUnrestrictedUserSelector = (state: RootState) =>
  state.appState.role === 'admin' || state.appState.role === 'clinician';
