/** @jsxImportSource @emotion/react */

import { CDN_BASE_URL } from '../../../../externalConfig';
import { ActivityDefinition } from '../../../../types/library';
import { fonts } from '../../../../style/fonts';
import { CSSObject } from '@emotion/react';
import { shapes } from '../../../../style/shapes';

function getActivityVideoURL(activityDefinition: ActivityDefinition) {
  return `${CDN_BASE_URL}/media/movements/videos/${activityDefinition.id}.mp4`;
}

const activityDefinitionPreviewCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  alignItems: 'center',
  padding: 20,
  height: '100%',
  border: shapes.border,
  borderRadius: 8,
  video: {
    borderRadius: 8,
  },
  '.description': {
    maxHeight: 150,
    height: 'fit-content',
    flexGrow: 1,
    overflowY: 'auto',
    ...fonts.text,
  },
};

export function ActivityDefinitionPreview({
  activityDefinition,
}: {
  activityDefinition: ActivityDefinition;
}) {
  return (
    <div
      css={activityDefinitionPreviewCss}
      className="activity-definition-preview"
    >
      <video
        controls
        autoPlay
        src={getActivityVideoURL(activityDefinition)}
        width={'100%'}
        muted
        controlsList="nodownload"
        onContextMenu={(e) => {
          e.preventDefault();
          return false;
        }}
      />
      <div className="description">{activityDefinition.description}</div>
    </div>
  );
}
