/** @jsxImportSource @emotion/react */

import { CSSObject } from '@emotion/react';
import { PatientPrivacySetting } from '../../../../types/backendType';
import ButtonToggle from '../../../commons/ButtonToggle';
import { fonts } from '../../../../style/fonts';
import DisplayField from '../../../commons/form/DisplayField';
import { PatientFormMode } from './patientFormTypes';
import { format, parseISO } from 'date-fns';
import { useCallback, useState } from 'react';
import { Dialog } from '@mui/material';
import {
  card,
  cardBodyCss,
  cardFooterCss,
  cardHeaderCss,
} from '../../../../style/shapes';
import { buttons } from '../../../../style/buttons';
import Loader from '../../../commons/Loader';
import { colors } from '../../../../style/colors';
import CloseIcon from '../../../../assets/close.svg';
import {
  HIPAA_AUTHORIZATION_ID,
  PRIVACY_NOTICE_ID,
} from './CreatePatientFormUtils';
import { breakpoints } from '../../../../style/breakpoints';

enum ConsentOptions {
  Approved = 'Approved',
  Declined = 'Declined',
}

const patientPrivacySettingCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',

  '& > div': {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },

  '.button-toggle': {
    label: {
      ...fonts.largeLabel,
      whiteSpace: 'break-spaces',

      [breakpoints.small]: {
        width: '107px',
        minWidth: '107px',
      },

      [breakpoints.large]: {
        width: '148px',
        minWidth: '148px',
      },
    },
    '.buttons-container': {
      height: '40px',
      'button:first-of-type': {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
      },

      'button:last-of-type': {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
      },
    },
  },
};

function PrivacyDialog({
  setIsPrivacyDialogOpen,
  documentId,
  handlePrivacySettingsChange,
  isUpdatingPatientPrivacySettings,
}: {
  setIsPrivacyDialogOpen: (value: boolean) => void;
  documentId: string;
  handlePrivacySettingsChange: (documentId: string) => void;
  isUpdatingPatientPrivacySettings: boolean;
}) {
  return (
    <Dialog
      css={{
        '.MuiDialog-paper': { width: 500, maxWidth: 800 },
        '.MuiDialog-container': { height: '100%' },
      }}
      open={true}
      onClose={() => {
        setIsPrivacyDialogOpen(false);
      }}
    >
      <div
        css={{
          ...card,
          '.loader-background': {
            backgroundColor: `${colors.dividerGrey}80`,
          },
        }}
      >
        <div css={cardHeaderCss}>
          Decline Privacy Consent
          <button type="button">
            <CloseIcon
              width={13}
              onClick={() => setIsPrivacyDialogOpen(false)}
            />
          </button>
        </div>
        <div css={cardBodyCss}>
          {isUpdatingPatientPrivacySettings ? (
            <Loader />
          ) : (
            <>
              <span css={{ ...fonts.largeLabel }}>
                Are you sure you want to decline?
              </span>
              <p>
                Declined privacy settings can only be approved again by the
                patient. Your changes will take effect after clicking Confirm.
              </p>
            </>
          )}
        </div>
        <div css={cardFooterCss}>
          <button
            css={{ ...buttons.secondary }}
            type="button"
            onClick={() => setIsPrivacyDialogOpen(false)}
            disabled={isUpdatingPatientPrivacySettings}
          >
            Cancel
          </button>
          <button
            css={{ ...buttons.primary }}
            type="button"
            onClick={() => {
              handlePrivacySettingsChange(documentId);
              setIsPrivacyDialogOpen(false);
            }}
            disabled={isUpdatingPatientPrivacySettings}
          >
            Confirm
          </button>
        </div>
      </div>
    </Dialog>
  );
}
export default function PatientPrivacySettings({
  patientPrivacySettings,
  handlePrivacySettingsChange,
  mode,
  isUpdatingPatientPrivacySettings,
}: {
  patientPrivacySettings: PatientPrivacySetting[];
  handlePrivacySettingsChange: (documentId: string) => void;
  mode: PatientFormMode;
  isUpdatingPatientPrivacySettings: boolean;
}) {
  const patientPrivacyPolicy = patientPrivacySettings[0];
  const patientHipaaAuthorization = patientPrivacySettings[1];

  const formatDate = useCallback((isoDate: string) => {
    return format(parseISO(isoDate), 'MM/dd/yyyy');
  }, []);

  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] =
    useState<boolean>(false);
  const [documentIdToUpdate, setDocumentIdToUpdate] = useState<string>();

  if (!patientPrivacyPolicy || !patientHipaaAuthorization) {
    return <></>;
  }

  return (
    <>
      <div css={patientPrivacySettingCss}>
        {/* Privacy Policy */}
        <div>
          {(!patientPrivacyPolicy.agree || mode.mode === 'preview') && (
            <DisplayField
              label="Privacy Consent"
              value={
                (patientPrivacyPolicy.agree
                  ? ConsentOptions.Approved
                  : ConsentOptions.Declined) +
                (patientPrivacyPolicy.consent_date
                  ? ` on ${formatDate(patientPrivacyPolicy.consent_date)}`
                  : '')
              }
            />
          )}

          {patientPrivacyPolicy.agree && mode.mode === 'edit' && (
            <>
              <ButtonToggle
                buttons={[
                  {
                    buttonLabel: ConsentOptions.Approved,
                    value: ConsentOptions.Approved,
                    disabled: true,
                  },
                  { buttonLabel: ConsentOptions.Declined, value: '' },
                ]}
                onChange={() => {
                  setIsPrivacyDialogOpen(true);
                  setDocumentIdToUpdate(PRIVACY_NOTICE_ID);
                }}
                value={
                  patientPrivacyPolicy.agree ? ConsentOptions.Approved : ''
                }
                label="Privacy Consent"
              />
              {patientPrivacyPolicy.consent_date && (
                <span>{formatDate(patientPrivacyPolicy.consent_date)}</span>
              )}
            </>
          )}
          {patientPrivacyPolicy.document_version && (
            <span css={{ whiteSpace: 'nowrap' }}>{`Version ${Number(
              patientPrivacyPolicy.document_version,
            ).toFixed(1)}`}</span>
          )}
        </div>

        {/* HIPAA Authorization */}
        <div>
          {(!patientHipaaAuthorization.agree || mode.mode === 'preview') && (
            <DisplayField
              label="HIPAA Authorization"
              value={
                (patientHipaaAuthorization.agree
                  ? ConsentOptions.Approved
                  : ConsentOptions.Declined) +
                (patientHipaaAuthorization.consent_date
                  ? ` on ${formatDate(patientHipaaAuthorization.consent_date)}`
                  : '')
              }
            />
          )}
          {patientHipaaAuthorization.agree && mode.mode === 'edit' && (
            <>
              <ButtonToggle
                buttons={[
                  {
                    buttonLabel: ConsentOptions.Approved,
                    value: ConsentOptions.Approved,
                    disabled: true,
                  },
                  { buttonLabel: 'Declined', value: '' },
                ]}
                onChange={() => {
                  setIsPrivacyDialogOpen(true);
                  setDocumentIdToUpdate(HIPAA_AUTHORIZATION_ID);
                }}
                value={
                  patientHipaaAuthorization.agree ? ConsentOptions.Approved : ''
                }
                label="HIPAA Authorization"
              />
              {patientHipaaAuthorization.consent_date && (
                <span>
                  {formatDate(patientHipaaAuthorization.consent_date)}
                </span>
              )}
            </>
          )}
          {patientHipaaAuthorization.document_version && (
            <span css={{ whiteSpace: 'nowrap' }}>{`Version ${Number(
              patientHipaaAuthorization.document_version,
            ).toFixed(1)}`}</span>
          )}
        </div>
      </div>
      {isPrivacyDialogOpen && documentIdToUpdate && (
        <PrivacyDialog
          setIsPrivacyDialogOpen={setIsPrivacyDialogOpen}
          documentId={documentIdToUpdate}
          handlePrivacySettingsChange={handlePrivacySettingsChange}
          isUpdatingPatientPrivacySettings={isUpdatingPatientPrivacySettings}
        />
      )}
    </>
  );
}
