/** @jsxImportSource @emotion/react */

import { useMemo, useRef, useState, ReactNode } from 'react';
import ArrowCircleIcon from '../../assets/arrowCircle.svg';
import { CSSObject } from '@emotion/react';
import useResizeObserver from '../../hooks/useResizeObserver';

const carouselCss: CSSObject = {
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',

  '& > div.carousel-item': {
    marginRight: '1rem',
    transition: '0.3s ease-in-out transform',
  },
  '& > button': {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '40%',
    transform: 'translateY(-50%)',
    height: '120%',
    width: '20%',
    transformOrigin: 'center center',
    opacity: 0,
    transition: '0.3s ease-in-out opacity',
    pointerEvents: 'none',
  },
  '& > button.back-arrow': {
    left: 0,
    background: `linear-gradient(90deg, rgba(255,255,255,1) 0%,
      rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)`,
    '& > svg': {
      transform: 'rotate(180deg)',
    },
  },
  '& > button.forward-arrow': {
    right: 0,
    background: `linear-gradient(270deg, rgba(255,255,255,1) 0%,
     rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)`,
    justifyContent: 'flex-end',
  },
  '& > button.arrow-visible': {
    opacity: 1,
    '& > svg': {
      pointerEvents: 'auto',
    },
  },
};

type CarouselProps<T> = {
  items: T[];
  renderItem: (item: T) => ReactNode;
};

export default function Carousel<T extends { id: string }>({
  items,
  renderItem,
}: CarouselProps<T>) {
  // Denotes which item of items is currently displayed in the first position
  const [offset, setOffset] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const backArrowRef = useRef<HTMLButtonElement>(null);

  const { clientWidth, scrollWidth } = useResizeObserver(containerRef, 20);

  // Check whether the container is currently overflowing
  const isOverflowing = useMemo(
    () => scrollWidth > clientWidth,
    [clientWidth, scrollWidth],
  );

  const handleForward = () =>
    setOffset((currentOffset) => Math.min(items.length - 1, currentOffset + 1));
  const handleBackward = () =>
    setOffset((currentOffset) => Math.max(0, currentOffset - 1));

  const widthPart = scrollWidth / items.length;
  // Calculate how much to offset the container so that the first entry
  // shown is the one denoted by offset
  const transformOffset =
    -widthPart * offset + (offset > 0 ? widthPart / 2 : 0);
  // Denotes how many parts of the whole can currently fit in the container's visible width
  const partsInView = clientWidth / widthPart;
  const shouldShowForwardArrow = items.length - offset > partsInView;

  return (
    <div css={carouselCss} ref={containerRef} className="carousel-container">
      {items.map((item) => {
        return (
          <div
            css={{
              transform: `translateX(${transformOffset}px)`,
            }}
            className="carousel-item"
            key={item.id}
          >
            {renderItem(item)}
          </div>
        );
      })}
      <button
        className={`back-arrow ${offset > 0 ? 'arrow-visible' : ''}`}
        ref={backArrowRef}
        disabled={!offset}
      >
        <ArrowCircleIcon onClick={handleBackward} width="24px" height="24px" />
      </button>
      <button
        className={`forward-arrow ${
          shouldShowForwardArrow && isOverflowing ? 'arrow-visible' : ''
        }`}
        disabled={!shouldShowForwardArrow || !isOverflowing}
      >
        <ArrowCircleIcon onClick={handleForward} width="24px" height="24px" />
      </button>
    </div>
  );
}
