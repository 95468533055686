import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import httpClient from './httpClient';
import { AWS_REGION, AWS_USER_POOL_WEB_CLIENT_ID } from '../externalConfig';

const client = new CognitoIdentityProviderClient({
  region: AWS_REGION,
});

const unusableQrCodeErrorMessage =
  'An issue has occured and an unusable QR code has been sent to the patient.';

async function saveSessionInDB(userId: string, session: string, patientEmail: string) {
  const data = {
    session_id: session,
    username: patientEmail,
  };

  try {
    await httpClient.post('custom-auth/session', data, {
      headers: { 'x-user-id': userId },
    });
    return;
  } catch (error) {
    throw new Error(
      `An issue has occurred with saving the patient session for login using the QR code: ${error}`,
    );
  }
}

export async function getSecondPartOfOtp(userId: string) {
  try {
    const response = await httpClient.get('custom-auth/auth', {
      headers: { 'x-user-id': userId },
    });
    const secondPartOfOtp = response.data;
    return secondPartOfOtp;
  } catch (error) {
    throw new Error('Failed to retrrieve the second part of the one time password');
  }
}

export async function sendPatientQRCode(patientEmail: string) {
  const AuthFlow = 'CUSTOM_AUTH' as const;
  const params = {
    AuthFlow,
    AuthParameters: {
      USERNAME: patientEmail,
    },
    ClientId: AWS_USER_POOL_WEB_CLIENT_ID,
  };
  try {
    const command = new InitiateAuthCommand(params);
    const userData = await client.send(command);
    if (userData.Session && userData.ChallengeParameters?.user_id) {
      await saveSessionInDB(
        userData.ChallengeParameters.user_id,
        userData.Session,
        patientEmail,
      );
      const secondPartOfOtp = await getSecondPartOfOtp(userData.ChallengeParameters.user_id);
      return {
        fullOtp: userData.ChallengeParameters.otp1 + secondPartOfOtp,
        successMessage: 'QR code has been sent to the patient for faster sign-in.',
      };
    } else {
      throw new Error(unusableQrCodeErrorMessage);
    }
  } catch (error) {
    throw new Error(`An issue has occured with the patient QR code: ${error}`);
  }
}
