/** @jsxImportSource @emotion/react */

import { ReactElement } from 'react';
import { CSSObject } from '@emotion/react';
import { colors } from '../../style/colors';

const tabsContainerCss: CSSObject = {
  display: 'flex',
};

const tabCss: CSSObject = {
  color: colors.blue4,
  fontWeight: 700,

  svg: { color: colors.blue4 },

  '&:hover, &.selected': {
    color: colors.blue2,
    fontWeight: 700,
    svg: { color: colors.blue2 },
  },

  '&.selected': {
    textDecoration: 'underline',
    textDecorationThickness: '3px',
    textUnderlineOffset: '6px',
    textDecorationColor: colors.blue2,
  },

  '&:not(:last-of-type)': {
    marginRight: '2rem',
  },
};

export type Tab = {
  label?: string;
  view?: ReactElement;
  id: string;
  hidden?: boolean;
};

export default function TabsView({
  tabs,
  selectedId,
  onClick,
}: {
  tabs: Tab[];
  selectedId: string;
  onClick: (id: string) => void;
}) {
  return (
    <div className="tabs-container" css={tabsContainerCss}>
      {tabs.map((tab) => {
        let FinalElement: string | ReactElement | null = null;
        if ('label' in tab && tab.label) {
          FinalElement = tab.label;
        } else if ('view' in tab && tab.view) {
          FinalElement = tab.view;
        } else {
          throw new Error('tab config must contain label or element');
        }
        return (
          <button
            key={tab.id}
            className={
              'tab-button' + (selectedId === tab.id ? ' selected' : '')
            }
            css={tabCss}
            onClick={() => onClick(tab.id)}
            hidden={tab.hidden}
          >
            {FinalElement}
          </button>
        );
      })}
    </div>
  );
}
