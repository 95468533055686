/** @jsxImportSource @emotion/react */
import { CSSObject } from '@emotion/react';
import { shapes } from '../../../../style/shapes';
import { SharedInputPropsType } from './treatmentPlanTypes';
import { EditableInputField } from '../../../commons/form/forStateFrom';
import { toDatePickerFormat } from '../../../../utils/dateUtils';
import { useSelector } from 'react-redux';
import { getEditTemplateIdSelector } from '../../../../state-manager/selectors/appSelectors';
import { buttons } from '../../../../style/buttons';
import { useAppDispatch } from '../../../../state-manager/store';
import { cleanEditTemplateIdAction } from '../../../../state-manager/slices/appSlice';

const headerContainerCss: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  gap: 200,
  borderBottom: shapes.border,
  padding: '0 20px',
};

export default function FormHeader({
  sharedInputProps,
}: {
  sharedInputProps: SharedInputPropsType;
}) {
  const dispatch = useAppDispatch();

  const editTemplateId = useSelector(getEditTemplateIdSelector);

  return (
    <div className="form-header" css={headerContainerCss}>
      <EditableInputField {...sharedInputProps} label="Name" name="name" />
      <EditableInputField
        {...sharedInputProps}
        label="Date"
        name="startDate"
        type="date"
        className="side-label"
        min={toDatePickerFormat(new Date())}
      />
      {editTemplateId && (
        <button
          css={{ ...buttons.secondarySmall }}
          type="button"
          onClick={() => {
            dispatch(cleanEditTemplateIdAction());
          }}
        >
          X Edit Template
        </button>
      )}
    </div>
  );
}
