import httpClient from './httpClient';
import { CaseDto } from '../types/backendType';

//Get /case-id/assignment
export async function fetchCases(patientId: string): Promise<CaseDto[] | undefined> {
  try {
    const response = await httpClient.get<CaseDto[]>('/case-id/assignment', {
      headers: { 'x-user-id': patientId },
    });
    const cases = response.data;
    return cases;
  } catch (e) {
    throw new Error(`Failed to fetch user cases: ${e}`);
  }
}

//Post /case-id/assignment
export async function createCase(patientId: string, caseId: string): Promise<CaseDto> {
  try {
    const response = await httpClient.post<CaseDto>(
      '/case-id/assignment',
      { case_id: caseId },
      {
        headers: { 'x-user-id': patientId },
      },
    );
    const caseDto = response.data;
    return caseDto;
  } catch (e) {
    throw new Error(`Failed to create case: ${e}`);
  }
}
