import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UsersState } from '../storeTypes';
import { resetAction } from '../actions/resetAction';
import { UserDto } from '../../types/backendType';

const initialState: UsersState = {
  users: {},
};

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
  reducers: {
    setUsersAction: (draftState: UsersState, action: PayloadAction<{ users: UserDto[] }>) => {
      action.payload.users.forEach((user) => {
        draftState.users[user.user_id] = user;
      });
    },
  },
});

export const { setUsersAction } = usersSlice.actions;

export default usersSlice;
