import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { resetAction } from '../actions/resetAction';
import { LibraryState } from '../storeTypes';
import { ActivityDefinition } from '../../types/library';
import { TreatmentPlanTemplateDto } from '../../types/backendType';

const initialState: LibraryState = {
  activityDefinitions: {},
  treatmentPlanTemplates: {},
};

const librarySlice = createSlice({
  name: 'librarySlice',
  initialState,
  extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
  reducers: {
    deleteTreatmentPlanTemplateFromStoreAction: (
      draftState: LibraryState,
      action: PayloadAction<{ id: string }>,
    ) => {
      const id = action.payload.id;
      delete draftState.treatmentPlanTemplates[id];
    },
    addTreatmentPlanTemplateAction: (
      draftState: LibraryState,
      action: PayloadAction<{ treatmentPlanTemplateDto: TreatmentPlanTemplateDto }>,
    ) => {
      const treatmentPlanTemplateDto = action.payload.treatmentPlanTemplateDto;
      draftState.treatmentPlanTemplates[treatmentPlanTemplateDto.treatment_plan_id] =
        treatmentPlanTemplateDto;
    },
    setTreatmentPlanTemplatesAction: (
      draftState: LibraryState,
      action: PayloadAction<{ treatmentPlanTemplatesDto: TreatmentPlanTemplateDto[] }>,
    ) => {
      draftState.treatmentPlanTemplates = {};
      action.payload.treatmentPlanTemplatesDto.forEach((treatmentPlanTemplate) => {
        if (!draftState.treatmentPlanTemplates[treatmentPlanTemplate.treatment_plan_id]) {
          draftState.treatmentPlanTemplates[treatmentPlanTemplate.treatment_plan_id] =
            treatmentPlanTemplate;
        }
      });
    },
    setActivityDefinitionsAction: (
      draftState: LibraryState,
      action: PayloadAction<{ activityDefinitions: ActivityDefinition[] }>,
    ) => {
      action.payload.activityDefinitions.forEach((activityDefinition) => {
        if (!draftState.activityDefinitions[activityDefinition.id]) {
          draftState.activityDefinitions[activityDefinition.id] = activityDefinition;
        }
      });
    },
  },
});

export const {
  setTreatmentPlanTemplatesAction,
  setActivityDefinitionsAction,
  deleteTreatmentPlanTemplateFromStoreAction,
  addTreatmentPlanTemplateAction,
} = librarySlice.actions;

export default librarySlice;
