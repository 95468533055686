import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export default function useWindowDimensions() {
  const [dimensions, setDimensions] = useState([0, 0]);

  useEffect(() => {
    setDimensions([window.innerWidth, window.innerHeight]);

    const handler = debounce(() => {
      setDimensions([window.innerWidth, window.innerHeight]);
    }, 100);

    window.addEventListener('resize', handler, {
      passive: true,
    });
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);
  return dimensions;
}
